import ApiUrls from "../../../common/api-urls";
import { IGetAllRequestModel } from "../../../common/model/get-all-request-model";
import HttpService from "../../../common/service/http.service";
import { IClientAreaModel } from "../model/client-area.model";

export default class ClientAreaService {

    static async LoadAll(request?: IGetAllRequestModel): Promise<IClientAreaModel[]> {
        return await HttpService.Get<IClientAreaModel[]>(ApiUrls.ClientArea.GetAll());
    }

    static async LoadAllByClientId(id: number): Promise<IClientAreaModel[]> {
        return await HttpService.Get<IClientAreaModel[]>(ApiUrls.ClientArea.GetAllByClientId(id));
    }

    static async Create(data: IClientAreaModel): Promise<IClientAreaModel> {
        return await HttpService.Post<IClientAreaModel, IClientAreaModel>(ApiUrls.ClientArea.Create(), data);
    }

    static async Edit(data: IClientAreaModel): Promise<IClientAreaModel> {
        return await HttpService.Patch<IClientAreaModel, IClientAreaModel>(ApiUrls.ClientArea.Edit(data.id), data);
    }
    static async Delete(id: number) {
        await HttpService.Delete(ApiUrls.ClientArea.Delete(id));
    }

    static async GetById(id: number): Promise<IClientAreaModel> {
        return await HttpService.Get<IClientAreaModel>(ApiUrls.ClientArea.GetById(id));
    }

}
