import { ZoneClassificationType } from "../../../../../common/enums";
import { clientAddressDefault, clientDefault } from "../../../../client/model/client.default";
import { IBaseCleanroomAreaTestModel, ICleanroomAreaModel } from "../../report.model";

export const CleanroomAreaDefault : ICleanroomAreaModel = {
    roomName:"",
    roomNumber:"",
    clientId:0,
    client:clientDefault,
    clientArea:null,
    clientAddress:clientAddressDefault,
    clientAddressId:0,
    clientAreaId:0,
    reportCategoryId:0,
    downstreamAcceptanceCriteria:0,
    zoneClassificationType:ZoneClassificationType.EUGMP,
    filterType:"",
};

export const BaseCleanroomAreaTestDefault:IBaseCleanroomAreaTestModel={
    cleanroomAreaReportId:0,
    dataLogger:null,
    dataLoggerId:0,
    dateOfTest:"",
    dueDate: "",
    remarks:"",
    preparedById:0,
    preparedOnDate:"",
    checkedOnDate:"",
    verifiedByName:"",
    verifiedOnDate:"",
}