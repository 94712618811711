import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType, ZoneClassificationType } from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "dateOfTest",
        rules: [
            {
                type: ValidationType.Required,
                message: "Date of test is required"
            }
        ]
    },
    {
        fieldName: "preparedOnDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "Prepared on date is required",
            },
            {
                type: ValidationType.DateShouldBeLessThenCurrentDate,
                message: "Prepared on date should be less than current date"
            }
        ]
    },
    {
        fieldName: "preparedById",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Prepared by user is required",
            },
        ]
    },
    {
        fieldName: "dataLoggerId",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Datalogger is required",
            },
        ]
    },
    {
        fieldName: "consideredParticleSizes",
        rules: [
            {
                type: ValidationType.Required,
                message: "ConsideredParticleSizes is required",
            },
        ]
    },
]

export const particleCountTestValidator: IValidatorModel = {
    screen: ValidationScreens.ParticleCountTest,
    fields: fields
}
