import { createAsyncThunk, createSlice, isFulfilled, PayloadAction } from "@reduxjs/toolkit";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IAirChangesPerHourTestObservationModel } from "../../model/cleanroom-area/test/air-changes-per-hour-test.model";
import CleanroomAreaObservationService from "../../service/cleanroom-area/observation.service";
import { airChangesPerHourTestObservationDefault } from "../../model/cleanroom-area/defaults/test.default";
import CleanroomAreaReadingService from "../../service/cleanroom-area/reading.service";
import { ICleanroomAreaFilterIntegrityTestObservationModel } from "../../model/cleanroom-area/test/filter-integrity-test.model";
import { ICleanroomAreaNonViableParticleCountTestObservationModel } from "../../model/cleanroom-area/test/non-viable-particle-count-test.model";
import { ICleanroomAreaRecoveryReading } from "../../model/cleanroom-area/test/recovery-test.model";
import { ICleanroomAreaAirflowPatternTestObservationModel } from "../../model/cleanroom-area/test/airflow-pattern-test.model";
import { ICleanroomAreaSoundLevelTestObservationModel } from "../../model/cleanroom-area/test/sound-level-test-model";
import { ICleanroomAreaLightIntensityTestObservationModel } from "../../model/cleanroom-area/test/light-intensity-test.model";
import { ICleanroomAreaUVLightIntensityTestObservationModel } from "../../model/cleanroom-area/test/uv-light-intensity-test.model";
import { ICleanroomAreaDifferentialPressureTestRoomObservationModel } from "../../model/cleanroom-area/test/differential-pressure-test.model";

// -- ACPH
export const loadACPHObservationById = createAsyncThunk("report/area/observation/acph/loadById", CleanroomAreaObservationService.GetACPHObservationById);
export const createACPHObservation = createAsyncThunk("report/area/observation/acph/create", CleanroomAreaObservationService.CreateACPHObservation);
export const editACPHObservation = createAsyncThunk("report/area/observation/acph/edit", CleanroomAreaObservationService.EditACPHObservation);

// -- ACPH REading
export const createACPHReading = createAsyncThunk("report/area/observation/acph/reading/create", CleanroomAreaReadingService.CreateACPHReading);
export const editACPHReading = createAsyncThunk("report/area/observation/acph/reading/edit", CleanroomAreaReadingService.EditACPHReading);

// -- Filter Integrity --
export const loadFIObservationById = createAsyncThunk("report/area/observation/fi/loadById", CleanroomAreaObservationService.GetFIObservationById);
export const createFIObservation = createAsyncThunk("report/area/observation/fi/create", CleanroomAreaObservationService.CreateFIObservation);
export const editFIObservation = createAsyncThunk("report/area/observation/fi/edit", CleanroomAreaObservationService.EditFIObservation);

// --- common ----
export const deleteReading = createAsyncThunk("report/area/observation/reading/delete", CleanroomAreaReadingService.DeleteReading);
export const deleteObservation = createAsyncThunk("report/observation/delete", CleanroomAreaObservationService.DeleteObservation);
// ---- ----

// -- NVPC --
export const loadNVPCLocationById = createAsyncThunk("report/area/location/nvpc/loadById", CleanroomAreaObservationService.GetNVPCLocationById);
export const createNVPCLocation = createAsyncThunk("report/area/location/nvpc/create", CleanroomAreaObservationService.CreateNVPCLocation);
export const editNVPCLocation = createAsyncThunk("report/area/location/nvpc/edit", CleanroomAreaObservationService.EditNVPCLocation);
export const deleteNVPCLocation = createAsyncThunk("report/area/location/nvpc/delete", CleanroomAreaObservationService.DeleteNVPCLocation);

// --- NVPC Observation
export const loadNVPCObservationById = createAsyncThunk("report/area/location/observation/nvpc/loadById", CleanroomAreaReadingService.GetNVPCObservationById);
export const createNVPCObservation = createAsyncThunk("report/area/location/observation/nvpc/create", CleanroomAreaReadingService.CreateNVPCObservation);
export const editNVPCObservation = createAsyncThunk("report/area/location/observation/nvpc/edit", CleanroomAreaReadingService.EditNVPCObservation);
export const deleteNVPCObservation = createAsyncThunk("report/area/location/observation/delete", CleanroomAreaReadingService.DeleteNVPCObservation);

export const loadCleanroomAreaRecoveryReadingById = createAsyncThunk("report/area/recovery/reading/loadById", CleanroomAreaObservationService.GetRecoveryReadingById);
export const createCleanroomAreaRecoveryReading = createAsyncThunk("report/area/recovery/reading/create", CleanroomAreaObservationService.CreateRecoveryReading);
export const editCleanroomAreaRecoveryReading = createAsyncThunk("report/area/recovery/reading/edit", CleanroomAreaObservationService.EditRecoveryReading);
export const deleteCleanroomAreaRecoveryReading = createAsyncThunk("report/area/recovery/reading/delete", CleanroomAreaObservationService.DeleteRecoveryReading);

export const loadAFPTObservationById = createAsyncThunk("report/area/observation/afpt/loadById", CleanroomAreaObservationService.GetAFPTObservationById);
export const createAFPTObservation = createAsyncThunk("report/area/observation/afpt/create", CleanroomAreaObservationService.CreateAFPTObservation);
export const editAFPTObservation = createAsyncThunk("report/area/observation/afpt/edit", CleanroomAreaObservationService.EditAFPTObservation);

export const loadAFPTObservationSmokeFlow= createAsyncThunk("report/area/smokeflow/observation/afpt/loadById", CleanroomAreaReadingService.GetAFPTSmokeFlowById);
export const createAFPTObservationSmokeFlow = createAsyncThunk("report/area/smokeflow/observation/afpt/create", CleanroomAreaReadingService.CreateAFPTSmokeFlow);
export const editAFPTObservationSmokeFlow = createAsyncThunk("report/area/smokeflow/observation/afpt/edit", CleanroomAreaReadingService.EditAFPTSmokeFlow);
export const deleteAFPTObservationSmokeFlow= createAsyncThunk("report/area/smokeflow/observation/afpt/delete", CleanroomAreaReadingService.DeleteAFPTSmokeFlow);

export const loadSLObservationById = createAsyncThunk("report/area/observation/sl/loadById", CleanroomAreaObservationService.GetSLObservationById);
export const createSLObservation = createAsyncThunk("report/area/observation/sl/create", CleanroomAreaObservationService.CreateSLObservation);
export const editSLObservation = createAsyncThunk("report/area/observation/sl/edit", CleanroomAreaObservationService.EditSLObservation);

export const loadSLReadingById = createAsyncThunk("report/area/observation/sl/reading/loadById", CleanroomAreaReadingService.GetSLReadingById);
export const createSLReading = createAsyncThunk("report/area/observation/sl/reading/create",CleanroomAreaReadingService.CreateSLReading);
export const editSLReading = createAsyncThunk("report/area/observation/sl/reading/edit", CleanroomAreaReadingService.EditSLReading);

export const loadLIObservationById = createAsyncThunk("report/area/observation/li/loadById", CleanroomAreaObservationService.GetLIObservationById);
export const createLIObservation = createAsyncThunk("report/area/observation/li/create", CleanroomAreaObservationService.CreateLIObservation);
export const editLIObservation = createAsyncThunk("report/area/observation/li/edit", CleanroomAreaObservationService.EditLIObservation);

export const loadLIReadingById = createAsyncThunk("report/area/observation/li/reading/loadById", CleanroomAreaReadingService.GetLIReadingById);
export const createLIReading = createAsyncThunk("report/area/observation/li/reading/create",CleanroomAreaReadingService.CreateLIReading);
export const editLIReading = createAsyncThunk("report/area/observation/li/reading/edit", CleanroomAreaReadingService.EditLIReading);

export const loadUVLIObservationById = createAsyncThunk("report/area/observation/uvli/loadById", CleanroomAreaObservationService.GetUVLIObservationById);
export const createUVLIObservation = createAsyncThunk("report/area/observation/uvli/create", CleanroomAreaObservationService.CreateUVLIObservation);
export const editUVLIObservation = createAsyncThunk("report/area/observation/uvli/edit", CleanroomAreaObservationService.EditUVLIObservation);

export const loadUVLIReadingById = createAsyncThunk("report/area/observation/uvli/reading/loadById", CleanroomAreaReadingService.GetUVLIReadingById);
export const createUVLIReading = createAsyncThunk("report/area/observation/uvli/reading/create",CleanroomAreaReadingService.CreateUVLIReading);
export const editUVLIReading = createAsyncThunk("report/area/observation/uvli/reading/edit", CleanroomAreaReadingService.EditUVLIReading);

export const loadDFObservationById = createAsyncThunk("report/area/observation/df/loadById", CleanroomAreaObservationService.GetDFObservationById);
export const createDFObservation = createAsyncThunk("report/area/observation/df/create",CleanroomAreaObservationService.CreateDFObservation);
export const editDFObservation = createAsyncThunk("report/area/observation/df/edit", CleanroomAreaObservationService.EditDFObservation);
export const DeleteDFObservation = createAsyncThunk("report/area/observation/df/delete", CleanroomAreaObservationService.DeleteDFObservation);

export interface ICleanroomAreaObservationState {
    observationList: IAirChangesPerHourTestObservationModel[],
    observationData: IAirChangesPerHourTestObservationModel | ICleanroomAreaFilterIntegrityTestObservationModel | ICleanroomAreaNonViableParticleCountTestObservationModel | ICleanroomAreaRecoveryReading | ICleanroomAreaAirflowPatternTestObservationModel | ICleanroomAreaSoundLevelTestObservationModel | ICleanroomAreaLightIntensityTestObservationModel | ICleanroomAreaUVLightIntensityTestObservationModel | ICleanroomAreaDifferentialPressureTestRoomObservationModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
    stageMoveLoading: boolean,
    deletedRoomId: number,
}

const initialState: ICleanroomAreaObservationState = {
    observationList: [],
    observationData: airChangesPerHourTestObservationDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    stageMoveLoading: false,
    deletedRoomId: -1,
}

const cleanroomAreaObservationSlice = createSlice({
    name: 'cleanroom-area-observation',
    initialState,
    reducers: {
        clearObservationDataState: (state, action) => { state.observationData = action.payload },
        updateObservationDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.observationData[action.payload.name] = action.payload.value;
        },
        setDeletdRoomId: (state, action: PayloadAction<number>) => {
            state.deletedRoomId = action.payload;
        }
    },
    extraReducers: (builder) => {

        // load, create
        builder.addMatcher(isFulfilled(createACPHObservation, loadACPHObservationById,createFIObservation,loadFIObservationById,loadCleanroomAreaRecoveryReadingById,createCleanroomAreaRecoveryReading,loadSLObservationById,createSLObservation,createLIObservation,loadLIObservationById,createUVLIObservation,editUVLIObservation,createDFObservation,loadDFObservationById), (state, action) => {
            state.observationData = action.payload;
            state.createEditLoading = false;
        });

        // edit
        builder.addMatcher(isFulfilled(editACPHObservation,editFIObservation,editCleanroomAreaRecoveryReading,editSLObservation,editLIObservation,editUVLIObservation,editDFObservation), (state, action) => {
            state.observationData = action.payload;
        });

    }
});

const { actions, reducer } = cleanroomAreaObservationSlice;

export const { clearObservationDataState, updateObservationDataState, setDeletdRoomId } = actions;

export default reducer;