import {
    IFieldValidationRequestModel,
    IFieldValidationResponseModel,
    IValidatorModel
} from "../model/validator.model";
import { ValidationScreens, ValidationType } from "../enums";

import { loginValidator } from "../validator/login.validator";
import { defaultFieldValidationResponse, defaultValidator } from "../model/defaults/validator.defaults";
import { clientValidator } from "../../features/client/validator/client.validator";
import { clientAddressValidator } from "../../features/client/validator/client-address.validator";
import { passwordChangeValidator } from "../../features/user/validator/password-change.validator";
import { userValidator } from "../../features/user/validator/user.validator";
import { accessValidator } from "../../features/access/validator/access.validator";
import { dataLoggerCategoryValidator } from "../validator/data-logger-category.validator";
import { dataLoggerValidator } from "../../features/data-logger/validator/data-logger.validator";
import { dataLoggerMakeValidator } from "../../features/data-logger/validator/data-logger-make.validator";
import { purchaseOrderBasicValidator } from "../../features/purchase-order/purchase-order/purchase-order-basic.validator";
import { purchaseOrderWithPOItemValidator } from "../../features/purchase-order/purchase-order/purchase-order-with-po-item.validator";
import { purchaseOrderLineItemValidator } from "../../features/purchase-order/purchase-order/purchase-order-line-item.validator";
import { purchaseOrderServiceItemValidator } from "../../features/purchase-order/purchase-order/purchase-order-service-item.validator";
import { workflowSalesDataEntryStageValidator } from "../../features/workflow/validator/stages/1-sales-data-entry.workflow.validator";
import { workflowSalesReviewStageValidator } from "../../features/workflow/validator/stages/2-sales-review.workflow.validator";
import { workflowTasksStageValidator } from "../../features/workflow/validator/stages/4-tasks.workflow.validator";
import { workflowClosureStageValidator } from "../../features/workflow/validator/stages/5-closure.workflow.validator";
import { workflowTaskCreationStageValidator } from "../../features/workflow-task/validator/stages/1-creation-workflow-task.validator";
import { workflowTaskBookingStageValidator } from "../../features/workflow-task/validator/stages/3-booking-workflow-task.validator";
import { workflowTaskSiteActivityStageValidator } from "../../features/workflow-task/validator/stages/4-site-activity-workflow-task.validator";
import { workflowTaskDocumentReviewStageValidator } from "../../features/workflow-task/validator/stages/5-operations-review-workflow-task.validator";
import { workflowTaskReportingStageValidator, } from "../../features/workflow-task/validator/stages/7-reporting-workflow-task.validator";
import { workflowTaskReportReviewStageValidator } from "../../features/workflow-task/validator/stages/8-report-review-workflow-task.validator";
import { workflowTaskQualityAssuranceStageValidator } from "../../features/workflow-task/validator/stages/10-quality-assurance-review-workflow-task.validator";
import { workflowTaskClientReviewStageValidator } from "../../features/workflow-task/validator/stages/11-client-review-workflow-task.validator";
import { workflowTaskReportDeliveryStageValidator } from "../../features/workflow-task/validator/stages/12-report-delivery-workflow-task.validator";
import { workflowActivityPlaningStageValidator } from "../../features/workflow-task/validator/stages/2.activity-planning-task.validator";
import { workflowTaskInvoicingStageValidator } from "../../features/workflow-task/validator/stages/13-invoicing-workflow-task.validator";
import { clientEquipmentValidator } from "../../features/client/validator/client-equipment.validator";
import { cleanroomEquipmentValidator } from "../../features/report/validator/cleanroom-equipment/cleanroom-equipment.validator";
import { airflowVelocityTestValidator } from "../../features/report/validator/cleanroom-equipment/test/airflow-velocity-test/airflow-velocity-test.validator";
import { airflowVelocityTestObservationValidator } from "../../features/report/validator/cleanroom-equipment/test/airflow-velocity-test/airflow-velocity-test-observation.validator";
import { filterIntegrityTestValidator } from "../../features/report/validator/cleanroom-equipment/test/filter-integrity/filter-integrity-test.validator";
import { filterIntegrityTestObservationValidator } from "../../features/report/validator/cleanroom-equipment/test/filter-integrity/filter-integrity-test-observation";
import { particleCountTestValidator } from "../../features/report/validator/cleanroom-equipment/test/nvpc-test/nvpc-test.validator";
import { particleCountTestObservationValidator } from "../../features/report/validator/cleanroom-equipment/test/nvpc-test/nvpc-test-observation.validator";
import { lightIntensityTestValidator } from "../../features/report/validator/cleanroom-equipment/test/light-intensity-test/light-intensity-test.validator";
import { lightIntensityTestObservationValidator } from "../../features/report/validator/cleanroom-equipment/test/light-intensity-test/light-intensity-test-observation.validator";
import { soundLevelTestValidator } from "../../features/report/validator/cleanroom-equipment/test/sound-level-test/sound-level-test.validator";
import { soundLevelTestObservationValidator } from "../../features/report/validator/cleanroom-equipment/test/sound-level-test/sound-level-test-observation.validator";
import moment from "moment/moment";
import { fullDisplayDate } from "../utils";
import { recoveryTestValidator } from "../../features/report/validator/cleanroom-equipment/test/recovery-test/recovery-test.validator";
import { recoveryTestObservationValidator } from "../../features/report/validator/cleanroom-equipment/test/recovery-test/recovery-test-observation.validator";
import { uvliTestValidator } from "../../features/report/validator/cleanroom-equipment/test/uv-light-intensity-test/uv-light-intensity-test.validator";
import { uvliTestObservationValidator } from "../../features/report/validator/cleanroom-equipment/test/uv-light-intensity-test/uv-light-test-observation.validator";
import { airflowPatternTestValidator } from "../../features/report/validator/cleanroom-equipment/test/airflow-pattern-test/airflow-pattern-test.validator";
import { dataLoggerCameraDetailValidator } from "../../features/data-logger/validator/data-logger-camera-detail.validator";
import {clientAreaValidator} from "../../features/client/validator/client-area.validator";
import {cleanroomAreaValidator} from "../../features/report/validator/cleanroom-area/cleanroom-area.validator";
import { airChangesPerHourTestDefault, airChangesPerHourTestObservationDefault, CleanroomAreaNonViableParticleCountTestDefault } from "../../features/report/model/cleanroom-area/defaults/test.default";
import { airChangesPerHourTestValidator } from "../../features/report/validator/cleanroom-area/tests/air-changes-per-hour-test/air-changes-per-hour-test.validator";
import { airChangesPerHourTestObservationValidator } from "../../features/report/validator/cleanroom-area/tests/air-changes-per-hour-test/air-changes-per-hour-test-observation.validator";
import { cleanroomAreaFilterIntegrityTestObservationValidator } from "../../features/report/validator/cleanroom-area/tests/filter-integrity-test/FI-test-observation.validator";
import { cleanroomAreaFilterintegrityTestValidator } from "../../features/report/validator/cleanroom-area/tests/filter-integrity-test/filter-integrity-test-create-edit-page.validator";
import { cleanroomAreaParticleCountTestValidator } from "../../features/report/validator/cleanroom-area/tests/non-viable-particle-count-test/nvpc-test.validator";
import { CleanroomAreaNonViableParticleCountTestObservationValidator } from "../../features/report/validator/cleanroom-area/tests/non-viable-particle-count-test/nvpc-test-observation.validator";
import { cleanroomAreaAirflowPatternTestObservationValidator } from "../../features/report/validator/cleanroom-area/tests/air-flow-patter-test/airflow-pattern-test-observation-validator";
import { cleanroomAreaDifferentialPressureTestObservationValidator } from "../../features/report/validator/cleanroom-area/tests/differential-pressure/differential-pressure-test-observation-validator";
import { cleanroomAreaDifferentialPressureTestValidator } from "../../features/report/validator/cleanroom-area/tests/differential-pressure/differential-pressure-test.validator";

export default class ValidatorHelper {

    static GetValidator(screen: ValidationScreens): IValidatorModel {
        switch (screen) {
            case ValidationScreens.Login: return loginValidator;

            case ValidationScreens.AccessRole: return accessValidator;
            case ValidationScreens.Client: return clientValidator;
            case ValidationScreens.ClientAddress: return clientAddressValidator;
            case ValidationScreens.ClientEquipment: return clientEquipmentValidator;
            case ValidationScreens.ClientArea: return clientAreaValidator;
            case ValidationScreens.DataLogger: return dataLoggerValidator;
            case ValidationScreens.DataLoggerMake: return dataLoggerMakeValidator;
            case ValidationScreens.DataLoggerCameraDetail: return dataLoggerCameraDetailValidator;
            case ValidationScreens.DataLoggerCategory: return dataLoggerCategoryValidator;
            case ValidationScreens.PurchaseOrderBasic: return purchaseOrderBasicValidator;
            case ValidationScreens.PurchaseOrderWithPOItem: return purchaseOrderWithPOItemValidator;
            case ValidationScreens.PurchaseOrderLineItem: return purchaseOrderLineItemValidator;
            case ValidationScreens.PurchaseOrderServiceItem: return purchaseOrderServiceItemValidator;
            case ValidationScreens.User: return userValidator;
            case ValidationScreens.UserPasswordChange: return passwordChangeValidator;

            // Workflow
            case ValidationScreens.WorkflowSalesDataEntryStage: return workflowSalesDataEntryStageValidator;
            case ValidationScreens.WorkflowSalesReviewStage: return workflowSalesReviewStageValidator;
            case ValidationScreens.WorkflowTasksStage: return workflowTasksStageValidator;
            case ValidationScreens.WorkflowClosureStage: return workflowClosureStageValidator;

            // Workflow Task
            case ValidationScreens.WorkflowTaskCreationStage: return workflowTaskCreationStageValidator;
            case ValidationScreens.WorkflowTaskActivityPlaningStage: return workflowActivityPlaningStageValidator;
            case ValidationScreens.WorkflowTaskBookingStage: return workflowTaskBookingStageValidator;
            case ValidationScreens.WorkflowTaskSiteActivityStage: return workflowTaskSiteActivityStageValidator;
            case ValidationScreens.WorkflowTaskDocumentReviewStage: return workflowTaskDocumentReviewStageValidator;
            case ValidationScreens.WorkflowTaskReportingStage: return workflowTaskReportingStageValidator;
            case ValidationScreens.WorkflowTaskReportReviewStage: return workflowTaskReportReviewStageValidator;
            case ValidationScreens.WorkflowTaskQualityAssuranceStage: return workflowTaskQualityAssuranceStageValidator;
            case ValidationScreens.WorkflowTaskClientReviewStage: return workflowTaskClientReviewStageValidator;
            case ValidationScreens.WorkflowTaskReportDeliveryStage: return workflowTaskReportDeliveryStageValidator;
            case ValidationScreens.WorkflowTaskInvoicingStage: return workflowTaskInvoicingStageValidator;

            // Report -> cleanroom equipment
            case ValidationScreens.ReportCleanroomEquipment: return cleanroomEquipmentValidator;
            case ValidationScreens.AirflowVelocityTest: return airflowVelocityTestValidator;
            case ValidationScreens.AirflowVelocityTestObservation: return airflowVelocityTestObservationValidator;
            case ValidationScreens.FilterIntegrityTest: return filterIntegrityTestValidator;
            case ValidationScreens.FilterIntegrityTestObservation: return filterIntegrityTestObservationValidator;
            case ValidationScreens.ParticleCountTest: return particleCountTestValidator;
            case ValidationScreens.ParticleCountTestObservation: return particleCountTestObservationValidator;
            case ValidationScreens.LightIntensityTest: return lightIntensityTestValidator;
            case ValidationScreens.LightIntensityTestObservation: return lightIntensityTestObservationValidator;
            case ValidationScreens.RecoveryTest: return recoveryTestValidator;
            case ValidationScreens.RecoveryTestObservation: return recoveryTestObservationValidator;
            case ValidationScreens.SoundlevelTest: return soundLevelTestValidator;
            case ValidationScreens.SoundLevelTestObservation: return soundLevelTestObservationValidator;
            case ValidationScreens.UVLightIntensityTest: return uvliTestValidator;
            case ValidationScreens.UVLightIntensityTestObservation: return uvliTestObservationValidator;
            case ValidationScreens.airflowPatternTest: return airflowPatternTestValidator;
            
            // --- area 
            case ValidationScreens.ReportCleanroomArea: return cleanroomAreaValidator;
            case ValidationScreens.CleanroomAreaAirChangesPerHourTest: return airChangesPerHourTestValidator;
            case ValidationScreens.CleanroomAreaAirChangesPerHourTestObservation: return airChangesPerHourTestObservationValidator;
            case ValidationScreens.CleanroomAreaFilterIntegrityTest: return cleanroomAreaFilterintegrityTestValidator;
            case ValidationScreens.CleanroomAreaFilterIntegrityTestObservation: return cleanroomAreaFilterIntegrityTestObservationValidator;
            case ValidationScreens.CleanroomAreaNonViableParticleCountTest: return cleanroomAreaParticleCountTestValidator;
            case ValidationScreens.CleanroomAreaNonViableParticleCountTestObservation: return CleanroomAreaNonViableParticleCountTestObservationValidator;
            case ValidationScreens.CleanroomAreaAirFlowPatternTestObservation: return cleanroomAreaAirflowPatternTestObservationValidator;

            case ValidationScreens.CleanroomAreaDifferentialPressureTest: return cleanroomAreaDifferentialPressureTestValidator;
            case ValidationScreens.CleanroomAreaDifferentialPressureTestObservation: return cleanroomAreaDifferentialPressureTestObservationValidator;

            default: return defaultValidator;
        }
    }

    static GetField = (fieldName: string, results: IFieldValidationResponseModel[]): IFieldValidationResponseModel => {
        const index = results == null ? -1 : results.findIndex(r => r.fieldName === fieldName);
        if (index === -1) return defaultFieldValidationResponse;
        return results[index];
    };

    static ValidateAll(validatorData: IValidatorModel, formData: any, dependencies?: any): IFieldValidationResponseModel[] {
        if (validatorData.fields.length === 0) return [];
        let result: IFieldValidationResponseModel[] = [];
        for (let i = 0; i < validatorData.fields.length; i++) {
            const f = validatorData.fields[i];

            const payload: IFieldValidationRequestModel = {
                fieldName: f.fieldName,
                value: this.fetchValue(formData, f.fieldName)
            };
            const dependencyValue = f.dependencyFieldName ? this.fetchValue(dependencies, f.dependencyFieldName) : undefined;
            const validateAfterFieldIsSet = f.validateIfFieldIsSet ? this.fetchValue(dependencies, f.validateIfFieldIsSet) : undefined;
            const fieldResult = this.ValidateField(validatorData, payload, dependencyValue, validateAfterFieldIsSet ?? undefined, f?.callBack ?? undefined);
            result.push(fieldResult);
        }

        return result;
    }

    static ValidateField(validatorData: IValidatorModel, payload: IFieldValidationRequestModel, dependenceFieldValue?: any, validateIfFieldIsSet?: any, callBack?: any): IFieldValidationResponseModel {
        if (validatorData.fields.length === 0 || (validateIfFieldIsSet != undefined && !validateIfFieldIsSet)) return defaultFieldValidationResponse;
        const fields = validatorData.fields.filter(f => f.fieldName === payload.fieldName);
        if (fields == null || fields.length === 0) return defaultFieldValidationResponse;
        const fieldData = fields[0];

        if (fieldData == null || fieldData.rules.length === 0) return defaultFieldValidationResponse;

        let result: IFieldValidationResponseModel = {
            ...defaultFieldValidationResponse,
            fieldName: fieldData.fieldName
        };

        for (let i = 0; i < fieldData.rules.length; i++) {
            const r = fieldData.rules[i];
            switch (r.type) {
                case ValidationType.IsOptional: {
                    if (!payload.value) {
                        result.className = "";
                        result.message = "";
                        result.failed = false;
                        return result;
                    }
                    break;
                }
                case ValidationType.Required: {
                    if (!this.RequiredCheck(payload.value)) {
                        result.className = "error";
                        result.message = r.message;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.NumberOnly: {
                    if (!this.NumberCheck(payload.value)) {
                        result.className = "error";
                        result.message = r.message;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.NumberGreaterThanZero: {
                    if (!this.NumberGreaterThanZeroCheck(payload.value)) {
                        result.className = "error";
                        result.message = r.message;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.ListGreaterThanZero: {
                    if (!this.ListGreaterThanZeroCheck(payload.value)) {
                        result.className = "error";
                        result.message = r.message;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.TasksCompleted: {
                    if (payload.value?.length === 0) {
                        result.className = "error";
                        result.message = r.message;
                        result.failed = true;
                        return result;
                    }
                    for (let i = 0; i < payload.value.length; i++) {
                        if (!this.IsComplete(payload.value[i])) {
                            result.className = "error";
                            result.message = r.message;
                            result.failed = true;
                            return result;
                        }
                    }
                    break;
                }
                case ValidationType.RequiredIfTrue: {
                    if (!payload.value) {
                        result.className = "error";
                        result.message = r.message;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.DateShouldBeGreaterThenCurrentDate: {
                    const payloadVal = moment.utc(payload.value);
                    const currentDate = moment.utc(new Date());
                    if (payloadVal.isSameOrBefore(currentDate)) {
                        result.className = "error";
                        result.message = `${r.message} (i.e. ${fullDisplayDate(currentDate.toString())})`;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.DateShouldBeLessThenCurrentDate: {
                    const payloadVal = moment.utc(payload.value);
                    const currentDate = moment.utc(new Date());

                    if (payloadVal.isSameOrAfter(currentDate)) {
                        result.className = "error";
                        result.message = `${r.message} (i.e. ${fullDisplayDate(currentDate.toString())})`;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.DateShouldBeLessThenDependentDate: {
                    const payloadVal = moment.utc(payload.value);
                    const dependenceVal = moment.utc(dependenceFieldValue);

                    if (payloadVal.isSameOrAfter(dependenceVal)) {
                        result.className = "error";
                        result.message = `${r.message} (i.e. ${fullDisplayDate(dependenceVal.toString())})`;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.DateShouldBeGreaterDependentDate: {

                    const payloadVal = moment.utc(payload.value);
                    const dependenceVal = moment.utc(dependenceFieldValue);
                    if (payloadVal.isSameOrBefore(dependenceVal)) {
                        result.className = "error";
                        result.message = `${r.message} (i.e. ${fullDisplayDate(dependenceVal.toString())})`;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.IsEmail: {

                    const payloadVal = payload.value;

                    if (!payloadVal.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/)) {
                        result.className = "error";
                        result.message = `${r.message}`;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.IsMobileNo: {

                    const payloadVal = payload.value;

                    if (!payloadVal.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
                        result.className = "error";
                        result.message = `${r.message}`;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.IsPanCardNumber: {

                    const payloadVal = payload.value;
                    let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);
                    if (!regex.test(payloadVal)) {
                        result.className = "error";
                        result.message = `${r.message}`;
                        result.failed = true;
                        return result;
                    }
                    break;
                }
                case ValidationType.IsValidGSTIN: {
                    const payloadVal = payload.value;
                    let regex = new RegExp(/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/);
                    if (!regex.test(payloadVal)) {
                        result.className = "error";
                        result.message = `${r.message}`;
                        result.failed = true;
                        return result;
                    }
                    break;
                }

                case ValidationType.ValueShouldBeSame: {
                    if (payload.value != dependenceFieldValue) {
                        result.className = "error";
                        result.message = `${r.message}`;
                        result.failed = true;
                        return result;
                    }

                    break;
                }

                case ValidationType.ValueShouldNotBeSame: {
                    if (payload.value == dependenceFieldValue) {
                        result.className = "error";
                        result.message = `${r.message}`;
                        result.failed = true;
                        return result;
                    }
                    break;
                }

                case ValidationType.ValidateIfCallbackIsTrue: {
                    if (callBack && callBack(payload.value, dependenceFieldValue)) {
                        break;
                    }
                    return defaultFieldValidationResponse;
                }

            }
        }

        return result;
    }

    private static RequiredCheck(value: any): boolean {
        if (Array.isArray(value)) {
            return value?.length > 0;
        }
        else if (!(typeof (value) == "string")) {
            return (value != null && value !== "");
        }

        return value != null && value !== "" && value != undefined && value?.trimStart()?.length > 0;
    }

    private static NumberCheck(value: any): boolean {
        return value != null && !isNaN(Number(value));
    }

    private static NumberGreaterThanZeroCheck(value: any): boolean {
        return value != null && !isNaN(Number(value)) && Number(value) > 0;
    }

    private static IsComplete(value: any): boolean {
        return value != null && value?.isComplete;
    }

    private static ListGreaterThanZeroCheck(value: any): boolean {
        return value != null && value.length > 0
    }

    private static fetchValue = (e, name) => {
        if (name?.indexOf('.') > 0) {
            const attributes = name.split('.');
            let obj = e;
            for (let i = 0; i < attributes.length; i++) {
                const a = attributes[i];
                obj = obj[a];
                if (obj == null) {
                    return null;
                }
            }
            return obj;
        }

        return e[name];
    }
}
