export enum ColumnRenderType {
    Text,
    Switch,
    Link,
    Status,
    Menu,
    ButtonLink
}

export enum ColumnPropMappingType {
    None,
    Concat
}

export enum DataLoggerType {
    Wireless,
    Wired
}

export enum GenericFormGroupType {
    Normal = 1,
    DynamicRow
}

export enum GenericFormFieldType {
    None,
    ReadOnlyHeading,
    ReadOnlyText,
    Text,
    Dropdown,
    Datepicker
}

export enum EntityType {
    Others = 1,
    PurchaseOrder,
    Workflow,
    WorkflowTask,
    DataLogger,
    Booking,
    User,
}

export enum FileStorageType {
    Link = 1,
    Upload
}

export enum WorkflowStage {
    None = 0,
    SalesDataEntry = 1,
    SalesReview,
    Tasks,
    Closure
}

export enum WorkflowTaskStage {
    None = 0,
    TaskCreation = 1,
    ActivityPlanning,
    Booking,
    SiteActivity,
    OperationsReview,
    ReportAssignment,
    ReportPreparation,
    ReportReview,
    QaAssignment,
    QaReview,
    ClientReview,
    ReportDelivery,
    Invoicing,
    TaskClosure,
}

export enum PoType {
    Defined = 1,
    Open
}

export enum UserStatus {
    Active = 1,
    InActive = 2
}

export enum WorkflowTaskPriority {
    Low = 1,
    Medium,
    High
}

export enum LocalStorageKeys {
    AccessToken = 1,
    TokenExpiry,
    "grid/tile view"
}

export enum FeatureNames {
    None = 0,
    Client = 1,
    ClientAddress,
    ClientEquipment,
    ClientMake,
    ClientArea,
    Dashboard,
    DataLogger,
    DataLoggerCategory,
    DataLoggerMake,
    DataLoggerCamera,
    PurchaseOrder,
    PurchaseOrderServiceItem,
    PurchaseOrderLineItem,
    AccessRole,
    User,
    Workflow,
    WorkflowTask,
    MyWork,
    Notifications,
    Report,
    CameraDetail,
}

export enum AccessLevel {
    None,
    View = 1,
    Edit,
    Create,
    Delete
}

export enum ValidationScreens {
    None,
    Login,
    AccessRole,
    Client,
    ClientAddress,
    ClientEquipment,
    ClientArea,
    Dashboard,
    DataLogger,
    DataLoggerCategory,
    DataLoggerMake,
    DataLoggerCameraDetail,
    PurchaseOrderBasic,
    PurchaseOrderWithPOItem,
    PurchaseOrderServiceItem,
    PurchaseOrderLineItem,
    User,
    UserPasswordChange,
    WorkflowSalesDataEntryStage,
    WorkflowSalesReviewStage,
    WorkflowTasksStage,
    WorkflowClosureStage,
    WorkflowTaskCreationStage,
    WorkflowTaskBookingStage,
    WorkflowTaskSiteActivityStage,
    WorkflowTaskDocumentReviewStage,
    WorkflowTaskReportingStage,
    WorkflowTaskReportReviewStage,
    WorkflowTaskQualityAssuranceStage,
    WorkflowTaskClientReviewStage,
    WorkflowTaskReportDeliveryStage,
    WorkflowTaskActivityPlaningStage,
    WorkflowTaskInvoicingStage,
    ReportCleanroomEquipment,
    ReportCleanroomArea,
    AirflowVelocityTest,
    AirflowVelocityTestObservation,
    airflowPatternTest,
    FilterIntegrityTest,
    FilterIntegrityTestObservation,
    LightIntensityTest,
    LightIntensityTestObservation,
    ParticleCountTest,
    ParticleCountTestObservation,
    RecoveryTest,
    RecoveryTestObservation,
    SoundlevelTest,
    SoundLevelTestObservation,
    UVLightIntensityTest,
    UVLightIntensityTestObservation,
    CleanroomAreaAirChangesPerHourTest,
    CleanroomAreaAirChangesPerHourTestObservation,
    CleanroomAreaFilterIntegrityTest,
    CleanroomAreaFilterIntegrityTestObservation,
    CleanroomAreaNonViableParticleCountTest,
    CleanroomAreaNonViableParticleCountTestObservation,
    CleanroomAreaAirFlowPatternTestObservation,
    CleanroomAreaDifferentialPressureTest,
    CleanroomAreaDifferentialPressureTestObservation,
}

export enum ValidationType {
    None,
    Required,
    AlphabetOnly,
    NumberOnly,
    NumberGreaterThanZero,
    NumberMax,
    Regex,
    TasksCompleted,
    ListGreaterThanZero,
    RequiredIfTrue,
    DateShouldBeGreaterThenCurrentDate,
    DateShouldBeLessThenCurrentDate,
    DateShouldBeLessThenDependentDate,
    DateShouldBeGreaterDependentDate,
    IsEmail,
    IsMobileNo,
    IsPanCardNumber,
    IsOptional,
    IsValidGSTIN,
    ValidateIfFieldIsSet,
    ValueShouldBeSame,
    ValueShouldNotBeSame,
    ValidateIfCallbackIsTrue
}

export enum NotificationModalType {
    success = "success",
    error = "error",
    warning = "warning"
}

export enum Enviornments {
    local = "local",
    dev = "dev",
    staging = "staging",
    prod = "prod",
}

export enum ZoneClassificationType {
    EUGMP = 1,
    ISO,
    ClientSpecification
}

export enum CleanroomEquipmentTests {
  airflowvelocity = 'airflowvelocity',
  airflowpattern = 'airflowpattern',
  lightintensity = 'lightintensity',
  uvlightintensity = 'uvlightintensity',
  filterintegrity = 'filterintegrity',
  soundlevel = 'soundlevel',
  recovery = 'recovery',
  nonviableparticlecount='nonviableparticlecount',
}

export enum CleanroomAreaTests {
    airchangesperhour='airchangesperhour',
    filterintegrity='filterintegrity',
    nonviableparticlecount='nonviableparticlecount',
    recovery='recovery',
    airflowpattern='airflowpattern',
    soundlevel='soundlevel',
    lightintensity='lightintensity',
    uvlightintensity='uvlightintensity',
    differentialpressure = 'differentialpressure'
  }

export enum EUGMPZoneClassificationState {
   AtRest=1,
   InOperation,
   AsBuilt
}

export enum TestObservationResult
{
    Pass = 1,
    Fail
}


export enum AerosolGeneratorType
{
    HotGenerator = "Hot Generator",
    LaskinTypePolyDisperseAerosolGenerator="Laskin Type Poly Disperse Aerosol Generator"
    
}

export enum AerosolMedium
{
    PolyAlphaOlefin="Poly Alpha Olefin",
    Dioctyl="Dioctyl",
    Phthalate="Phthalate",
    Di2EthylHexylSebacate="Di-2-Ethyl Hexyl Sebacate",
}
export enum TestObservationOn
{
    FilterId= 1,
    GrillId
}

export enum AVTestObservationUnit
{
   FPM = 1,
   MS
}

export enum YesNoAnswer
{
    Yes='Yes',
    No = 'No'
}

export enum ObservationLocationLabel
{
    LocationName=1,
    LocationNumber,
    LocationNameOrNumber,
}

export enum ReportCategories{
    CleanroomEquipment,
    CleanroomArea,
    Thermal
}

export enum FilterIntegrityTestAcceptanceCriterias{
    "≤ 0.01%"="≤ 0.01%",
    "< 0.01%"="< 0.01%",
}


