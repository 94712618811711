import { IClientAreaModel, clientAreaDefault } from "../model/client-area.model";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import ScreenUrls, { redirectHandler } from "../../../common/screen-urls";
import ClientAreaService from "../service/client-area.service";

export interface IClientAreaState {
    areaList: IClientAreaModel[],
    areaData: IClientAreaModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
}


const initialState: IClientAreaState = {
    areaList: [],
    areaData: clientAreaDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
}

export const loadAreas = createAsyncThunk("client/area/loadAll", ClientAreaService.LoadAll);
export const loadAreaById = createAsyncThunk("client/area/loadById", ClientAreaService.GetById);
export const createArea = createAsyncThunk("client/area/create", ClientAreaService.Create);
export const editArea = createAsyncThunk("client/area/edit", ClientAreaService.Edit);


export const quickAddArea = createAsyncThunk("client/quickAdd/area/create", ClientAreaService.Create);

const clientAreaSlice = createSlice({
    name: 'client-area',
    initialState,
    reducers: {
        clearAreaDataState: (state, action: any) => {
            state.areaData = action?.payload ?? clientAreaDefault;
        },
        clearAreaListState: (state) => {
            state.areaList = [];
        },
        updateClientAreaDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.areaData[action.payload.name] = action.payload.value;
        },
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadAreas.pending, (state, action) => {
            clearAreaListState();
            state.listLoading = true;
        });
        builder.addCase(loadAreas.rejected, (state, action) => {
            clearAreaListState();
            state.listLoading = false;
        });
        builder.addCase(loadAreas.fulfilled, (state, action: any) => {
            state.areaList = action.payload ?? [];
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadAreaById.pending, (state, action) => { state.dataLoading = true; });
        builder.addCase(loadAreaById.rejected, (state, action) => {
            state.dataLoading = false;
        });
        builder.addCase(loadAreaById.fulfilled, (state, action) => {
            state.areaData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createArea.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createArea.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createArea.fulfilled, (state, action) => {
            state.areaData = action.payload;
            state.createEditLoading = false;
            redirectHandler(ScreenUrls.Clients.Area.Edit(`${action.payload?.id}`));
        });

        // ----- quickAdd ---
        builder.addCase(quickAddArea.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(quickAddArea.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(quickAddArea.fulfilled, (state, action) => {
            state.areaData = action.payload;
            state.createEditLoading = false;
        });


        // Edit
        builder.addCase(editArea.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editArea.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editArea.fulfilled, (state, action) => {
            state.areaData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = clientAreaSlice;

export const { clearAreaListState, clearAreaDataState, updateClientAreaDataState } = actions;

export default reducer;
