import { ICleanroomAreaModel, ICleanroomEquipmentModel, IEUGMPModel, IISOModel, IReportModel, } from "../model/report.model";
import ApiUrls from "../../../common/api-urls";
import HttpService from "../../../common/service/http.service";

export default class ReportService {
    static async LoadAllEquipment(): Promise<IReportModel[]> {
        return await HttpService.Get<IReportModel[]>(ApiUrls.Report.CleanroomEquipment.GetAll());
    }

    static async Create(data: ICleanroomEquipmentModel): Promise<ICleanroomEquipmentModel> {
        return await HttpService.Post<ICleanroomEquipmentModel, ICleanroomEquipmentModel>(ApiUrls.Report.CleanroomEquipment.Create(), data);
    }

    static async Edit(data: ICleanroomEquipmentModel): Promise<ICleanroomEquipmentModel> {
        return await HttpService.Patch<ICleanroomEquipmentModel, ICleanroomEquipmentModel>(ApiUrls.Report.CleanroomEquipment.Edit(data?.id ?? 0), data);
    }

    static async DeleteEquipment(id: number) {
        await HttpService.Delete(ApiUrls.Report.CleanroomEquipment.Delete(id));
    }

    static async GetById(id: number): Promise<ICleanroomEquipmentModel> {
        return await HttpService.Get<ICleanroomEquipmentModel>(ApiUrls.Report.CleanroomEquipment.GetById(id));
    }

    static async GetISOStandardById(id: string): Promise<IISOModel> {
        return await HttpService.Get<IISOModel>(ApiUrls.Report.GetAllISOClassById(id));
    }

    static async GetEUGMPtandardById(id: string): Promise<IEUGMPModel> {
        return await HttpService.Get<IEUGMPModel>(ApiUrls.Report.GetAllEUGMPClassById(id));
    }

    //-- area--

    static async LoadAllArea(): Promise<IReportModel[]> {
        return await HttpService.Get<IReportModel[]>(ApiUrls.Report.CleanroomArea.GetAll());
    }

    static async CreateAreaReport(data: ICleanroomAreaModel): Promise<ICleanroomAreaModel> {
        return await HttpService.Post<ICleanroomAreaModel, ICleanroomAreaModel>(ApiUrls.Report.CleanroomArea.Create(), data);
    }

    static async EditAreaReport(data: ICleanroomAreaModel): Promise<ICleanroomAreaModel> {
        return await HttpService.Patch<ICleanroomAreaModel, ICleanroomAreaModel>(ApiUrls.Report.CleanroomArea.Edit(data?.id ?? 0), data);
    }

    static async DeleteAreaReport(id: number) {
        await HttpService.Delete(ApiUrls.Report.CleanroomArea.Delete(id));
    }

    static async GetAreaReportById(id: number): Promise<ICleanroomAreaModel> {
        return await HttpService.Get<ICleanroomAreaModel>(ApiUrls.Report.CleanroomArea.GetById(id));
    }
}
