import TestService from "../../../service/cleanroom-equipment/test.service";
import { CleanroomEquipmentTests } from "../../../../../common/enums";
import { ICleanroomEquipmentModel } from "../../../model/report.model";
import { IUserModel } from "../../../../user/model/user.model";
import moment from "moment";
import { styles, PDFFooterComponent, displayDate, useDispatch, localToUtcDate, saveAs, PDFHeaderComponent, Document, React, useState, useEffect, Page, Text, View, Image, pdf, PDFViewer, PDFClientDetailComponent, PDFRemarkComponent } from "../PDF/base-pdf-components";
import { IAirflowPatterTestModel } from "../../../model/cleanroom-equipment/test/airflow-pattern-test.model";
import { editAFPTTest } from '../../../store/cleanroom-equipment/test.slice';

export const AirflowPatternTestPDFComponent = ({ data }: { data: IAirflowPatterTestModel | any }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap={false}>
                <PDFHeaderComponent />

                {/* ---- body ---- */}
                <View style={{ ...styles.section }}>
                    <View style={{ ...styles.main }}>
                        <PDFClientDetailComponent data={data} title={"Air Flow Pattern"} />

                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Equipment :</Text>
                        </View>

                        <View style={{}}>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                    <Text style={{}}>Equipment Name</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                    <Text style={{}}>{data.clientEquipment?.equipmentName ?? ''}</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Equipment Id</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data.clientEquipment?.equipmentId ?? ""}</Text>
                                    </View>
                                </View>

                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Make</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data.clientEquipment?.make ?? ""}</Text>
                                    </View>
                                </View>

                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Area Of Test
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data?.areaOfTest}</Text>
                                    </View>
                                </View>

                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Model Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data?.clientEquipment?.modelNumber ?? ""}</Text>
                                    </View>
                                </View>


                            </View>

                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Location</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{data?.location ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Serial Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data?.clientEquipment?.serialNumber ?? ""}</Text>
                                    </View>
                                </View>

                            </View>

                        </View>

                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{}}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Standard :</Text>
                            </View>

                            <View style={{ ...styles.paddingTB }}>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.flexBox, ...styles.col, }}>
                                        <Text style={{}}>Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>{data?.dataLogger?.name ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data.dataLogger?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data.dataLogger?.make?.name ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Instrument Id</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{data?.dataLogger?.instrumentId ?? ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Serial Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{data?.dataLogger?.serialNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Calibration Done Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{displayDate(data.dataLogger?.lastCalibratedOn)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Calibration Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{displayDate(data.dataLogger?.calibrationDueOn)}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{}}>
                                    <View style={{ ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Camera Name
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data?.cameraDetail?.cameraName}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Model Number
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data?.cameraDetail?.modelNumber}</Text>
                                            </View>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Make
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data?.cameraDetail?.make ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Camera Id
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data?.cameraDetail?.cameraId ?? ""}</Text>
                                            </View>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Serial Number
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data?.cameraDetail?.serialNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Name</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Number</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>{data?.refStandardOrProtocolOrDocumentName ?? ""}</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>

                                    <Text style={{}}>
                                        {data?.procedureOrProtocolOrDocumentNumber ?? ""}

                                    </Text>
                                </View>
                            </View>
                        </View>
                        {/* ---- observations ---- */}
                        <View wrap={false}>
                            <View style={{ ...styles.paddingTB }}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Observations :</Text>
                            </View>
                            <View style={{ ...styles.flexBox, ...styles.bold, ...styles.textCenter }}>
                                <View style={{ ...styles.label, width: 30, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Sr. No</Text>
                                </View>
                                {
                                    data.doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills != null ?
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                            <Text style={{}}>
                                                Does the smoke flows smoothly downwards from supply grills
                                            </Text>
                                        </View> : ""
                                }
                                {
                                    data.doesTheSmokeFlowsTowardsTheReturnGrills != null ?
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                            <Text style={{}}>
                                                Does the smoke flows towards the return grills
                                            </Text>
                                        </View> : ""
                                }
                                {
                                    data.doesTheDifferentialPressureIsZeroWhenEquipmentIsInOFFCondition != null ?
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                            <Text style={{}}>
                                                Does the differential pressure is zero when equipment is in OFF condition
                                            </Text>
                                        </View> : ""
                                }
                                {
                                    data.doesTheDifferentialPressureIsWithinTheLimitWhenEquipmentIsInONCondition
                                        != null ?
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                            <Text style={{}}>
                                                Does the differential pressure is within the limit when equipment is in ON condition
                                            </Text>
                                        </View> : ""
                                }
                                {
                                    data.doesTheSmokeFlowsAwayFromTheExhaustFilter != null ?
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                            <Text style={{}}>
                                                Does the smoke flows away from the exhaust filter
                                            </Text>
                                        </View> : ""
                                }

                            </View>


                            <View style={{ ...styles.flexBox, ...styles.textCenter }}>
                                <View style={{ ...styles.label, width: 30, ...styles.col }}>
                                    <Text style={{}}>{1}</Text>
                                </View>
                                {
                                    data?.doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills != null ? <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>
                                            {
                                                data?.doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills ? "Yes" :
                                                    data?.doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills == false ? "No"
                                                        : "NA"}
                                        </Text>
                                    </View> : ""
                                }
                                {
                                    data?.doesTheSmokeFlowsTowardsTheReturnGrills != null ? <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{
                                            data?.doesTheSmokeFlowsTowardsTheReturnGrills ? "Yes"
                                                :
                                                data?.doesTheSmokeFlowsTowardsTheReturnGrills == false ? "No"
                                                    :
                                                    "NA"
                                        }</Text>
                                    </View> : ""
                                }
                                {
                                    data?.doesTheDifferentialPressureIsZeroWhenEquipmentIsInOFFCondition != null ?
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{
                                                data?.doesTheDifferentialPressureIsZeroWhenEquipmentIsInOFFCondition ? "Yes" :
                                                    data?.doesTheDifferentialPressureIsZeroWhenEquipmentIsInOFFCondition == false ? "No"
                                                        : "NA"
                                            }</Text>
                                        </View> : ""
                                }

                                {
                                    data?.doesTheDifferentialPressureIsWithinTheLimitWhenEquipmentIsInONCondition != null ?
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{
                                                data?.doesTheDifferentialPressureIsWithinTheLimitWhenEquipmentIsInONCondition ?
                                                    "Yes"
                                                    :
                                                    data?.doesTheDifferentialPressureIsWithinTheLimitWhenEquipmentIsInONCondition == false ?
                                                        "No"
                                                        : "NA"
                                            }</Text>
                                        </View> : ""
                                }
                                {
                                    data?.doesTheSmokeFlowsAwayFromTheExhaustFilter != null ? <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.doesTheSmokeFlowsAwayFromTheExhaustFilter == true ? "Yes" :
                                            data?.doesTheSmokeFlowsAwayFromTheExhaustFilter == false ? "No" :
                                                "NA"}</Text>
                                    </View> : ""
                                }

                            </View>

                        </View>
                        <PDFRemarkComponent data={data} />
                    </View>
                </View >

                {/* --- footer ---- */}
                <PDFFooterComponent />
            </Page >
        </Document >
    )
}

export interface IProps {
    data: ICleanroomEquipmentModel,
    id: number,
    currentUser: IUserModel
}

const AirFlowPatterPDFComponent = ({ id, data, currentUser }: IProps) => {
    const [loading, setLoading] = useState(false);
    const dispatch: any = useDispatch();
    const downloadPdf = async () => {
        let res: any;
        setLoading(true);
        try {
            if (id) {
                res = await TestService.GetAFPTTestById({ testName: CleanroomEquipmentTests.airflowpattern, id });
                if (res?.id) {
                    const fileName = 'airflow-pattern-test.pdf';
                    const blob = await pdf(<AirflowPatternTestPDFComponent data={{ ...data, ...res }} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
        } catch (errr) {

        } finally {
            setLoading(false);
            let date = moment(new Date()).toString();
            dispatch(editAFPTTest({
                testName: CleanroomEquipmentTests.airflowpattern, data:
                {
                    ...res,
                    editLocked: true,
                    reportPdfGeneratedById: currentUser?.id,
                    reportPdfGeneratedOn: localToUtcDate(date),
                },
            }))
        }

    };

    useEffect(() => {
        setLoading(false);
    }, [data]);

    return (<React.Fragment>
        {/* <PDFViewer width={'100%'}>
            <AirflowPatternTestPDFComponent data={data} />
        </PDFViewer> */}
        <button className="btn m-0 btn-primary px-3 py-2" onClick={downloadPdf}>{!loading ? "Download PDF" : "Loading"}</button>
    </React.Fragment>)
}
export default AirFlowPatterPDFComponent;


