import {IClientAddressModel} from "../model/client.model";
import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {clientAddressDefault} from "../model/client.default";
import {IStoreSaveModel} from "../../../common/model/store-save.model";
import ClientAddressService from "../service/client-address.service";

export interface IClientAddressState {
    clientAddressList: IClientAddressModel[],
    clientAddressData: IClientAddressModel,
    listLoading: boolean,
    dataLoading: boolean,
    createEditLoading: boolean
}

const initialState: IClientAddressState = {
    clientAddressList: [],
    clientAddressData: clientAddressDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false
}

export const loadClientAddresses = createAsyncThunk("client/address/loadAll", ClientAddressService.LoadAll);
export const loadClientAddressById = createAsyncThunk("client/address/loadById", ClientAddressService.GetById);
export const createClientAddress = createAsyncThunk("client/address/create", ClientAddressService.Create);
export const editClientAddress = createAsyncThunk("client/address/edit", ClientAddressService.Edit);

const clientAddressSlice = createSlice({
    name: 'client-address',
    initialState,
    reducers: {
        clearClientAddressDataState: (state) => { state.clientAddressData = clientAddressDefault },
        clearClientAddressListState: (state) => {
            state.clientAddressList = [];
        },
        updateClientAddressDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.clientAddressData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: (builder) => {
        // Load All
        builder.addCase(loadClientAddresses.pending, (state, action) => {
            clearClientAddressListState();
            state.listLoading = true;
        });
        builder.addCase(loadClientAddresses.rejected, (state, action) => {
            clearClientAddressListState();
            state.listLoading = false;
            // NotificationHelper.Error(action?.error?.message);
        });
        builder.addCase(loadClientAddresses.fulfilled, (state, action) => {
            state.clientAddressList = action.payload;
            state.listLoading = false;
        });

        // Load By ID
        builder.addCase(loadClientAddressById.pending, (state, action) => { state.dataLoading = true; });
        builder.addCase(loadClientAddressById.rejected, (state, action) => {
            state.dataLoading = false;
        });
        builder.addCase(loadClientAddressById.fulfilled, (state, action) => {
            state.clientAddressData = action.payload;
            state.dataLoading = false;
        });

        // Create
        builder.addCase(createClientAddress.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(createClientAddress.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(createClientAddress.fulfilled, (state, action) => {
            state.clientAddressData = action.payload;
            state.createEditLoading = false;
        });

        // Edit
        builder.addCase(editClientAddress.pending, (state, action) => { state.createEditLoading = true; });
        builder.addCase(editClientAddress.rejected, (state, action) => {
            state.createEditLoading = false;
        });
        builder.addCase(editClientAddress.fulfilled, (state, action) => {
            state.clientAddressData = action.payload;
            state.createEditLoading = false;
        });
    }
});

const { actions, reducer } = clientAddressSlice;

export const { clearClientAddressDataState, clearClientAddressListState, updateClientAddressDataState } = actions;

export default reducer;
