import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType} from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "filterId",
        rules: [
            {
                type: ValidationType.Required,
                message: "Filter Id is required"
            }
        ]
    },
    {
        fieldName: "obtainedResultsInDownstream",
        rules: [
            {
                type: ValidationType.Required,
                message: "Obtained result in downstream is required",
            },
        ]
    },
]

export const cleanroomAreaFilterIntegrityTestObservationValidator: IValidatorModel = {
    screen: ValidationScreens.CleanroomAreaFilterIntegrityTestObservation,
    fields: fields
}
