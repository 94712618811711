import { createSlice, PayloadAction, isFulfilled, isRejected } from "@reduxjs/toolkit";
import { NotificationModalType } from "../enums";
import { loginUser, loadCurrentUser } from "../../features/user/store/user-session.slice";
import { loadCategories, loadCategoryById, createCategory, editCategory } from "./category.slice";
import { createFile } from "./file.slice";
import { loadAccessRoles, loadAccessRoleById, createAccessRole, editAccessRole } from "../../features/access/store/access-role.slice";
import { loadFeatures, loadFeatureById, createFeature, editFeature } from "../../features/access/store/feature.slice";
import { loadClientAddresses, loadClientAddressById, createClientAddress, editClientAddress } from "../../features/client/store/client-address.slice";
import { loadClients, loadClientById, createClient, editClient } from "../../features/client/store/client.slice";
import { loadSummary } from "../../features/dashboard/store/dashboard.slice";
import { loadBookings, editBooking, createBooking, loadBookingById } from "../../features/data-logger/store/data-logger-booking.slice";
import { editDataLoggerMake, loadDataLoggerMakers, createDataLoggerMake, loadDataLoggerMakeById } from "../../features/data-logger/store/data-logger-make.slice";
import { loadDataLoaders, loadDataLoaderById, createDataLogger, editDataLogger } from "../../features/data-logger/store/data-logger.slice";
import { loadPurchaseOrderLineItems, loadPurchaseOrderLineItemById, createPurchaseOrderLineItem, editPurchaseOrderLineItem, } from "../../features/purchase-order/store/purchase-order-line-item.slice";
import { loadPurchaseOrderServiceItems, loadPurchaseOrderServiceItemById, createPurchaseOrderServiceItem, editPurchaseOrderServiceItem } from "../../features/purchase-order/store/purchase-order-service-item.slice";
import { loadPurchaseOrders, loadPurchaseOrderById, createPurchaseOrder, editPurchaseOrder, watchPurchaseOrder } from "../../features/purchase-order/store/purchase-order.slice";
import { loadUsers, loadUserById, createUser, editUser, passwordChange } from "../../features/user/store/user.slice";
import { loadWorkflows, loadWorkflowById, createWorkflow, editWorkflow, moveToNextStage, moveToRejectStage, completeWorkflow, watchWorkflow } from "../../features/workflow/store/workflow.slice";
import { loadWorkflowTasks, loadWorkflowTaskById, createWorkflowTask, editWorkflowTask, moveToNextWorkflowTaskStage, moveToWorkflowTaskRejectStage, completeWorkflowTask, setToDefaultStageFlow, watchWorkflowTask } from '../../features/workflow-task/store/workflow-task.slice';
import { ForgotPassword, ResetPasswordAnonymous } from '../../features/password-recovery/store/password-recovery.slice';
import { createEquipment, editEquipment } from "../../features/client/store/client-equipment.slice";
import { createEquipment as createEquipmentReport, editEquipment as editEquipmentReport, createAreaReport, editAreaReport } from "../../features/report/store/cleanroom-equipment/cleanroom-equipment.slice";
import { createAVTest, editAVTest, createFITest, editFITest, createLITest, editLITest, createPCTest, editPCTest, createRecoveryTest, editRecoveryTest } from "../../features/report/store/cleanroom-equipment/test.slice";
import { createArea, editArea, loadAreaById, loadAreas } from "../../features/client/store/client-area.slice";
import { createACPHTest, createNVPCTest, editACPHTest, editNVPCTest } from "../../features/report/store/cleanroom-area/cleanroom-area-test.slice";
import { createFITest as createCleanroomAreaFITest, editFITest as editCleanroomAreaFITest } from "../../features/report/store/cleanroom-area/cleanroom-area-test.slice";
export interface IAlert {
    show: boolean,
    message: string,
    title: string,
    className: NotificationModalType,
}

const initialState: IAlert = {
    show: false,
    message: "",
    title: "",
    className: NotificationModalType.success,
}

const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        setShowModal: (state, action: PayloadAction<IAlert>) => {
            state.show = action.payload.show;
            state.message = action.payload.message;
            state.className = action.payload.className;
            state.title = action.payload.title;
        },
        setHideModal: (state, action: PayloadAction<boolean>) => {
            state.show = action.payload;
            state.message = "";
            state.className = NotificationModalType.success;
            state.title = "";
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginUser.fulfilled, (state, action) => {
            if (action.payload && !action.payload?.accessToken) {
                state.show = true;
                state.message = "Invalid user credentials";
                state.className = NotificationModalType.error;
                state.title = "Error occured";
            }
        });
        // ----- custom messages actions ------
        builder.addCase(passwordChange.fulfilled, (state, action: any) => {
            state.show = true;
            state.message = action?.payload?.id ? "Password Changed successfully" : action?.payload;
            state.className = action?.payload?.id ? NotificationModalType.success : NotificationModalType.error;
            state.title = action?.payload?.id ? "Success" : "Error occured";
        });
        builder.addCase(ForgotPassword.fulfilled, (state, action: any) => {
            state.show = true;
            state.message = action.payload?.accessToken?.length > 0 ? "Please check your email to reset your password." : action.payload;
            state.className = action.payload?.accessToken?.length > 0 ? NotificationModalType.success : NotificationModalType.error;
            state.title = action.payload?.accessToken?.length > 0 ? "Success" : "Error";
        });
        builder.addCase(ResetPasswordAnonymous.fulfilled, (state, action) => {
            if (action.payload != "Ok" && action.payload?.length > 0) {
                state.show = true;
                state.message = action.payload ?? "Something went wrong";
                state.className = NotificationModalType.error;
                state.title = "Error";
            }
        });
        // ---- create user fullfilled action response -----
        builder.addCase(createUser.fulfilled, (state, action: any) => {
            let msg = "Created successfully";
            let className = NotificationModalType.success;
            let title = "Success";
            if (!action?.payload?.id) {
                msg = action.payload;
                className = NotificationModalType.error;
                title = "Error";
            }
            state.show = true;
            state.message = msg;
            state.className = className;
            state.title = title;
        });

        // ----- create actions ------
        builder.addMatcher(isFulfilled(createClientAddress, createCategory, createFile, createAccessRole, createFeature, createEquipment, createClient, createBooking, createDataLoggerMake, createDataLogger, createPurchaseOrderLineItem, createPurchaseOrderServiceItem, createPurchaseOrder, createWorkflow, createEquipmentReport, createAreaReport, createAVTest, createFITest, createLITest, createPCTest, createRecoveryTest, createArea, createACPHTest, createCleanroomAreaFITest,createNVPCTest), (state, action) => {
            state.show = true;
            state.message = "Created successfully";
            state.className = NotificationModalType.success;
            state.title = "Success";
        });
        // ----- update actions ------
        builder.addMatcher(isFulfilled(editClientAddress, editUser, editCategory, editAccessRole, editFeature, editClient, editBooking, editDataLoggerMake, editDataLogger, editEquipment, editPurchaseOrderLineItem, editPurchaseOrderServiceItem, editPurchaseOrder, editWorkflow, moveToNextStage, moveToRejectStage, completeWorkflow, moveToNextWorkflowTaskStage, moveToWorkflowTaskRejectStage, completeWorkflowTask, watchPurchaseOrder, watchWorkflow, watchWorkflowTask, editEquipmentReport, editAreaReport, editAVTest, editFITest, editLITest, editPCTest, editRecoveryTest, editArea, editACPHTest, editCleanroomAreaFITest,editNVPCTest), (state, action) => {
            state.show = true;
            state.message = "Updated successfully";
            state.className = NotificationModalType.success;
            state.title = "Success";
        });

        // ----- rejected actions ------
        builder.addMatcher(isRejected(editClientAddress, loginUser, loadCurrentUser, editUser, passwordChange, createUser, loadUsers, loadUserById, loadCategories, editCategory, createCategory, createFile, loadCategoryById, loadAccessRoles, loadAccessRoleById, createAccessRole, editAccessRole, loadFeatures, loadFeatureById, createFeature, editFeature, loadClientAddresses, loadClientAddressById, createClientAddress, editClientAddress, loadClients, loadClientById, createClient, editClient, loadSummary, editBooking, createBooking, loadBookingById, editDataLoggerMake, loadDataLoggerMakers, loadDataLoggerMakeById, loadBookings, createDataLoggerMake, loadDataLoaders, loadDataLoaderById, createDataLogger, editDataLogger, loadPurchaseOrderLineItems, loadPurchaseOrderLineItemById, createAreaReport, editAreaReport, createPurchaseOrderLineItem, editPurchaseOrderLineItem, loadPurchaseOrderServiceItemById, createPurchaseOrderServiceItem, editPurchaseOrderServiceItem, loadPurchaseOrders, loadPurchaseOrderById, createPurchaseOrder, editPurchaseOrder, loadWorkflows, loadWorkflowById, createWorkflow, editWorkflow, moveToNextStage, moveToRejectStage, completeWorkflow, loadWorkflowTasks, loadWorkflowTaskById, createWorkflowTask, editWorkflowTask, moveToNextWorkflowTaskStage, moveToWorkflowTaskRejectStage, completeWorkflowTask, setToDefaultStageFlow, loadPurchaseOrderServiceItems, watchPurchaseOrder, watchWorkflow, watchWorkflowTask, editArea, loadAreaById, loadAreas, createACPHTest, editACPHTest,createCleanroomAreaFITest,editCleanroomAreaFITest), (state, action) => {
            state.show = true;
            state.message = typeof (action?.error?.message) == "string" ? action?.error?.message : "";
            state.className = NotificationModalType.error;
            state.title = "Error";
        });
    }
});

const { actions, reducer } = alertSlice;


export const { setShowModal, setHideModal } = actions;

export default reducer;


