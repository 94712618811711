import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "description",
        rules: [
            {
                type: ValidationType.Required,
                message: "Description is required"
            }
        ]
    },
]

export const cleanroomAreaAirflowPatternTestObservationValidator: IValidatorModel = {
    screen: ValidationScreens.CleanroomAreaAirFlowPatternTestObservation,
    fields: fields
}

