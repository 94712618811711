import { FormBaseComponent, IFormBaseState } from "../../../../../../../common/component/form-base.component";
import React from "react";
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import { AgGridDefaultColDef } from "../../../../../../../common/app-defaults";
import { AgGridReact } from "ag-grid-react";
import { IBaseTestPayload } from "../../../../../model/cleanroom-equipment/test/test.model";
import { IUserModel } from "../../../../../../user/model/user.model";
import ScreenUrls from "../../../../../../../common/screen-urls";
import DataLoggerService from "../../../../../../data-logger/service/data-logger.service";
import DataLoggerSelectorAccordionComponent from "../../../../../../workflow-task/component/data-logger-selector.component";
import { Link } from "react-router-dom";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { clearTestDataState, createNVPCTest, editNVPCTest, loadNVPCTestById, updateTestDataState, } from "../../../../../store/cleanroom-area/cleanroom-area-test.slice";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import { EUGMPConsideredParticleSizeList, ISOConsideredParticleSizeList, ReportTestEquipmentState, Standards } from "../../../../../../../common/_static/report.data";
import { ZoneClassificationType } from "../../../../../../../common/enums";
import { deleteNVPCObservation } from "../../../../../store/cleanroom-area/cleanroom-area-observation.slice";
import DropdownDisplayHelper from "../../../../../../../common/helper/dropdown-display.helper";
import { AppConstants } from "../../../../../../../common/app-constants";
import { utcToLocalDate, localToUtcDate } from "../../../../../../../common/utils";
import { IFormControlDropdownItemModel } from "../../../../../../../common/control/model/form.control.field.model";
import AsyncSelect from "react-select/async";
import { DeferredLoadOptions } from "../../../../../../../common/utils";
import UserService from "../../../../../../user/service/user.service";
import { loadCleanroomAreaById, } from "../../../../../store/cleanroom-equipment/cleanroom-equipment.slice";
import { ICleanroomAreaModel } from "../../../../../model/report.model";
import { AccessLevel, CleanroomAreaTests, ValidationScreens } from "../../../../../../../common/enums";
import { CleanroomAreaNonViableParticleCountTestDefault, } from "../../../../../model/cleanroom-area/defaults/test.default";
import { ICleanroomAreaNonViableParticleCountTestModel, ICleanroomAreaNonViableParticleCountTestObservationModel, ICleanroomAreaNVPCTestPayload } from "../../../../../model/cleanroom-area/test/non-viable-particle-count-test.model";
import TestLocationModel from "./nvpc-test-location-create-edit-page";
import NVPCTestPDFComponent from "../../../../../component/cleanroom/cleanroom-area-pdf/non-viable-particle-count-test-pdf";
import TestEditEnableComponent from "../../../../../component/cleanroom/test-edit-enable-component";
export interface IProps {
    id: number,
    testId?: number,
    accessLevel: number,
    testData: ICleanroomAreaNonViableParticleCountTestModel,
    equipmentData: ICleanroomAreaModel,
    dataLoading: boolean,
    createTest: (data: ICleanroomAreaNVPCTestPayload) => void,
    editTest: (data: ICleanroomAreaNVPCTestPayload) => void,
    loadTestById: (data: ICleanroomAreaNVPCTestPayload) => void,
    createEditLoading: boolean,
    currentUser: IUserModel,
    clearTestDataState: (payload) => void,
    updateTestDataState: (payload) => void,
    loadCleanroomAreaById: (id: number) => void,
    refetchApi: boolean,
    deleteObservation: (data: IBaseTestPayload) => void,
}

export interface IState extends IFormBaseState {
    openObservationPopup: boolean,
    hideZoneClassification: boolean,
    editObservation: null | ICleanroomAreaNonViableParticleCountTestObservationModel,
}

class TestManager extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
            openObservationPopup: false,
            editObservation: null,
            hideZoneClassification: false,
        };
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.CleanroomAreaNonViableParticleCountTest);
        if (this.props.id) {
            await this.props.loadCleanroomAreaById(Number(this.props.id));
        }
        if (this.props.testId) {
            await this.props.loadTestById({ testName: CleanroomAreaTests.nonviableparticlecount ?? "", id: Number(this.props.testId) });
        } else {
            await this.props.clearTestDataState(CleanroomAreaNonViableParticleCountTestDefault);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (!prevProps.refetchApi && this.props.refetchApi && this.props.testData.id) {
            this.props.loadTestById({ testName: CleanroomAreaTests.nonviableparticlecount ?? "", id: Number(this.props?.testId) });
        };
    }

    loadUserOption = (inputValue: string) => DeferredLoadOptions(inputValue, UserService.UserDropdown)

    loadDataLoggers = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
        new Promise<IFormControlDropdownItemModel[]>(async (resolve) => {
            const dataLoggers = await DataLoggerService.LoadAll();

            if (dataLoggers == null || !Array.isArray(dataLoggers?.data)) {
                resolve([]); return;
            }

            const list = dataLoggers?.data?.map(d => {
                const obj: IFormControlDropdownItemModel = {
                    label: DropdownDisplayHelper.DataLogger(d),
                    value: d.id.toString()
                }
                return obj;
            });

            if (search == null || search === "") resolve(list);
            resolve(list.filter(l => l.label.indexOf(search) > 0))
        }));

    getObservations = () => {
        let arr: ICleanroomAreaNonViableParticleCountTestObservationModel[] = [];
        if (this.props.testData?.locations?.length == 0) return arr;
        for (let i = 0; i <= this.props.testData?.locations?.length; i++) {
            for (let j = 0; j <= this.props.testData?.locations[i]?.observations?.length; j++) {
                if (this.props.testData?.locations[i]?.observations[j]?.locationId) {
                    arr.push({
                        ...this.props.testData?.locations[i]?.observations[j],
                        nonViableParticleCountTestId: this.props.testData?.id ?? 0,
                        locationNumber: this.props.testData?.locations[i]?.locationNumber,
                    });
                }
            }
        }
        return arr;
    }

    render() {
        if (this.state.hideForm) return;
        return (
            <React.Fragment>
                {(() => {
                    if (this.props.dataLoading || this.props.createEditLoading)
                        return (
                            <div className="loading--bar fixed--top">
                                <span></span>
                            </div>
                        );
                })()}
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="page-header">
                            <div className="page-block">
                                <div className="row">
                                    <div className="col-md-12 d-flex align-items-center justify-content-between">
                                        <Link
                                            to={ScreenUrls.Report.CleanroomArea.Edit(this.props.id)}
                                            className="btn drp-icon btn-rounded btn-primary dropdown-toggle"
                                        >
                                            <i className="feather icon-arrow-left"></i>
                                        </Link>
                                        <div className="col-auto">
                                            {
                                                this.props?.testData?.id ?

                                                    <div className="d-flex">
                                                        {
                                                            this.props?.testData && this.props.testData.id ?
                                                                <NVPCTestPDFComponent id={this.props.testData?.id ?? 0}
                                                                    currentUser={this.props.currentUser}
                                                                    data={{ ...this.props.equipmentData, ...this.props.testData }} />
                                                                : null
                                                        }

                                                        {
                                                            this.props.testData.editLocked ?
                                                                <TestEditEnableComponent
                                                                category="cleanroomArea"
                                                                    testName={CleanroomAreaTests.nonviableparticlecount}
                                                                    reportCategory={this.props.equipmentData?.reportCategoryId ?? 0}
                                                                    testId={this.props.testData?.id}
                                                                    reportId={this.props.testData?.cleanroomAreaReportId}
                                                                /> : ""
                                                        } 
                                                    </div>
                                                    : null
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`main-body ${this.props?.testData?.editLocked ? 'disabledSection':""}`}>
                            <div className="page-wrapper">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">
                                                    Non Viable Particle Count Test Details
                                                </div>
                                            </div>
                                            <div className="card-block">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("dateOfTest").className}`}>
                                                            <label htmlFor="dateOfTest">Date of Test<span className="requried-span">*</span></label>
                                                            <DatePicker key="dateOfTest"
                                                                selected={utcToLocalDate(this.props.testData?.dateOfTest ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.ValidateField("dateOfTest", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "dateOfTest", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("dateOfTest").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("dueDate").className}`}>
                                                            <label htmlFor="dueDate">Due Date</label>
                                                            <DatePicker key="dueDate"
                                                                selected={utcToLocalDate(this.props?.testData?.dueDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.props.updateTestDataState({ name: "dueDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("preparedById").className}`}>
                                                            <label htmlFor="preparedById">Prepared By<span className="requried-span">*</span></label>
                                                            <AsyncSelect id="preparedById" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadUserOption}
                                                                value={this.props.testData.preparedBy
                                                                    ? { label: DropdownDisplayHelper.User(this.props.testData?.preparedBy), value: this.props.testData?.preparedBy?.id?.toString() }
                                                                    : { label: "Select User", value: "" }}
                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    this.ValidateField("preparedById", cId);
                                                                    if (cId === null || cId === 0 || (this.props.testData.preparedBy != null && cId === this.props.testData.preparedBy.id)) return;

                                                                    const client = await UserService.GetById(cId);

                                                                    this.props.updateTestDataState({ name: "preparedBy", value: client });
                                                                    this.props.updateTestDataState({ name: "preparedById", value: client.id });

                                                                    //this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("preparedById").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("preparedOnDate").className}`}>
                                                            <label htmlFor="preparedOnDate">Prepared On Date<span className="requried-span">*</span></label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData.preparedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.ValidateField("preparedOnDate", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "preparedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("preparedOnDate").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("checkedById").className}`}>
                                                            <label htmlFor="checkedById">Checked By</label>
                                                            <AsyncSelect id="checkedById" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadUserOption}
                                                                value={this.props.testData.checkedBy
                                                                    ? { label: DropdownDisplayHelper.User(this.props.testData.checkedBy), value: this.props.testData.checkedBy.id.toString() }
                                                                    : { label: "Select User", value: "" }}
                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    this.ValidateField("checkedById", cId);
                                                                    if (cId === null || cId === 0 || (this.props.testData.checkedBy != null && cId === this.props.testData.checkedBy.id)) return;

                                                                    const client = await UserService.GetById(cId);

                                                                    this.props.updateTestDataState({ name: "checkedBy", value: client });
                                                                    this.props.updateTestDataState({ name: "checkedById", value: client.id });

                                                                    //this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("checkedById").message} />

                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Checked On Date</label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData.checkedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.props.updateTestDataState({ name: "checkedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="name">Verified By</label>
                                                            <input id="name" className="form-control" type="text"
                                                                value={this.props.testData?.verifiedByName ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "verifiedByName", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Verified On Date</label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData.verifiedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    // this.ValidateField("poDate", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "verifiedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="name">Air Volume Sampled per Location (L)
                                                            </label>
                                                            <input id="name" className="form-control" type="number"
                                                                value={this.props.testData?.airVolumeSampledPerLocation ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "airVolumeSampledPerLocation", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="name">Sampling Time at each Samples (Min)
                                                            </label>
                                                            <input id="name" className="form-control" type="number"
                                                                value={this.props.testData?.samplingTimeAtEachSamples ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "samplingTimeAtEachSamples", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group`}>
                                                            <label htmlFor="abc">Occupancy State</label>
                                                            <ReactSelect id="abc"
                                                                className="async-select-control"
                                                                options={ReportTestEquipmentState}
                                                                value={this.props.testData?.occupancyState
                                                                    ? ReportTestEquipmentState.find((item) => item.value == this.props.testData?.occupancyState?.toString())
                                                                    : { label: "Select Type", value: "" }}

                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    if (cId === null || cId === 0) return;
                                                                    let standard = ReportTestEquipmentState.find((item) => Number(item.value) == cId);
                                                                    this.props.updateTestDataState({ name: "occupancyState", value: Number(standard?.value) });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-6">
                                                        <div className={`form-group ${this.vResult("consideredParticleSizes").className}`}>
                                                            <label htmlFor="consideredParticleSizes">Considered Particle sizes<span className="requried-span">*</span>
                                                            </label>
                                                            {
                                                                this.props.equipmentData?.eugmpStandard ?
                                                                    <ReactSelect id="consideredParticleSizes"
                                                                        className="async-select-control"
                                                                        isMulti
                                                                        options={EUGMPConsideredParticleSizeList}
                                                                        value={this.props.testData?.consideredParticleSizes
                                                                            ? EUGMPConsideredParticleSizeList?.filter((item) => this.props.testData?.consideredParticleSizes?.split(",")?.includes(item.value))
                                                                            : { label: "Select particle sizes", value: "" }}

                                                                        onChange={async (e) => {
                                                                            let str: string = "";
                                                                            if (Array.isArray(e)) {
                                                                                str = e?.filter?.(item => item.value)?.map((item) => item.value)?.join(",");
                                                                            }
                                                                            this.ValidateField("consideredParticleSizes", str);
                                                                            this.props.updateTestDataState({ name: "consideredParticleSizes", value: str })
                                                                        }}
                                                                    />
                                                                    :
                                                                    <ReactSelect id="consideredParticleSizes"
                                                                        className="async-select-control"
                                                                        isMulti
                                                                        options={ISOConsideredParticleSizeList}
                                                                        value={this.props.testData?.consideredParticleSizes
                                                                            ? ISOConsideredParticleSizeList?.filter((item) => this.props.testData?.consideredParticleSizes?.split(",")?.includes(item.value))
                                                                            : { label: "Select particle sizes", value: "" }}

                                                                        onChange={async (e) => {
                                                                            let str: string = "";
                                                                            if (Array.isArray(e)) {
                                                                                str = e?.filter?.(item => item.value)?.map((item) => item.value)?.join(",");
                                                                            }
                                                                            this.ValidateField("consideredParticleSizes", str);
                                                                            this.props.updateTestDataState({ name: "consideredParticleSizes", value: str })
                                                                        }}
                                                                    />


                                                            }
                                                            <ValidationMessageControl message={this.vResult("consideredParticleSizes").message} />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.props.equipmentData.zoneClassificationType == ZoneClassificationType.ClientSpecification ?
                                                            (() => {
                                                                let array: any[] = [];
                                                                let filters = this.props.testData?.consideredParticleSizes?.split(",");
                                                                if (filters?.length > 0) {
                                                                    if (filters?.includes('0.5µm')) {
                                                                        let element = <div className="col-3">
                                                                            <div className={`form-group`}>
                                                                                <label htmlFor="">Number of particles / m3 ( ≥ 0.5µm )</label>
                                                                                <input
                                                                                    type={"number"}
                                                                                    className="form-control"
                                                                                    value={this.props.testData?.pointFiveMicron ?? ""}
                                                                                    onChange={e => {
                                                                                        e.preventDefault();
                                                                                        this.props.updateTestDataState({ name: "pointFiveMicron", value: e.target.value });
                                                                                        // this.props.updateHasError(this.hasError);
                                                                                    }} />
                                                                            </div>
                                                                        </div>;
                                                                        array.push(element);
                                                                    }
                                                                    if (filters?.includes('1µm')) {
                                                                        let element = <div className="col-3">
                                                                            <div className={`form-group`}>
                                                                                <label htmlFor="">Number of particles / m3 ( ≥ 1µm )</label>
                                                                                <input
                                                                                    type={"number"}
                                                                                    className="form-control"
                                                                                    value={this.props.testData?.oneMicron ?? ""}
                                                                                    onChange={e => {
                                                                                        e.preventDefault();
                                                                                        this.props.updateTestDataState({ name: "oneMicron", value: e.target.value });
                                                                                        // this.props.updateHasError(this.hasError);
                                                                                    }} />
                                                                            </div>
                                                                        </div>;
                                                                        array.push(element);
                                                                    }
                                                                    if (filters?.includes('5µm')) {
                                                                        let element = <div className="col-3">
                                                                            <div className={`form-group`}>
                                                                                <label htmlFor="">Number of particles / m3 ( ≥ 5µm )</label>
                                                                                <input
                                                                                    type={"number"}
                                                                                    className="form-control"
                                                                                    value={this.props.testData?.fiveMicron ?? ""}
                                                                                    onChange={e => {
                                                                                        e.preventDefault();
                                                                                        this.props.updateTestDataState({ name: "fiveMicron", value: e.target.value });
                                                                                        // this.props.updateHasError(this.hasError);
                                                                                    }} />
                                                                            </div>
                                                                        </div>;
                                                                        array.push(element);
                                                                    }
                                                                }
                                                                return (array)
                                                            })() : ""
                                                    }

                                                    <div className="col-12 mb-3">
                                                        <div className={`form-group ${this.vResult("dataLoggerId").className}`}>
                                                            <ValidationMessageControl message={this.vResult("dataLoggerId").message} />
                                                            <DataLoggerSelectorAccordionComponent
                                                                defaultSelections={this.props.testData?.dataLogger ? [this.props.testData?.dataLogger] : null}
                                                                category={null}
                                                                onSelection={(d) => {
                                                                    this.ValidateField('dataLoggerId', d.id);
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: d });
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: d.id });
                                                                }}
                                                                onUnSelection={(d) => {
                                                                    this.ValidateField('dataLoggerId', 0);
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: 0 });
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: null });
                                                                }}
                                                                onUnSelectionAll={() => {
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: 0 });
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: null });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className={`form-group ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Conclusion</label>
                                                            <textarea id="equipmentName"
                                                                className="form-control"
                                                                value={this.props.testData?.remarks ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "remarks", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }}>
                                                            </textarea>
                                                            {/* <ValidationMessageControl message={this.vResult("poNumber").message} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>

                                    </div>
                                </div>
                            </div>
                            <article className="card no-bg">
                                <div className="row">
                                    <div className="col-12 text-right">

                                        <button type="button" className="btn mr-0 mb-0 btn-primary"
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                const error = !this.ValidateForm(this.props.testData, this.props.testData);
                                                this.reloadForm();
                                                if (error) return;
                                                if (this.props.testData?.id) {
                                                    await this.props.editTest({ testName: CleanroomAreaTests.nonviableparticlecount ?? "", data: this.props.testData });
                                                } else {
                                                    const { createdById, createdBy, preparedBy, checkedBy,

                                                        ...rest } = this.props.testData;
                                                    const payload = {
                                                        ...rest,
                                                        cleanroomAreaReportId: Number(this.props.id ?? 0),
                                                        clientId: this.props.equipmentData.clientId,
                                                        clientAddressId: this.props.equipmentData.clientAddressId,
                                                        clientEquipmentId: this.props.equipmentData.clientAreaId,
                                                    };

                                                    this.props.createTest({ testName: CleanroomAreaTests.nonviableparticlecount ?? "", data: payload });
                                                }
                                            }}>Submit</button>
                                    </div>
                                </div>
                            </article>
                            {
                                (() => {
                                    if (!this.props.testData?.id) return;
                                    return (
                                        <article className="card mb-4">
                                            <div className="card-header card-form-header d-flex w-100 align-items-center justify-content-between">
                                                <div className="card-form-header-title">
                                                    Test Observations
                                                </div>

                                                <button className="btn mr-0 mb-0 btn-primary small-btn"
                                                    onClick={() => this.setState({ ...this.state, openObservationPopup: !this.state.openObservationPopup, editObservation: null })}
                                                >
                                                    Add Observations
                                                </button>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12 grid-wrapper">
                                                        <div className="card-block px-0 ag-theme-alpine">
                                                            <AgGridReact
                                                                pagination={true}
                                                                paginationPageSize={10}
                                                                unSortIcon={true}
                                                                rowHeight={60}
                                                                domLayout="autoHeight"
                                                                defaultColDef={AgGridDefaultColDef}
                                                                columnDefs={[
                                                                    {
                                                                        hide: this.props.accessLevel < AccessLevel.Edit,
                                                                        headerName: "Edit", field: 'id', width: 100, cellClass: "grid-cell grid-cell-link",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => {
                                                                            return (
                                                                                <span
                                                                                    onClick={() => {
                                                                                        this.setState({ ...this.state, editObservation: params.data, openObservationPopup: true })
                                                                                    }}
                                                                                >
                                                                                    <i className={`feather icon-edit f-16 text-green`}></i>
                                                                                </span>
                                                                            )
                                                                        }
                                                                    },
                                                                    {
                                                                        hide: this.props.accessLevel < AccessLevel.Delete,
                                                                        headerName: "Delete", field: 'id', width: 100, cellClass: "grid-cell grid-cell-link",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => {
                                                                            return (
                                                                                <span
                                                                                    onClick={async () => {
                                                                                        this.props.deleteObservation({ testName: CleanroomAreaTests.nonviableparticlecount, id: params.value });
                                                                                    }}
                                                                                >
                                                                                    <i className={`feather icon-trash ml-2 f-16 text-green`}></i>
                                                                                </span>
                                                                            )
                                                                        }
                                                                    },

                                                                    {
                                                                        headerName: "ID#", field: "id", width: 100, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        headerName: "location Number", field: "locationNumber", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        headerName: "0.5µm", field: "pointFiveOrMoreMicron", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        headerName: "1µm", field: "oneOrMoreMicron", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        headerName: "5µm", field: "fiveOrMoreMicron", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },

                                                                ]}
                                                                rowData={this.getObservations()}>
                                                            </AgGridReact>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    )
                                })()
                            }

                            {this.props.testData?.lastComment?.commentDescription ?
                                <div className="card card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="equipmentName">Recent comment</label>
                                            <textarea
                                                className="form-control not-allowed"
                                                disabled
                                                value={this.props.testData?.lastComment?.commentDescription}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                : ""
                            }

                            {
                                this.props.testData?.id && this.state.openObservationPopup ?
                                    <TestLocationModel
                                        locations={this.props.testData?.locations?.map((item) => { return { name: item.locationNumber, id: item?.id } }) ?? []}
                                        toggle={this.state.openObservationPopup}
                                        handleToggleState={() => this.setState({ ...this.state, openObservationPopup: false, editObservation: null })}
                                        testData={this.props.testData}
                                        testName={CleanroomAreaTests.nonviableparticlecount ?? ""}
                                        defaultData={this.state.editObservation}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                </div >
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    testData: state.cleanroomAreaTest.testData,
    dataLoading: state.cleanroomAreaTest.dataLoading,
    createEditLoading: state.cleanroomAreaTest.createEditLoading,
    currentUser: state.userSession.currentUser,
    equipmentData: state.cleanroomequipment.equipmentData,
    refetchApi: state.cleanroomAreaTest.refetchApi,
});
export default connect(mapStateToProps, {
    clearTestDataState, updateTestDataState, editTest: editNVPCTest, createTest: createNVPCTest, loadTestById: loadNVPCTestById, loadCleanroomAreaById, deleteObservation: deleteNVPCObservation
})(TestManager);

