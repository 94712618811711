import { ICleanroomAreaModel } from "../../../../model/report.model";
import {
    DeferredLoadOptions,
} from "../../../../../../common/utils";
import { AgGridReact } from "ag-grid-react";
import ClientService from "../../../../../client/service/client.service";
import ClientAddressService from "../../../../../client/service/client-address.service";
import { IAppState } from "../../../../../../store";
import { connect } from "react-redux";
import { deleteTest } from "../../../../store/cleanroom-area/cleanroom-area-test.slice";
import React from "react";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../../../../common/screen-urls";
import AsyncSelect from "react-select/async";
import { IFormControlDropdownItemModel } from "../../../../../../common/control/model/form.control.field.model";
import DropdownDisplayHelper from "../../../../../../common/helper/dropdown-display.helper";
import { IUserModel } from "../../../../../user/model/user.model";
import ValidationMessageControl from "../../../../../../common/control/validation-message.control";
import {
    FormBaseComponent,
    IFormBaseState,
} from "../../../../../../common/component/form-base.component";
import { updateCleanroomEquipmentDataState, createAreaReport, loadCleanroomAreaById, editAreaReport, clearCleanroomAreaDataState } from "../../../../store/cleanroom-equipment/cleanroom-equipment.slice";
import { AccessLevel, CleanroomAreaTests, ValidationScreens, ZoneClassificationType } from "../../../../../../common/enums";
import ClientAreaSelectorComponent from "../../../../component/cleanroom/client-area-selector.component";
import { AgGridDefaultColDef } from "../../../../../../common/app-defaults";
import { IBaseTestPayload } from "../../../../model/cleanroom-equipment/test/test.model";
import AirChangesPerHourPDFComponent from "../../../../component/cleanroom/cleanroom-area-pdf/air-changes-per-hour-test-pdf";
import FilterIntegrityPDFComponent from "../../../../component/cleanroom/cleanroom-area-pdf/filter-integrity-test.component";
import NonViableParticleCountTestPDFComponent from "../../../../component/cleanroom/cleanroom-area-pdf/non-viable-particle-count-test-pdf";
import RecoveryTestPdf from "../../../../component/cleanroom/cleanroom-area-pdf/recovery-test-pdf";
import AirFlowPatternTestPdf from "../../../../component/cleanroom/cleanroom-area-pdf/air-flow-pattern-test-pdf";
import SoundLevelPDFComponent from "../../../../component/cleanroom/cleanroom-area-pdf/sound-level-test-pdf";
import LightIntensityPDFComponent from "../../../../component/cleanroom/cleanroom-area-pdf/light-intensity-test-pdf";
import UVLightTestPDFComponent from "../../../../component/cleanroom/cleanroom-area-pdf/uv-light-intensity-test-pdf";
import DPTestPdfComponent from "../../../../component/cleanroom/cleanroom-area-pdf/differential-pressure-test-pdf";
import ReactSelect from "react-select";
import { Standards } from "../../../../../../common/_static/report.data";
import ReportService from "../../../../service/report.service";
import DropdownService from "../../../../../../common/service/dropdown.service";
import { cleanroomAreaValidator } from "../../../../validator/cleanroom-area/cleanroom-area.validator";
export interface IProps {
    id?: string;
    areaData: ICleanroomAreaModel;
    createAreaReport: (data: ICleanroomAreaModel) => void,
    editCleanroomArea: (data: ICleanroomAreaModel) => void,
    loadCleanroomAreaById: (id: number) => void;
    clearAreaDataState: () => void;
    updateAreaDataState: (data) => void,
    dataLoading: boolean;
    createEditLoading: boolean;
    currentUser: IUserModel;
    accessLevel: number,
    refetchReportData: boolean,
    deleteTest: (data: IBaseTestPayload) => void,
}

export interface IState extends IFormBaseState {
    hideClientAddress: boolean;
    openTestPopup: boolean,
    hideZoneClassification: boolean,
    acphTestSectionCollapsed: boolean,
    fiTestSectionCollapsed: boolean,
    nvpcTestSectionCollapsed: boolean,
    recoveryTestSectionCollapsed: boolean,
    afptTestSectionCollapsed: boolean,
    slTestSectionCollapsed: boolean,
    LITestSectionCollapsed: boolean,
    UVLITestSectionCollapsed: boolean,
    DFTestSectionCollapsed: boolean,
}

class CleanroomAreaCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideZoneClassification: false,
            hideClientAddress: false,
            hideForm: false,
            openTestPopup: false,
            acphTestSectionCollapsed: false,
            fiTestSectionCollapsed: false,
            nvpcTestSectionCollapsed: false,
            recoveryTestSectionCollapsed: false,
            afptTestSectionCollapsed: false,
            slTestSectionCollapsed: false,
            LITestSectionCollapsed: false,
            UVLITestSectionCollapsed: false,
            DFTestSectionCollapsed: false,
        };
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.ReportCleanroomArea);

        if (this.props.id != null && this.props.id != "") {
            await this.props.loadCleanroomAreaById(Number(this.props.id));
        } else {
            await this.props.clearAreaDataState();
        }
    }
    loadClientOption = (inputValue: string) => DeferredLoadOptions(inputValue, ClientService.ClientDropdown);

    loadClientAddressOption = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
        new Promise<IFormControlDropdownItemModel[]>(async (resolve) => {
            if (this.props.areaData.client == null || this.props.areaData.client.id == null || this.props.areaData.client.id === 0) {
                resolve([]);
                return;
            }

            const client = await ClientService.GetById(Number(this.props.areaData.client?.id));
            if (client == null || client.clientAddresses == null) {
                resolve([]);
                return;
            }

            const list = client.clientAddresses.map(d => {
                const obj: IFormControlDropdownItemModel = {
                    label: DropdownDisplayHelper.ClientAddress(d),
                    value: d.id.toString()
                }
                return obj;
            });

            if (search == null || search === "") resolve(list);
            resolve(list.filter(l => l.label.indexOf(search) > 0))
        }))

    reloadBillingAddressDropdown = () => {
        this.setState({ hideClientAddress: true })

        setTimeout(() => {
            this.setState({ hideClientAddress: false })
        }, 1);
    }

    loadEUGMPClasses = (inputValue: string) => DeferredLoadOptions(inputValue, DropdownService.LoadEUGMPClasses);

    loadISOClasses = (inputValue: string) => DeferredLoadOptions(inputValue, DropdownService.LoadISOClasses);

    reloadZoneValueDropdown = () => {
        this.setState({ hideZoneClassification: true })

        setTimeout(() => {
            this.setState({ hideZoneClassification: false })
        }, 1);
    }

    async componentWillUnmount() {
        this.props.clearAreaDataState();
        await this.setValidator(ValidationScreens.None);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps.refetchReportData != this.props.refetchReportData) {
            if (this.props.id) {
                await this.props.loadCleanroomAreaById(Number(this.props.id));
            }
        }
    }

    render() {
        if (this.state.hideForm) return;
        return (
            <React.Fragment>
                {(() => {
                    if (this.props.dataLoading || this.props.createEditLoading)
                        return (
                            <div className="loading--bar fixed--top">
                                <span></span>
                            </div>
                        );
                })()}
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="page-header">
                            <div className="page-block">
                                <div className="row">
                                    <div className="col-md-12 d-flex align-items-center justify-content-between">
                                        <Link
                                            to={ScreenUrls.Report.Index()}
                                            className="btn drp-icon btn-rounded btn-primary dropdown-toggle"
                                        >
                                            <i className="feather icon-arrow-left"></i>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main-body">
                            <div className="page-wrapper">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">
                                                    Basic Details
                                                </div>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("reportNumber").className}`}>
                                                            <label htmlFor="reportNumber">Report Number<span className="requried-span">*</span></label>
                                                            <input id="reportNumber" className="form-control" type="text"
                                                                value={this.props.areaData?.reportNumber ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("reportNumber", e.target.value);
                                                                    this.props.updateAreaDataState({ name: "reportNumber", value: e.target.value });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("reportNumber").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("clientId").className}`}>
                                                            <label htmlFor="clientid">Client<span className="requried-span">*</span></label>
                                                            <AsyncSelect id="clientId" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadClientOption}
                                                                value={this.props.areaData.client?.id
                                                                    ? { label: DropdownDisplayHelper.Client(this.props.areaData.client), value: this.props.areaData.client.id.toString() }
                                                                    : { label: "Select Client", value: "" }}
                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    this.ValidateField("clientId", cId);
                                                                    if (cId === null || cId === 0 || (this.props.areaData.client != null && cId === this.props.areaData.client.id)) return;

                                                                    const client = await ClientService.GetById(cId);

                                                                    this.props.updateAreaDataState({ name: "client", value: client });
                                                                    this.props.updateAreaDataState({ name: "clientId", value: client.id });

                                                                    this.props.updateAreaDataState({ name: "clientAddress", value: null });
                                                                    this.props.updateAreaDataState({ name: "clientAddressId", value: null });

                                                                    this.reloadBillingAddressDropdown();

                                                                    //this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("clientId").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("clientAddressId").className}`}>
                                                            <label htmlFor="clientAddressId">Client Address<span className="requried-span">*</span></label>
                                                            {(() => {
                                                                if (this.state.hideClientAddress) return;
                                                                return (
                                                                    <AsyncSelect id="clientAddressId" cacheOptions defaultOptions
                                                                        className="async-select-control"
                                                                        loadOptions={this.loadClientAddressOption}
                                                                        value={this.props.areaData.clientAddress?.id
                                                                            ? { label: DropdownDisplayHelper.ClientAddress(this.props.areaData.clientAddress), value: this.props.areaData.clientAddress.id.toString() }
                                                                            : { label: "Select ClientAddress", value: "" }}
                                                                        onChange={async (e) => {
                                                                            const cId = Number(e?.value);
                                                                            this.ValidateField("clientAddressId", cId);
                                                                            if (cId === null || cId === 0 || (this.props.areaData.clientAddress != null && cId === this.props.areaData.clientAddress.id)) return;

                                                                            const client = await ClientAddressService.GetById(cId);

                                                                            this.props.updateAreaDataState({ name: "clientAddress", value: client });
                                                                            this.props.updateAreaDataState({ name: "clientAddressId", value: client.id });
                                                                        }} />
                                                                )
                                                            })()}

                                                            <ValidationMessageControl message={this.vResult("clientAddressId").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("").className}`}>
                                                            <label htmlFor="">Downstream acceptance Criteria</label>
                                                            <input id="" className="form-control" type="number"
                                                                value={this.props.areaData?.downstreamAcceptanceCriteria ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("downstreamAcceptanceCriteria", +e.target.value);
                                                                    this.props.updateAreaDataState({ name: "downstreamAcceptanceCriteria", value: e?.target?.value });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("downstreamAcceptanceCriteria").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="name">Filter Type</label>
                                                            <input id="name" className="form-control" type="text"
                                                                value={this.props.areaData?.filterType ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateAreaDataState({ name: "filterType", value: e.target.value })
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("").className}`}>
                                                            <label htmlFor="">Room Name</label>
                                                            <input id="" className="form-control" type="text"
                                                                value={this.props.areaData?.roomName ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("roomName", e.target.value);
                                                                    this.props.updateAreaDataState({ name: "roomName", value: e?.target?.value ?? "" });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("roomName").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("").className}`}>
                                                            <label htmlFor="">Room Number</label>
                                                            <input id="" className="form-control" type="text"
                                                                value={this.props.areaData?.roomNumber ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("roomNumber", e.target.value);
                                                                    this.props.updateAreaDataState({ name: "roomNumber", value: e?.target?.value ?? "" });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("roomNumber").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group`}>
                                                            <label htmlFor="standard">Standard<span className="requried-span">*</span></label>
                                                            <ReactSelect id="standard"
                                                                className="async-select-control"
                                                                options={Standards}
                                                                value={this.props.areaData?.zoneClassificationType
                                                                    ? Standards.find((item) => item.value == this.props.areaData?.zoneClassificationType?.toString())
                                                                    : { label: "Select standard", value: "" }}

                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    if (cId === null || cId === 0) return;
                                                                    let standard = Standards.find((item) => Number(item.value) == cId);
                                                                    this.props.updateAreaDataState({ name: "zoneClassificationType", value: Number(standard?.value) });
                                                                    if (Number(standard?.value) == ZoneClassificationType.EUGMP) {
                                                                        this.props.updateAreaDataState({ name: 'eugpmStandardId', value: Number(standard?.value) });
                                                                        this.reloadZoneValueDropdown();
                                                                    }
                                                                    else if (Number(standard?.value) == ZoneClassificationType.ISO) {
                                                                        this.props.updateAreaDataState({ name: 'isoStandardId', value: Number(standard?.value) });
                                                                        this.reloadZoneValueDropdown();
                                                                    }
                                                                    else if (Number(standard?.value) == ZoneClassificationType.ClientSpecification) {
                                                                        this.props.updateAreaDataState({ name: 'isoStandardId', value: null });
                                                                        this.props.updateAreaDataState({ name: 'eugmpStandardId', value: null });
                                                                        this.props.updateAreaDataState({ name: 'isoStandard', value: null });
                                                                        this.props.updateAreaDataState({ name: 'eugmpStandard', value: null });
                                                                    }
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        this.props.areaData.zoneClassificationType == ZoneClassificationType.ClientSpecification ?
                                                            null
                                                            :
                                                            <div className="col-3">
                                                                {(() => {
                                                                    if (this.state.hideZoneClassification) return;
                                                                    if (this.props.areaData.zoneClassificationType == ZoneClassificationType.EUGMP) {
                                                                        return (
                                                                            <div className={`form-group ${this.vResult("eugmpStandardId").className}`}>
                                                                                <label htmlFor="eugmpStandardId">Area Classification Value<span className="requried-span">*</span></label>
                                                                                <AsyncSelect id="eugmpStandardId" cacheOptions defaultOptions
                                                                                    className="async-select-control"
                                                                                    loadOptions={this.loadEUGMPClasses}
                                                                                    value={this.props.areaData.eugmpStandardId ?
                                                                                        { label: DropdownDisplayHelper.EUGMP(this.props.areaData.eugmpStandard ?? null), value: this.props.areaData.eugmpStandard?.id.toString() }
                                                                                        : { label: "Select Grade", value: "" }}
                                                                                    onChange={async (e) => {
                                                                                        const cId = Number(e?.value);
                                                                                        this.ValidateField("eugmpStandardId", cId, this.props.areaData?.zoneClassificationType, undefined, cleanroomAreaValidator.fields[4]?.callBack);
                                                                                        if (cId === null) return;
                                                                                        const value = await ReportService.GetEUGMPtandardById(cId.toString());
                                                                                        this.props.updateAreaDataState({ name: "eugmpStandardId", value: value.id });
                                                                                        this.props.updateAreaDataState({ name: "eugmpStandard", value: value });
                                                                                        this.props.updateAreaDataState({ name: "isoStandardId", value: null });
                                                                                        this.props.updateAreaDataState({ name: "isoStandard", value: null });
                                                                                    }} />
                                                                                <ValidationMessageControl message={this.vResult("eugmpStandardId").message} />
                                                                            </div>
                                                                        )
                                                                    }
                                                                    if (this.props.areaData.zoneClassificationType == ZoneClassificationType.ISO) {
                                                                        return (
                                                                            <div className={`form-group ${this.vResult("isoStandardId").className}`}>
                                                                                <label htmlFor="isoStandardId">Area Classification Value<span className="requried-span">*</span></label>
                                                                                <AsyncSelect id="isoStandardId" cacheOptions defaultOptions
                                                                                    className="async-select-control"
                                                                                    loadOptions={this.loadISOClasses}
                                                                                    value={this.props.areaData.isoStandardId ?
                                                                                        { label: this.props.areaData.isoStandard?.classNumber, value: this.props.areaData.isoStandard?.id?.toString() }
                                                                                        : { label: "Select class", value: "" }}
                                                                                    onChange={async (e) => {
                                                                                        const cId = Number(e?.value);
                                                                                        this.ValidateField("isoStandardId", cId, this.props.areaData?.zoneClassificationType, undefined, cleanroomAreaValidator?.fields[5]?.callBack);
                                                                                        if (cId === null) return;
                                                                                        const value = await ReportService.GetISOStandardById(cId.toString());
                                                                                        this.props.updateAreaDataState({ name: "isoStandardId", value: value.id });
                                                                                        this.props.updateAreaDataState({ name: "isoStandard", value: value });
                                                                                        this.props.updateAreaDataState({ name: "eugmpStandardId", value: null });
                                                                                        this.props.updateAreaDataState({ name: "eugmpStandardId", value: null });
                                                                                    }} />
                                                                                <ValidationMessageControl message={this.vResult("isoStandardId").message} />
                                                                            </div>
                                                                        )
                                                                    }
                                                                })()}
                                                            </div>
                                                    }
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="name">Protocol Name</label>
                                                            <input id="name" className="form-control" type="text"
                                                                value={this.props.areaData?.refStandardOrProtocolOrDocumentName ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateAreaDataState({ name: "refStandardOrProtocolOrDocumentName", value: e.target.value })

                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="name">Protocol Number</label>
                                                            <input id="name" className="form-control" type="text"
                                                                value={this.props.areaData?.procedureOrProtocolOrDocumentNumber ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();

                                                                    this.props.updateAreaDataState({ name: "procedureOrProtocolOrDocumentNumber", value: e.target.value })

                                                                }} />

                                                        </div>
                                                    </div>
                                                    {
                                                        this.props.areaData?.client?.id ?
                                                            <div className="col-12">
                                                                <div className={''}>
                                                                    <div className={`form-group mb-0 ${this.vResult("clientAreaId").className}`}>
                                                                        <ValidationMessageControl message={this.vResult("clientAreaId").message} />
                                                                    </div>
                                                                    <ClientAreaSelectorComponent
                                                                        clientId={this.props.areaData?.client?.id}
                                                                        defaultSelections={this.props.areaData?.clientArea ? [this.props.areaData?.clientArea] : null}
                                                                        onSelection={(d) => {
                                                                            this.ValidateField('clientAreaId', d.id);
                                                                            this.props.updateAreaDataState({ name: "clientAreaId", value: d.id });
                                                                            this.props.updateAreaDataState({ name: "clientArea", value: d });
                                                                        }}
                                                                        onUnSelection={(d) => {
                                                                            this.ValidateField('clientAreaId', d.id);
                                                                            if (d.id == this.props.areaData?.clientAreaId) {
                                                                                this.props.updateAreaDataState({ name: "clientAreaId", value: 0 });
                                                                                this.props.updateAreaDataState({ name: "clientArea", value: null });
                                                                            }
                                                                        }}
                                                                    />
                                                                </div>
                                                            </div> : ""
                                                    }
                                                </div>
                                            </div>
                                        </article>

                                        <article className="card no-bg">
                                            <div className="row">
                                                <div className="col-12 text-right">
                                                    <button type="button" className="btn mr-0 mb-0 btn-primary"
                                                        onClick={async (e) => {
                                                            e.preventDefault();
                                                            const error = !this.ValidateForm(this.props.areaData, this.props.areaData);
                                                            this.reloadForm();
                                                            if (error) return;
                                                            if (this.props.areaData?.id) {
                                                                await this.props.editCleanroomArea(this.props.areaData);
                                                            } else {
                                                                const { client, clientAddress, ...rest } = this.props.areaData;
                                                                this.props.createAreaReport({ ...rest });
                                                            }
                                                        }}>Submit</button>
                                                </div>
                                            </div>
                                        </article>


                                        {/* --- ACPH Test --- */}
                                        {
                                            (() => {
                                                if (!this.props.areaData?.id) return;
                                                return (
                                                    <div className="accordion no-bg mt-3" id="acphTestAccordionContainer">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                                                    data-target="#acphTestAccordionControl" aria-expanded="false" aria-controls="acphTestAccordionControl"
                                                                    onClick={() => { this.setState({ ...this.state, acphTestSectionCollapsed: !this.state.acphTestSectionCollapsed }) }}>
                                                                    <i className={`button-icon feather ${this.state.acphTestSectionCollapsed ? 'icon-chevrons-up' : 'icon-chevrons-down'}`}></i>
                                                                    Air Changes Per Hour Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.areaData?.airChangesPerHourTests?.length}</span>
                                                                </button>
                                                            </div>

                                                            <div id="acphTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                                                data-parent="#acphTestAccordionContainer">
                                                                <article className="card">
                                                                    <div className="card-body pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12 text-right my-2">
                                                                                <Link className={"btn drp-icon btn-primary m-0"}
                                                                                    to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.airchangesperhour}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 grid-wrapper">
                                                                                <div className="card-block px-0 ag-theme-alpine">
                                                                                    <AgGridReact
                                                                                        pagination={true}
                                                                                        paginationPageSize={10}
                                                                                        unSortIcon={true}
                                                                                        rowHeight={60}
                                                                                        domLayout="autoHeight"
                                                                                        defaultColDef={AgGridDefaultColDef}
                                                                                        columnDefs={[
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <Link
                                                                                                            to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.airchangesperhour}/${ScreenUrls.EditUri}/${params.value}`}
                                                                                                        >
                                                                                                            <i className={`feather icon-edit`}></i>
                                                                                                        </Link>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <span
                                                                                                            className=""
                                                                                                            onClick={() => { this.props.deleteTest({ testName: CleanroomAreaTests.airchangesperhour, id: params.value }) }}

                                                                                                        >
                                                                                                            <i className={`feather icon-trash f-16 text-green`}></i>
                                                                                                        </span>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },
                                                                                            {
                                                                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },

                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                                                                cellRenderer: (params) => {
                                                                                                    return (params.value)
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Action", field: 'id', width: 170, cellClass: "grid-cell",
                                                                                                cellRenderer: (params) => {
                                                                                                    return <AirChangesPerHourPDFComponent id={params.value ?? 0}
                                                                                                        currentUser={this.props.currentUser}
                                                                                                        data={{ ...this.props.areaData, ...params.data }} />
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                        rowData={this.props.areaData?.airChangesPerHourTests ?? []}>
                                                                                    </AgGridReact>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>


                                                        </div>
                                                    </div>
                                                )
                                            })()
                                        }

                                        {/* --- FI Test --- */}
                                        {
                                            (() => {
                                                if (!this.props.areaData?.id) return;
                                                return (
                                                    <div className="accordion no-bg mt-3" id="fiTestAccordionContainer">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                                                    data-target="#fiTestAccordionControl" aria-expanded="false" aria-controls="fiTestAccordionControl"
                                                                    onClick={() => { this.setState({ ...this.state, fiTestSectionCollapsed: !this.state.fiTestSectionCollapsed }) }}>
                                                                    <i className={`button-icon feather ${this.state.fiTestSectionCollapsed ? 'icon-chevrons-up' : 'icon-chevrons-down'}`}></i>
                                                                    Filter Integrity Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.areaData?.filterIntegrityTests?.length}</span>
                                                                </button>
                                                            </div>

                                                            <div id="fiTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                                                data-parent="#fiTestAccordionContainer">
                                                                <article className="card">
                                                                    <div className="card-body pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12 text-right my-2">
                                                                                <Link className={"btn drp-icon btn-primary m-0"}
                                                                                    to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.filterintegrity}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 grid-wrapper">
                                                                                <div className="card-block px-0 ag-theme-alpine">
                                                                                    <AgGridReact
                                                                                        pagination={true}
                                                                                        paginationPageSize={10}
                                                                                        unSortIcon={true}
                                                                                        rowHeight={60}
                                                                                        domLayout="autoHeight"
                                                                                        defaultColDef={AgGridDefaultColDef}
                                                                                        columnDefs={[
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <Link
                                                                                                            to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.filterintegrity}/${ScreenUrls.EditUri}/${params.value}`}
                                                                                                        >
                                                                                                            <i className={`feather icon-edit`}></i>
                                                                                                        </Link>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <span
                                                                                                            className=""
                                                                                                            onClick={() => { this.props.deleteTest({ testName: CleanroomAreaTests.filterintegrity, id: params.value }) }}

                                                                                                        >
                                                                                                            <i className={`feather icon-trash f-16 text-green`}></i>
                                                                                                        </span>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },
                                                                                            {
                                                                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },

                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                                                                cellRenderer: (params) => {
                                                                                                    return (params.value)
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Action", field: 'id', width: 170, cellClass: "grid-cell",
                                                                                                cellRenderer: (params) => {
                                                                                                    return <FilterIntegrityPDFComponent id={params.value ?? 0}
                                                                                                        currentUser={this.props.currentUser}
                                                                                                        data={{ ...this.props.areaData, ...params.data }} />
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                        rowData={this.props.areaData?.filterIntegrityTests ?? []}>
                                                                                    </AgGridReact>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>


                                                        </div>
                                                    </div>
                                                )
                                            })()
                                        }

                                        {/* --- NVPC Test --- */}
                                        {
                                            (() => {
                                                if (!this.props.areaData?.id) return;
                                                return (
                                                    <div className="accordion no-bg mt-3" id="nvpcTestAccordionContainer">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                                                    data-target="#nvpcTestAccordionControl" aria-expanded="false" aria-controls="nvpcTestAccordionControl"
                                                                    onClick={() => { this.setState({ ...this.state, nvpcTestSectionCollapsed: !this.state.nvpcTestSectionCollapsed }) }}>
                                                                    <i className={`button-icon feather ${this.state.nvpcTestSectionCollapsed ? 'icon-chevrons-up' : 'icon-chevrons-down'}`}></i>
                                                                    Non-Viable Particle Count Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.areaData?.nonViableParticleCountTests?.length}</span>
                                                                </button>
                                                            </div>

                                                            <div id="nvpcTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                                                data-parent="#nvpcTestAccordionContainer">
                                                                <article className="card">
                                                                    <div className="card-body pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12 text-right my-2">
                                                                                <Link className={"btn drp-icon btn-primary m-0"}
                                                                                    to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.nonviableparticlecount}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 grid-wrapper">
                                                                                <div className="card-block px-0 ag-theme-alpine">
                                                                                    <AgGridReact
                                                                                        pagination={true}
                                                                                        paginationPageSize={10}
                                                                                        unSortIcon={true}
                                                                                        rowHeight={60}
                                                                                        domLayout="autoHeight"
                                                                                        defaultColDef={AgGridDefaultColDef}
                                                                                        columnDefs={[
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <Link
                                                                                                            to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.nonviableparticlecount}/${ScreenUrls.EditUri}/${params.value}`}
                                                                                                        >
                                                                                                            <i className={`feather icon-edit`}></i>
                                                                                                        </Link>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <span
                                                                                                            className=""
                                                                                                            onClick={() => { this.props.deleteTest({ testName: CleanroomAreaTests.nonviableparticlecount, id: params.value }) }}

                                                                                                        >
                                                                                                            <i className={`feather icon-trash f-16 text-green`}></i>
                                                                                                        </span>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },
                                                                                            {
                                                                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },

                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                                                                cellRenderer: (params) => {
                                                                                                    return (params.value)
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Action", field: 'id', width: 170, cellClass: "grid-cell",
                                                                                                cellRenderer: (params) => {
                                                                                                    return <NonViableParticleCountTestPDFComponent id={params.value ?? 0}
                                                                                                        currentUser={this.props.currentUser}
                                                                                                        data={{ ...this.props.areaData, ...params.data }} />
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                        rowData={this.props.areaData?.nonViableParticleCountTests ?? []}>
                                                                                    </AgGridReact>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>


                                                        </div>
                                                    </div>
                                                )
                                            })()
                                        }

                                        {
                                            (() => {
                                                if (!this.props.areaData?.id) return;
                                                return (
                                                    <div className="accordion no-bg mt-3" id="recoveryTestAccordionContainer">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                                                    data-target="#recoveryTestAccordionControl" aria-expanded="false" aria-controls="recoveryTestAccordionControl"
                                                                    onClick={() => { this.setState({ ...this.state, recoveryTestSectionCollapsed: !this.state.recoveryTestSectionCollapsed }) }}>
                                                                    <i className={`button-icon feather ${this.state.recoveryTestSectionCollapsed ? 'icon-chevrons-up' : 'icon-chevrons-down'}`}></i>
                                                                    Recovery Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.areaData?.recoveryTests?.length}</span>
                                                                </button>
                                                            </div>

                                                            <div id="recoveryTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                                                data-parent="#recoveryTestAccordionContainer">
                                                                <article className="card">
                                                                    <div className="card-body pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12 text-right my-2">
                                                                                <Link className={"btn drp-icon btn-primary m-0"}
                                                                                    to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.recovery}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 grid-wrapper">
                                                                                <div className="card-block px-0 ag-theme-alpine">
                                                                                    <AgGridReact
                                                                                        pagination={true}
                                                                                        paginationPageSize={10}
                                                                                        unSortIcon={true}
                                                                                        rowHeight={60}
                                                                                        domLayout="autoHeight"
                                                                                        defaultColDef={AgGridDefaultColDef}
                                                                                        columnDefs={[
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <Link
                                                                                                            to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.recovery}/${ScreenUrls.EditUri}/${params.value}`}
                                                                                                        >
                                                                                                            <i className={`feather icon-edit`}></i>
                                                                                                        </Link>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <span
                                                                                                            className=""
                                                                                                            onClick={() => { this.props.deleteTest({ testName: CleanroomAreaTests.recovery, id: params.value }) }}

                                                                                                        >
                                                                                                            <i className={`feather icon-trash f-16 text-green`}></i>
                                                                                                        </span>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },
                                                                                            {
                                                                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },

                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                                                                cellRenderer: (params) => {
                                                                                                    return (params.value)
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Action", field: 'id', width: 170, cellClass: "grid-cell",
                                                                                                cellRenderer: (params) => {
                                                                                                    return <RecoveryTestPdf id={params.value ?? 0}
                                                                                                        currentUser={this.props.currentUser}
                                                                                                        data={{ ...this.props.areaData, ...params.data }} />
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                        rowData={this.props.areaData?.recoveryTests ?? []}>
                                                                                    </AgGridReact>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>


                                                        </div>
                                                    </div>
                                                )
                                            })()
                                        }

                                        {
                                            (() => {
                                                if (!this.props.areaData?.id) return;
                                                return (
                                                    <div className="accordion no-bg mt-3" id="afpTestAccordionContainer">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                                                    data-target="#afpTestAccordionControl" aria-expanded="false" aria-controls="afpTestAccordionControl"
                                                                    onClick={() => { this.setState({ ...this.state, afptTestSectionCollapsed: !this.state.afptTestSectionCollapsed }) }}>
                                                                    <i className={`button-icon feather ${this.state.afptTestSectionCollapsed ? 'icon-chevrons-up' : 'icon-chevrons-down'}`}></i>
                                                                    Air Flow Pattern Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.areaData?.airFlowPatternTests?.length}</span>
                                                                </button>
                                                            </div>

                                                            <div id="afpTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                                                data-parent="#afpTestAccordionContainer">
                                                                <article className="card">
                                                                    <div className="card-body pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12 text-right my-2">
                                                                                <Link className={"btn drp-icon btn-primary m-0"}
                                                                                    to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.airflowpattern}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 grid-wrapper">
                                                                                <div className="card-block px-0 ag-theme-alpine">
                                                                                    <AgGridReact
                                                                                        pagination={true}
                                                                                        paginationPageSize={10}
                                                                                        unSortIcon={true}
                                                                                        rowHeight={60}
                                                                                        domLayout="autoHeight"
                                                                                        defaultColDef={AgGridDefaultColDef}
                                                                                        columnDefs={[
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <Link
                                                                                                            to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.airflowpattern}/${ScreenUrls.EditUri}/${params.value}`}
                                                                                                        >
                                                                                                            <i className={`feather icon-edit`}></i>
                                                                                                        </Link>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <span
                                                                                                            className=""
                                                                                                            onClick={() => { this.props.deleteTest({ testName: CleanroomAreaTests.airflowpattern, id: params.value }) }}

                                                                                                        >
                                                                                                            <i className={`feather icon-trash f-16 text-green`}></i>
                                                                                                        </span>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },
                                                                                            {
                                                                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },

                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                                                                cellRenderer: (params) => {
                                                                                                    return (params.value)
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Action", field: 'id', width: 170, cellClass: "grid-cell",
                                                                                                cellRenderer: (params) => {
                                                                                                    return <AirFlowPatternTestPdf id={params.value ?? 0}
                                                                                                        currentUser={this.props.currentUser}
                                                                                                        data={{ ...this.props.areaData, ...params.data }} />
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                        rowData={this.props.areaData?.airFlowPatternTests ?? []}>
                                                                                    </AgGridReact>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })()
                                        }

                                        {
                                            (() => {
                                                if (!this.props.areaData?.id) return;
                                                return (
                                                    <div className="accordion no-bg mt-3" id="slTestAccordionContainer">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                                                    data-target="#slTestAccordionControl" aria-expanded="false" aria-controls="slTestAccordionControl"
                                                                    onClick={() => { this.setState({ ...this.state, slTestSectionCollapsed: !this.state.slTestSectionCollapsed }) }}>
                                                                    <i className={`button-icon feather ${this.state.slTestSectionCollapsed ? 'icon-chevrons-up' : 'icon-chevrons-down'}`}></i>
                                                                    Sound Level Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.areaData?.soundLevelTests?.length}</span>
                                                                </button>
                                                            </div>

                                                            <div id="slTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                                                data-parent="#slTestAccordionContainer">
                                                                <article className="card">
                                                                    <div className="card-body pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12 text-right my-2">
                                                                                <Link className={"btn drp-icon btn-primary m-0"}
                                                                                    to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.soundlevel}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 grid-wrapper">
                                                                                <div className="card-block px-0 ag-theme-alpine">
                                                                                    <AgGridReact
                                                                                        pagination={true}
                                                                                        paginationPageSize={10}
                                                                                        unSortIcon={true}
                                                                                        rowHeight={60}
                                                                                        domLayout="autoHeight"
                                                                                        defaultColDef={AgGridDefaultColDef}
                                                                                        columnDefs={[
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <Link
                                                                                                            to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.soundlevel}/${ScreenUrls.EditUri}/${params.value}`}
                                                                                                        >
                                                                                                            <i className={`feather icon-edit`}></i>
                                                                                                        </Link>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <span
                                                                                                            className=""
                                                                                                            onClick={() => { this.props.deleteTest({ testName: CleanroomAreaTests.soundlevel, id: params.value }) }}

                                                                                                        >
                                                                                                            <i className={`feather icon-trash f-16 text-green`}></i>
                                                                                                        </span>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },
                                                                                            {
                                                                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },

                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                                                                cellRenderer: (params) => {
                                                                                                    return (params.value)
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Action", field: 'id', width: 170, cellClass: "grid-cell",
                                                                                                cellRenderer: (params) => {
                                                                                                    return <SoundLevelPDFComponent id={params.value ?? 0}
                                                                                                        currentUser={this.props.currentUser}
                                                                                                        data={{ ...this.props.areaData, ...params.data }} />
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                        rowData={this.props.areaData?.soundLevelTests ?? []}>
                                                                                    </AgGridReact>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })()
                                        }

                                        {
                                            (() => {
                                                if (!this.props.areaData?.id) return;
                                                return (
                                                    <div className="accordion no-bg mt-3" id="uvliTestAccordionContainer">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                                                    data-target="#uvliTestAccordionControl" aria-expanded="false" aria-controls="uvliTestAccordionControl"
                                                                    onClick={() => { this.setState({ ...this.state, UVLITestSectionCollapsed: !this.state.UVLITestSectionCollapsed }) }}>
                                                                    <i className={`button-icon feather ${this.state.UVLITestSectionCollapsed ? 'icon-chevrons-up' : 'icon-chevrons-down'}`}></i>
                                                                    UV Light Intensity Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.areaData?.uvLightIntensityTests?.length}</span>
                                                                </button>
                                                            </div>

                                                            <div id="uvliTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                                                data-parent="#uvliTestAccordionContainer">
                                                                <article className="card">
                                                                    <div className="card-body pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12 text-right my-2">
                                                                                <Link className={"btn drp-icon btn-primary m-0"}
                                                                                    to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.uvlightintensity}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 grid-wrapper">
                                                                                <div className="card-block px-0 ag-theme-alpine">
                                                                                    <AgGridReact
                                                                                        pagination={true}
                                                                                        paginationPageSize={10}
                                                                                        unSortIcon={true}
                                                                                        rowHeight={60}
                                                                                        domLayout="autoHeight"
                                                                                        defaultColDef={AgGridDefaultColDef}
                                                                                        columnDefs={[
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <Link
                                                                                                            to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.uvlightintensity}/${ScreenUrls.EditUri}/${params.value}`}
                                                                                                        >
                                                                                                            <i className={`feather icon-edit`}></i>
                                                                                                        </Link>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <span
                                                                                                            className=""
                                                                                                            onClick={() => { this.props.deleteTest({ testName: CleanroomAreaTests.uvlightintensity, id: params.value }) }}

                                                                                                        >
                                                                                                            <i className={`feather icon-trash f-16 text-green`}></i>
                                                                                                        </span>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },
                                                                                            {
                                                                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },

                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                                                                cellRenderer: (params) => {
                                                                                                    return (params.value)
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Action", field: 'id', width: 170, cellClass: "grid-cell",
                                                                                                cellRenderer: (params) => {
                                                                                                    return <UVLightTestPDFComponent id={params.value ?? 0}
                                                                                                        currentUser={this.props.currentUser}
                                                                                                        data={{ ...this.props.areaData, ...params.data }} />
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                        rowData={this.props.areaData?.uvLightIntensityTests ?? []}>
                                                                                    </AgGridReact>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })()
                                        }

                                        {
                                            (() => {
                                                if (!this.props.areaData?.id) return;
                                                return (
                                                    <div className="accordion no-bg mt-3" id="liTestAccordionContainer">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                                                    data-target="#liTestAccordionControl" aria-expanded="false" aria-controls="liTestAccordionControl"
                                                                    onClick={() => { this.setState({ ...this.state, LITestSectionCollapsed: !this.state.LITestSectionCollapsed }) }}>
                                                                    <i className={`button-icon feather ${this.state.LITestSectionCollapsed ? 'icon-chevrons-up' : 'icon-chevrons-down'}`}></i>
                                                                    Light Intensity Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.areaData?.lightIntensityTests?.length}</span>
                                                                </button>
                                                            </div>

                                                            <div id="liTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                                                data-parent="#liTestAccordionContainer">
                                                                <article className="card">
                                                                    <div className="card-body pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12 text-right my-2">
                                                                                <Link className={"btn drp-icon btn-primary m-0"}
                                                                                    to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.lightintensity}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 grid-wrapper">
                                                                                <div className="card-block px-0 ag-theme-alpine">
                                                                                    <AgGridReact
                                                                                        pagination={true}
                                                                                        paginationPageSize={10}
                                                                                        unSortIcon={true}
                                                                                        rowHeight={60}
                                                                                        domLayout="autoHeight"
                                                                                        defaultColDef={AgGridDefaultColDef}
                                                                                        columnDefs={[
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <Link
                                                                                                            to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.lightintensity}/${ScreenUrls.EditUri}/${params.value}`}
                                                                                                        >
                                                                                                            <i className={`feather icon-edit`}></i>
                                                                                                        </Link>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <span
                                                                                                            className=""
                                                                                                            onClick={() => { this.props.deleteTest({ testName: CleanroomAreaTests.lightintensity, id: params.value }) }}

                                                                                                        >
                                                                                                            <i className={`feather icon-trash f-16 text-green`}></i>
                                                                                                        </span>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },
                                                                                            {
                                                                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },

                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                                                                cellRenderer: (params) => {
                                                                                                    return (params.value)
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Action", field: 'id', width: 170, cellClass: "grid-cell",
                                                                                                cellRenderer: (params) => {
                                                                                                    return <LightIntensityPDFComponent id={params.value ?? 0}
                                                                                                        currentUser={this.props.currentUser}
                                                                                                        data={{ ...this.props.areaData, ...params.data }} />
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                        rowData={this.props.areaData?.lightIntensityTests ?? []}>
                                                                                    </AgGridReact>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })()
                                        }

                                        {
                                            (() => {
                                                if (!this.props.areaData?.id) return;
                                                return (
                                                    <div className="accordion no-bg mt-3" id="DFTestAccordionContainer">
                                                        <div className="card">
                                                            <div className="card-header">
                                                                <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                                                                    data-target="#DFTestAccordionControl" aria-expanded="false" aria-controls="DFTestAccordionControl"
                                                                    onClick={() => { this.setState({ ...this.state, DFTestSectionCollapsed: !this.state.DFTestSectionCollapsed }) }}>
                                                                    <i className={`button-icon feather ${this.state.DFTestSectionCollapsed ? 'icon-chevrons-up' : 'icon-chevrons-down'}`}></i>
                                                                    Differential Pressure Tests <span className="ml-2 btn-rounded btn-secondary m-0 font-12 pill">{this.props.areaData?.differentialPressureTests?.length}</span>
                                                                </button>
                                                            </div>

                                                            <div id="DFTestAccordionControl" className="collapse" aria-labelledby="headingOne"
                                                                data-parent="#DFTestAccordionContainer">
                                                                <article className="card">
                                                                    <div className="card-body pt-0">
                                                                        <div className="row">
                                                                            <div className="col-12 text-right my-2">
                                                                                <Link className={"btn drp-icon btn-primary m-0"}
                                                                                    to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.differentialpressure}/${ScreenUrls.CreateUri}`}>Add Test</Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-12 grid-wrapper">
                                                                                <div className="card-block px-0 ag-theme-alpine">
                                                                                    <AgGridReact
                                                                                        pagination={true}
                                                                                        paginationPageSize={10}
                                                                                        unSortIcon={true}
                                                                                        rowHeight={60}
                                                                                        domLayout="autoHeight"
                                                                                        defaultColDef={AgGridDefaultColDef}
                                                                                        columnDefs={[
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Edit,
                                                                                                headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <Link
                                                                                                            to={`${ScreenUrls.Report.CleanroomArea.Edit(this.props.areaData?.id)}/${CleanroomAreaTests.differentialpressure}/${ScreenUrls.EditUri}/${params.value}`}
                                                                                                        >
                                                                                                            <i className={`feather icon-edit`}></i>
                                                                                                        </Link>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                hide: this.props.accessLevel < AccessLevel.Delete,
                                                                                                headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => {
                                                                                                    return (

                                                                                                        <span
                                                                                                            className=""
                                                                                                            onClick={() => { this.props.deleteTest({ testName: CleanroomAreaTests.differentialpressure, id: params.value }) }}

                                                                                                        >
                                                                                                            <i className={`feather icon-trash f-16 text-green`}></i>
                                                                                                        </span>

                                                                                                    )
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },
                                                                                            {
                                                                                                headerName: "Verified By", field: "verifiedByName", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                                                suppressMovable: true,
                                                                                                cellRenderer: (params) => <span>{params.value}</span>
                                                                                            },

                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Conclusion", field: 'remarks', flex: 2, cellClass: "grid-cell grid-cell-button",
                                                                                                cellRenderer: (params) => {
                                                                                                    return (params.value)
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                suppressMovable: true,
                                                                                                headerName: "Action", field: 'id', width: 170, cellClass: "grid-cell",
                                                                                                cellRenderer: (params) => {
                                                                                                    return <DPTestPdfComponent id={params.value ?? 0}
                                                                                                        currentUser={this.props.currentUser}
                                                                                                        data={{ ...this.props.areaData, ...params.data }} />
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                        rowData={this.props.areaData?.differentialPressureTests ?? []}>
                                                                                    </AgGridReact>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </article>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })()
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    areaData: state.cleanroomequipment.equipmentData,
    refetchReportData: state.cleanroomequipment.refetchReportData,
    dataLoading: state.report.dataLoading,
    createEditLoading: state.report.createEditLoading,
    currentUser: state.userSession.currentUser,
});
export default connect(mapStateToProps, {
    createAreaReport,
    clearAreaDataState: clearCleanroomAreaDataState,
    updateAreaDataState: updateCleanroomEquipmentDataState,
    editCleanroomArea: editAreaReport,
    loadCleanroomAreaById,
    deleteTest,
})(CleanroomAreaCreateEditPage);


