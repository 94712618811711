import TimesNewRoman from "../../../../../assets/fonts/time-new-roman/times.ttf";
import TimesNewRomanBold from "../../../../../assets/fonts/time-new-roman/times-bold.ttf";
import IncepOne from "../../../../../assets/images/incepbio.jpg";
import Location from "../../../../../assets/images/location.png";
import WWW from "../../../../../assets/images/www.png";
import { useDispatch } from "react-redux";
import moment from "moment";
import Call from "../../../../../assets/images/call.png";
import React, { useEffect, useState } from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { IUserModel } from "../../../../user/model/user.model";
import { displayDate, localToUtcDate } from "../../../../../common/utils";

Font.register({
    family: 'Times', src: TimesNewRoman, fontWeight: 400
})

Font.register({
    family: 'times-bold', src: TimesNewRomanBold
})

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#FFF',
        color: '#333f54',
        fontFamily: 'Times'
    },
    section: {
        width: '100%',
        paddingLeft: 15,
        paddingRight: 15,
    },
    paddingY: {
        paddingTop: "15px",
        paddingBottom: "15px",
    },
    col: {
        padding: 6,
        border: '0.5px solid #000',
    },
    reportTitle: {
        textAlign: 'center',
        textTransform: 'uppercase',
        fontSize: 12,
        fontWeight: 500,
    },
    flexColumn: {
        flexDirection: 'column',
    },
    flexBox: {
        display: "flex",
        flexDirection: 'row',
        fontSize: 9,
        alignItems: 'stretch',
    },
    label: {
        width: 101,
        fontSize: 9,
    },
    flex_1: {
        flex: 1,
    },
    flex_2: {
        flex: 2,
    },
    flex_3: {
        flex: 3,
    },
    font_13: {
        fontSize: '12px',
        marginBottom: 5,
    },
    font_12: {
        fontSize: '10px',
    },
    paddingTB: {
        padding: '6px 0',
    },
    image: {
        width: '90%',
    },
    bold: {
        fontFamily: 'times-bold',
        fontWeight: 'bold',
    },
    main: {
        border: '0.5px double #000',
        padding: 4,
    },
    footer: {
        backgroundColor: '#004D88',
        color: '#FFF',
        margin: '0 -30',
        padding: '6 30'
    },
    pageNumber: {
        textAlign: 'center',
        fontSize: 10,
    }
    , footerImage: {
        borderRadius: '50%',
        backgroundColor: '#FFF',
        padding: 2,
        width: 12,
        marginRight: 5,
    },
    textCenter: {
        textAlign: 'center'
    },
    flex_center: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    flex_verticle_center: {
        alignItems: "flex-start",
        justifyContent: "center",
    },
    fix_footer: {
        marginTop: 'auto'
    },
});

export {
    styles,
    useDispatch,
    moment,
    React, useEffect, useState,
    Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font, pdf, saveAs, displayDate, localToUtcDate
};
export type { IUserModel };

export const PDFHeaderComponent = () => {
    return (
        <View style={{ ...styles.paddingY }} fixed>
            <View style={{ ...styles.section, ...styles.flexBox, alignItems: 'center' }}>
                <view style={{ ...styles.flex_1 }}>
                    <Image src={IncepOne} style={{ ...styles.image }} />
                </view>
                <view style={{ ...styles.flex_2 }}>
                    <View style={{ backgroundColor: '#009C8D', width: '100%', height: '8', position: 'relative' }}>
                        <View style={{ backgroundColor: '#004D88', position: 'absolute', width: '30%', height: '100%', right: 0 }}></View>
                        <View style={{ backgroundColor: '#FFF', position: 'absolute', width: '2%', height: '200%', right: '29%', transform: 'rotate(-20deg)', top: '-50%' }}></View>
                    </View>
                </view>
            </View>
            <View style={{ ...styles.section, ...styles.font_12, marginTop: 2 }}>
                <Text style={styles.bold}>An ISO 9001 : 2015 Certified Company</Text>
            </View>
        </View >
    )
}

export const PDFFooterComponent = () => {
    return (
        <View wrap={false} style={{ ...styles.paddingY, fontWeight: 900, ...styles.section, ...styles.font_12, ...styles.fix_footer }} fixed>
            <View>
                <Text style={{ textAlign: 'center', ...styles.paddingTB }} render={({ pageNumber, totalPages }) => {
                    return (`Page ${pageNumber} of ${totalPages}`);
                }} fixed />
            </View>
            <View style={{ color: "#004D88", }}>
                <Text style={{ ...styles.font_13, ...styles.bold }}>Incepbio Private Limited</Text>
            </View>
            <View style={{ ...styles.paddingTB, ...styles.footer, ...styles.flexBox, width: "80%", alignItems: 'center' }}>
                <Image src={Location} style={{ ...styles.footerImage }}></Image>
                <Text style={{}}>
                    #24, 22nd Main, Marenahalli, J.P. Nagar 2nd Phase, Bengaluru - 560078
                </Text>
                <Text style={{ width: 20, height: 20, borderRight: '4px solid #FFF', borderBottom: '4px solid #FFF', transform: 'rotate(-45deg)' }}>

                </Text>
                <Image src={Call} style={{ marginLeft: 8, ...styles.footerImage }}></Image>
                <Text>+91-63669 42391</Text>
            </View>

            <View style={{ ...styles.flexBox, ...styles.paddingTB, alignItems: 'center' }}>
                <Image src={WWW} style={{ ...styles.footerImage, width: 18 }}></Image>
                <Text style={{ ...styles.bold }}>
                    support@incepbio.com
                </Text>
            </View>
        </View>
    )
}

export const PDFClientDetailComponent = ({ data, title }) => {
    return (
        <>
            <View style={{ ...styles.paddingTB }}>
                <Text style={{ ...styles.reportTitle, ...styles.bold }}>Test Report For {title}</Text>
            </View>
            <View style={{ ...styles.paddingTB }}>
                <View style={{ ...styles.flexBox, }}>
                    <View style={{ ...styles.flex_2, ...styles.col }}>
                        <Text style={{ ...styles.label, ...styles.bold, ...styles.flex_verticle_center }}>Customer Details :</Text>
                    </View>
                    <view style={{ ...styles.flexBox, ...styles.flex_1, }}>
                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                            <Text style={{ ...styles.label }}>Report Number</Text>
                        </View>
                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                            <Text style={{ ...styles.label, ...styles.bold }}>
                                {data?.reportNumber ?? ''}
                            </Text>
                        </View>
                    </view>
                </View>
                <View style={{ ...styles.flexBox }}>
                    <View style={{ ...styles.flex_2, ...styles.col, ...styles.flex_verticle_center }}>
                        <Text style={{}}>
                            {data?.client?.companyName} {"\n"}
                            {data?.clientAddress?.addressLine1}
                            {data?.clientAddress?.addressLine2 ? `, ${data?.clientAddress?.addressLine2}` : ""}
                            {"\n"}
                            {data.clientAddress?.city}, {data.clientAddress?.state}
                        </Text>
                    </View>
                    <view style={{ ...styles.flex_1 }}>
                        <View style={{ ...styles.flexBox, flex: 1, ...styles.flexColumn, }} >
                            <View style={{ ...styles.flexBox, flex: 1 }}>
                                <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                    <Text style={{ ...styles.label }}>Date of test</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                    <Text style={{ ...styles.label }}>{displayDate(data.dateOfTest ?? "")}</Text>
                                </View>
                            </View>
                            {
                                data?.dueDate ?
                                    <View style={{ ...styles.flexBox, flex: 1 }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{ ...styles.label }}>Due Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{ ...styles.label }}>{data?.dueDate ? displayDate(data.dueDate) : ""}</Text>
                                        </View>
                                    </View>
                                    : ""
                            }
                        </View>
                    </view>
                </View>
            </View>
        </>
    )
}

export const PDFRemarkComponent = ({ data }) => {
    return (
        <>
            {/* --- result --- */}
            <View style={{ ...styles.paddingTB, ...styles.font_12 }} wrap={false}>
                <View style={{ ...styles.col, border: 0 }}>
                    <Text style={{ ...styles.bold }}>Conclusion :</Text>
                </View>
                <View style={{ ...styles.col, border: 0 }}>
                    <Text>{data.remarks ?? ""}</Text>
                </View>
            </View>

            {/* ---- signature ---- */}
            <View style={{ ...styles.paddingTB, ...styles.font_12 }} wrap={false}>
                <View style={{ ...styles.flexBox, ...styles.bold }}>
                    <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                            <Text>Prepared By</Text>
                        </View>
                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                            <Text style={{}}>Reviewed By</Text>
                        </View>
                    </View>
                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                            <Text style={{}}>Verified By</Text>
                        </View>
                    </View>
                </View>
                <View style={{ ...styles.font_12, ...styles.flexBox }}>
                    <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>Name</Text>
                            </View>
                            <View style={{ ...styles.flex_2, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>
                                    {data.preparedBy?.firstName} {data.preparedBy?.lastName}
                                </Text>
                            </View>
                        </View>
                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>Name</Text>
                            </View>
                            <View style={{ ...styles.flex_2, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>{data.checkedBy?.firstName} {data.checkedBy?.lastName}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>Name</Text>
                            </View>
                            <View style={{ ...styles.flex_2, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>{data.verifiedByName}</Text>
                            </View>
                        </View>
                    </View>

                </View>
                <View style={{ ...styles.font_12, ...styles.flexBox }}>
                    <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>Signature</Text>
                            </View>
                            <View style={{ ...styles.flex_2, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>

                                </Text>
                            </View>
                        </View>
                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>Signature</Text>
                            </View>
                            <View style={{ ...styles.flex_2, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}></Text>
                            </View>
                        </View>
                    </View>
                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>Signature</Text>
                            </View>
                            <View style={{ ...styles.flex_2, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}></Text>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={{ ...styles.font_12, ...styles.flexBox }}>
                    <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>Date</Text>
                            </View>
                            <View style={{ ...styles.flex_2, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>
                                    {data?.preparedOnDate ? displayDate(data?.preparedOnDate) : ""}
                                </Text>
                            </View>
                        </View>
                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>

                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>Date</Text>
                            </View>
                            <View style={{ ...styles.flex_2, ...styles.col, ...styles.flex_verticle_center }}>
                                <Text style={{}}>
                                    {data?.checkedOnDate ? displayDate(data?.checkedOnDate) : ""}
                                </Text>
                            </View>

                        </View>
                    </View>
                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                            <Text style={{}}>Date</Text>
                        </View>
                        <View style={{ ...styles.flex_2, ...styles.col, ...styles.flex_verticle_center }}>
                            <Text >
                                {data.verifiedOnDate ? displayDate(data.verifiedOnDate) : ""}
                            </Text>
                        </View>
                    </View>
                </View>
                <View style={{ ...styles.font_12, ...styles.flexBox, ...styles.bold }}>
                    <View style={{ ...styles.flex_2 }}>
                        <View style={{ width: "100%", textAlign: 'center', ...styles.col }}>
                            <Text style={{}}>For IncepBio Private Limited</Text>
                        </View>
                    </View>
                    <View style={{ ...styles.flex_1, }}>
                        <View style={{ width: "100%", textAlign: 'center', ...styles.col }}>
                            <Text style={{}}>For {data?.client?.companyName}</Text>
                        </View>
                    </View>
                </View>
            </View>
        </>
    )
}





