import React, { useEffect, useRef, useState } from "react";

import { Document, Page, Text, View, PDFViewer, Image, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { CleanroomAreaTests, TestObservationResult } from "../../../../../common/enums";
import { IUserModel } from "../../../../user/model/user.model";
import { ICleanroomAreaModel } from "../../../model/report.model";
import { localToUtcDate } from '../../../../../common/utils';
import moment from 'moment';
import { displayDate } from "../../../../../common/utils";
import { styles, PDFFooterComponent, PDFHeaderComponent, PDFClientDetailComponent, PDFRemarkComponent } from "../PDF/base-pdf-components";
import { useDispatch } from "react-redux";

import { editCleanroomAreaDFTest } from "../../../store/cleanroom-area/cleanroom-area-test.slice";
import CleanroomAreaTestService from "../../../service/cleanroom-area/test.service";


export const DPTestPDFComponent = ({ data }: { id?: number, data: any }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap={false}>
                <PDFHeaderComponent />

                {/* ---- body ---- */}
                <View style={{ ...styles.section }}>
                    <View style={{ ...styles.main }}>
                        <PDFClientDetailComponent data={data} title={"Differential Pressure Across Rooms"} />
                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Area:</Text>
                        </View>

                        <View style={{}}>
                            {/* --- fixed allignment --- */}
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Area Of Test</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.clientArea?.areaOfTheTest ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Location</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.clientArea?.location ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* ---- data logger details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{}}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Standard :</Text>
                            </View>

                            <View style={{ ...styles.paddingTB }}>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>{data?.dataLogger?.name ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.make?.name ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Instrument Id</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.instrumentId ?? ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Serial Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.serialNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Calibration Done Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{displayDate(data.dataLogger?.lastCalibratedOn)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Calibration Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>
                                                    {displayDate(data.dataLogger?.calibrationDueOn)}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Name</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Number</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>
                                    <Text style={{}}>{data?.refStandardOrProtocolOrDocumentName ?? ""}</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>{data?.procedureOrProtocolOrDocumentNumber ?? ""}</Text>
                                </View>
                            </View>
                        </View>
                        <View style={{ ...styles.paddingTB }} wrap={false}>
                            <View><Text style={{ ...styles.col, ...styles.flexBox, ...styles.bold,...styles.flex_center}}>OBTAINED TEST  RESULTS</Text></View>
                            <View style={{ ...styles.flexBox, ...styles.bold }}>
                                <View style={{ width: 40, ...styles.col, ...styles.flex_center,...styles.bold }}>
                                    <Text style={{}}> Sr No
                                    </Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.flexColumn }}>
                                    <View style={{ ...styles.flex_1, ...styles.col,...styles.flex_center }}>
                                        <Text style={{}}>Room Name/Zone</Text>
                                    </View>
                                    <View style={{ ...styles.flexBox,}}>
                                        <View style={{ ...styles.flex_1, ...styles.col,...styles.flex_center }}><Text style={{}}>High Pressure Room Name/Zone</Text></View>
                                        <View style={{ ...styles.flex_1, ...styles.col,...styles.flex_center }}><Text style={{}}>Low Pressure Room Name/Zone</Text></View>
                                    </View>
                                </View>
                                <View style={{width:70,...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Differential Pressure Mesured in (Pa)
                                    </Text>
                                </View>
                                <View style={{width:70,...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Acceptance Criteria (Pa)
                                    </Text>
                                </View>
                                <View style={{ width: 50, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Result
                                    </Text>
                                </View>
                            </View>
                            {
                                data?.roomObservations?.map((item, index) => {
                                    return (
                                        <View style={{ ...styles.flexBox,}}>
                                            <View style={{ width: 40, ...styles.col, ...styles.flex_verticle_center}}>
                                                <Text style={{}}> {index + 1}
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.flex_1,...styles.flexBox}}>
                                                <View style={{...styles.flex_1,...styles.col,...styles.flex_center}}>
                                                    <Text>{item?.highPressureRoomOrZone ?? ""}</Text>
                                                </View>
                                                <View style={{...styles.flex_1,...styles.col,...styles.flex_center}}>
                                                    <Text>{item?.lowPressureRoomOrZone ?? ""}</Text>
                                                </View>
                                            </View>
                                            <View style={{width:70, ...styles.col,...styles.flex_center}}>
                                                <Text style={{}}>{item?.differentialPressure ?? ""}
                                                </Text>
                                            </View>
                                            <View style={{width:70,...styles.col,...styles.flex_center}}>
                                                <Text style={{}}>NLT {item?.acceptanceCriteria}
                                                </Text>
                                            </View>
                                            <View style={{ width: 50, ...styles.col,...styles.flex_center}}>
                                                <Text style={{}}>
                                                    {item.result == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                        item.result == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}
                                                </Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>

                        <PDFRemarkComponent data={data}/>
                    </View>
                </View>
                <PDFFooterComponent />
            </Page >
        </Document >
    )
}

export interface IProps {
    data: ICleanroomAreaModel,
    id: number,
    currentUser: IUserModel
}

const DifferentialPressureTestPDFComponent = ({ id, data, currentUser }: IProps) => {
    const dispatch: any = useDispatch();
    const graphRef: any = useRef();
    const [loading, setLoading] = useState(false);

    const downloadPdf = async () => {
        let res: any;
        setLoading(true);
        try {
            if (id) {
                res = await CleanroomAreaTestService.GetDFTestById({ testName: CleanroomAreaTests.differentialpressure, id });
                if (res?.id) {
                    const fileName = 'differential-pressure-across-rooms-test.pdf';
                    const blob = await pdf(<DPTestPDFComponent data={{ ...data, ...res }} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
        } catch (errr) {

        } finally {
            setLoading(false);
            let date = moment(new Date()).toString();
            dispatch(editCleanroomAreaDFTest({
                testName: CleanroomAreaTests.differentialpressure, data:
                {
                    ...res,
                    editLocked: true,
                    reportPdfGeneratedById: currentUser?.id,
                    reportPdfGeneratedOn: localToUtcDate(date),
                },
            }))
        }

    };

    useEffect(() => {
        setLoading(false);
    }, [data]);

    return (<React.Fragment>
        {/* <PDFViewer width={'100%'}>
            <DPTestPDFComponent data={{ ...data }} />
        </PDFViewer> */}
        <button className="btn m-0 btn-primary px-3 py-2" onClick={downloadPdf}>Download PDF</button>
    </React.Fragment>)
}

export default DifferentialPressureTestPDFComponent;




