import TestService from "../../../service/cleanroom-equipment/test.service";
import { CleanroomEquipmentTests, FilterIntegrityTestAcceptanceCriterias, TestObservationResult, ZoneClassificationType } from "../../../../../common/enums";
import { ICleanroomEquipmentModel } from "../../../model/report.model";
import { IFilterIntegrityModel } from "../../../model/cleanroom-equipment/test/filter-integrity-test.model";
import { AerosolGeneratorTypeList, AerosolMediumList, FilterIntegrityTestAcceptanceCriteriaList } from "../../../../../common/_static/report.data";
import { editFITest } from '../../../store/cleanroom-equipment/test.slice';
import {
    styles,
    useDispatch,
    moment,
    React, useEffect, useState,
    Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font, pdf, saveAs, IUserModel,
    displayDate, localToUtcDate, PDFHeaderComponent,
    PDFFooterComponent,
    PDFClientDetailComponent,
    PDFRemarkComponent
} from "../PDF/base-pdf-components";

export const FilterIntegrityTest = ({ data }: { data: IFilterIntegrityModel | any }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap={false}>
                {/* ---- header ------ */}
                <PDFHeaderComponent />

                {/* ---- body ---- */}
                <View style={{ ...styles.section }}>
                    <View style={{ ...styles.main }}>
                        <PDFClientDetailComponent data={data} title={"Filter Integrity"} />

                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Equipment :</Text>
                        </View>

                        <View style={{}}>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Equipment Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data.clientEquipment?.equipmentName ?? ''}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Area Of Test</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data.areaOfTest ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Equipment Id</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data.clientEquipment?.equipmentId ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Location</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.location ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Make</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data.clientEquipment?.make ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Filter Type</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.filterType ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Model Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.clientEquipment?.modelNumber ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Filter Efficiency</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.filterEfficiency ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Serial Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.clientEquipment?.serialNumber ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Zone Classification</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.zoneClassificationType == ZoneClassificationType.ClientSpecification ? "Client Specification" :
                                                data?.zoneClassificationType == ZoneClassificationType.EUGMP ? `Grade ${data.eugmpStandard.grade}` : data?.zoneClassificationType == ZoneClassificationType.ISO ? `ISO ${data.isoStandard?.classNumber}` : ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Downstream Acceptance Criteria
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data?.downstreamAcceptanceCriteria == FilterIntegrityTestAcceptanceCriterias["≤ 0.01%"] ? `NMT 0.01%` :
                                            data?.downstreamAcceptanceCriteria == FilterIntegrityTestAcceptanceCriterias["< 0.01%"] ? `LT 0.01%` : ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Total Number of Filter
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{data?.numberOfFilters}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{}}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Standard :</Text>
                            </View>

                            <View style={{ ...styles.paddingTB }}>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>{data?.dataLogger?.name ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.make?.name ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Instrument Id</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.instrumentId ?? ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Serial Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.serialNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Calibration Done Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{displayDate(data.dataLogger?.lastCalibratedOn)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Calibration Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{displayDate(data.dataLogger?.calibrationDueOn)}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Aerosol Generator type
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>
                                                {
                                                    data?.aerosolGeneratorType > -1 ?
                                                        AerosolGeneratorTypeList.find((item) => item.value == data?.aerosolGeneratorType?.toString())?.label
                                                        : null
                                                }
                                            </Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Aerosol Medium
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>
                                                    {
                                                        data?.aerosolMedium > -1 ?
                                                            AerosolMediumList.find((item) => item.value == data?.aerosolMedium?.toString())?.label
                                                            : null
                                                    }
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Name</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>

                                    <Text style={{ ...styles.bold }}>Protocol Number</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>
                                    <Text style={{}}>{data?.refStandardOrProtocolOrDocumentName ?? ""}</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>

                                    <Text style={{}}>
                                        {data?.procedureOrProtocolOrDocumentNumber ?? ""}

                                    </Text>
                                </View>
                            </View>
                        </View>
                        {/* ---- observations ---- */}
                        <View wrap={false}>
                            <View style={{ ...styles.paddingTB }}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Observations :</Text>
                            </View>
                            <View style={{ ...styles.flexBox, ...styles.bold, ...styles.textCenter }}>
                                <View style={{ ...styles.label, width: 30, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Sr.No</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>
                                        Filter Id
                                    </Text>
                                </View>

                                <View style={{ ...styles.flex_1, }}>
                                    <View style={{ ...styles.flexBox, ...styles.flexColumn, }}>
                                        <View style={{ ...styles.col, textAlign: 'center' }}>
                                            <Text style={{}}>Upstream Concentration Before Integrity
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flexBox, }}>
                                            <View style={{ ...styles.col, ...styles.flex_1, textAlign: 'center' }}>
                                                <Text style={{}}>
                                                    (µg/l)
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.col, ...styles.flex_1, textAlign: 'center' }}>
                                                <Text style={{}}>
                                                    (%)
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, }}>
                                    <View style={{ ...styles.flexBox, ...styles.flex_1, ...styles.flexColumn }}>
                                        <View style={{ ...styles.col, ...styles.flex_center }}>
                                            <Text style={{}}>Obtained Results in Downstream</Text>
                                        </View>
                                        <View style={{ ...styles.col, textAlign: 'center' }}>
                                            <Text style={{}}>
                                                (%)
                                            </Text>
                                        </View>
                                    </View>

                                </View>
                                <View style={{ ...styles.flex_1, }}>
                                    <View style={{ ...styles.flexBox, ...styles.flexColumn }}>
                                        <View style={{ ...styles.col, ...styles.flex_center }}>
                                            <Text style={{}}>Upstream PAO Concentration After Integrity</Text>
                                        </View>
                                        <View style={{ ...styles.col, textAlign: 'center', }}>
                                            <Text style={{}}>
                                                (µg/l / %)
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_2, }}>
                                    <View style={{ ...styles.flexBox, ...styles.flexColumn, flex: 1 }}>
                                        <View style={{ ...styles.col, ...styles.flex_1, ...styles.flex_center }}>
                                            <Text style={{}}>Upstream PAO Before and After Integrity should not vary ±15%
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.col, ...styles.flex_center }}>
                                            <Text style={{}}>
                                                (µg/l / %)
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, width: 60, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Result
                                    </Text>
                                </View>
                            </View>
                            {
                                data.observations?.map((item, index) => {
                                    return (
                                        <View style={{ ...styles.flexBox, ...styles.textCenter }}>
                                            <View style={{ ...styles.label, width: 30, ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>{index + 1}</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>{item.filterId}</Text>
                                            </View>

                                            <View style={{ ...styles.flex_1 }}>
                                                <View style={{ ...styles.flexBox }}>
                                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                                        <Text style={{}}>{item?.upstreamConcentrationBeforeIntegrity}</Text>
                                                    </View>
                                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                                        <Text style={{}}>{item?.upstreamConcentrationBeforeIntegrityPercentage}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                            <View style={{ ...styles.flex_1 }}>
                                                <View style={{ ...styles.flexBox, ...styles.col, ...styles.flex_center }}>
                                                    <Text style={{}}>{item.obtainedResultInDownStreamPercentage?.toLocaleString(undefined, { minimumFractionDigits: 4 }) ?? ""}</Text>
                                                </View>
                                            </View>
                                            <View style={{ ...styles.flex_1 }}>
                                                <View style={{ ...styles.flexBox, ...styles.col, ...styles.flex_center }}>
                                                    <Text style={{}}>
                                                        {item?.upstreamPAOConcentrationAfterIntegrityPercentage ?? 0}
                                                    </Text>
                                                </View>
                                            </View>
                                            <View style={{ ...styles.flex_2, }}>
                                                <View style={{ ...styles.flexBox, ...styles.col, ...styles.flex_center }}>
                                                    <Text style={{}}>
                                                        {item.upstreamPAOBeforeAndAfterIntegrity}
                                                    </Text>
                                                </View>
                                            </View>

                                            <View style={{ ...styles.label, width: 60, ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>{
                                                    item.result == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                        item.result == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}

                                                </Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                        <PDFRemarkComponent data={data} />
                    </View>
                </View >
                <PDFFooterComponent />
            </Page >
        </Document >
    )
}

export interface IProps {
    data: ICleanroomEquipmentModel,
    id: number,
    currentUser: IUserModel
}
const FilterIntegrityPDFComponent = ({ id, data, currentUser }: IProps) => {
    const [loading, setLoading] = useState(false);
    const dispatch: any = useDispatch();
    const downloadPdf = async () => {
        let res: any;
        setLoading(true);
        try {
            if (id) {
                res = await TestService.GetFITestById({ testName: CleanroomEquipmentTests.filterintegrity, id });
                if (res?.id) {
                    const fileName = 'filter-integrity-test.pdf';
                    const blob = await pdf(<FilterIntegrityTest data={{ ...data, ...res }} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
        } catch (errr) {

        } finally {
            setLoading(false);
            let date = moment(new Date()).toString();
            dispatch(editFITest({
                testName: CleanroomEquipmentTests.filterintegrity, data:
                {
                    ...res,
                    editLocked: true,
                    reportPdfGeneratedById: currentUser?.id,
                    reportPdfGeneratedOn: localToUtcDate(date),
                },
            }));
        }

    };

    useEffect(() => {
        setLoading(false);
    }, [data]);

    return (<React.Fragment>
        {/* <PDFViewer width={'100%'}>
            <FilterIntegrityTest data={data} />
        </PDFViewer> */}
        <button className="btn m-0 btn-primary px-3 py-2" onClick={downloadPdf}>{!loading ? "Download PDF" : "Loading"}</button>
    </React.Fragment>)
}

export default FilterIntegrityPDFComponent;





