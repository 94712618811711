import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType} from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "highPressureRoomOrZone",
        rules: [
            {
                type: ValidationType.Required,
                message: "High Pressure Room/Zone is required"
            }
        ]
    },
    {
        fieldName: "lowPressureRoomOrZone",
        rules: [
            {
                type: ValidationType.Required,
                message: "Low Pressure Room/Zone is required",
            },
        ]
    },
    {
        fieldName: "acceptanceCriteria",
        rules: [
            {
                type: ValidationType.Required,
                message: "Acceptance Criteria is required",
            },
        ]
    },
    {
        fieldName: "differentialPressure",
        rules: [
            {
                type: ValidationType.Required,
                message: "Differential Pressure is required",
            },
        ]
    }
]

export const cleanroomAreaDifferentialPressureTestObservationValidator: IValidatorModel = {
    screen: ValidationScreens.CleanroomAreaDifferentialPressureTestObservation,
    fields: fields
}