import { Text, View } from '@react-pdf/renderer';
import { CleanroomAreaTests, TestObservationResult } from "../../../../../common/enums";
import BasePDFComponent from "./base-test-report-pdf.component";
import { IUserModel } from "../../../../user/model/user.model";
import { IAirChangesPerHourTestModel } from '../../../model/cleanroom-area/test/air-changes-per-hour-test.model';

export const AirChangesPerHourPDFComponent = ({ id, currentUser, data }: { id: number, currentUser: IUserModel, data: IAirChangesPerHourTestModel | any }) => {
    return (
        <BasePDFComponent testName={CleanroomAreaTests.airchangesperhour} title="ACPH" id={id} data={data} currentUser={currentUser}
            callBack={(styles: any, data: any, maxReadings: number) => {
                return (
                    <View wrap={false}>
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Observations :</Text>
                        </View>
                        <View style={{ ...styles.flexBox, ...styles.flexColumn, fontSize: 10, }}>
                            <View style={{ ...styles.flexBox, ...styles.bold, ...styles.textCenter }}>
                                <View style={{ ...styles.label, width: 30, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Sr. No</Text>
                                </View>
                                <View style={{ ...styles.label, width: 130, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{ ...styles.label, }}>
                                        {data?.observationLabelName} Id
                                    </Text>
                                </View>
                                {
                                    data?.instrumentUsedToPerformObservation == "Anemometer" ?
                                        <View style={{ ...styles.label, width: 50, ...styles.flexBox, ...styles.flexColumn }}>
                                            <View style={{ ...styles.col, ...styles.flex_center, ...styles.flex_2 }}>
                                                <Text style={{}}> {data?.observationLabelName} Area</Text>
                                            </View>
                                            <View style={{ ...styles.col, ...styles.flex_center, ...styles.flex_1 }}>
                                                <Text style={{}}>
                                                    Ft <Text style={{ verticalAlign: 'super', fontSize: 7 }}>2</Text>
                                                </Text>
                                            </View>
                                        </View>
                                        : null
                                }
                                {
                                    data?.instrumentUsedToPerformObservation == "Anemometer" ?
                                        <View style={{ flex: 6, minWidth: 100, ...styles.flexBox, ...styles.flexColumn }}>
                                            <View style={{ ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>Air Velocity Readings in FPM
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.flexBox, }}>
                                                {
                                                    Array(maxReadings)?.fill(0).map((item, index) => {
                                                        return (
                                                            <View style={{ ...styles.flex_1, padding: '6', borderRight: "0.5px solid black" }}>
                                                                <Text>R{(index + 1)}</Text>
                                                            </View>
                                                        )
                                                    })
                                                }
                                            </View>
                                        </View> : null
                                }
                                {
                                    data?.instrumentUsedToPerformObservation == "Anemometer" ?
                                        <View style={{ ...styles.label, width: 50, ...styles.flexBox, ...styles.flexColumn }}>
                                            <View style={{ ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>Avg Flow Rate</Text>
                                            </View>
                                            <View style={{ ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>
                                                    FPM
                                                </Text>
                                            </View>
                                        </View> : null
                                }

                                <View style={{ ...styles.label, width: 50, ...styles.flexBox, ...styles.flexColumn }}>
                                    <View style={{ ...styles.col, ...styles.flex_center }}>
                                        <Text style={{}}>Air Flow Rate</Text>
                                    </View>
                                    <View style={{ ...styles.col, ...styles.flex_center }}>
                                        <Text style={{}}>
                                            CFM
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, ...data?.instrumentUsedToPerformObservation == "Anemometer" ? { width: 60 } : { flex: 1 }, ...styles.flexBox, ...styles.flexColumn }}>
                                    <View style={{ ...styles.col, ...styles.flex_center }}>
                                        <Text style={{}}>Total Air Flow Rate(Vt) </Text>
                                    </View>
                                    <View style={{ ...styles.col, ...styles.flex_center }}>
                                        <Text style={{}}>
                                            CFM
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, ...data?.instrumentUsedToPerformObservation == "Anemometer" ? { width: 60 } : { flex: 1 }, ...styles.flexBox, ...styles.flexColumn }}>
                                    <View style={{ ...styles.col, ...styles.flex_center, ...styles.flex_1 }}>
                                        <Text style={{}}> Room Volume (Rv) </Text>
                                    </View>
                                    <View style={{ ...styles.col, ...styles.flex_center, ...styles.flex_1 }}>
                                        <Text style={{}}>
                                            Ft<Text style={{ verticalAlign: 'super', fontSize: 7 }}>3</Text>
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, ...data?.instrumentUsedToPerformObservation == "Anemometer" ? { width: 80 } : { flex: 1 }, ...styles.flexBox, ...styles.flexColumn }}>
                                    <View style={{ ...styles.col, ...styles.flex_center }}>
                                        <Text style={{}}> Air Changes Per Hr(VtX60)/Rv</Text>
                                    </View>
                                    <View style={{ ...styles.col, ...styles.flex_center }}>
                                        <Text style={{}}>
                                            Number
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, width: 40, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Result</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox, ...styles.textCenter, fontSize: 10 }}>
                                <View style={{ ...styles.label, width: 30, ...styles.flexBox, ...styles.flexColumn }}>
                                    {
                                        data.observations?.map((item, index) => {
                                            return (
                                                <View style={{ ...styles.col, ...styles.flex_center }}>
                                                    <Text style={{}}>{index + 1}</Text>
                                                </View>)
                                        })}
                                </View>
                                <View style={{ ...styles.label, width: 130, ...styles.flexBox, ...styles.flexColumn }}>
                                    {
                                        data.observations?.map((item, index) => {
                                            return (
                                                <View style={{ ...styles.col, ...styles.flex_center }}>
                                                    <Text style={{}}>{item.filterOrGrillId}</Text>
                                                </View>)
                                        })}
                                </View>
                                {
                                    data?.instrumentUsedToPerformObservation == "Anemometer" ?
                                        <View style={{ ...styles.label, width: 50, ...styles.flexBox, ...styles.flexColumn }}>
                                            {
                                                data.observations?.map((item, index) => {
                                                    return (
                                                        <View style={{ ...styles.col, ...styles.flex_center }}>
                                                            <Text style={{}}>{item.filterOrGrillArea}</Text>
                                                        </View>)
                                                })}
                                        </View>
                                        : null
                                }
                                {
                                    data?.instrumentUsedToPerformObservation == "Anemometer" ?
                                        <View style={{ flex: 6, minWidth: 100, ...styles.flexBox, ...styles.flexColumn, }}>
                                            {
                                                data.observations?.map((item, index) => {
                                                    return (<View style={{ ...styles.flexBox, border: "0.5px solid black" }}>
                                                        {Array(maxReadings)?.fill(0)?.map((item2, index2) => {
                                                            return (
                                                                <View style={{ ...styles.flex_1, padding: '6', height: "100%", borderRight: "0.5px solid black" }}>
                                                                    <Text style={{}}>{item?.readings[index2]?.reading ?? ""}</Text>
                                                                </View>
                                                            )
                                                        })
                                                        }
                                                    </View>)
                                                }
                                                )
                                            }
                                        </View>
                                        : null
                                }
                                {
                                    data?.instrumentUsedToPerformObservation == "Anemometer" ?
                                        <View style={{ ...styles.label, width: 50, ...styles.flexBox, ...styles.flexColumn }}>
                                            {
                                                data.observations?.map((item, index) => {
                                                    return (
                                                        <View style={{ ...styles.col, ...styles.flex_center }}>
                                                            <Text style={{}}>{item?.averageFlowRate}</Text>
                                                        </View>)
                                                })}
                                        </View>
                                        : null}

                                <View style={{ ...styles.label, width: 50, ...styles.flexBox, ...styles.flexColumn }}>
                                    {
                                        data.observations?.map((item, index) => {
                                            return (
                                                <View style={{ ...styles.col, ...styles.flex_center }}>
                                                    <Text style={{}}>{item.airFlowRate}</Text>
                                                </View>)
                                        })}
                                </View>

                                <View style={{ ...styles.label, ...data?.instrumentUsedToPerformObservation == "Anemometer" ? { width: 60 } : { flex: 1 }, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>{data?.totalAirFlowRate}</Text>
                                </View>
                                <View style={{ ...styles.label, ...data?.instrumentUsedToPerformObservation == "Anemometer" ? { width: 60 } : { flex: 1 }, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>{data?.roomVolume}</Text>
                                </View>
                                <View style={{ ...styles.label, ...data?.instrumentUsedToPerformObservation == "Anemometer" ? { width: 80 } : { flex: 1 }, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>
                                        {data?.airChangesPerHour}
                                    </Text>
                                </View>

                                <View style={{ ...styles.label, width: 40, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>{
                                        data.testObservationResult == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                            data.testObservationResult == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}
                                    </Text>
                                </View>
                            </View>
                        </View>
                    </View>
                )
            }}
        />)
}

export default AirChangesPerHourPDFComponent;





