import { matchPath, Route, Routes, useLocation } from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import ClientPage from "./screen/client.page";
import ClientCreateEditPage from "./screen/form/client.create-edit.page";
import ClientAddressCreateEditPage from "./screen/form/client-address.create-edit.page";
import RouteHelper from "../../common/helper/route-helper";
import { AccessLevel, FeatureNames } from "../../common/enums";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";
import ClientEquipmentPage from "./screen/client-equipment.page";
import ClientEquipmentCreateEditPage from "./screen/form/client-equipment.create-edit.page";
import ClientAreaPage from "./screen/client-area.page";
import ClientAreaCreateEditPage from "./screen/form/client-area-create-edit.page";

const ClientRoutes = () => {
    const client = RouteHelper.GetFeatureAccessLevel(FeatureNames.Client);
    const clientAddress = RouteHelper.GetFeatureAccessLevel(FeatureNames.ClientAddress);
    const clientEquipment = RouteHelper.GetFeatureAccessLevel(FeatureNames.ClientEquipment);
    const clientArea = RouteHelper.GetFeatureAccessLevel(FeatureNames.ClientArea);

    const location = useLocation();
    return <Routes>
        <Route element={<AccountLayout />}>

            <Route path={ScreenUrls.Clients.Index()}>
                <Route index element={client.accessLevel >= AccessLevel.View ? <ClientPage accessLevel={client.accessLevel} /> : <AccessDeniedPage />} />
                <Route path={ScreenUrls.CreateUri}
                    element={client.accessLevel >= AccessLevel.Create
                        ? <ClientCreateEditPage accessLevel={client.accessLevel} accessLevelAddress={clientAddress.accessLevel} />
                        : <AccessDeniedPage />} />
                <Route path={ScreenUrls.EditUriParam}
                    element={client.accessLevel >= AccessLevel.Edit
                        ? <ClientCreateEditPage id={matchPath({ path: ScreenUrls.Clients.Edit(":id") }, location.pathname)?.params["id"]}
                            accessLevel={client.accessLevel} accessLevelAddress={clientAddress.accessLevel} />
                        : <AccessDeniedPage />} />

                <Route path={ScreenUrls.Clients.Addresses.indexPage(":clientId")}>
                    <Route path={ScreenUrls.CreateUri}
                        element={client.accessLevel >= AccessLevel.Create
                            ? <ClientAddressCreateEditPage
                                clientId={matchPath({ path: ScreenUrls.Clients.Addresses.Create(":clientId") }, location.pathname)?.params["clientId"]}
                                accessLevel={client.accessLevel} />
                            : <AccessDeniedPage />} />
                    <Route path={ScreenUrls.EditUriParam}
                        element={client.accessLevel >= AccessLevel.Edit
                            ? <ClientAddressCreateEditPage
                                id={matchPath({ path: ScreenUrls.Clients.Addresses.Edit(":id", ":clientId") }, location.pathname)?.params["id"]}
                                clientId={matchPath({ path: ScreenUrls.Clients.Addresses.Edit(":id", ":clientId") }, location.pathname)?.params["clientId"]}
                                accessLevel={client.accessLevel} />
                            : <AccessDeniedPage />} />
                </Route>
                <Route path={ScreenUrls.Clients.Equipment.Index()}>
                    <Route index
                        element={clientEquipment?.accessLevel >= AccessLevel.View ? <ClientEquipmentPage accessLevel={clientEquipment?.accessLevel} /> : <AccessDeniedPage />}
                    />
                    <Route path={ScreenUrls.CreateUri}
                        element={clientEquipment?.accessLevel >= AccessLevel.Create ? <ClientEquipmentCreateEditPage accessLevel={clientEquipment?.accessLevel} /> : <AccessDeniedPage />}
                    />
                    <Route path={ScreenUrls.EditUriParam}
                        element={clientEquipment?.accessLevel >= AccessLevel.Edit ? <ClientEquipmentCreateEditPage accessLevel={clientEquipment?.accessLevel}
                            id={matchPath({ path: ScreenUrls.Clients.Equipment.Edit(":id") }, location.pathname)?.params["id"]} /> : <AccessDeniedPage />}
                    />
                </Route>
                <Route path={ScreenUrls.Clients.Area.Index()}>
                    <Route index
                        element={clientArea?.accessLevel >= AccessLevel.View ? <ClientAreaPage accessLevel={clientArea?.accessLevel} /> : <AccessDeniedPage />}
                    />
                    <Route path={ScreenUrls.CreateUri}
                        element={clientArea?.accessLevel >= AccessLevel.Create ? <ClientAreaCreateEditPage accessLevel={clientArea?.accessLevel} /> : <AccessDeniedPage />}
                    />
                    <Route path={ScreenUrls.EditUriParam}
                        element={clientArea?.accessLevel >= AccessLevel.Edit ? <ClientAreaCreateEditPage accessLevel={clientArea?.accessLevel}
                            id={matchPath({ path: ScreenUrls.Clients.Area.Edit(":id") }, location.pathname)?.params["id"]} /> : <AccessDeniedPage />}
                    />
                </Route>
            </Route>
        </Route>
    </Routes>
}

export default ClientRoutes;
