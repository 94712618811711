import { clientAddressDefault, clientDefault } from "../../../../client/model/client.default";
import {ICleanroomEquipmentModel, IReportModel, ReportCommentModel} from "../../report.model";
import {ZoneClassificationType} from '../../../../../common/enums';

export const reportDefault: IReportModel = {
    id:0,
    type:"",
    Client:clientDefault,
    clientAddress:clientAddressDefault,
    reportCategory:"",
}

export const cleanroomEquipmentDefault:ICleanroomEquipmentModel = {
    clientId:0,
    client:clientDefault,
    clientEquipment:null,
    clientAddress:clientAddressDefault,
    clientAddressId:0,
    clientEquipmentId:0,
    reportCategoryId:0,
    zoneClassificationType:ZoneClassificationType.EUGMP,
    filterEfficiency:"",
    filterType:"",
    occupancyState:0,
}

export const ReportCommentDefault : ReportCommentModel = {
    id:0,
    commentDescription:"",
    reportCategoryId:0,
    reportId:0,
    reportTestId:0,
    reportTestName:""
}
