import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType, ZoneClassificationType } from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "dateOfTest",
        rules: [
            {
                type: ValidationType.Required,
                message: "Date of test is required"
            }
        ]
    },
    {
        fieldName: "preparedOnDate",
        rules: [
            {
                type: ValidationType.Required,
                message: "Prepared on date is required",
            },
            {
                type: ValidationType.DateShouldBeLessThenCurrentDate,
                message: "Prepared on date should be less than current date"
            }
        ]
    },
    {
        fieldName: "preparedById",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Prepared by user is required",
            },
        ]
    },
    {
        fieldName: "dataLoggerId",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Datalogger is required",
            },
        ]
    },
    {
        fieldName: "acceptanceCriteria",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Acceptance Criteria is required",
            },
        ]
    },
    {
        fieldName: "instrumentUsedToPerformObservation",
        rules: [
            {
                type: ValidationType.Required,
                message: "Instrument Used To Perform Observation is required",
            },
        ]
    },
    {
        fieldName: "observationLabelName",
        rules: [
            {
                type: ValidationType.Required,
                message: "Observation Label Name is required",
            },
        ]
    },
]

export const airChangesPerHourTestValidator: IValidatorModel = {
    screen: ValidationScreens.CleanroomAreaAirChangesPerHourTest,
    fields: fields
}

