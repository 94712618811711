import { Text, View, } from '@react-pdf/renderer';
import { CleanroomEquipmentTests, TestObservationResult } from "../../../../../common/enums";
import BasePDFComponent from "../PDF/base-test-report-pdf.component";
import { IUserModel } from "../../../../user/model/user.model";
import { ICleanroomEquipmentModel } from "../../../model/report.model";
import { getObservationLocationLabel } from '../../../../../common/_static/report.data';

export const UVLightTestPDFComponent = ({ id, currentUser, data }: { id: number, currentUser: IUserModel, data: ICleanroomEquipmentModel }) => {
    return (
        <BasePDFComponent testName={CleanroomEquipmentTests.uvlightintensity} title="UV light intensity" id={id} data={data} currentUser={currentUser}
            callBack={(styles: any, data: any, maxReadings: number) => {
                return (
                    <View wrap={false}>
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Observations :</Text>
                        </View>
                        <View style={{ ...styles.flexBox, ...styles.flexColumn, ...styles.font_12, }}>
                            <View style={{ ...styles.flexBox, ...styles.bold, ...styles.textCenter }}>
                                <View style={{ ...styles.label, width: 35, ...styles.col,...styles.flex_center  }}>
                                    <Text style={{}}>Sr.No</Text>
                                </View>
                                <View style={{ ...styles.label, width: 120, ...styles.col,...styles.flex_center  }}>
                                    <Text style={{ ...styles.label }}>
                                    {getObservationLocationLabel(data?.observationLocationLabel)}
                                    </Text>
                                </View>

                                <View style={{ ...styles.flex_2 }}>
                                    <View style={{ ...styles.flexBox, flexDirection: 'column' }}>
                                        <View style={{ ...styles.col,...styles.flex_center  }}>
                                            <Text style={{}}>Measured UV Light Intensity(mW/cm²)
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flexBox }}>
                                            {
                                                Array(maxReadings)?.fill(0).map((item, index) => {
                                                    return (
                                                        <View style={{ ...styles.flex_1, padding: 2, borderRight: "0.5px solid black" }}>
                                                            <Text>R{(index + 1)}</Text>
                                                        </View>
                                                    )
                                                })
                                            }
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, width: 70, ...styles.col,...styles.flex_center  }}>
                                    <Text style={{}}>Avg UV Light Intensity(mW/cm²)</Text>
                                </View>
                                <View style={{ ...styles.label, width: 100, ...styles.col,...styles.flex_center  }}>

                                    <Text style={{}}>Acceptance Criteria (mW/cm²)
                                    </Text>
                                </View>
                                <View style={{ ...styles.label, width: 60, ...styles.col ,...styles.flex_center }}>
                                    <Text style={{}}>Result</Text>
                                </View>
                            </View>
                            {
                                data.observations?.map((item, index) => {
                                    return (
                                        <View style={{ ...styles.flexBox, ...styles.textCenter }}>
                                            <View style={{ ...styles.label, width: 35, ...styles.col }}>
                                                <Text style={{}}>{index + 1}</Text>
                                            </View>
                                            <View style={{ ...styles.label, width: 120, ...styles.col }}>
                                                <Text style={{}}>{item.locationNameOrNumber}</Text>
                                            </View>

                                            <View style={{ ...styles.flex_2, ...styles.flexBox, border: "0.5px solid black" }}>
                                                {
                                                    Array(maxReadings)?.fill(0).map((item2, index2) => {
                                                        return (
                                                            <View style={{ ...styles.flex_1, padding: 2, height: "100%", borderRight: "0.5px solid black" }}>
                                                                <Text style={{}}>{item?.roomReadings[index2]?.reading ?? ""}</Text>
                                                            </View>
                                                        )
                                                    })
                                                }

                                            </View>
                                            <View style={{ ...styles.label, width: 70, ...styles.col, }}>
                                                <Text style={{}}>{item.averageUVLightIntensity}</Text>
                                            </View>
                                            <View style={{ ...styles.label, width: 100, ...styles.col, }}>
                                                <Text style={{}}>
                                                    {(() => {
                                                        if (data.acceptanceCriteriaMin && data.acceptanceCriteriaMax) {
                                                            return `${data.acceptanceCriteriaMin} to ${data.acceptanceCriteriaMax}`;
                                                        } else if (data.acceptanceCriteriaMin && !data.acceptanceCriteriaMax) {
                                                            return `NLT ${data.acceptanceCriteriaMin}`;
                                                        } else if (data.acceptanceCriteriaMax && !data.acceptanceCriteriaMin) {
                                                            return `NMT ${data.acceptanceCriteriaMax}`;
                                                        }
                                                        return "";

                                                    })()}

                                                </Text>
                                            </View>

                                            <View style={{ ...styles.label, width: 60, ...styles.col }}>
                                                <Text style={{}}>{
                                                    item.result == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                        item.result == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}

                                                </Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>
                )
            }}
        />)
}

export default UVLightTestPDFComponent;





