
import { Text, View, } from '@react-pdf/renderer';
import { CleanroomAreaTests, TestObservationResult } from "../../../../../common/enums";
import BasePDFComponent from "./base-test-report-pdf.component";
import { IUserModel } from "../../../../user/model/user.model";
import { ICleanroomAreaModel } from '../../../model/report.model';
import { getObservationLocationLabel } from '../../../../../common/_static/report.data';

export const SoundLevelTestPDFComponent = ({ id, currentUser, data }: { id: number, currentUser: IUserModel, data: ICleanroomAreaModel }) => {
    return (
        <BasePDFComponent testName={CleanroomAreaTests.uvlightintensity} title="UV Light Intensity" id={id} data={data} currentUser={currentUser}
            callBack={(styles: any, data: any, maxReadings: number) => {
                return (
                    <View wrap={false}>
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Observations :</Text>
                        </View>
                        <View style={{ ...styles.flexBox, ...styles.flexColumn, ...styles.font_12, }}>
                            <View style={{ ...styles.flexBox, ...styles.bold, ...styles.textCenter }}>
                                <View style={{ ...styles.label, width: 35, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Sr.No</Text>
                                </View>
                                <View style={{ ...styles.label, width: 120, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{ ...styles.label }}>
                                        {getObservationLocationLabel(data?.observationLocationLabel)}
                                    </Text>
                                </View>

                                <View style={{ ...styles.flex_2 }}>
                                    <View style={{ ...styles.flexBox, flexDirection: 'column' }}>
                                        <View style={{ ...styles.col, ...styles.flex_center }}>
                                            <Text style={{}}>Measured UV Light Intensity(mW/cm²)</Text>
                                        </View>
                                        <View style={{ ...styles.flexBox }}>
                                            {
                                                Array(maxReadings)?.fill(0).map((item, index) => {
                                                    return (
                                                        <View style={{ ...styles.flex_1, padding: 2, borderRight: "0.5px solid black" }}>
                                                            <Text>R{(index + 1)}</Text>
                                                        </View>
                                                    )
                                                })
                                            }
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.label, width: 70, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Average UV Light Intensity(mW/cm²)</Text>
                                </View>
                                <View style={{ ...styles.label, width: 100, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Acceptance Criteria (mW/cm²)</Text>
                                </View>
                                <View style={{ ...styles.label, width: 60, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Result</Text>
                                </View>
                            </View>
                            {
                                data.observations?.map((item, index) => {
                                    return (
                                        <View style={{ ...styles.flexBox, ...styles.textCenter }}>
                                            <View style={{ ...styles.label, width: 35, ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>{index + 1}</Text>
                                            </View>
                                            <View style={{ ...styles.label, width: 120, ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>{item.locationNameOrNumber}</Text>
                                            </View>

                                            <View style={{ ...styles.flex_2, ...styles.flexBox, border: "0.5px solid black" }}>
                                                {
                                                    Array(maxReadings)?.fill(0).map((item2, index2) => {
                                                        return (
                                                            <View style={{ ...styles.flex_1, padding: 2, height: "100%", borderRight: "0.5px solid black" }}>
                                                                <Text style={{}}>{item?.readings && index2 > -1 ? item?.readings[index2]?.reading : ""}</Text>
                                                            </View>
                                                        )
                                                    })
                                                }

                                            </View>
                                            <View style={{ ...styles.label, width: 70, ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>{item.averageUVLightIntensity}</Text>
                                            </View>
                                            <View style={{ ...styles.label, width: 100, ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>
                                                    {(() => {
                                                        if (item.acceptanceCriteriaMin && item.acceptanceCriteriaMax) {
                                                            return `${item.acceptanceCriteriaMin} to ${item.acceptanceCriteriaMax}`;
                                                        } else if (item.acceptanceCriteriaMin && !item.acceptanceCriteriaMax) {
                                                            return `NLT ${item.acceptanceCriteriaMin}`;
                                                        } else if (item.acceptanceCriteriaMax && !item.acceptanceCriteriaMin) {
                                                            return `NMT ${item.acceptanceCriteriaMax}`;
                                                        }
                                                        return "";

                                                    })()}

                                                </Text>
                                            </View>

                                            <View style={{ ...styles.label, width: 60, ...styles.col, ...styles.flex_center }}>
                                                <Text style={{}}>{
                                                    item.result == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                        item.result == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}

                                                </Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>
                    </View>
                )
            }}
        />)
}

export default SoundLevelTestPDFComponent;





