import { BaseComponent } from "../../../common/component/base.component";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ScreenUrls from "../../../common/screen-urls";
import { AgGridReact } from "ag-grid-react";
import { AccessLevel } from "../../../common/enums";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import { IClientAreaModel } from "../model/client-area.model";
import { clearAreaListState, loadAreas } from "../store/client-area.slice";

export interface IProps {
    accessLevel: number;
    clientAreaList: IClientAreaModel[],
    listLoading: boolean;
    loadClientAreas: () => void;
    clearAreaListState: () => void,
}

class ClientAreaPage extends BaseComponent<
    IProps,
    {
    }
> {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    async componentDidMount() {
        await this.props.loadClientAreas();
    }

    async reloadArea() {
        setTimeout(async () => {
            await this.props.loadClientAreas();
        }, 200);
    }

    updateState(newState: any) {
        setTimeout(() => {
            this.setState({
                ...this.state,
                ...newState,
            });
        }, 1);
    }

    render() {
        return (
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        {(() => {
                                            if (this.props.listLoading)
                                                return (
                                                    <div className="loading--bar">
                                                        <span></span>
                                                    </div>
                                                );
                                        })()}
                                        <div className="card-header d-flex justify-content-between align-items-center">
                                            <h5 className="d-flex align-items-center">
                                                List of Area
                                            </h5>

                                            <div className="">
                                                <div className="btn-group card-option">
                                                    {(() => {
                                                        if (this.props.accessLevel >= AccessLevel.Create) {
                                                            return (
                                                                <Link
                                                                    className="btn drp-icon m-0 btn-rounded btn-primary"
                                                                    to={ScreenUrls.Clients.Area.Create()}
                                                                >
                                                                    Add New
                                                                </Link>
                                                            );
                                                        }
                                                    })()}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-block">
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="card-block px-0 ag-theme-alpine">
                                                        <AgGridReact
                                                            domLayout="autoHeight"
                                                            pagination={true}
                                                            paginationPageSize={10}
                                                            unSortIcon={true}
                                                            rowHeight={60}
                                                            defaultColDef={AgGridDefaultColDef}
                                                            columnDefs={[
                                                                {
                                                                    suppressMovable: true,
                                                                    hide:
                                                                        this.props.accessLevel <
                                                                        AccessLevel.Edit,
                                                                    headerName: "Edit",
                                                                    field: "id",
                                                                    width: 50,
                                                                    cellClass: "grid-cell grid-cell-link",
                                                                    cellRenderer: (params) => {
                                                                        return (<Link
                                                                            to={ScreenUrls.Clients.Area.Edit(
                                                                                params.value
                                                                            )}
                                                                        >
                                                                            <i
                                                                                className={`feather icon-edit`}
                                                                            ></i>
                                                                        </Link>)
                                                                    },
                                                                },
                                                                {
                                                                    suppressMovable: true,
                                                                    headerName: "AHU/VAU Name",
                                                                    field: "ahuOrVAUName",
                                                                    flex: 2,
                                                                    sortable: true,
                                                                    cellClass: "grid-cell",
                                                                    cellRenderer: (params) => {
                                                                        return <span>{params.value}</span>;
                                                                    },
                                                                },

                                                                {
                                                                    suppressMovable: true,
                                                                    headerName: "AHU/VAU Id",
                                                                    width: 180,
                                                                    field: "ahuOrVAUId",
                                                                    sortable: true,
                                                                    cellClass: "grid-cell",
                                                                    comparator: (
                                                                        valueA,
                                                                        valueB,
                                                                        nodeA,
                                                                        nodeB,
                                                                        isDescending
                                                                    ) => {
                                                                        if (valueA?.name == valueB?.name)
                                                                            return 0;
                                                                        return valueA?.name > valueB?.name
                                                                            ? 1
                                                                            : -1;
                                                                    },
                                                                    cellRenderer: (params) => {
                                                                        return (
                                                                            <span>{params.value}</span>
                                                                        );
                                                                    },
                                                                },
                                                                {
                                                                    suppressMovable: true,
                                                                    headerName: "Area Of The Test",
                                                                    width: 180,
                                                                    field: "areaOfTheTest",
                                                                    sortable: true,
                                                                    cellClass: "grid-cell",
                                                                    cellRenderer: (params) => {
                                                                        return <span>{params.value}</span>;
                                                                    },
                                                                },
                                                                {
                                                                    suppressMovable: true,
                                                                    headerName: "Location",
                                                                    width: 180,
                                                                    field: "location",
                                                                    sortable: true,
                                                                    cellClass: "grid-cell",
                                                                    cellRenderer: (params) => {
                                                                        return <span>{params.value}</span>;
                                                                    },
                                                                },
                                                                {
                                                                    suppressMovable: true,
                                                                    headerName: "Client",
                                                                    flex: 3,
                                                                    field: "client",
                                                                    sortable: true,
                                                                    cellClass: "grid-cell",
                                                                    cellRenderer: (params) => {
                                                                        return (
                                                                            <span>
                                                                                {params.value.companyName}
                                                                            </span>
                                                                        );
                                                                    },
                                                                },

                                                            ]}
                                                            rowData={this.props.clientAreaList ?? []}
                                                        >

                                                        </AgGridReact>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    clientAreaList: state.clientArea.areaList,
    listLoading: state.clientArea.listLoading,
});

export default connect(mapStateToProps, {
    loadClientAreas: loadAreas, clearAreaListState
})(ClientAreaPage);
