import React from "react";
import { IFormBaseState, FormBaseComponent, } from "../../../../../../../common/component/form-base.component";
import Modal from 'react-modal';
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { IACPHTestObservationPayload, IACPHTestReadingPayload, IAirChangesPerHourTestModel, IAirChangesPerHourTestObservationModel, IAirChangesPerHourTestObservationReading } from "../../../../../model/cleanroom-area/test/air-changes-per-hour-test.model";
import { airChangesPerHourTestObservationDefault, airChangesPerHourTestObservationReadingDefault } from "../../../../../model/cleanroom-area/defaults/test.default";
import { createACPHObservation, createACPHReading, editACPHObservation, editACPHReading, deleteReading, clearObservationDataState, updateObservationDataState, setDeletdRoomId, } from "../../../../../store/cleanroom-area/cleanroom-area-observation.slice";
import { IBaseTestPayload } from "../../../../../model/cleanroom-equipment/test/test.model";
import { CleanroomAreaTests, ValidationScreens } from "../../../../../../../common/enums";

export interface IProps {
    toggle: boolean,
    handleToggleState: any,
    testData: IAirChangesPerHourTestModel,
    observationData: IAirChangesPerHourTestObservationModel,
    clearObservationDataState: (data: IAirChangesPerHourTestObservationModel) => void,
    updateObservationDataState: (data) => void,
    createObservation: (data: IACPHTestObservationPayload) => void,
    editObservation: (data: IACPHTestObservationPayload) => void,
    testName: string,
    defaultData?: null | IAirChangesPerHourTestObservationModel,
    createReading: (data: IACPHTestReadingPayload) => void,
    editReading: (data: IACPHTestReadingPayload) => void,
    deleteReading: (data: IBaseTestPayload) => void,
    deletedRoomId?: number,
    setDeletdRoomId: (data: number) => void,
}

export interface IState extends IFormBaseState {
    readingData: IAirChangesPerHourTestObservationReading,
    openReadingSection: boolean,
    editReadingIndex: number,
}

class TestObservationComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            readingData: airChangesPerHourTestObservationReadingDefault,
            hideForm: false,
            openReadingSection: false,
            editReadingIndex: -1,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.CleanroomAreaAirChangesPerHourTestObservation);
        this.props.clearObservationDataState(this.props.defaultData ?? airChangesPerHourTestObservationDefault);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.defaultData && this.props.defaultData != prevProps.defaultData) {
            this.props.clearObservationDataState(this.props.defaultData);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    render() {
        return <React.Fragment>
            <Modal
                isOpen={this.props.toggle}
                onRequestClose={this.props.handleToggleState}
            >
                <div className={`d-flex f-w-500 flex-column`} style={{ maxHeight: "90vh" }}>
                    <div className="f-18">
                        Add Observations
                    </div>
                    <div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("filterOrGrillId").className}`}>
                                    <label htmlFor="filterOrGrillId">
                                        {this.props.testData?.observationLabelName} Id
                                        <span className="requried-span">*</span></label>
                                    <input id="filterOrGrillId" className="form-control" type="text"
                                        value={this.props.observationData?.filterOrGrillId ?? ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            this.ValidateField("filterOrGrillId", e.target?.value);
                                            this.props.updateObservationDataState({ name: "filterOrGrillId", value: e.target?.value });
                                            // this.props.updateHasError(this.hasError);
                                        }} />

                                    <ValidationMessageControl message={this.vResult("filterOrGrillId").message} />
                                </div>
                            </div>
                            {
                                this.props.testData?.instrumentUsedToPerformObservation == "Anemometer" ?
                                    <div className="col-6">
                                        <div className={`form-group ${this.vResult("filterOrGrillArea").className}`}>
                                            <label htmlFor="filterOrGrillArea">
                                                {this.props.testData?.observationLabelName} Area
                                                <span className="requried-span">*</span></label>
                                            <input id="filterOrGrillArea" className="form-control" type="number"
                                                value={this.props.observationData?.filterOrGrillArea ?? ""}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    this.ValidateField("filterOrGrillArea", +e.target?.value);
                                                    this.props.updateObservationDataState({ name: "filterOrGrillArea", value: e.target?.value });
                                                    // this.props.updateHasError(this.hasError);
                                                }} />

                                            <ValidationMessageControl message={this.vResult("filterOrGrillArea").message} />
                                        </div>
                                    </div> : ""
                            }

                            {(() => {
                                if (this.props.testData?.instrumentUsedToPerformObservation == "Anemometer") {
                                    if (this.props.observationData?.id) {
                                        return (
                                            <div className="col-6">
                                                <div className={`form-group ${this.vResult("airFlowRate").className}`}>
                                                    <label htmlFor="airFlowRate">Air Flow Rate</label>
                                                    <input id="acceptanceCriteriaMin" className={`form-control not-allowed`} type="number"
                                                        value={this.props.observationData?.airFlowRate ?? ""}
                                                        disabled={true}
                                                        onChange={e => {
                                                            e.preventDefault();
                                                            this.ValidateField("airFlowRate", e.target?.value);
                                                            this.props.updateObservationDataState({ name: "airFlowRate", value: e?.target?.value });
                                                            // this.props.updateHasError(this.hasError);
                                                        }} />
                                                    <ValidationMessageControl message={this.vResult("airFlowRate").message} />
                                                </div>
                                            </div>
                                        )
                                    }

                                }
                                else {
                                    return (
                                        <div className="col-6">
                                            <div className={`form-group ${this.vResult("airFlowRate").className}`}>
                                                <label htmlFor="airFlowRate">Air Flow Rate<span className="requried-span">*</span></label>
                                                <input id="acceptanceCriteriaMin" className={`form-control `} type="number"
                                                    value={this.props.observationData?.airFlowRate ?? ""}

                                                    onChange={e => {
                                                        e.preventDefault();
                                                        this.ValidateField("airFlowRate", e.target?.value);
                                                        this.props.updateObservationDataState({ name: "airFlowRate", value: e?.target?.value });
                                                        // this.props.updateHasError(this.hasError);
                                                    }} />
                                                <ValidationMessageControl message={this.vResult("airFlowRate").message} />
                                            </div>
                                        </div>
                                    )
                                }
                            })()
                            }

                            {
                                this.props.testData?.instrumentUsedToPerformObservation == "Anemometer" && this.props.observationData?.id && this.props.observationData?.readings?.length ?
                                    <div className="col-6">
                                        <div className={`form-group ${this.vResult("averageFlowRate").className}`}>
                                            <label htmlFor="averageFlowRate">Average Flow Rate</label>
                                            <input id="averageFlowRate" className="form-control not-allowed" type="text"
                                                value={this.props.observationData?.averageFlowRate ?? ""}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                        <div className="row">
                            <div className="col-12 text-right">
                                <button
                                    onClick={(e: any) => {
                                        const error = !this.ValidateForm(this.props.observationData);
                                        this.reloadForm();
                                        if (error) return;
                                        if (this.props.observationData?.id) {
                                            this.props.editObservation({ testName: this.props.testName, data: this.props.observationData });
                                        } else {
                                            let payload = { ...this.props.observationData };
                                            payload.airChangesPerHourTestId = this.props.testData.id ?? 0;
                                            this.props.createObservation({ testName: this.props.testName, data: payload });
                                        }
                                        if (this.props.testData?.instrumentUsedToPerformObservation != "Anemometer") {
                                            this.props.handleToggleState()
                                        }
                                    }}
                                    className="text-capitalize f-w-800 border-0 btn-primary btn-sm mt-2 align-self-end">
                                    Submit
                                </button>
                            </div>
                        </div>
                        {
                            this.props?.observationData && this.props.observationData?.id && this.props.testData?.instrumentUsedToPerformObservation == "Anemometer" ?
                                <div className="row">
                                    <div className="col-12">
                                        <div className="my-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    Readings :
                                                    {!this.state.openReadingSection && this.props?.observationData?.readings?.length < 11 ?
                                                        <button
                                                            className="text-capitalize f-w-800 ml-2 border-0 btn-primary btn-sm align-self-end"
                                                            onClick={() => {
                                                                this.setState({ ...this.state, openReadingSection: !this.state.openReadingSection });
                                                            }}>+</button>
                                                        : null}
                                                </div>
                                                {
                                                    this.state.openReadingSection &&
                                                    <>
                                                        <div className="col-4">
                                                            <input className="form-control" type="number"
                                                                value={this.state.readingData.reading ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.setState({
                                                                        ...this.state,
                                                                        readingData: { ...this.state.readingData, reading:e?.target.value ?? 0 }
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-4">
                                                            <button
                                                                className="text-capitalize f-w-800 border-0 btn-primary btn-sm"
                                                                onClick={() => {
                                                                    if (Number(this.state.readingData?.reading) > -1) {
                                                                        let item = { ...this.state.readingData, airChangesPerHourTestObservationId: this.props.observationData?.id ?? 0 };
                                                                        if (this.state.readingData?.airChangesPerHourTestObservationId && this.state.editReadingIndex > -1) {
                                                                            this.props.editReading({ testName: this.props.testName, data: item });
                                                                        } else {
                                                                            this.props.createReading({ testName: this.props.testName, data: item });
                                                                        }
                                                                        this.setState({ ...this.state, openReadingSection: false, editReadingIndex: -1, readingData: airChangesPerHourTestObservationReadingDefault, });
                                                                    }

                                                                }}>
                                                                {this.state.editReadingIndex > -1 ? "Edit" : "Add"}
                                                            </button>
                                                            <button
                                                                className="text-capitalize f-w-800 border-0 btn-primary btn-sm ml-2"
                                                                onClick={() => {

                                                                    this.setState({ ...this.state, openReadingSection: false, editReadingIndex: -1, readingData: airChangesPerHourTestObservationReadingDefault, });
                                                                }}>
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        {
                                            Array.isArray(this.props.observationData?.readings) && this.props.observationData?.readings?.length > 0 ?
                                                <div className="d-flex align-items-center">
                                                    {this.props.observationData?.readings?.map((item: IAirChangesPerHourTestObservationReading, index: number) => {
                                                        return <div className="d-flex align-items-center border p-2 mr-2">
                                                            <div>{item?.reading ?? ""}</div>
                                                            <div className="ml-3">
                                                                <span
                                                                    onClick={() => {
                                                                        this.setState({ ...this.state, editReadingIndex: index, readingData: item, openReadingSection: true });
                                                                    }}
                                                                >
                                                                    <i className={`feather icon-edit f-14  text-green`}></i>
                                                                </span>
                                                                <span
                                                                    onClick={() => {
                                                                        this.props.setDeletdRoomId(index);
                                                                        this.props.deleteReading({ testName: CleanroomAreaTests.airchangesperhour, id: item.id });
                                                                    }}
                                                                >
                                                                    <i className={`ml-2 feather icon-trash f-14 text-green`}></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </Modal>
        </React.Fragment >

    }
}

const mapStateToProps = (state: IAppState) => ({
    observationData: state.cleanroomAreaObservation.observationData,
    dataLoading: state.cleanroomAreaObservation.dataLoading,
    createEditLoading: state.cleanroomAreaObservation.createEditLoading,
    deleteRoomId: state.cleanroomAreaObservation.deletedRoomId,
});
export default connect(mapStateToProps, {
    clearObservationDataState, updateObservationDataState, createObservation: createACPHObservation, setDeletdRoomId, editObservation: editACPHObservation, createReading: createACPHReading, editReading: editACPHReading, deleteReading
})(TestObservationComponent);

