import { EUGMPZoneClassificationState, ObservationLocationLabel, ZoneClassificationType } from "../../../../../common/enums";
import { IBaseCleanroomAreaTestModel } from "../../report.model";
import { IAirChangesPerHourTestModel, IAirChangesPerHourTestObservationModel, IAirChangesPerHourTestObservationReading } from "../test/air-changes-per-hour-test.model";
import { ICleanroomAreaAirflowPatternTestModel, ICleanroomAreaAirflowPatternTestObservationModel, ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel } from "../test/airflow-pattern-test.model";
import { ICleanroomAreaDifferentialPressureTestModel, ICleanroomAreaDifferentialPressureTestRoomObservationModel } from "../test/differential-pressure-test.model";
import { ICleanroomAreaFilterIntegrityTestModel, ICleanroomAreaFilterIntegrityTestObservationModel } from "../test/filter-integrity-test.model";
import { ICleanroomAreaLightIntensityTestModel, ICleanroomAreaLightIntensityTestObservationModel, ICleanroomAreaLightIntensityTestReadingModel } from "../test/light-intensity-test.model";
import { ICleanroomAreaNonViableParticleCountTestLocationModel, ICleanroomAreaNonViableParticleCountTestModel, ICleanroomAreaNonViableParticleCountTestObservationModel } from "../test/non-viable-particle-count-test.model";
import { ICleanroomAreaRecoveryReading, ICleanroomAreaRecoveryTestModel } from "../test/recovery-test.model";
import { ICleanroomAreaSoundLevelTestModel, ICleanroomAreaSoundLevelTestObservationModel, ICleanroomAreaSoundLevelTestReadingModel } from "../test/sound-level-test-model";
import { ICleanroomAreaUVLightIntensityTestModel, ICleanroomAreaUVLightIntensityTestObservationModel, ICleanroomAreaUVLightIntensityTestReadingModel } from "../test/uv-light-intensity-test.model";

export const cleanroomAreaTestDefault: IBaseCleanroomAreaTestModel = {
    cleanroomAreaReportId: 0,
    id: 0,
    dataLoggerId: 0,
    dataLogger: null,
    dateOfTest: "",
    dueDate: "",
    preparedById: 0,
    preparedOnDate: "",
    checkedOnDate: "",
    verifiedByName: "",
    verifiedOnDate: "",
    remarks: ""
}

export const airChangesPerHourTestDefault: IAirChangesPerHourTestModel = {
    ...cleanroomAreaTestDefault,
    id: 0,
    acceptanceCriteria: 0,
    instrumentUsedToPerformObservation: "",
    observationLabelName: "",
    totalAirFlowRate: 0,
    roomVolume: 0,
    airChangesPerHour: 0,
}

export const airChangesPerHourTestObservationDefault: IAirChangesPerHourTestObservationModel = {
    id: 0,
    filterOrGrillId: "",
    airFlowRate: 0,
    airChangesPerHourTestId: 0,
    readings: []
}

export const airChangesPerHourTestObservationReadingDefault: IAirChangesPerHourTestObservationReading = {
    id: 0,
    reading: 0,
    airChangesPerHourTestObservationId: 0,
}

export const cleanroomAreaFITestDefault: ICleanroomAreaFilterIntegrityTestModel = {
    ...cleanroomAreaTestDefault,
    id: 0,
    filterEfficiency:"",
    numberOfFilter: 0,
    aerosolGeneratorType: 0,
    aerosolMedium: 0,
}

export const cleanroomAreaFITestObservationDefault: ICleanroomAreaFilterIntegrityTestObservationModel = {
    id: 0,
    cleanroomAreaFilterIntegrityTestId: 0,
    filterId: "",
    upstreamConcentrationBeforeIntegrity: null,
    upstreamConcentrationBeforeIntegrityPercentage: null,
    obtainedResultsInDownstream: 0,
    upstreamPAOConcentrationAfterIntegrity: null,
    upstreamPAOBeforeAndAfterIntegrityShouldNotVary15Percent: null,
    testObservationResult: null,
}

export const CleanroomAreaNonViableParticleCountTestDefault: ICleanroomAreaNonViableParticleCountTestModel = {
    ...cleanroomAreaTestDefault,
    occupancyState: EUGMPZoneClassificationState.AtRest,
    consideredParticleSizes: "",
    airVolumeSampledPerLocation: "",
    samplingTimeAtEachSamples: "",
    numberOfSamplingLocation: null,
    numberOfSamplePerLocation: null,
    pointFiveOrMoreMicronMaximum: null,
    oneOrMoreMicronMaximum: null,
    fiveOrMoreMicronMaximum: null,
    pointFiveOrMoreMicronMean: null,
    oneOrMoreMicronMean: null,
    fiveOrMoreMicronMean: null,
    pointFiveOrMoreMicronResult: null,
    oneOrMoreMicronResult: null,
    fiveOrMoreMicronResult: null,
    pointFiveMicron: null,
    oneMicron: null,
    fiveMicron: null,
    locations: [],
}

export const CleanroomAreaNonViableParticleCountTestLocationDefault: ICleanroomAreaNonViableParticleCountTestLocationModel = {
    id: 0,
    cleanroomAreaNonViableParticleCountTestId: 0,
    locationNumber: "",
    pointFiveOrMoreMicronMean: null,
    oneOrMoreMicronMean: null,
    fiveOrMoreMicronMean: null,
    pointFiveOrMoreMicronMaximum: null,
    oneOrMoreMicronMaximum: null,
    fiveOrMoreMicronMaximum: null,
    observations: [],
}

export const CleanroomAreaNonViableParticleCountTestObservationDefault: ICleanroomAreaNonViableParticleCountTestObservationModel = {
    id: 0,
    locationNumber: "",
    pointFiveOrMoreMicron: null,
    oneOrMoreMicron: null,
    fiveOrMoreMicron: null,
    locationId: 0,
    nonViableParticleCountTestId: 0,
}

export const CleanroomAreaRecoveryTestDefault: ICleanroomAreaRecoveryTestModel = {
    ...cleanroomAreaTestDefault,
    occupancyState: null,
    consideredParticleSizes: "",
    airVolumeSampledPerLocation: "",
    samplingTimeAtEachSamples: 0,
    numberOfSamplingLocation: null,
    locationId: "",
    clientSpecificationPointFiveMicron: null,
    clientSpecificationOneMicron: null,
    clientSpecificationFiveMicron: null,
    initialReadingTime: null,
    initialReadingAtPointFiveParticleSize: null,
    initialReadingAtOneParticleSize: null,
    initialReadingAtFiveParticleSize: null,
    worstReadingTime: null,
    worstReadingAtPointFiveParticleSize: null,
    worstReadingAtOneParticleSize: null,
    worstReadingAtFiveParticleSize: null,
    finalReadingTime: null,
    finalReadingAtPointFiveParticleSize: null,
    finalReadingAtOneParticleSize: null,
    finalReadingAtFiveParticleSize: null,
    acceptanceCriteriaMin: null,
    acceptanceCriteriaMax: null,
    result: null,
    recoveryTime: null,
    recoveryReadings: [],
}

export const CleanroomAreaRecoveryReadingDefalt: ICleanroomAreaRecoveryReading = {
    id: 0,
    recoveryTestId: 0,
    recoveryTime: null,
    recoveryReadingAtPointFiveParticleSize: null,
    recoveryReadingAtOneParticleSize: null,
    recoveryReadingAtFiveParticleSize: null,
}

export const CleanroomAreaAirflowPatternTestDefault: ICleanroomAreaAirflowPatternTestModel = {
    ...cleanroomAreaTestDefault,
    cameraDetailId: 0,
    observations: [],
}

export const CleanroomAreaAirflowPatternTestObservationDefault: ICleanroomAreaAirflowPatternTestObservationModel = {
    id: 0,
    airFlowPatternTestId: 0,
    cleanroomAreaAirFlowPatternTest: null,
    description: "",
    doesTheSmokeFlowsTowardsTheReturnGrills: null,
    doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills: null,
    smokeFlowDetails:[],
}

export const CleanroomAreaAirFlowPatternTestObservationSmokeFlow:ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel={
    id:0,
    observationId:0,   
    result:null,
    higherPressureGradient:"",
    lowerPressureGradient:"",
    doesTheDifferentialPressureIsZeroWhileDoorOpening:null,
}

export const CleanroomAreaSLTestDefault : ICleanroomAreaSoundLevelTestModel = {
    ...cleanroomAreaTestDefault,
    observationLocationLabel:ObservationLocationLabel.LocationNameOrNumber,
    observations:[],
}

export const CleanroomAreaSLTestObservationDefault : ICleanroomAreaSoundLevelTestObservationModel = {
    id:0,
    soundLevelTestId:0,
    locationNameOrNumber:"",
    result: null,
    averageSoundLevel: null,
    acceptanceCriteriaMin: null,
    acceptanceCriteriaMax: null,
    readings:[],
}

export const CleanroomAreaSLTestReadingDefault : ICleanroomAreaSoundLevelTestReadingModel = {
    id:0,
    reading:0,
    observationId:0,
}

export const CleanroomAreaLITestDefault : ICleanroomAreaLightIntensityTestModel = {
    ...cleanroomAreaTestDefault,
    observationLocationLabel:ObservationLocationLabel.LocationNameOrNumber,
    observations:[],
}

export const CleanroomAreaLITestObservationDefault : ICleanroomAreaLightIntensityTestObservationModel = {
    id:0,
    lightIntensityTestId:0,
    locationNameOrNumber:"",
    result: null,
    averageLightIntensity: null,
    acceptanceCriteriaMin: null,
    acceptanceCriteriaMax: null,
    readings:[],
}

export const CleanroomAreaLITestReadingDefault : ICleanroomAreaLightIntensityTestReadingModel = {
    id:0,
    reading:0,
    observationId:0,
}

export const CleanroomAreaUVLITestDefault : ICleanroomAreaUVLightIntensityTestModel = {
    ...cleanroomAreaTestDefault,
    observationLocationLabel:ObservationLocationLabel.LocationNameOrNumber,
    observations:[],
}

export const CleanroomAreaUVLITestObservationDefault : ICleanroomAreaUVLightIntensityTestObservationModel = {
    id:0,
    uvLightIntensityTestId:0,
    locationNameOrNumber:"",
    result: null,
    averageUVLightIntensity: null,
    acceptanceCriteriaMin: null,
    acceptanceCriteriaMax: null,
    readings:[],
}

export const CleanroomAreaUVLITestReadingDefault : ICleanroomAreaUVLightIntensityTestReadingModel = {
    id:0,
    reading:0,
    observationId:0,
}

export const CleanroomAreaDFTestDefaults : ICleanroomAreaDifferentialPressureTestModel ={
    ...cleanroomAreaTestDefault,
    roomObservations:[],
}

export const CleanroomAreaDFTestRoomobservationDefaults : ICleanroomAreaDifferentialPressureTestRoomObservationModel ={
    id: 0,
    differentialPressureTestId: 0,
    highPressureRoomOrZone: "",
    lowPressureRoomOrZone: "",
    differentialPressure: null,
    acceptanceCriteria: null,
    result: null,
}