import { BaseComponent } from "../../../../common/component/base.component";
import { connect } from "react-redux";
import { IAppState } from "../../../../store";
import React from "react";
import { AgGridReact } from "ag-grid-react";
import { AgGridDefaultColDef } from '../../../../common/app-defaults';
import { IClientEquipmentModel } from "../../../client/model/client-equipment.model";
import SearchBox from "../../../../common/component/searchbox.component";
import ClientEquipmentService from "../../../client/service/client-equipment.service";
import ClientEquipmentCreateComponent from "../../../client/component/quick-add-client-equipment.component";

export interface IProps {
    defaultSelections?: null | IClientEquipmentModel[];
    onSelection: (data: IClientEquipmentModel) => void;
    onUnSelection: (data: IClientEquipmentModel) => void;
    clientId: number,
}

class EquipmentSelectorAccordionComponent extends BaseComponent<IProps, {
    collapsed: boolean,
    listLoading: boolean,
    clientEquipmentList: IClientEquipmentModel[],
    previousSelection?: IClientEquipmentModel[] | null,
    addEquipmentSection: boolean,
}> {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: true,
            listLoading: false,
            clientEquipmentList: [],
            previousSelection: this.props.defaultSelections,
            addEquipmentSection: false,
        }
    }

    reloadEquipments = async () => {
        setTimeout(async () => {
            const list = await ClientEquipmentService.LoadAllByClientId(this.props.clientId);
            this.updateState({ clientEquipmentList: list });
        }, 200)
    }
    async componentDidMount() {
        await this.reloadEquipments();
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<{ collapsed: boolean; listLoading: boolean; clientEquipmentList: IClientEquipmentModel[]; previousSelection?: IClientEquipmentModel[] | null; addEquipmentSection: boolean }>, snapshot?: any) {
        if (prevProps.clientId != this.props.clientId || (!this.state.addEquipmentSection && prevState.addEquipmentSection)) {
            await this.reloadEquipments();
        }
    }

    updateState(newState: any) {
        this.setState({
            ...this.state,
            ...newState
        });
    }
    render() {
        return <React.Fragment>
            {(() => { if (this.state.listLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
            <div className="accordion no-bg" id="dataLoggerSelectorAccordionContainer">
                <div className="card overflow-visible">
                    <div className="card-header">
                        <button className="btn btn-primary accordion--btn" type="button" data-toggle="collapse"
                            data-target="#dataLoggerSelectorAccordionControl" aria-expanded="false" aria-controls="dataLoggerSelectorAccordionControl"
                            onClick={() => { this.setState({ collapsed: !this.state.collapsed }) }}>
                            <i className={`button-icon feather ${this.state.collapsed ? 'icon-chevrons-down' : 'icon-chevrons-up'}`}></i>
                            Client Equipment Selection<span className="requried-span text-white">*</span>
                        </button>
                    </div>
                    <div id="dataLoggerSelectorAccordionControl" className="collapse" aria-labelledby="headingOne"
                        data-parent="#dataLoggerSelectorAccordionContainer">
                        <div className="card-body">
                            {/* --------- quick add box --------- */}
                            {
                                this.state.addEquipmentSection ?
                                    <ClientEquipmentCreateComponent
                                        clientId={this.props.clientId}
                                        callBackHandler={() => this.setState({ ...this.state, addEquipmentSection: false })}
                                    />
                                    :
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-group">
                                                <SearchBox
                                                    label="Make"
                                                    htmlFor="dtGridSerialNumber"
                                                    value={""}
                                                    onBlurHandler={async (e) => {
                                                        // if (e != null) {
                                                        //     this.props.changeCurrentPage(1);
                                                        //     this.updateState({
                                                        //         serialNumber: e.target.value,
                                                        //     });
                                                        //     await this.reloadDataLoggers();
                                                        // }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <SearchBox
                                                label="Serial Number"
                                                htmlFor="dtGridSerialNumber"
                                                value={""}
                                                onBlurHandler={async (e) => {
                                                    // if (e != null) {
                                                    //     this.props.changeCurrentPage(1);
                                                    //     this.updateState({
                                                    //         serialNumber: e.target.value,
                                                    //     });
                                                    //     await this.reloadDataLoggers();
                                                    // }
                                                }}
                                            />
                                        </div>
                                        <div className="col-3"></div>
                                        <div className="col-3 align-self-center text-right">
                                            <button className="btn mr-0 mb-0 btn-primary"
                                                onClick={() => this.setState({ ...this.state, addEquipmentSection: !this.state.addEquipmentSection })}
                                            >Add Equipment</button>
                                        </div>
                                    </div>
                            }
                            <div className="row">
                                <div className="col-12">
                                    <div className="card-block px-0 ag-theme-alpine">
                                        <AgGridReact
                                            domLayout='autoHeight'
                                            pagination={false}
                                            paginationPageSize={9}
                                            unSortIcon={true}
                                            rowHeight={60}
                                            rowSelection="multiple"
                                            columnDefs={[
                                                {
                                                    width: 45,
                                                    suppressMovable: true,
                                                    cellClass: "grid-cell",
                                                    checkboxSelection: (row) => {
                                                        if (row.data == null || row.data == undefined) return false;
                                                        const isRowSelectable = row.node.isSelected() || (row.data != null);
                                                        row.node.selectable = isRowSelectable;
                                                        return isRowSelectable;
                                                    },
                                                    cellRenderer: (params) => {
                                                        if (this.props.defaultSelections != null) {
                                                            const filtered = this.props.defaultSelections.filter(d => d.id === params.data.id);
                                                            params.node.selected = filtered != null && filtered.length > 0;
                                                            return <span>{params.value}</span>
                                                        }

                                                        return <span>{params.value}</span>
                                                    },
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "#", field: 'id', width: 80, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Equipment Name", field: "equipmentName", flex: 2, sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Make", flex: 1, field: "make", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                                {
                                                    suppressMovable: true,
                                                    headerName: "Serial #", flex: 1, field: "serialNumber", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                }, {
                                                    suppressMovable: true,
                                                    headerName: "Model Number", flex: 1, field: "modelNumber", sortable: true, cellClass: "grid-cell",
                                                    cellRenderer: (params) => {
                                                        return <span>{params.value}</span>
                                                    }
                                                },
                                            ]}
                                            rowData={this.state.clientEquipmentList}
                                            onRowSelected={(r) => {
                                                if (r.data == null) {
                                                    return;
                                                }
                                                if (r.node.isSelected()) {
                                                    this.props.onSelection(r.data);
                                                } else {
                                                    this.props.onUnSelection(r.data)
                                                }
                                            }}
                                            suppressRowClickSelection={true}
                                            defaultColDef={AgGridDefaultColDef}>
                                        </AgGridReact>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}

const mapStateToProps = (state: IAppState) => ({})
export default connect(mapStateToProps, {})(EquipmentSelectorAccordionComponent);

