
import { Text, View, } from '@react-pdf/renderer';
import { CleanroomEquipmentTests, EUGMPZoneClassificationState, TestObservationResult, ZoneClassificationType } from "../../../../../common/enums";
import { ISOConsideredParticleSizeList, ReportTestEquipmentState } from "../../../../../common/_static/report.data";
import BasePDFComponent from "../PDF/base-test-report-pdf.component";
import { IUserModel } from "../../../../user/model/user.model";
import { ICleanroomEquipmentModel } from "../../../model/report.model";

export const ParticleCountTestPDFComponent = ({ id, currentUser, data }: { id: number, currentUser: IUserModel, data: ICleanroomEquipmentModel }) => {
    return (
        <BasePDFComponent testName={CleanroomEquipmentTests.nonviableparticlecount} title="Non-Viable Particle Count" id={id} data={data} currentUser={currentUser}
            callBack={(styles: any, data: any, maxReadings: number) => {
                return (
                    <>
                        <View style={{ ...styles.paddingTB }} wrap={false}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Sampling Details :</Text>
                        </View>
                        <View style={{}}>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Occupancy State
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{ReportTestEquipmentState?.find((item) => item.value == data?.occupancyState?.toString())?.label}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Considered Particle sizes
                                            </Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{data.consideredParticleSizes ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Number of Sampling Location
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data?.noOfSamplingLocation ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Air Volume Sampled per Location

                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data?.airVolumeSampledPerLocation? `${data?.airVolumeSampledPerLocation}L` :""}</Text>
                                    </View>
                                </View>
                            </View>

                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Number of Sample per Location
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data?.noOfSamplePerLocation ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Sampling Time at each Samples
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data?.samplingTimeAtEachSamples ?? ""}</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>
                                    <Text style={{ ...styles.textCenter, ...styles.bold }}>
                                        Maximum allowable concentrations (particles / m<Text style={{ verticalAlign: 'super', fontSize: 7 }}>3</Text>) for particles equal to and greater than the considered sizes
                                    </Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                    <Text style={{}}> Zone Classification
                                    </Text>
                                </View>
                                {
                                    ISOConsideredParticleSizeList?.filter((item) => data?.consideredParticleSizes?.split(",")?.includes(item.label))?.map((item, index) => {
                                        return (<View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text>{item.label}</Text>
                                        </View>)
                                    })
                                }
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                    <Text style={{}}>
                                        {
                                            data?.zoneClassificationType == ZoneClassificationType.ClientSpecification ? "Client Specification" :
                                                data?.zoneClassificationType == ZoneClassificationType.EUGMP ?
                                                    `Grade ${data?.eugmpStandard?.grade}`
                                                    :
                                                    `ISO ${data?.isoStandard?.classNumber}`
                                        }
                                    </Text>
                                </View>
                                {
                                    data?.consideredParticleSizes?.includes("0.5µm") ?
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text> {
                                                data?.zoneClassificationType == ZoneClassificationType.ClientSpecification ?
                                                    data.pointFiveMicron
                                                    :
                                                    data?.zoneClassificationType == ZoneClassificationType.EUGMP ?
                                                        EUGMPZoneClassificationState?.AtRest == data?.occupancyState ?
                                                            data?.eugmpStandard?.pointFiveOrMoreMicronCubicMetre_AtRest ?? "Not Applicable"
                                                            :
                                                            data?.eugmpStandard?.pointFiveOrMoreMicronCubicMetre_InOperation ?? "Not Applicable"
                                                        :
                                                        data?.zoneClassificationType == ZoneClassificationType.ISO ? data?.isoStandard?.pointFiveMicron ?? "Not Applicable" : ""
                                            }
                                            </Text>
                                        </View>
                                        :
                                        ""
                                }
                                {
                                    data?.consideredParticleSizes?.includes("1µm") ?
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text>
                                                {
                                                    data?.zoneClassificationType == ZoneClassificationType.ClientSpecification ?
                                                        data.oneMicron
                                                        :
                                                        data?.zoneClassificationType == ZoneClassificationType.EUGMP ?
                                                            EUGMPZoneClassificationState?.AtRest == data?.occupancyState ?
                                                                data?.eugmpStandard?.oneOrMoreMicronCubicMetre_AtRest ?? "Not Applicable"
                                                                :
                                                                data?.eugmpStandard?.oneOrMoreMicronCubicMetre_InOperation ?? "Not Applicable"
                                                            :
                                                            data?.zoneClassificationType == ZoneClassificationType.ISO ? data?.isoStandard?.oneMicron ?? "Not Applicable" : ""
                                                }
                                            </Text>
                                        </View>
                                        :
                                        ""
                                }
                                {
                                    data?.consideredParticleSizes?.split(",")?.includes("5µm") ?
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text>
                                                {
                                                    data?.zoneClassificationType == ZoneClassificationType.ClientSpecification ?
                                                        data.fiveMicron
                                                        :
                                                        data?.zoneClassificationType == ZoneClassificationType.EUGMP ?
                                                            EUGMPZoneClassificationState?.AtRest == data?.occupancyState ?
                                                                data?.eugmpStandard?.fiveOrMoreMicronCubicMetre_AtRest ?? "Not Applicable"
                                                                :
                                                                data?.eugmpStandard?.fiveOrMoreMicronCubicMetre_InOperation ?? "Not Applicable"
                                                            :
                                                            data?.zoneClassificationType == ZoneClassificationType.ISO ? data?.isoStandard?.fiveMicron ?? "Not Applicable" : ""
                                                }
                                            </Text>
                                        </View>
                                        :
                                        ""
                                }
                            </View>
                        </View>

                        <View style={{ ...styles.paddingTB }} wrap={false}>
                            <View style={{ ...styles.flexBox }}>
                                <Text style={{ ...styles.flex_1, ...styles.col, ...styles.bold, textAlign: 'center' }}>OBTAINED RESULTS
                                </Text>
                            </View>
                            <View style={{ ...styles.flexBox, ...styles.bold }}>
                                <View style={{ width: 80, ...styles.col }}>
                                    <Text style={{}}> Location Number
                                    </Text>
                                </View>
                                {

                                    ISOConsideredParticleSizeList?.filter((item) => data?.consideredParticleSizes?.split(",")?.includes(item.label))?.map((item, index) => {
                                        return (
                                            <View style={{ ...styles.flex_2, }}>
                                                <View style={{ ...styles.flexBox }}>
                                                    <View style={{ ...styles.col, flex: data?.noOfSamplePerLocation > 1 ? 2 : 1 }}>
                                                        <Text> Number of particles / m<Text style={{ verticalAlign: 'super', fontSize: 7 }}>3</Text>( {">="} {item.label})
                                                        </Text>
                                                    </View>
                                                    {
                                                        data?.noOfSamplePerLocation > 1 ?
                                                            <View style={{ ...styles.col, ...styles.flex_1 }}>
                                                                <Text style={{}}>
                                                                    Location Avg
                                                                </Text>
                                                            </View>
                                                            : null
                                                    }
                                                </View>
                                            </View>
                                        )
                                    })

                                }

                            </View>
                            {
                                data?.locations?.map((item) => {
                                    return (
                                        <View style={{ ...styles.flexBox }}>
                                            <View style={{ width: 80, ...styles.col }}>
                                                <Text style={{}}> {item.locationNumber ?? ""}
                                                </Text>
                                            </View>
                                            {
                                                data?.consideredParticleSizes?.includes("0.5µm") ?
                                                    <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                                        <View style={{ flex: data?.noOfSamplePerLocation > 1 ? 2 : 1, ...styles.flexBox }}>
                                                            {
                                                                item?.observations?.map((item2) => {
                                                                    return (
                                                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                                                            <Text style={{}}>
                                                                                {item2?.pointFiveOrMoreMicron ?? 0}
                                                                            </Text>
                                                                        </View>
                                                                    )
                                                                })
                                                            }
                                                        </View>
                                                        {
                                                            data?.noOfSamplePerLocation > 1 ?
                                                                <View style={{ ...styles.flex_1, ...styles.col }}>
                                                                    <Text style={{}}>
                                                                        {item?.pointFiveOrMoreMicronMean ?? 0}
                                                                    </Text>
                                                                </View>
                                                                : null
                                                        }
                                                    </View>
                                                    :
                                                    ""
                                            }
                                            {
                                                data?.consideredParticleSizes?.includes("1µm") ?
                                                    <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                                        <View style={{ flex: data?.noOfSamplePerLocation > 1 ? 2 : 1, ...styles.flexBox }}>
                                                            {
                                                                item?.observations?.map((item2) => {
                                                                    return (
                                                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                                                            <Text style={{}}>
                                                                                {item2?.oneOrMoreMicron ?? 0}
                                                                            </Text>
                                                                        </View>
                                                                    )
                                                                })
                                                            }

                                                        </View>
                                                        {
                                                            data?.noOfSamplePerLocation > 1 ?
                                                                <View style={{ ...styles.flex_1, ...styles.col }}>
                                                                    <Text style={{}}>
                                                                        {item?.oneOrMoreMicronMean ?? 0}
                                                                    </Text>
                                                                </View>
                                                                : null
                                                        }
                                                    </View>
                                                    :
                                                    ""
                                            }
                                            {
                                                data?.consideredParticleSizes?.split(",")?.includes("5µm") ?
                                                    <View style={{ ...styles.flex_2, ...styles.flexBox }}>
                                                        <View style={{ flex: data?.noOfSamplePerLocation > 1 ? 2 : 1, ...styles.flexBox }}>
                                                            {
                                                                item?.observations?.map((item2) => {
                                                                    return (
                                                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                                                            <Text style={{}}>
                                                                                {item2?.fiveOrMoreMicron ?? 0}
                                                                            </Text>
                                                                        </View>
                                                                    )
                                                                })
                                                            }

                                                        </View>
                                                        {
                                                            data?.noOfSamplePerLocation > 1 ?
                                                                <View style={{ ...styles.flex_1, ...styles.col }}>
                                                                    <Text style={{}}>
                                                                        {item?.fiveOrMoreMicronMean ?? 0}
                                                                    </Text>
                                                                </View>
                                                                : null
                                                        }
                                                    </View>
                                                    :
                                                    ""
                                            }

                                        </View>
                                    )
                                })
                            }
                            {
                                data?.locations?.length > 0 && data?.locations?.some((element) => element.observations?.length > 1) ?
                                    <>
                                        <View style={{ ...styles.flexBox }}>
                                            <View style={{ width: 80, ...styles.col }}>
                                                <Text style={{}}> Maximum
                                                </Text>
                                            </View>
                                            {
                                                data?.consideredParticleSizes?.includes("0.5µm") ?
                                                    <View style={{ ...styles.col, ...styles.flex_2 }}>
                                                        <Text>
                                                            {
                                                                data?.pointFiveOrMoreMicronMaximum ?? ""
                                                            }
                                                        </Text>
                                                    </View>

                                                    :
                                                    ""
                                            }

                                            {
                                                data?.consideredParticleSizes?.includes("1µm") ?
                                                    <View style={{ ...styles.col, ...styles.flex_2 }}>
                                                        <Text>
                                                            {
                                                                data?.oneOrMoreMicronMaximum ?? ""
                                                            }
                                                        </Text>
                                                    </View>

                                                    :
                                                    ""
                                            }
                                            {
                                                data?.consideredParticleSizes?.split(",")?.includes("5µm") ?
                                                    <View style={{ ...styles.col, ...styles.flex_2 }}>
                                                        <Text>
                                                            {
                                                                data?.fiveOrMoreMicronMaximum ?? ""
                                                            }
                                                        </Text>
                                                    </View>

                                                    :
                                                    ""
                                            }

                                        </View>

                                        <View style={{ ...styles.flexBox }}>
                                            <View style={{ width: 80, ...styles.col }}>
                                                <Text style={{}}> Mean
                                                </Text>
                                            </View>
                                            {
                                                data?.consideredParticleSizes?.includes("0.5µm") ?
                                                    <View style={{ ...styles.col, ...styles.flex_2 }}>
                                                        <Text>
                                                            {
                                                                data?.pointFiveOrMoreMicronMean ?? ""
                                                            }
                                                        </Text>
                                                    </View>

                                                    :
                                                    ""
                                            }

                                            {
                                                data?.consideredParticleSizes?.includes("1µm") ?
                                                    <View style={{ ...styles.col, ...styles.flex_2 }}>
                                                        <Text>
                                                            {
                                                                data?.oneOrMoreMicronMean ?? ""
                                                            }
                                                        </Text>
                                                    </View>

                                                    :
                                                    ""
                                            }
                                            {
                                                data?.consideredParticleSizes?.split(",")?.includes("5µm") ?
                                                    <View style={{ ...styles.col, ...styles.flex_2 }}>
                                                        <Text>
                                                            {
                                                                data?.fiveOrMoreMicronMean ?? ""
                                                            }
                                                        </Text>
                                                    </View>

                                                    :
                                                    ""
                                            }

                                        </View>
                                    </>
                                    : ""
                            }


                            <View style={{ ...styles.flexBox }}>
                                <View style={{ width: 80, ...styles.col }}>
                                    <Text style={{}}> Complies
                                    </Text>
                                </View>
                                {
                                    data?.consideredParticleSizes?.includes("0.5µm") ?
                                        <View style={{ ...styles.col, ...styles.flex_2 }}>
                                            <Text>
                                                {
                                                    data?.pointFiveOrMoreMicronResult == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                        data?.pointFiveOrMoreMicronResult == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"
                                                }
                                            </Text>
                                        </View>

                                        :
                                        ""
                                }

                                {
                                    data?.consideredParticleSizes?.includes("1µm") ?
                                        <View style={{ ...styles.col, ...styles.flex_2 }}>
                                            <Text>
                                                {

                                                    data?.oneOrMoreMicronResult == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                        data?.oneOrMoreMicronResult == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}

                                            </Text>
                                        </View>

                                        :
                                        ""
                                }
                                {
                                    data?.consideredParticleSizes?.split(",")?.includes("5µm") ?
                                        <View style={{ ...styles.col, ...styles.flex_2 }}>
                                            <Text>
                                                {
                                                    data?.fiveOrMoreMicronResult == TestObservationResult.Pass ? TestObservationResult[TestObservationResult.Pass] :
                                                        data?.fiveOrMoreMicronResult == TestObservationResult.Fail ? TestObservationResult[TestObservationResult.Fail] : "NA"}

                                            </Text>
                                        </View>

                                        :
                                        ""
                                }

                            </View>
                        </View>
                    </>
                )
            }}
        />)
}

export default ParticleCountTestPDFComponent;





