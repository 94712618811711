import React from "react";
import { IFormBaseState, FormBaseComponent, } from "../../../../../../../common/component/form-base.component";
import Modal from 'react-modal';
import { TestObservationResult, ValidationScreens } from "../../../../../../../common/enums";
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import { clearObservationDataState, updateObservationDataState, createDFObservation, editDFObservation } from "../../../../../store/cleanroom-area/cleanroom-area-observation.slice";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { CleanroomAreaDFTestRoomobservationDefaults } from "../../../../../model/cleanroom-area/defaults/test.default";
import { ICleanroomAreaDFTestRoomObservationPayload, ICleanroomAreaDifferentialPressureTestModel, ICleanroomAreaDifferentialPressureTestRoomObservationModel } from "../../../../../model/cleanroom-area/test/differential-pressure-test.model";

export interface IProps {
    toggle: boolean,
    handleToggleState: any,
    testData: ICleanroomAreaDifferentialPressureTestModel,
    observationData: ICleanroomAreaDifferentialPressureTestRoomObservationModel,
    clearObservationDataState: (data: ICleanroomAreaDifferentialPressureTestRoomObservationModel) => void,
    updateObservationDataState: (data) => void,
    createObservation: (data: ICleanroomAreaDFTestRoomObservationPayload) => void,
    editObservation: (data: ICleanroomAreaDFTestRoomObservationPayload) => void,
    testName: string,
    defaultData?: null | ICleanroomAreaDifferentialPressureTestRoomObservationModel,
}

export interface IState extends IFormBaseState {

}

class TestObservationComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.CleanroomAreaDifferentialPressureTestObservation);
        this.props.clearObservationDataState(this.props.defaultData ?? CleanroomAreaDFTestRoomobservationDefaults);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.defaultData && this.props.defaultData != prevProps.defaultData) {
            this.props.clearObservationDataState(this.props.defaultData);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    render() {
        return <React.Fragment>
            <Modal
                ariaHideApp={false}
                isOpen={this.props.toggle}
                onRequestClose={this.props.handleToggleState}
            >
                <div className={`d-flex flex-column`} style={{ maxHeight: "90vh" }}>
                    <div className="f-18">
                        Add Observation
                    </div>
                    <div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("highPressureRoomOrZone").className}`}>
                                    <label htmlFor="highPressureRoomOrZone">High Pressure Room/Zone<span className="requried-span">*</span></label>
                                    <input id="highPressureRoomOrZone" className="form-control" type="text"
                                        value={this.props.observationData?.highPressureRoomOrZone ?? ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            this.ValidateField("highPressureRoomOrZone", e.target?.value);
                                            this.props.updateObservationDataState({ name: "highPressureRoomOrZone", value: e?.target?.value });
                                            // this.props.updateHasError(this.hasError);
                                        }} />
                                    <ValidationMessageControl message={this.vResult("highPressureRoomOrZone").message} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("lowPressureRoomOrZone").className}`}>
                                    <label htmlFor="lowPressureRoomOrZone">Low Pressure Room/Zone<span className="requried-span">*</span></label>
                                    <input id="lowPressureRoomOrZone" className="form-control" type="text"
                                        value={this.props.observationData?.lowPressureRoomOrZone ?? ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            this.ValidateField("lowPressureRoomOrZone", e.target?.value);
                                            this.props.updateObservationDataState({ name: "lowPressureRoomOrZone", value: e?.target?.value });
                                            // this.props.updateHasError(this.hasError);
                                        }} />
                                    <ValidationMessageControl message={this.vResult("lowPressureRoomOrZone").message} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("acceptanceCriteria").className}`}>
                                    <label htmlFor="acceptanceCriteria">Acceptance Criteria (<span title="Not Less Than">NLT</span>)<span className="requried-span">*</span></label>
                                    <input id="acceptanceCriteria" className="form-control" type="number"
                                        value={this.props.observationData?.acceptanceCriteria ?? ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            this.ValidateField("acceptanceCriteria", +e.target?.value);
                                            this.props.updateObservationDataState({ name: "acceptanceCriteria", value: e?.target?.value });
                                            // this.props.updateHasError(this.hasError);
                                        }} />
                                    <ValidationMessageControl message={this.vResult("acceptanceCriteria").message} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("differentialPressure").className}`}>
                                    <label htmlFor="differentialPressure">Differential Pressure<span className="requried-span">*</span>
                                    </label>
                                    <input id="differentialPressure" className="form-control" type="number"
                                        value={this.props.observationData?.differentialPressure ?? ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            this.ValidateField("differentialPressure", +e.target?.value);
                                            this.props.updateObservationDataState({ name: "differentialPressure", value: e?.target?.value })
                                            // this.props.updateHasError(this.hasError);
                                        }} />
                                    <ValidationMessageControl message={this.vResult("differentialPressure").message} />
                                </div>
                            </div>
                            {
                                this.props.observationData?.id ?
                                    <div className="col-6">
                                        <div className={`form-group ${this.vResult("name").className}`}>
                                            <label htmlFor="name">Result</label>
                                            <input id="name" className="form-control not-allowed" type="text"
                                                value={this.props.observationData?.result == TestObservationResult.Pass ? "Pass" :
                                                    this.props.observationData?.result == TestObservationResult.Fail ? "Fail" : ""}
                                                disabled={true}
                                            />
                                        </div>
                                    </div>
                                    : ''
                            }
                        </div>
                        <div className="row">
                            <div className="col-12 text-right">
                                <button
                                    onClick={(e: any) => {
                                        const error = !this.ValidateForm(this.props.observationData);
                                        this.reloadForm();
                                        if (error) return;
                                        if (this.props.observationData?.id) {
                                            this.props.editObservation({ testName: this.props.testName, data: this.props.observationData, })
                                        } else {
                                            let payload = { ...this.props.observationData };
                                            payload.differentialPressureTestId = this.props.testData.id ?? 0;
                                            this.props.createObservation({ testName: this.props.testName, data: payload })
                                        }
                                        this.props.handleToggleState();
                                    }}
                                    className="text-capitalize f-w-800 border-0 btn-primary btn-sm mt-2 align-self-end">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment >
    }
}


const mapStateToProps = (state: IAppState) => ({
    observationData: state.cleanroomAreaObservation.observationData,
    dataLoading: state.cleanroomAreaObservation.dataLoading,
    createEditLoading: state.cleanroomAreaObservation.createEditLoading,
});
export default connect(mapStateToProps, {
    clearObservationDataState, updateObservationDataState, createObservation: createDFObservation, editObservation: editDFObservation
})(TestObservationComponent);

