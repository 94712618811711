import React, { useEffect, useRef, useState } from "react";

import { Document, Page, Text, View, PDFViewer, Image, pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import { CleanroomAreaTests } from "../../../../../common/enums";
import { IUserModel } from "../../../../user/model/user.model";
import { ICleanroomAreaModel } from "../../../model/report.model";
import { localToUtcDate} from '../../../../../common/utils';
import moment from 'moment';
import { styles, PDFFooterComponent, PDFHeaderComponent, PDFClientDetailComponent, PDFRemarkComponent } from "../PDF/base-pdf-components";
import { useDispatch } from "react-redux";

import { editCleanroomAreaAFPTest } from "../../../store/cleanroom-area/cleanroom-area-test.slice";
import CleanroomAreaTestService from "../../../service/cleanroom-area/test.service";


export const AFPTPDFComponent = ({ data }: { id?: number, data: any }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap={false}>
                <PDFHeaderComponent />

                {/* ---- body ---- */}
                <View style={{ ...styles.section }}>
                    <View style={{ ...styles.main }}>
                        <PDFClientDetailComponent data={data} title={"Air Flow Pattern"} />
                    
                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Area:</Text>
                        </View>

                        <View style={{}}>
                            {/* --- fixed allignment --- */}
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>AHU Name
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.clientArea?.ahuOrVAUName ?? ''}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Area Of Test</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.clientArea?.areaOfTheTest ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>AHU ID
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.clientArea?.ahuOrVAUId ?? ''}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Location</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.clientArea?.location ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* ---- data logger details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{}}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Standard :</Text>
                            </View>

                            <View style={{ ...styles.paddingTB }}>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>{data?.dataLogger?.name ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.make?.name ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Instrument Id</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.instrumentId ?? ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Serial Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.serialNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>Camera Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>{data?.cameraDetail?.cameraName ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.cameraDetail?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.cameraDetail?.make ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Camera Id</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.cameraDetail?.cameraId ?? ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Serial Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.cameraDetail?.serialNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>

                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Name</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Number</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>
                                    <Text style={{}}>{data?.refStandardOrProtocolOrDocumentName ?? ""}</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>{data?.procedureOrProtocolOrDocumentNumber ?? ""}</Text>
                                </View>
                            </View>
                        </View>

                        <View style={{ ...styles.paddingTB }} wrap={false}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Observations :</Text>
                        </View>

                        <View style={{ ...styles.paddingTB }} wrap={false}>
                            <View style={{ ...styles.flexBox, ...styles.bold }}>
                                <View style={{ width: 40, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}> Sr No
                                    </Text>
                                </View>
                                <View style={{ width: 120, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}> Description
                                    </Text>
                                </View>
                                <View style={{ width: 70, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}> Does the smoke flows smoothly downwards from supply grills
                                    </Text>
                                </View>
                                <View style={{ width: 70, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Does the smoke flows towards the return grills
                                    </Text>
                                </View>
                                <View style={{ width: 70, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>Does the differential pressure is zero while door opening
                                    </Text>
                                </View>
                                <View style={{ ...styles.label, flex: 1, ...styles.flexBox, ...styles.flexColumn, }}>
                                    <View style={{ ...styles.col, ...styles.flex_1, ...styles.flex_center }}>
                                        <Text style={{}}>Does the smoke flow from a higher pressure gradient to a lower pressure gradient</Text>
                                    </View>
                                    <View style={{ ...styles.flexBox }}>
                                        <View style={{ width: 40, ...styles.col, ...styles.flex_center }}>
                                            <Text style={{}}>
                                                Result
                                            </Text>
                                        </View>
                                        <View style={{ flex: 1, ...styles.flex_center, ...styles.col, }}>
                                            <Text style={{}}>
                                                Higher Pressure Gradient
                                            </Text>
                                        </View>
                                        <View style={{ flex: 1, ...styles.flex_center, ...styles.col, }}>
                                            <Text style={{}}>
                                                Lower Pressure Gradient
                                            </Text>
                                        </View>
                                    </View>
                                </View>

                            </View>
                            {
                                data?.observations?.map((item, index) => {
                                    return (
                                        <View style={{ ...styles.flexBox, ...styles.bold }}>
                                            <View style={{ width: 40, ...styles.col, ...styles.flex_center}}>
                                                <Text style={{}}> {index + 1}
                                                </Text>
                                            </View>
                                            <View style={{ width: 120, ...styles.col,...styles.flex_center}}>
                                                <Text style={{}}> {item.description}
                                                </Text>
                                            </View>
                                            <View style={{ width: 70, ...styles.col,...styles.flex_center}}>
                                                <Text style={{}}>{item?.doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills ? "yes" : item?.doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills == false ? "No" : "NA"}
                                                </Text>
                                            </View>
                                            <View style={{ width: 70, ...styles.col,...styles.flex_center }}>
                                                <Text style={{}}>{item?.doesTheSmokeFlowsTowardsTheReturnGrills ? "Yes" : item?.doesTheSmokeFlowsTowardsTheReturnGrills == false ? "No" : "NA"}
                                                </Text>
                                            </View>
                                            <View style={{ ...styles.flex_1,}}>
                                                {
                                                    item?.smokeFlowDetails?.map((item2) => {
                                                        return (<View style={{ ...styles.flexBox }}>
                                                            <View style={{ width: 70, ...styles.col, ...styles.flex_center}}>
                                                                <Text style={{}}>{item2?.doesTheDifferentialPressureIsZeroWhileDoorOpening ? "Yes" : item?.doesTheDifferentialPressureIsZeroWhileDoorOpening == false ? "No" : "NA"}
                                                                </Text>
                                                            </View>
                                                            <View style={{ ...styles.label, flex: 1, ...styles.flexBox }}>
                                                                <View style={{ width: 40, ...styles.col,...styles.flex_center}}>
                                                                    <Text style={{}}>
                                                                        {item2?.result ? "yes" :
                                                                            item2?.result == false ? "No" : "NA"}
                                                                    </Text>
                                                                </View>
                                                                <View style={{ flex: 1, ...styles.flex_center, ...styles.col,...styles.flex_center}}>
                                                                    <Text style={{}}>
                                                                        {item2?.higherPressureGradient}
                                                                    </Text>
                                                                </View>
                                                                <View style={{ flex: 1, ...styles.flex_center, ...styles.col,...styles.flex_center}}>
                                                                    <Text style={{}}>
                                                                        {item2?.lowerPressureGradient}
                                                                    </Text>
                                                                </View>
                                                            </View>
                                                        </View>)
                                                    })
                                                }
                                            </View>
                                        </View>
                                    )
                                })
                            }
                        </View>

                        <PDFRemarkComponent data={data}/>
                    </View>
                </View>
                <PDFFooterComponent />
            </Page >
        </Document >
    )
}

export interface IProps {
    data: ICleanroomAreaModel,
    id: number,
    currentUser: IUserModel
}

const AFPTestPDFComponent = ({ id, data, currentUser }: IProps) => {
    const dispatch: any = useDispatch();
    const graphRef: any = useRef();
    const [loading, setLoading] = useState(false);

    const downloadPdf = async () => {
        let res: any;
        setLoading(true);
        try {
            if (id) {
                res = await CleanroomAreaTestService.GetAFPTestById({ testName: CleanroomAreaTests.airflowpattern, id });
                if (res?.id) {
                    const fileName = 'airflow-pattern-test.pdf';
                    const blob = await pdf(<AFPTPDFComponent data={{ ...data, ...res }} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
        } catch (errr) {

        } finally {
            setLoading(false);
            let date = moment(new Date()).toString();
            dispatch(editCleanroomAreaAFPTest({
                testName: CleanroomAreaTests.airflowpattern, data:
                {
                    ...res,
                    editLocked: true,
                    reportPdfGeneratedById: currentUser?.id,
                    reportPdfGeneratedOn: localToUtcDate(date),
                },
            }))
        }

    };

    useEffect(() => {
        const fetchData = async () => {
            let res: any;
            try {
                if (id) {
                    res = await CleanroomAreaTestService.GetAFPTestById({ testName: CleanroomAreaTests.airflowpattern, id });
                }
            } catch (errr) {
            }
        }
        fetchData();
    }, [])

    useEffect(() => {
        setLoading(false);
    }, [data]);

    return (<React.Fragment>
        {/* <PDFViewer width={'100%'}>
            <AFPTPDFComponent data={{ ...data }} />
        </PDFViewer> */}
        <button className="btn m-0 btn-primary px-3 py-2" onClick={downloadPdf}>Download PDF</button>
    </React.Fragment>)
}

export default AFPTestPDFComponent;




