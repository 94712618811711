import { IClientModel } from "./client.model";

export interface IClientAreaModel {
    id: number,
    ahuOrVAUName: string,
    ahuOrVAUId: string,
    clientId: number,
    client: null | IClientModel,
    areaOfTheTest: string,
    location: string,
}

export const clientAreaDefault: IClientAreaModel = {
    id: 0,
    ahuOrVAUName: "",
    ahuOrVAUId: "",
    clientId: 0,
    client: null,
    areaOfTheTest: "",
    location: "",
}