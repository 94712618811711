import React from "react";
import { IFormBaseState, FormBaseComponent, } from "../../../../../../../common/component/form-base.component";
import Modal from 'react-modal';
import { ValidationScreens } from "../../../../../../../common/enums";
import { IBaseTestPayload } from "../../../../../model/cleanroom-equipment/test/test.model";
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import { setDeletdRoomId, clearObservationDataState, updateObservationDataState, } from "../../../../../store/cleanroom-area/cleanroom-area-observation.slice";
import { pcTestObservationDefault } from "../../../../../model/cleanroom-equipment/defaults/test.default";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { IPCObservation } from "../../../../../model/cleanroom-equipment/test/particle-count-test.model";
import { ICleanroomAreaNonViableParticleCountTestModel, ICleanroomAreaNonViableParticleCountTestObservationModel, ICleanroomAreaNVPCTestObservationPayload } from "../../../../../model/cleanroom-area/test/non-viable-particle-count-test.model";
import { CleanroomAreaNonViableParticleCountTestObservationDefault } from "../../../../../model/cleanroom-area/defaults/test.default";
import { createNVPCObservation, deleteNVPCObservation, editNVPCObservation } from "../../../../../store/cleanroom-area/cleanroom-area-observation.slice";

export interface IProps {
    toggle: boolean,
    handleToggleState: any,
    testData: ICleanroomAreaNonViableParticleCountTestModel,
    locations: { name: string, id: number }[],
    observationData: ICleanroomAreaNonViableParticleCountTestObservationModel,
    clearObservationDataState: (data: ICleanroomAreaNonViableParticleCountTestObservationModel) => void,
    updateObservationDataState: (data) => void,
    createObservation: (data: ICleanroomAreaNVPCTestObservationPayload) => void,
    editObservation: (data: ICleanroomAreaNVPCTestObservationPayload) => void,
    testName: string,
    defaultData?: null | ICleanroomAreaNonViableParticleCountTestObservationModel,
    createReading: (data: ICleanroomAreaNVPCTestObservationPayload) => void,
    editReading: (data: ICleanroomAreaNVPCTestObservationPayload) => void,
    deleteReading: (data: IBaseTestPayload) => void,
    deletedRoomId?: number,
    setDeletdRoomId: (data: number) => void,
}

export interface IState extends IFormBaseState {
    readingData: IPCObservation,
    openReadingSection: boolean,
    editReadingIndex: number,
    showSuggetions: boolean,
}

class TestObservationComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            readingData: pcTestObservationDefault,
            hideForm: false,
            openReadingSection: false,
            editReadingIndex: -1,
            showSuggetions: false,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.CleanroomAreaNonViableParticleCountTestObservation);
        this.props.clearObservationDataState(this.props.defaultData ?? CleanroomAreaNonViableParticleCountTestObservationDefault);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.defaultData && this.props.defaultData != prevProps.defaultData) {
            this.props.clearObservationDataState(this.props.defaultData);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    render() {
        return <React.Fragment>
            <Modal
                ariaHideApp={false}
                isOpen={this.props.toggle}
                onRequestClose={this.props.handleToggleState}
            >
                <div className={`d-flex f-w-500 flex-column`} style={{ maxHeight: "90vh" }}>
                    <div className="f-18">
                        Add Observations
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className={`form-group ${this.vResult("locationNumber").className} suggetion-box`}>
                                <label htmlFor="locationNumber">Location Number<span className="requried-span">*</span></label>
                                <input
                                    id="locationNumber"
                                    disabled={this.props.defaultData?.locationId ? true : false}
                                    className="form-control" type="text"
                                    autoComplete="off"
                                    value={this.props.observationData?.locationNumber ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("locationNumber", e.target.value);
                                        if (this.props.observationData?.locationId) {
                                            this.props.updateObservationDataState({ name: "locationId", value: 0 });
                                        };
                                        this.props.updateObservationDataState({ name: "locationNumber", value: e.target.value })
                                        // this.props.updateHasError(this.hasError);
                                    }}
                                    onFocus={() => this.setState({ ...this.state, showSuggetions: !this.state?.showSuggetions })}
                                />
                                {this.props?.locations?.length > 0 ?
                                    <div className={`child-div ${this.state?.showSuggetions ? 'show' : 'hide'}`}>
                                        {this.props?.locations?.map((item) => {
                                            return (<li onClick={(e) => {
                                                e.preventDefault();
                                                this.props.updateObservationDataState({ name: 'locationId', value: item.id });
                                                this.props.updateObservationDataState({ name: 'locationNumber', value: item?.name });
                                                this.setState({ ...this.state, showSuggetions: false });
                                            }}
                                                className="options">
                                                {item?.name}

                                            </li>)
                                        })
                                        }
                                    </div>
                                    : null
                                }
                                <ValidationMessageControl message={this.vResult("locationNumber").message} />
                            </div>
                        </div>
                        {
                            this.props.testData?.consideredParticleSizes?.includes("0.5µm") ?
                                <div className="col-6">
                                    <div className={`form-group ${this.vResult("pointFiveOrMoreMicron").className}`}>
                                        <label htmlFor="pointFiveOrMoreMicron">0.5µm</label>
                                        <input id="pointFiveOrMoreMicron" className="form-control" type="number"
                                            value={this.props.observationData?.pointFiveOrMoreMicron ?? ""}
                                            onChange={e => {
                                                e.preventDefault();
                                                this.ValidateField("pointFiveOrMoreMicron", +e.target.value);
                                                this.props.updateObservationDataState({ name: "pointFiveOrMoreMicron", value: e.target.value })
                                                // this.props.updateHasError(this.hasError);
                                            }}
                                        />
                                        <ValidationMessageControl message={this.vResult("pointFiveOrMoreMicron").message} />
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.props.testData?.consideredParticleSizes?.includes("1µm") ?
                                <div className="col-6">
                                    <div className={`form-group ${this.vResult("oneOrMoreMicron").className}`}>
                                        <label htmlFor="oneOrMoreMicron">1µm</label>
                                        <input id="oneOrMoreMicron" className="form-control" type="number"
                                            value={this.props.observationData?.oneOrMoreMicron ?? ""}
                                            onChange={e => {
                                                e.preventDefault();
                                                this.ValidateField("oneOrMoreMicron", +e.target.value);
                                                this.props.updateObservationDataState({ name: "oneOrMoreMicron", value: e.target.value })
                                                // this.props.updateHasError(this.hasError);
                                            }}
                                        />
                                        <ValidationMessageControl message={this.vResult("oneOrMoreMicron").message} />
                                    </div>
                                </div>
                                : null
                        }

                        {
                            this.props.testData?.consideredParticleSizes?.split(",")?.includes("5µm") ?
                                <div className="col-6">
                                    <div className={`form-group ${this.vResult("fiveOrMoreMicron").className}`}>
                                        <label htmlFor="fiveOrMoreMicron">5µm</label>
                                        <input id="fiveOrMoreMicron" className="form-control" type="number"
                                            value={this.props.observationData?.fiveOrMoreMicron ?? ""}
                                            onChange={e => {
                                                e.preventDefault();
                                                this.ValidateField("fiveOrMoreMicron", +e.target.value);
                                                this.props.updateObservationDataState({ name: "fiveOrMoreMicron", value: e.target.value })
                                                // this.props.updateHasError(this.hasError);
                                            }}
                                        />
                                        <ValidationMessageControl message={this.vResult("fiveOrMoreMicron").message} />
                                    </div>
                                </div>
                                : null
                        }
                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <button
                                onClick={(e: any) => {
                                    const error = !this.ValidateForm(this.props.observationData);
                                    this.reloadForm();
                                    if (error) return;
                                    if (this.props.observationData?.id) {
                                        this.props.editObservation({ testName: this.props.testName, data: this.props.observationData })
                                    } else {
                                        let payload = { ...this.props.observationData, nonViableParticleCountTestId: this.props.testData?.id ?? 0 };
                                        this.props.createObservation({ testName: this.props.testName, data: payload })
                                    }
                                    this.props.handleToggleState();
                                }}
                                className="text-capitalize f-w-800 border-0 btn-primary btn-sm mt-2 align-self-end">
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </React.Fragment >
    }
}


const mapStateToProps = (state: IAppState) => ({
    observationData: state.cleanroomAreaObservation.observationData,
    dataLoading: state.cleanroomAreaObservation.dataLoading,
    createEditLoading: state.cleanroomAreaObservation.createEditLoading,
    deleteRoomId: state.cleanroomAreaObservation.deletedRoomId,
});
export default connect(mapStateToProps, {
    clearObservationDataState, updateObservationDataState, setDeletdRoomId, createObservation: createNVPCObservation, editObservation: editNVPCObservation, createReading: createNVPCObservation, editReading: editNVPCObservation, deleteReading: deleteNVPCObservation
})(TestObservationComponent);

