import TestService from "../../../service/cleanroom-equipment/test.service";
import { CleanroomEquipmentTests, ZoneClassificationType } from "../../../../../common/enums";
import { ICleanroomEquipmentModel } from "../../../model/report.model";
import { IAirflowVelocityTestObservationModel, IAirflowVelocityModel } from "../../../model/cleanroom-equipment/test/test.model";
import { ILITestObservationModel } from "../../../model/cleanroom-equipment/test/light-intensity-test.model";
import { ISoundLevelObservationModel } from "../../../model/cleanroom-equipment/test/sound-level-test.model";
import { IUVLightTestObservationModel } from "../../../model/cleanroom-equipment/test/uv-light-test.model";
import { editAVTest, editPCTest, editLITest, editSLTest, editUVLITest, editFITest } from '../../../store/cleanroom-equipment/test.slice';
import {
    styles,
    useDispatch,
    moment,
    React, useEffect, useState,
    Document, Page, Text, View, StyleSheet, PDFViewer, Image, Font, pdf, saveAs, IUserModel,
    displayDate, localToUtcDate, PDFHeaderComponent,
    PDFFooterComponent,
    PDFClientDetailComponent,
    PDFRemarkComponent
} from "./base-pdf-components";

export const BaseTest = ({ data, title, maxReadings, testName, callBack }: { title: string, testName: string, data: IAirflowVelocityModel | any, maxReadings?: number, callBack: any }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap={false}>
                {/* --- Header --- */}
                <PDFHeaderComponent />
                {/* ---- body ---- */}

                <View style={{ ...styles.section }}>
                    <View style={{ ...styles.main }}>
                        <PDFClientDetailComponent data={data} title={title} />

                        {/* ----  Equipnet details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Equipment :</Text>
                        </View>

                        <View style={{}}>
                            {/* --- fixed allignment --- */}
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Equipment Name
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data.clientEquipment?.equipmentName ?? ''}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Area Of Test</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{data.areaOfTest ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Equipment Id</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>
                                            {data.clientEquipment?.equipmentId ?? ""}
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Location</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{data?.location ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            {
                                testName == CleanroomEquipmentTests.nonviableparticlecount || testName == CleanroomEquipmentTests?.lightintensity ?
                                    <View style={{ ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data.clientEquipment?.make ?? ""}</Text>
                                            </View>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data?.clientEquipment?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    :
                                    <>
                                        <View style={{ ...styles.flexBox }}>
                                            <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                                <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                    <Text style={{}}>Make</Text>
                                                </View>
                                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                    <Text style={{}}>{data.clientEquipment?.make ?? ""}</Text>
                                                </View>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                        <Text style={{}}>Filter Type</Text>
                                                    </View>
                                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                        <Text style={{}}>{data?.filterType ?? ""}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                        <View style={{ ...styles.flexBox }}>
                                            <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                                <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                    <Text style={{}}>Model Number</Text>
                                                </View>
                                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                    <Text style={{}}>{data?.clientEquipment?.modelNumber ?? ""}</Text>
                                                </View>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                        <Text style={{}}>Filter Efficiency</Text>
                                                    </View>
                                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                        <Text style={{}}>{data?.filterEfficiency ?? ""}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </View>
                                    </>
                            }

                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>Serial Number</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                        <Text style={{}}>{data?.clientEquipment?.serialNumber ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Zone Classification</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{data?.zoneClassificationType == ZoneClassificationType.ClientSpecification ? "Client Specification" :
                                                data?.zoneClassificationType == ZoneClassificationType.EUGMP ? `Grade ${data?.eugmpStandard?.grade}` : data?.zoneClassificationType == ZoneClassificationType.ISO ? `ISO ${data.isoStandard?.classNumber}` : ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* ---- data logger details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{}}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Standard :</Text>
                            </View>

                            <View style={{ ...styles.paddingTB }}>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.col, }}>
                                        <Text style={{}}>{data?.dataLogger?.name ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data.dataLogger?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>{data.dataLogger?.make?.name ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Instrument Id</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{data?.dataLogger?.instrumentId ?? ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Serial Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{data?.dataLogger?.serialNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>Calibration Done Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                            <Text style={{}}>{displayDate(data.dataLogger?.lastCalibratedOn)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>Calibration Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_verticle_center }}>
                                                <Text style={{}}>
                                                    {displayDate(data.dataLogger?.calibrationDueOn)}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>

                                    <Text style={{ ...styles.bold }}>Protocol Name</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>

                                    <Text style={{ ...styles.bold }}>Protocol Number</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>{data?.refStandardOrProtocolOrDocumentName ?? ""}</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>{data?.procedureOrProtocolOrDocumentNumber ?? ""}</Text>
                                </View>
                            </View>
                        </View>

                        {
                            callBack(styles, data, maxReadings)
                        }
                        <PDFRemarkComponent data={data} />
                    </View>
                </View>

                {/* --- footer ---- */}
                <PDFFooterComponent />

            </Page >
        </Document >
    )
}

export interface IProps {
    data: ICleanroomEquipmentModel,
    id: number,
    currentUser: IUserModel,
    testName: string,
    title: string,
    callBack: any,
}
const BasePDFComponent = ({ id, data, currentUser, testName, title, callBack }: IProps) => {
    const [loading, setLoading] = useState(false);
    const dispatch: any = useDispatch();
    const downloadPdf = async () => {
        let res: any;
        let arr: any;
        setLoading(true);
        try {
            if (id && testName == CleanroomEquipmentTests.airflowvelocity) {
                res = await TestService.GetAVById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: IAirflowVelocityTestObservationModel, item2: IAirflowVelocityTestObservationModel) => item2.roomReadings?.length - item1.roomReadings.length);
                    const fileName = `airvelocity-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
            else if (id && testName == CleanroomEquipmentTests.lightintensity) {
                res = await TestService.GetLITestById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: ILITestObservationModel, item2: ILITestObservationModel) => item2.roomReadings?.length - item1.roomReadings.length);
                    const fileName = `lightintensity-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            } else if (id && testName == CleanroomEquipmentTests.nonviableparticlecount) {
                res = await TestService.GetPCTestById({ testName, id });
                if (res?.id) {
                    arr = res.locations ? [...res.locations] : [];
                    const fileName = `non-viable-particle-count-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
            else if (id && testName == CleanroomEquipmentTests.soundlevel) {
                res = await TestService.GetSLTestById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: ISoundLevelObservationModel, item2: ISoundLevelObservationModel) => item2.roomReadings?.length - item1.roomReadings.length);
                    const fileName = `sound-level-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
            else if (id && testName == CleanroomEquipmentTests.uvlightintensity) {
                res = await TestService.GetUVLITestById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: IUVLightTestObservationModel, item2: IUVLightTestObservationModel) => item2.roomReadings?.length - item1.roomReadings.length);
                    const fileName = `uv-light-intensity-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
            else if (id && testName == CleanroomEquipmentTests.filterintegrity) {
                res = await TestService.GetFITestById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: IUVLightTestObservationModel, item2: IUVLightTestObservationModel) => item2.roomReadings?.length - item1.roomReadings.length);
                    const fileName = `filter-integrity.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
        } catch (errr) {

        } finally {
            setLoading(false);
            let date = moment(new Date()).toString();
            let obj = {
                testName: testName, data:
                {
                    ...res,
                    editLocked: true,
                    reportPdfGeneratedById: currentUser?.id,
                    reportPdfGeneratedOn: localToUtcDate(date),
                },
            };

            if (testName == CleanroomEquipmentTests.airflowvelocity) {
                dispatch(editAVTest(obj));
            }
            else if (testName == CleanroomEquipmentTests.lightintensity) {
                dispatch(editLITest(obj));
            }
            else if (testName == CleanroomEquipmentTests.nonviableparticlecount) {
                dispatch(editPCTest(obj));
            }
            else if (testName == CleanroomEquipmentTests.soundlevel) {
                dispatch(editSLTest(obj));
            }
            else if (testName == CleanroomEquipmentTests.uvlightintensity) {
                dispatch(editUVLITest(obj));
            }
            else if (testName == CleanroomEquipmentTests.filterintegrity) {
                dispatch(editFITest(obj));
            }
        }
    };

    useEffect(() => {
        setLoading(false);
    }, [data]);

    return (<React.Fragment>
        {/* <PDFViewer width={'100%'}>
            <BaseTest
                data={data}
                maxReadings={10}
                testName={testName}
                title={title}
                callBack={callBack} />
        </PDFViewer> */}
        <button className="btn m-0 btn-primary px-3 py-2" onClick={downloadPdf}>{!loading ? "Download PDF" : "Loading"}</button>
    </React.Fragment>)
}

export default BasePDFComponent;





