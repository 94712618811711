import { FormBaseComponent, IFormBaseState } from "../../../../../../../common/component/form-base.component";
import React from "react";
import { connect } from "react-redux";
import ReactSelect from "react-select";
import { AgGridDefaultColDef } from "../../../../../../../common/app-defaults";
import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import { IAppState } from "../../../../../../../store";
import { IUserModel } from "../../../../../../user/model/user.model";
import ScreenUrls from "../../../../../../../common/screen-urls";
import DataLoggerService from "../../../../../../data-logger/service/data-logger.service";
import DataLoggerSelectorAccordionComponent from "../../../../../../workflow-task/component/data-logger-selector.component";
import { Link } from "react-router-dom";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import DatePicker from "react-datepicker";
import DropdownDisplayHelper from "../../../../../../../common/helper/dropdown-display.helper";
import { AppConstants } from "../../../../../../../common/app-constants";
import { utcToLocalDate, localToUtcDate } from "../../../../../../../common/utils";
import { IFormControlDropdownItemModel } from "../../../../../../../common/control/model/form.control.field.model";
import AsyncSelect from "react-select/async";
import { DeferredLoadOptions } from "../../../../../../../common/utils";
import UserService from "../../../../../../user/service/user.service";
import { loadCleanroomAreaById } from "../../../../../store/cleanroom-equipment/cleanroom-equipment.slice";
import { ICleanroomAreaModel } from "../../../../../model/report.model";
import { AccessLevel, CleanroomAreaTests, CleanroomEquipmentTests, TestObservationResult, ValidationScreens, ZoneClassificationType } from "../../../../../../../common/enums";
import { ISOConsideredParticleSizeList, EUGMPConsideredParticleSizeList, ReportTestEquipmentState, Standards } from "../../../../../../../common/_static/report.data";
import TestObservationComponent from "./recovery-reading";
import RecoveryTestPDFComponent from "../../../../../component/cleanroom/cleanroom-area-pdf/recovery-test-pdf";
import { ICleanroomAreaRecoveryReading, ICleanroomAreaRecoveryReadingPayload, ICleanroomAreaRecoveryTestModel, ICleanroomAreaRecoveryTestPayload } from "../../../../../model/cleanroom-area/test/recovery-test.model";
import { CleanroomAreaRecoveryTestDefault } from "../../../../../model/cleanroom-area/defaults/test.default";
import { createCleanroomAreaRecoveryTest, editCleanroomAreaRecoveryTest, clearTestDataState, updateTestDataState, loadCleanroomAreaRecoveryTest } from "../../../../../store/cleanroom-area/cleanroom-area-test.slice";
import { deleteCleanroomAreaRecoveryReading } from "../../../../../store/cleanroom-area/cleanroom-area-observation.slice";
import TestEditEnableComponent from "../../../../../component/cleanroom/test-edit-enable-component";

export interface IProps {
    id: number,
    testId?: number,
    accessLevel: number,
    testData: ICleanroomAreaRecoveryTestModel,
    areaData: ICleanroomAreaModel,
    dataLoading: boolean,
    createTest: (data: ICleanroomAreaRecoveryTestPayload) => void,
    editTest: (data: ICleanroomAreaRecoveryTestPayload) => void,
    loadTestById: (data: ICleanroomAreaRecoveryTestPayload) => void,
    createEditLoading: boolean,
    currentUser: IUserModel,
    clearTestDataState: (payload) => void,
    updateTestDataState: (payload) => void,
    loadCleanroomAreaById: (id: number) => void,
    refetchApi: boolean,
    deleteReading: (data: ICleanroomAreaRecoveryReadingPayload) => void,
}

export interface IState extends IFormBaseState {
    openObservationPopup: boolean,
    editReading: null | ICleanroomAreaRecoveryReading,
    hideZoneClassification: boolean,
}

class TestManager extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
            openObservationPopup: false,
            editReading: null,
            hideZoneClassification: false,
        };
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.RecoveryTest);
        if (this.props.id) {
            await this.props.loadCleanroomAreaById(Number(this.props.id));
        }
        if (this.props.testId) {
            await this.props.loadTestById({ testName: CleanroomAreaTests.recovery ?? "", id: Number(this.props.testId) });
        } else {
            await this.props.clearTestDataState(CleanroomAreaRecoveryTestDefault);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (!prevProps.refetchApi && this.props.refetchApi && this.props.testData.id) {
            this.props.loadTestById({ testName: CleanroomAreaTests.recovery ?? "", id: Number(this.props?.testId) });
        };
    }

    loadUserOption = (inputValue: string) => DeferredLoadOptions(inputValue, UserService.UserDropdown)

    loadDataLoggers = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
        new Promise<IFormControlDropdownItemModel[]>(async (resolve) => {
            const dataLoggers = await DataLoggerService.LoadAll();

            if (dataLoggers == null || !Array.isArray(dataLoggers?.data)) {
                resolve([]); return;
            }

            const list = dataLoggers?.data?.map(d => {
                const obj: IFormControlDropdownItemModel = {
                    label: DropdownDisplayHelper.DataLogger(d),
                    value: d.id.toString()
                }
                return obj;
            });

            if (search == null || search === "") resolve(list);
            resolve(list.filter(l => l.label.indexOf(search) > 0))
        }));

    render() {
        if (this.state.hideForm) return;
        return (
            <React.Fragment>
                {(() => {
                    if (this.props.dataLoading || this.props.createEditLoading)
                        return (
                            <div className="loading--bar fixed--top">
                                <span></span>
                            </div>
                        );
                })()}

                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="page-header">
                            <div className="page-block">
                                <div className="row">
                                    <div className="col-md-12 d-flex align-items-center justify-content-between">
                                        <Link
                                            to={ScreenUrls.Report.CleanroomArea.Edit(this.props.id)}
                                            className="btn drp-icon btn-rounded btn-primary dropdown-toggle"
                                        >
                                            <i className="feather icon-arrow-left"></i>
                                        </Link>
                                        <div className="col-auto">
                                            {
                                                this.props?.testData?.id ?

                                                    <div className="d-flex">
                                                        {
                                                            this.props?.testData && this.props.testData.id ?
                                                                <RecoveryTestPDFComponent
                                                                    id={this.props.testData?.id ?? 0}
                                                                    currentUser={this.props.currentUser}
                                                                    data={{ ...this.props.areaData, ...this.props.testData }}
                                                                />

                                                                : null
                                                        }

                                                        {
                                                            this.props.testData.editLocked ?
                                                                <TestEditEnableComponent
                                                                category="cleanroomArea"
                                                                    testName={CleanroomAreaTests.recovery}
                                                                    reportCategory={this.props.areaData?.reportCategoryId ?? 0}
                                                                    testId={this.props.testData?.id}
                                                                    reportId={this.props.testData?.cleanroomAreaReportId}
                                                                /> : ""
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`main-body ${this.props?.testData?.editLocked ? 'disabledSection':""}`}>
                            <div className="page-wrapper">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">
                                                    Recovery Test Details
                                                </div>
                                            </div>
                                            <div className="card-block">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("dateOfTest").className}`}>
                                                            <label htmlFor="dateOfTest">Date of Test<span className="requried-span">*</span></label>
                                                            <DatePicker key="dateOfTest"
                                                                selected={utcToLocalDate(this.props.testData?.dateOfTest ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.ValidateField("dateOfTest", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "dateOfTest", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("dateOfTest").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("dueDate").className}`}>
                                                            <label htmlFor="dueDate">Due Date</label>
                                                            <DatePicker key="dueDate"
                                                                selected={utcToLocalDate(this.props?.testData?.dueDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.props.updateTestDataState({ name: "dueDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="name">Air Volume Sampled per Location (L)
                                                            </label>
                                                            <input id="name" className="form-control" type="number"
                                                                value={this.props.testData?.airVolumeSampledPerLocation ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "airVolumeSampledPerLocation", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("").className}`}>
                                                            <label htmlFor="name">Sampling Time at each Location (Min)
                                                            </label>
                                                            <input id="name" className="form-control" type="number"
                                                                value={this.props.testData?.samplingTimeAtEachSamples ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "samplingTimeAtEachSamples", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("preparedById").className}`}>
                                                            <label htmlFor="preparedById">Prepared By<span className="requried-span">*</span></label>
                                                            <AsyncSelect id="preparedById" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadUserOption}
                                                                value={this.props.testData.preparedBy
                                                                    ? { label: DropdownDisplayHelper.User(this.props.testData?.preparedBy), value: this.props.testData?.preparedBy?.id?.toString() }
                                                                    : { label: "Select User", value: "" }}
                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    this.ValidateField("preparedById", cId);
                                                                    if (cId === null || cId === 0 || (this.props.testData.preparedBy != null && cId === this.props.testData.preparedBy.id)) return;

                                                                    const client = await UserService.GetById(cId);

                                                                    this.props.updateTestDataState({ name: "preparedBy", value: client });
                                                                    this.props.updateTestDataState({ name: "preparedById", value: client.id });

                                                                    //this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("preparedById").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("preparedOnDate").className}`}>
                                                            <label htmlFor="preparedOnDate">Prepared On Date<span className="requried-span">*</span></label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData.preparedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.ValidateField("preparedOnDate", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "preparedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("preparedOnDate").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("checkedById").className}`}>
                                                            <label htmlFor="checkedById">Checked By</label>
                                                            <AsyncSelect id="checkedById" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadUserOption}
                                                                value={this.props.testData.checkedBy
                                                                    ? { label: DropdownDisplayHelper.User(this.props.testData.checkedBy), value: this.props.testData.checkedBy.id.toString() }
                                                                    : { label: "Select User", value: "" }}
                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    this.ValidateField("checkedById", cId);
                                                                    if (cId === null || cId === 0 || (this.props.testData.checkedBy != null && cId === this.props.testData.checkedBy.id)) return;

                                                                    const client = await UserService.GetById(cId);

                                                                    this.props.updateTestDataState({ name: "checkedBy", value: client });
                                                                    this.props.updateTestDataState({ name: "checkedById", value: client.id });

                                                                    //this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("checkedById").message} />

                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Checked On Date</label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData.checkedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.props.updateTestDataState({ name: "checkedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="name">Verified By</label>
                                                            <input id="name" className="form-control" type="text"
                                                                value={this.props.testData?.verifiedByName ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "verifiedByName", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Verified On Date</label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData.verifiedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    // this.ValidateField("poDate", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "verifiedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("locationId").className}`}>
                                                            <label htmlFor="locationId">Location Id <span className="requried-span">*</span>
                                                            </label>
                                                            <input id="locationId" className="form-control" type="text"
                                                                value={this.props.testData?.locationId ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("locationId", e?.target?.value);
                                                                    this.props.updateTestDataState({ name: "locationId", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("locationId").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("acceptanceCriteriaMin").className}`}>
                                                            <label htmlFor="acceptanceCriteriaMin">Acceptance Criteria Min in Minutes</label>
                                                            <input id="acceptanceCriteriaMin" className="form-control" type="number"
                                                                value={this.props.testData?.acceptanceCriteriaMin ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();

                                                                    this.props.updateTestDataState({ name: "acceptanceCriteriaMin", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("acceptanceCriteriaMin").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                                            <label htmlFor="acceptanceCriteriaMax">Acceptance Criteria Max in Minutes</label>
                                                            <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                                                value={this.props.testData?.acceptanceCriteriaMax ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();

                                                                    this.props.updateTestDataState({ name: "acceptanceCriteriaMax", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group`}>
                                                            <label htmlFor="abc">Occupancy State</label>
                                                            <ReactSelect id="abc"
                                                                className="async-select-control"
                                                                options={ReportTestEquipmentState}
                                                                value={this.props.testData?.occupancyState
                                                                    ? ReportTestEquipmentState.find((item) => item.value == this.props.testData?.occupancyState?.toString())
                                                                    : { label: "Select Type", value: "" }}

                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    if (cId === null || cId === 0) return;
                                                                    let standard = ReportTestEquipmentState.find((item) => Number(item.value) == cId);
                                                                    this.props.updateTestDataState({ name: "occupancyState", value: Number(standard?.value) });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className={`form-group ${this.vResult("consideredParticleSizes").className}`}>
                                                            <label htmlFor="consideredParticleSizes">Considered Particle sizes<span className="requried-span">*</span>
                                                            </label>
                                                            {
                                                                this.props.areaData?.zoneClassificationType == ZoneClassificationType.EUGMP ?
                                                                    <ReactSelect id="consideredParticleSizes"
                                                                        className="async-select-control"
                                                                        isMulti
                                                                        options={EUGMPConsideredParticleSizeList}
                                                                        value={this.props.testData?.consideredParticleSizes
                                                                            ? EUGMPConsideredParticleSizeList?.filter((item) => this.props.testData?.consideredParticleSizes?.split(",")?.includes(item.value))
                                                                            : { label: "Select particle sizes", value: "" }}

                                                                        onChange={async (e) => {
                                                                            let str: string = "";
                                                                            if (Array.isArray(e)) {
                                                                                str = e?.filter?.(item => item.value)?.map((item) => item.value)?.join(",");
                                                                            }
                                                                            this.ValidateField("consideredParticleSizes", str);
                                                                            this.props.updateTestDataState({ name: "consideredParticleSizes", value: str })
                                                                        }}
                                                                    />
                                                                    :
                                                                    <ReactSelect id="consideredParticleSizes"
                                                                        className="async-select-control"
                                                                        isMulti
                                                                        options={ISOConsideredParticleSizeList}
                                                                        value={this.props.testData?.consideredParticleSizes
                                                                            ? ISOConsideredParticleSizeList?.filter((item) => this.props.testData?.consideredParticleSizes?.split(",")?.includes(item.value))
                                                                            : { label: "Select particle sizes", value: "" }}

                                                                        onChange={async (e) => {
                                                                            let str: string = "";
                                                                            if (Array.isArray(e)) {
                                                                                str = e?.filter?.(item => item.value)?.map((item) => item.value)?.join(",");
                                                                            }
                                                                            this.ValidateField("consideredParticleSizes", str);
                                                                            this.props.updateTestDataState({ name: "consideredParticleSizes", value: str })
                                                                        }}
                                                                    />


                                                            }
                                                            <ValidationMessageControl message={this.vResult("consideredParticleSizes").message} />
                                                        </div>
                                                    </div>


                                                    {
                                                        this.props?.testData?.id ?
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("name").className}`}>
                                                                    <label htmlFor="name">Result</label>
                                                                    <input id="name" className="form-control not-allowed" type="text"
                                                                        value={this.props.testData?.result == TestObservationResult.Pass ? "Pass" :
                                                                            this.props.testData?.result == TestObservationResult.Fail ? "Fail" : ""}
                                                                        disabled={true}
                                                                    />
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }

                                                    <div className="col-12 mb-3">
                                                        <div className={`form-group ${this.vResult("dataLoggerId").className}`}>
                                                            <ValidationMessageControl message={this.vResult("dataLoggerId").message} />
                                                            <DataLoggerSelectorAccordionComponent
                                                                defaultSelections={this.props.testData?.dataLogger ? [this.props.testData?.dataLogger] : null}
                                                                category={null}
                                                                onSelection={(d) => {
                                                                    this.ValidateField('dataLoggerId', d.id);
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: d });
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: d.id });
                                                                }}
                                                                onUnSelection={(d) => {
                                                                    this.ValidateField('dataLoggerId', 0);
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: 0 });
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: null });
                                                                }}
                                                                onUnSelectionAll={() => {
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: 0 });
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: null });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12">
                                                        <div className={`form-group ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Conclusion</label>
                                                            <textarea id="equipmentName"
                                                                className="form-control"
                                                                value={this.props.testData?.remarks ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "remarks", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }}>
                                                            </textarea>
                                                            {/* <ValidationMessageControl message={this.vResult("poNumber").message} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("initialReadingTime").className}`}>
                                                            <label htmlFor="initialReadingTime">Initial Reading Time<span className="requried-span">*</span></label>
                                                            <DatePicker key="initialReadingTime"
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={10}
                                                                timeCaption="Time"
                                                                timeFormat={AppConstants.TimeFormat}
                                                                dateFormat={AppConstants.TimeFormat}
                                                                selected={this.props.testData?.initialReadingTime ? utcToLocalDate(this.props.testData?.initialReadingTime ?? "") : ""}
                                                                onChange={(d) => {
                                                                    this.ValidateField("initialReadingTime", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "initialReadingTime", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }}
                                                            />
                                                            <ValidationMessageControl message={this.vResult("initialReadingTime").message} />
                                                        </div>
                                                    </div>

                                                    {
                                                        this?.props?.testData?.consideredParticleSizes && this.props?.testData?.consideredParticleSizes?.includes("0.5µm") ?
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                                                    <label htmlFor="acceptanceCriteriaMax">Initial Reading At 0.5µm</label>
                                                                    <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                                                        value={this.props.testData?.initialReadingAtPointFiveParticleSize ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();

                                                                            this.props.updateTestDataState({ name: "initialReadingAtPointFiveParticleSize", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                            // this.props.updateHasError(this.hasError);
                                                                        }} />

                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this?.props?.testData?.consideredParticleSizes && this.props?.testData?.consideredParticleSizes?.includes("1µm") ?
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                                                    <label htmlFor="acceptanceCriteriaMax">Initial Reading At 1µm(<span title="Not more than">NMT</span>)</label>
                                                                    <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                                                        value={this.props.testData?.initialReadingAtOneParticleSize ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();

                                                                            this.props.updateTestDataState({ name: "initialReadingAtOneParticleSize", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                            // this.props.updateHasError(this.hasError);
                                                                        }} />
                                                                    {/* <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this?.props?.testData?.consideredParticleSizes && this.props?.testData?.consideredParticleSizes?.split(",")?.includes("5µm") ?
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                                                    <label htmlFor="acceptanceCriteriaMax">Initial Reading At 5µm</label>
                                                                    <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                                                        value={this.props.testData?.initialReadingAtFiveParticleSize ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();

                                                                            this.props.updateTestDataState({ name: "initialReadingAtFiveParticleSize", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                            // this.props.updateHasError(this.hasError);
                                                                        }} />
                                                                    {/* <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("worstReadingTime").className}`}>
                                                            <label htmlFor="worstReadingTime">Worst Reading Time<span className="requried-span">*</span></label>
                                                            <DatePicker key="worstReadingTime"
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={10}
                                                                timeCaption="Time"
                                                                timeFormat={AppConstants.TimeFormat}
                                                                dateFormat={AppConstants.TimeFormat}
                                                                selected={this.props.testData?.worstReadingTime ? utcToLocalDate(this.props.testData?.worstReadingTime ?? "") : ""}

                                                                onChange={(d) => {
                                                                    this.ValidateField("worstReadingTime", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "worstReadingTime", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }}

                                                            />
                                                            <ValidationMessageControl message={this.vResult("worstReadingTime").message} />
                                                        </div>
                                                    </div>

                                                    {
                                                        this?.props?.testData?.consideredParticleSizes && this.props?.testData?.consideredParticleSizes?.includes("0.5µm") ?
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                                                    <label htmlFor="acceptanceCriteriaMax">Worst Reading At 0.5µm</label>
                                                                    <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                                                        value={this.props.testData?.worstReadingAtPointFiveParticleSize ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();

                                                                            this.props.updateTestDataState({ name: "worstReadingAtPointFiveParticleSize", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                            // this.props.updateHasError(this.hasError);
                                                                        }} />
                                                                    {/* <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this?.props?.testData?.consideredParticleSizes && this.props?.testData?.consideredParticleSizes?.includes("1µm") ?
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                                                    <label htmlFor="acceptanceCriteriaMax">Worst Reading At 1µm</label>
                                                                    <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                                                        value={this.props.testData?.worstReadingAtOneParticleSize ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();

                                                                            this.props.updateTestDataState({ name: "worstReadingAtOneParticleSize", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                            // this.props.updateHasError(this.hasError);
                                                                        }} />
                                                                    {/* <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this?.props?.testData?.consideredParticleSizes && this.props?.testData?.consideredParticleSizes?.split(",")?.includes("5µm") ?
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                                                    <label htmlFor="acceptanceCriteriaMax">Worst Reading At 5µm</label>
                                                                    <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                                                        value={this.props.testData?.worstReadingAtFiveParticleSize ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();

                                                                            this.props.updateTestDataState({ name: "worstReadingAtFiveParticleSize", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                            // this.props.updateHasError(this.hasError);
                                                                        }} />
                                                                    {/* <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("finalReadingTime").className}`}>
                                                            <label htmlFor="finalReadingTime">Final Reading Time<span className="requried-span">*</span></label>
                                                            <DatePicker key="finalReadingTime"
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={10}
                                                                timeCaption="Time"
                                                                timeFormat={AppConstants.TimeFormat}
                                                                dateFormat={AppConstants.TimeFormat}
                                                                selected={this.props.testData?.finalReadingTime ? utcToLocalDate(this.props.testData?.finalReadingTime ?? "") : ""}
                                                                onChange={(d) => {
                                                                    this.ValidateField("finalReadingTime", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "finalReadingTime", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }}
                                                            />
                                                            <ValidationMessageControl message={this.vResult("finalReadingTime").message} />
                                                        </div>
                                                    </div>

                                                    {
                                                        this?.props?.testData?.consideredParticleSizes && this.props?.testData?.consideredParticleSizes?.includes("0.5µm") ?
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                                                    <label htmlFor="acceptanceCriteriaMax">Final Reading At 0.5µm</label>
                                                                    <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                                                        value={this.props.testData?.finalReadingAtPointFiveParticleSize ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();

                                                                            this.props.updateTestDataState({ name: "finalReadingAtPointFiveParticleSize", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                            // this.props.updateHasError(this.hasError);
                                                                        }} />
                                                                    {/* <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this?.props?.testData?.consideredParticleSizes && this.props?.testData?.consideredParticleSizes?.includes("1µm") ?
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                                                    <label htmlFor="acceptanceCriteriaMax">Final Reading At 1µm</label>
                                                                    <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                                                        value={this.props.testData?.finalReadingAtOneParticleSize ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();

                                                                            this.props.updateTestDataState({ name: "finalReadingAtOneParticleSize", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                            // this.props.updateHasError(this.hasError);
                                                                        }} />
                                                                    {/* <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                    {
                                                        this?.props?.testData?.consideredParticleSizes && this.props?.testData?.consideredParticleSizes?.split(",")?.includes("5µm") ?
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                                                    <label htmlFor="acceptanceCriteriaMax">Final Reading At 5µm</label>
                                                                    <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                                                        value={this.props.testData?.finalReadingAtFiveParticleSize ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();

                                                                            this.props.updateTestDataState({ name: "finalReadingAtFiveParticleSize", value: e?.target?.value?.trim()?.length == 0 ? null : e?.target?.value });
                                                                            // this.props.updateHasError(this.hasError);
                                                                        }} />
                                                                    {/* <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} /> */}
                                                                </div>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </div>
                                            </div>
                                        </article>

                                    </div>
                                </div>
                            </div>
                            <article className="card no-bg">
                                <div className="row">
                                    <div className="col-12 text-right">
                                        <button type="button" className="btn mr-0 mb-0 btn-primary"
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                const error = !this.ValidateForm(this.props.testData, this.props.testData);
                                                this.reloadForm();
                                                if (error) return;

                                                if (this.props.testData?.id) {
                                                    await this.props.editTest({ testName: CleanroomEquipmentTests.recovery ?? "", data: this.props.testData });
                                                } else {
                                                    const { createdById, createdBy, preparedBy, checkedBy,

                                                        ...rest } = this.props.testData;
                                                    const payload = {
                                                        ...rest,
                                                        cleanroomAreaReportId: Number(this.props.id ?? 0),
                                                        clientId: this.props.areaData.clientId,
                                                        clientAddressId: this.props.areaData.clientAddressId,
                                                        clientEquipmentId: this.props.areaData.clientAreaId,
                                                    };

                                                    this.props.createTest({ testName: CleanroomAreaTests.recovery ?? "", data: payload });
                                                }
                                            }}>Submit</button>
                                    </div>
                                </div>
                            </article>
                            {
                                this.props.testData.id && this.props.testData.id > 0 ?
                                    <article className="card">
                                        <div className="card-header card-form-header d-flex w-100 align-items-center justify-content-between">
                                            <div className="card-form-header-title">
                                                Recovery Readings
                                            </div>

                                            <button className="btn mr-0 mb-0 btn-primary small-btn"
                                                onClick={() => this.setState({ ...this.state, openObservationPopup: !this.state.openObservationPopup, editReading: null })}
                                            >
                                                Add Reading
                                            </button>
                                        </div>
                                        <div className="card-body pt-0">
                                            <div className="row">
                                                <div className="col-12 grid-wrapper">
                                                    <div className="card-block px-0 ag-theme-alpine">
                                                        <AgGridReact
                                                            pagination={true}
                                                            paginationPageSize={10}
                                                            unSortIcon={true}
                                                            rowHeight={60}
                                                            domLayout="autoHeight"
                                                            defaultColDef={AgGridDefaultColDef}
                                                            columnDefs={[
                                                                {
                                                                    hide: this.props.accessLevel < AccessLevel.Edit,
                                                                    headerName: "Edit", field: 'id', width: 60, cellClass: "grid-cell grid-cell-link",
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => {
                                                                        return (
                                                                            <span
                                                                                onClick={() => {
                                                                                    this.setState({ ...this.state, editReading: params.data, openObservationPopup: true })
                                                                                }}
                                                                            >
                                                                                <i className={`feather icon-edit f-16 text-green`}></i>
                                                                            </span>
                                                                        )
                                                                    }
                                                                },
                                                                {
                                                                    hide: this.props.accessLevel < AccessLevel.Delete,
                                                                    headerName: "Delete", field: 'id', width: 80, cellClass: "grid-cell grid-cell-link",
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => {
                                                                        return (
                                                                            <span
                                                                                onClick={async () => {
                                                                                    this.props.deleteReading({ testName: CleanroomEquipmentTests.recovery, id: params.value });
                                                                                }}
                                                                            >
                                                                                <i className={`feather icon-trash ml-2 f-16 text-green`}></i>
                                                                            </span>
                                                                        )
                                                                    }
                                                                },

                                                                {
                                                                    headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => <span>{params.value}</span>
                                                                },
                                                                {
                                                                    headerName: "Reading Time", field: "recoveryTime", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => <span>
                                                                        {
                                                                            params?.value ? moment(utcToLocalDate(params?.value))?.format('HH:MM:SS') : ""
                                                                        }
                                                                    </span>
                                                                },
                                                                {
                                                                    headerName: "0.5µm", field: "recoveryReadingAtPointFiveParticleSize", width: 100, sortable: true, cellClass: "grid-cell",
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => <span>{params.value ? moment(utcToLocalDate(params.value))?.format(AppConstants.TimeFormat) : ""}</span>
                                                                },
                                                                {
                                                                    headerName: "1µm", field: "recoveryReadingAtOneParticleSize", width: 100, sortable: true, cellClass: "grid-cell",
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => <span>{params.value ? moment(utcToLocalDate(params.value))?.format(AppConstants.TimeFormat) : ""}</span>
                                                                },
                                                                {
                                                                    headerName: "5µm", field: "recoveryReadingAtFiveParticleSize", width: 100, sortable: true, cellClass: "grid-cell",
                                                                    suppressMovable: true,
                                                                    cellRenderer: (params) => <span>{params.value ? moment(utcToLocalDate(params?.value))?.format(AppConstants.TimeFormat) : ""}</span>
                                                                },

                                                            ]}
                                                            rowData={this.props.testData?.recoveryReadings ?? []}>
                                                        </AgGridReact>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    : ""
                            }


                            {this.props.testData?.lastComment?.commentDescription ?
                                <div className="card card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="equipmentName">Recent comment</label>
                                            <textarea
                                                className="form-control not-allowed"
                                                disabled
                                                value={this.props.testData?.lastComment?.commentDescription}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                : ""
                            }


                        </div>

                        {
                            this.props.testData?.id && this.state.openObservationPopup ?
                                <TestObservationComponent
                                    toggle={this.state.openObservationPopup}
                                    handleToggleState={() => this.setState({ ...this.state, openObservationPopup: false, editReading: null })}
                                    testData={this.props.testData}
                                    testName={CleanroomAreaTests.recovery ?? ""}
                                    defaultData={this.state.editReading}
                                />
                                : null
                        }

                    </div>
                </div >
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    testData: state.cleanroomAreaTest.testData,
    dataLoading: state.cleanroomAreaTest.dataLoading,
    createEditLoading: state.cleanroomAreaTest.createEditLoading,
    currentUser: state.userSession.currentUser,
    areaData: state.cleanroomequipment.equipmentData,
    refetchApi: state.cleanroomAreaTest.refetchApi,
});
export default connect(mapStateToProps, {
    clearTestDataState, updateTestDataState, editTest: editCleanroomAreaRecoveryTest, createTest: createCleanroomAreaRecoveryTest, loadTestById: loadCleanroomAreaRecoveryTest, loadCleanroomAreaById, deleteReading: deleteCleanroomAreaRecoveryReading
})(TestManager);



