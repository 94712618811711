import React from "react";
import { IFormBaseState, FormBaseComponent, } from "../../../../../../../common/component/form-base.component";
import Modal from 'react-modal';
import { CleanroomAreaTests, TestObservationResult, ValidationScreens } from "../../../../../../../common/enums";
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import { clearObservationDataState, updateObservationDataState, setDeletdRoomId, createSLObservation, editSLObservation, createSLReading, editSLReading, deleteReading } from "../../../../../store/cleanroom-area/cleanroom-area-observation.slice";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { CleanroomAreaSLTestObservationDefault, CleanroomAreaSLTestReadingDefault } from "../../../../../model/cleanroom-area/defaults/test.default";
import { IBaseTestPayload } from "../../../../../model/cleanroom-equipment/test/test.model";
import { ICleanroomAreaSLTestObservationPayload, ICleanroomAreaSLTestReadingPayload, ICleanroomAreaSoundLevelTestModel, ICleanroomAreaSoundLevelTestObservationModel, ICleanroomAreaSoundLevelTestReadingModel } from "../../../../../model/cleanroom-area/test/sound-level-test-model";

export interface IProps {
    toggle: boolean,
    locationLabel: string,
    handleToggleState: any,
    testData: ICleanroomAreaSoundLevelTestModel,
    observationData: ICleanroomAreaSoundLevelTestObservationModel,
    clearObservationDataState: (data: ICleanroomAreaSoundLevelTestObservationModel) => void,
    updateObservationDataState: (data) => void,
    createObservation: (data: ICleanroomAreaSLTestObservationPayload) => void,
    editObservation: (data: ICleanroomAreaSLTestObservationPayload) => void,
    testName: string,
    defaultData?: null | ICleanroomAreaSoundLevelTestObservationModel,
    editReading: (data: ICleanroomAreaSLTestReadingPayload) => void,
    createReading: (data: ICleanroomAreaSLTestReadingPayload) => void,
    deleteReading: (data: IBaseTestPayload) => void,
    deletedRoomId?: number,
    setDeletdRoomId: (data: number) => void,
}

export interface IState extends IFormBaseState {
    readingData: ICleanroomAreaSoundLevelTestReadingModel,
    openReadingSection: boolean,
    editReadingIndex: number,
}

class TestObservationComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            readingData: CleanroomAreaSLTestReadingDefault,
            hideForm: false,
            openReadingSection: false,
            editReadingIndex: -1,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.SoundLevelTestObservation);
        this.props.clearObservationDataState(this.props.defaultData ?? CleanroomAreaSLTestObservationDefault);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.defaultData && this.props.defaultData != prevProps.defaultData) {
            this.props.clearObservationDataState(this.props.defaultData);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    render() {
        return <React.Fragment>
            <Modal
                ariaHideApp={false}
                isOpen={this.props.toggle}
                onRequestClose={this.props.handleToggleState}
            >
                <div className={`d-flex flex-column`} style={{ maxHeight: "90vh" }}>
                    <div className="f-18">
                        Add Observation
                    </div>
                    <div>
                        <div className="row mt-3">
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("locationNameOrNumber").className}`}>
                                    <label htmlFor="locationNameOrNumber">{this.props?.locationLabel}<span className="requried-span">*</span></label>
                                    <input id="locationNameOrNumber" className="form-control" type="text"
                                        value={this.props.observationData?.locationNameOrNumber ?? ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            this.ValidateField("locationNameOrNumber", e.target?.value);
                                            this.props.updateObservationDataState({ name: "locationNameOrNumber", value: e?.target?.value });
                                            // this.props.updateHasError(this.hasError);
                                        }} />
                                    <ValidationMessageControl message={this.vResult("locationNameOrNumber").message} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("acceptanceCriteriaMin").className}`}>
                                    <label htmlFor="acceptanceCriteriaMin">Acceptance Criteria Min (<span title="Not less than">NLT</span>)</label>
                                    <input id="acceptanceCriteriaMin" className="form-control" type="number"
                                        value={this.props.observationData?.acceptanceCriteriaMin ?? ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            this.ValidateField("acceptanceCriteriaMin", +e.target?.value);
                                            this.props.updateObservationDataState({ name: "acceptanceCriteriaMin", value: e?.target?.value });
                                            // this.props.updateHasError(this.hasError);
                                        }} />
                                    <ValidationMessageControl message={this.vResult("acceptanceCriteriaMin").message} />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className={`form-group ${this.vResult("acceptanceCriteriaMax").className}`}>
                                    <label htmlFor="acceptanceCriteriaMax">Acceptance Criteria Max
                                        (<span title="Not more than">NMT</span>)

                                    </label>
                                    <input id="acceptanceCriteriaMax" className="form-control" type="number"
                                        value={this.props.observationData?.acceptanceCriteriaMax ?? ""}
                                        onChange={e => {
                                            e.preventDefault();
                                            this.ValidateField("acceptanceCriteriaMax", +e.target?.value);
                                            this.props.updateObservationDataState({ name: "acceptanceCriteriaMax", value: e?.target?.value })
                                            // this.props.updateHasError(this.hasError);
                                        }} />
                                    <ValidationMessageControl message={this.vResult("acceptanceCriteriaMax").message} />
                                </div>
                            </div>
                            {
                                this.props.observationData?.id && this.props.observationData?.readings?.length ?
                                    <>
                                        <div className="col-6">
                                            <div className={`form-group ${this.vResult("name").className}`}>
                                                <label htmlFor="name">Average Sound Level</label>
                                                <input id="name" className="form-control not-allowed" type="text"
                                                    value={this.props.observationData?.averageSoundLevel ?? ""}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className={`form-group ${this.vResult("name").className}`}>
                                                <label htmlFor="name">Result</label>
                                                <input id="name" className="form-control not-allowed" type="text"
                                                    value={this.props.observationData?.result == TestObservationResult.Pass ? "Pass" :
                                                        this.props.observationData?.result == TestObservationResult.Fail ? "Fail" : ""}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </>
                                    : ''
                            }
                        </div>
                        <div className="row">
                            <div className="col-12 text-right">
                                <button
                                    onClick={(e: any) => {
                                        const error = !this.ValidateForm(this.props.observationData);
                                        this.reloadForm();
                                        if (error) return;
                                        if (this.props.observationData?.id) {
                                            this.props.editObservation({ testName: this.props.testName, data: this.props.observationData })
                                        } else {
                                            let payload = { ...this.props.observationData };
                                            payload.soundLevelTestId = this.props.testData.id ?? 0;
                                            this.props.createObservation({ testName: this.props.testName, data: payload })
                                        }
                                    }}
                                    className="text-capitalize f-w-800 border-0 btn-primary btn-sm mt-2 align-self-end">
                                    Submit
                                </button>
                            </div>
                        </div>
                        {
                            this.props?.observationData && this.props.observationData?.id ?
                                <div className="row">
                                    <div className="col-12">
                                        <div className="my-2">
                                            <div className="d-flex align-items-center">
                                                <div>
                                                    Readings :
                                                    {!this.state.openReadingSection && this.props?.observationData?.readings?.length < 11 ?
                                                        <button
                                                            className="text-capitalize f-w-800 ml-2 border-0 btn-primary btn-sm align-self-end"
                                                            onClick={() => {
                                                                this.setState({ ...this.state, openReadingSection: !this.state.openReadingSection });
                                                            }}>+</button>
                                                        : null}
                                                </div>
                                                {
                                                    this.state.openReadingSection &&
                                                    <>
                                                        <div className="col-4">
                                                            <input className="form-control" type="number"
                                                                value={this.state.readingData.reading ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.setState({
                                                                        ...this.state,
                                                                        readingData: { ...this.state.readingData, reading:e?.target?.value ?? 0}
                                                                    });
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-4">
                                                            <button
                                                                className="text-capitalize f-w-800 border-0 btn-primary btn-sm"
                                                                onClick={() => {
                                                                    if (Number(this.state.readingData?.reading) > -1) {
                                                                        let item = { ...this.state.readingData, observationId: this.props.observationData?.id ?? 0 };
                                                                        if (this.state.readingData?.observationId && this.state.editReadingIndex > -1) {
                                                                            this.props.editReading({ testName: this.props.testName, data: item });
                                                                        } else {
                                                                            this.props.createReading({ testName: this.props.testName, data: item });
                                                                        }
                                                                        this.setState({ ...this.state, openReadingSection: false, editReadingIndex: -1, readingData: CleanroomAreaSLTestReadingDefault });
                                                                    }
                                                                }}>
                                                                {this.state.editReadingIndex > -1 ? "Edit" : "Add"}
                                                            </button>
                                                            <button
                                                                className="text-capitalize f-w-800 border-0 btn-primary btn-sm ml-2"
                                                                onClick={() => {

                                                                    this.setState({ ...this.state, openReadingSection: false, editReadingIndex: -1, readingData: CleanroomAreaSLTestReadingDefault });
                                                                }}>
                                                                Cancel
                                                            </button>
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </div>

                                        {
                                            Array.isArray(this.props.observationData?.readings) && this.props.observationData?.readings?.length > 0 ?
                                                <div className="d-flex align-items-center">
                                                    {this.props.observationData?.readings?.map((item: ICleanroomAreaSoundLevelTestReadingModel, index: number) => {
                                                        return <div className="d-flex align-items-center border p-2 mr-2">
                                                            <div>{item?.reading ?? ""}</div>
                                                            <div className="ml-3">
                                                                <span
                                                                    onClick={() => {
                                                                        this.setState({ ...this.state, editReadingIndex: index, readingData: item, openReadingSection: true });
                                                                    }}
                                                                >
                                                                    <i className={`feather icon-edit f-14  text-green`}></i>
                                                                </span>
                                                                <span
                                                                    onClick={() => {
                                                                        this.props.setDeletdRoomId(index);
                                                                        this.props.deleteReading({ testName: CleanroomAreaTests.soundlevel, id: item.id });
                                                                    }}
                                                                >
                                                                    <i className={`ml-2 feather icon-trash f-14 text-green`}></i>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                                :
                                null
                        }
                    </div>
                </div>
            </Modal>
        </React.Fragment >
    }
}


const mapStateToProps = (state: IAppState) => ({
    observationData: state.cleanroomAreaObservation.observationData,
    dataLoading: state.cleanroomAreaObservation.dataLoading,
    createEditLoading: state.cleanroomAreaObservation.createEditLoading,
    deleteRoomId: state.cleanroomAreaObservation.deletedRoomId,
});
export default connect(mapStateToProps, {
    clearObservationDataState, updateObservationDataState, setDeletdRoomId, createObservation: createSLObservation, editObservation: editSLObservation, createReading: createSLReading, editReading: editSLReading, deleteReading: deleteReading
})(TestObservationComponent);

