import { Route, Routes, matchPath, useLocation } from "react-router-dom";
import AccountLayout from "../../common/screen/layout/account.layout";
import ScreenUrls from "../../common/screen-urls";
import ReportPage from "./screen/report.page";
import CleanroomAreaCreateEditPage from "./screen/cleanroom/area/form/area-create-edit-page";
import CleanroomEquipmentCreateEditPage from "./screen/cleanroom/equipment/form/equipment-create-edit-page";

//=  cleanroom equipment
import AVTestManagerComponent from "./screen/cleanroom/equipment/tests/airflow-velocity-test/av-test-create-edit-page";
import FITestManagerComponent from "./screen/cleanroom/equipment/tests/filter-integrity-test/fi-test-create-edit-page";
import LITestManagerComponent from "./screen/cleanroom/equipment/tests/light-intensity-test/li-test-create-edit-page";
import PCTestManagerComponent from "./screen/cleanroom/equipment/tests/non-viable-particle-count-test/non-viable-particle-count-test-create-edit-page";
import RecoveryTestManagerComponent from "./screen/cleanroom/equipment/tests/recovery-test/recovery-test-create-edit-page";
import AirflowPatternTestManagerComponent from "./screen/cleanroom/equipment/tests/airflow-pattern-test/airflow-pattern-test-create-edit-page";
import SoundLevelTestmanagerComponent from "./screen/cleanroom/equipment/tests/sound-level-test/sound-level-test-create-edit-page";
import UVLightIntensitytestcomponent from "./screen/cleanroom/equipment/tests/uv-light-intensity-test/uv-light-intensity-test-create-edit-page";

//=== cleanroom area ===
import AirChangesPerHourTestHandler from "../report/screen/cleanroom/area/tests/air-changes-per-hour-test/air-changes-per-hour-text-create-edit-page";
import CleanroomAreaFITestHandler from "../report/screen/cleanroom/area/tests/filter-integrity-test/filter-integrity-test-create-edit-page";
import CleanroomAreaNonViableParticleCountTest from "../report/screen/cleanroom/area/tests/non-viable-particle-count-test/nvpc-test-create-edit-page";
import CleanroomAreaRecoveryTestComponent from "../report/screen/cleanroom/area/tests/recovery/recovery-test-create-edit-page";
import CleanroomAreaAirFlowTestComponent from "../report/screen/cleanroom/area/tests/air-flow-pattern-test/air-flow-pattern-test-create-edit-page";
import CleanroomAreaSoundLevelTestComponent from "../report/screen/cleanroom/area/tests/sound-level-test/sound-level-test-create-edit-page";
import CleanroomAreaLightIntensityComponent from "../report/screen/cleanroom/area/tests/light-intensity-test/light-intensity-test-create-edit-page";
import CleanroomAreaUVLightIntensityComponent from "../report/screen/cleanroom/area/tests/uv-light-intensity-test/uv-light-intensity-test-create-edit-page";
import CleanroomAreaDifferentialPressureComponent from "../report/screen/cleanroom/area/tests/differential-pressure-test/differential-pressure-test-create-edit-page";

import RouteHelper from "../../common/helper/route-helper";
import { AccessLevel, CleanroomAreaTests, CleanroomEquipmentTests, FeatureNames } from "../../common/enums";
import AccessDeniedPage from "../../common/screen/anonymous/access-denied.page";

const ReportRoutes = () => {
    const location = useLocation();
    const report = RouteHelper.GetFeatureAccessLevel(FeatureNames.Report);

    return <Routes>
        <Route element={<AccountLayout />}>

            <Route path={ScreenUrls.Report.Index()}>
                <Route index
                    element={report.accessLevel >= AccessLevel.View ? <ReportPage accessLevel={report.accessLevel} /> : <AccessDeniedPage />}
                />
            </Route>
            <Route path={ScreenUrls.Report.CleanroomEquipment.Index()}>
                <Route path={ScreenUrls.CreateUri}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomEquipmentCreateEditPage accessLevel={report.accessLevel} /> : <AccessDeniedPage />} />
                <Route
                    path={ScreenUrls.EditUriParam}
                    element={
                        report.accessLevel >= AccessLevel.Edit ? <CleanroomEquipmentCreateEditPage accessLevel={report.accessLevel}
                            id={
                                matchPath({ path: ScreenUrls.Report.CleanroomEquipment.Edit(":id") }, location.pathname)?.params["id"]}
                        /> : <AccessDeniedPage />} />

                {/* ----- airflow velocity test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <AVTestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <AVTestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowvelocity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ----- filter integrity test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <FITestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <FITestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.filterintegrity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ----- Light Intensity Test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <LITestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <LITestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.lightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ---- NVPC Test*/}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <PCTestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <PCTestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.nonviableparticlecount}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ---- Recovery Test ---- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <RecoveryTestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <RecoveryTestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.recovery}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                {/* ---- Sound Level Test ---- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <SoundLevelTestmanagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <SoundLevelTestmanagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.soundlevel}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ---- Airflow Pattern Test ---- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <AirflowPatternTestManagerComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <AirflowPatternTestManagerComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.airflowpattern}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ---- uv light intensity Test ---- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <UVLightIntensitytestcomponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <UVLightIntensitytestcomponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomEquipment.Edit(":id")}/${CleanroomEquipmentTests.uvlightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
            </Route>

            {/* --- area routes */}
            <Route path={ScreenUrls.Report.CleanroomArea.Index()}>
                <Route path={ScreenUrls.CreateUri}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomAreaCreateEditPage accessLevel={report.accessLevel} /> : <AccessDeniedPage />} />
                <Route
                    path={ScreenUrls.EditUriParam}
                    element={
                        report.accessLevel >= AccessLevel.Edit ? <CleanroomAreaCreateEditPage accessLevel={report.accessLevel}
                            id={
                                matchPath({ path: ScreenUrls.Report.CleanroomArea.Edit(":id") }, location.pathname)?.params["id"]}
                        /> : <AccessDeniedPage />} />

                {/* ----- Air Changes Per Hour test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.airchangesperhour}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <AirChangesPerHourTestHandler
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.airchangesperhour}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.airchangesperhour}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <AirChangesPerHourTestHandler
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.airchangesperhour}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.airchangesperhour}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                {/* ----- Cleanroom Area Filter Integrity test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.filterintegrity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomAreaFITestHandler
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.filterintegrity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.filterintegrity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <CleanroomAreaFITestHandler
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.filterintegrity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.filterintegrity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
                {/* ----- Cleanroom Area Non Viable particle count Test ----- */}
                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.nonviableparticlecount}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomAreaNonViableParticleCountTest
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.nonviableparticlecount}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.nonviableparticlecount}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <CleanroomAreaNonViableParticleCountTest
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.nonviableparticlecount}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.nonviableparticlecount}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.recovery}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomAreaRecoveryTestComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.recovery}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.recovery}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <CleanroomAreaRecoveryTestComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.recovery}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.recovery}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.airflowpattern}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomAreaAirFlowTestComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.airflowpattern}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.airflowpattern}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <CleanroomAreaAirFlowTestComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.airflowpattern}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.airflowpattern}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.soundlevel}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomAreaSoundLevelTestComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.soundlevel}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.soundlevel}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <CleanroomAreaSoundLevelTestComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.soundlevel}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.soundlevel}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.lightintensity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomAreaLightIntensityComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.lightintensity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.lightintensity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <CleanroomAreaLightIntensityComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.lightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.lightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.uvlightintensity}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomAreaUVLightIntensityComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.uvlightintensity}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.uvlightintensity}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <CleanroomAreaUVLightIntensityComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.uvlightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.uvlightintensity}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.differentialpressure}/${ScreenUrls.CreateUri}`}
                    element={report.accessLevel >= AccessLevel.Create ? <CleanroomAreaDifferentialPressureComponent
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.differentialpressure}/${ScreenUrls.CreateUri}` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />

                <Route
                    path={`${ScreenUrls.EditUriParam}/${CleanroomAreaTests.differentialpressure}/${ScreenUrls.EditUri}/:testId`}
                    element={report.accessLevel >= AccessLevel.Edit ? <CleanroomAreaDifferentialPressureComponent
                        testId={Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.differentialpressure}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["testId"])}
                        accessLevel={report.accessLevel}
                        id={
                            Number(matchPath({ path: `${ScreenUrls.Report.CleanroomArea.Edit(":id")}/${CleanroomAreaTests.differentialpressure}/${ScreenUrls.EditUri}/:testId` }, location.pathname)?.params["id"])}
                    /> : <AccessDeniedPage />} />
            </Route>
        </Route>
    </Routes>
}

export default ReportRoutes;
