import { createAsyncThunk, createSlice, isFulfilled, isPending, isRejected, PayloadAction } from "@reduxjs/toolkit";
import { ICleanroomAreaModel } from '../../model/report.model';
import CleanroomAreaTestService from "../../service/cleanroom-area/test.service";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import { IAirChangesPerHourTestModel } from "../../model/cleanroom-area/test/air-changes-per-hour-test.model";
import { airChangesPerHourTestDefault } from "../../model/cleanroom-area/defaults/test.default";
import { createACPHObservation, createACPHReading, createAFPTObservation, createCleanroomAreaRecoveryReading, createFIObservation, createNVPCLocation, createNVPCObservation, editAFPTObservation, deleteCleanroomAreaRecoveryReading, deleteNVPCLocation, deleteNVPCObservation, deleteObservation, deleteReading, editACPHObservation, editACPHReading, editCleanroomAreaRecoveryReading, editFIObservation, editNVPCLocation, editNVPCObservation, createAFPTObservationSmokeFlow, editAFPTObservationSmokeFlow, deleteAFPTObservationSmokeFlow, createSLObservation, editSLObservation, createSLReading, editSLReading, createLIObservation, editLIObservation, createLIReading, editLIReading, createUVLIObservation, editUVLIObservation, createUVLIReading, editUVLIReading, createDFObservation, editDFObservation, DeleteDFObservation } from "./cleanroom-area-observation.slice";
import ScreenUrls from "../../../../common/screen-urls";
import { CleanroomAreaTests } from "../../../../common/enums";
import { ICleanroomAreaFilterIntegrityTestModel } from "../../model/cleanroom-area/test/filter-integrity-test.model";
import { ICleanroomAreaNonViableParticleCountTestModel } from "../../model/cleanroom-area/test/non-viable-particle-count-test.model";
import { ICleanroomAreaRecoveryTestModel } from "../../model/cleanroom-area/test/recovery-test.model";
import { ICleanroomAreaAirflowPatternTestModel } from "../../model/cleanroom-area/test/airflow-pattern-test.model";
import { ICleanroomAreaSoundLevelTestModel } from "../../model/cleanroom-area/test/sound-level-test-model";
import { ICleanroomAreaLightIntensityTestModel } from "../../model/cleanroom-area/test/light-intensity-test.model";
import { ICleanroomAreaUVLightIntensityTestModel } from "../../model/cleanroom-area/test/uv-light-intensity-test.model";
import { ICleanroomAreaDifferentialPressureTestModel } from "../../model/cleanroom-area/test/differential-pressure-test.model";

// ----- ACPH Test -----
export const loadACPHTestById = createAsyncThunk("report/area/test/ACPH/loadById", CleanroomAreaTestService.GetACPHById);
export const createACPHTest = createAsyncThunk("report/area/test/ACPH/create", CleanroomAreaTestService.CreateACPHTest);
export const editACPHTest = createAsyncThunk("report/area/test/ACPH/edit", CleanroomAreaTestService.EditACPHTest);

// ---- FI Test ----
export const loadFITestById = createAsyncThunk("report/area/test/FI/loadById", CleanroomAreaTestService.GetFIById);
export const createFITest = createAsyncThunk("report/area/test/FI/create", CleanroomAreaTestService.CreateFITest);
export const editFITest = createAsyncThunk("report/area/test/FI/edit", CleanroomAreaTestService.EditFITest);

// ---- NVPC Test ----
export const loadNVPCTestById = createAsyncThunk("report/area/test/NVPC/loadById", CleanroomAreaTestService.GetNVPCById);
export const createNVPCTest = createAsyncThunk("report/area/test/NVPC/create", CleanroomAreaTestService.CreateNVPCTest);
export const editNVPCTest = createAsyncThunk("report/area/test/NVPC/edit", CleanroomAreaTestService.EditNVPCTest);

//= common =
export const deleteTest = createAsyncThunk("report/area/test/delete", CleanroomAreaTestService.DeleteTest);
export const enableCleanroomAreaTestEdit = createAsyncThunk("report/area/test/enable-edit", CleanroomAreaTestService.EnableTestEdit);

export const loadCleanroomAreaRecoveryTest = createAsyncThunk("report/area/test/recovery/loadById", CleanroomAreaTestService.GetRecoveryTestById);
export const createCleanroomAreaRecoveryTest = createAsyncThunk("report/area/test/recovery/create", CleanroomAreaTestService.CreateRecoveryTest);
export const editCleanroomAreaRecoveryTest = createAsyncThunk("report/area/test/recovery/edit", CleanroomAreaTestService.EditRecoveryTest);

export const loadCleanroomAreaAFPTest = createAsyncThunk("report/area/test/afpt/loadById", CleanroomAreaTestService.GetAFPTestById);
export const createCleanroomAreaAFPTest = createAsyncThunk("report/area/test/afpt/create", CleanroomAreaTestService.CreateAFPTest);
export const editCleanroomAreaAFPTest = createAsyncThunk("report/area/test/afpt/edit", CleanroomAreaTestService.EditAFPTest);

export const loadCleanroomAreaSLTest = createAsyncThunk("report/area/test/SL/loadById", CleanroomAreaTestService.GetSLTestById);
export const createCleanroomAreaSLTest = createAsyncThunk("report/area/test/SL/create", CleanroomAreaTestService.CreateSLTest);
export const editCleanroomAreaSLTest = createAsyncThunk("report/area/test/SL/edit", CleanroomAreaTestService.EditSLTest);

export const loadCleanroomAreaLITest = createAsyncThunk("report/area/test/li/loadById", CleanroomAreaTestService.GetLITestById);
export const createCleanroomAreaLITest = createAsyncThunk("report/area/test/li/create", CleanroomAreaTestService.CreateLITest);
export const editCleanroomAreaLITest = createAsyncThunk("report/area/test/li/edit", CleanroomAreaTestService.EditLITest);

export const loadCleanroomAreaUVLITest = createAsyncThunk("report/area/test/uvli/loadById", CleanroomAreaTestService.GetUVLITestById);
export const createCleanroomAreaUVLITest = createAsyncThunk("report/area/test/uvli/create", CleanroomAreaTestService.CreateUVLITest);
export const editCleanroomAreaUVLITest = createAsyncThunk("report/area/test/uvli/edit", CleanroomAreaTestService.EditUVLITest);

export const loadCleanroomAreaDFTest = createAsyncThunk("report/area/test/DF/loadById", CleanroomAreaTestService.GetDFTestById);
export const createCleanroomAreaDFTest = createAsyncThunk("report/area/test/DF/create", CleanroomAreaTestService.CreateDFTest);
export const editCleanroomAreaDFTest = createAsyncThunk("report/area/test/DF/edit", CleanroomAreaTestService.EditDFTest);

export interface ICleanroomAreaTestState {
    testList: ICleanroomAreaModel[],
    testData: IAirChangesPerHourTestModel | ICleanroomAreaFilterIntegrityTestModel | ICleanroomAreaNonViableParticleCountTestModel | ICleanroomAreaRecoveryTestModel | ICleanroomAreaAirflowPatternTestModel | ICleanroomAreaSoundLevelTestModel | ICleanroomAreaLightIntensityTestModel | ICleanroomAreaUVLightIntensityTestModel | ICleanroomAreaDifferentialPressureTestModel,
    dataLoading: boolean,
    listLoading: boolean,
    createEditLoading: boolean,
    stageMoveLoading: boolean,
    refetchApi: boolean
}

const initialState: ICleanroomAreaTestState = {
    testList: [],
    testData: airChangesPerHourTestDefault,
    dataLoading: false,
    listLoading: false,
    createEditLoading: false,
    stageMoveLoading: false,
    refetchApi: false,
}

const cleanroomAreaSlice = createSlice({
    name: 'cleanroomAreaTest',
    initialState,
    reducers: {
        clearTestDataState: (state, action) => {
            state.testData = action.payload
        },
        updateTestDataState: (state, action: PayloadAction<IStoreSaveModel>) => {
            state.testData[action.payload.name] = action.payload.value;
        }
    },
    extraReducers: async (builder) => {
        // create
        builder.addCase(createACPHTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomAreaReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomArea.Edit(action.payload?.cleanroomAreaReportId ?? 0)}/${CleanroomAreaTests.airchangesperhour}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        // create
        builder.addCase(createFITest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomAreaReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomArea.Edit(action.payload?.cleanroomAreaReportId ?? 0)}/${CleanroomAreaTests.filterintegrity}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        // create NVPC
        builder.addCase(createNVPCTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomAreaReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomArea.Edit(action.payload?.cleanroomAreaReportId ?? 0)}/${CleanroomAreaTests.nonviableparticlecount}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        builder.addCase(createCleanroomAreaRecoveryTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomAreaReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomArea.Edit(action.payload?.cleanroomAreaReportId ?? 0)}/${CleanroomAreaTests.recovery}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        builder.addCase(createCleanroomAreaAFPTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomAreaReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomArea.Edit(action.payload?.cleanroomAreaReportId ?? 0)}/${CleanroomAreaTests.airflowpattern}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        builder.addCase(createCleanroomAreaSLTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomAreaReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomArea.Edit(action.payload?.cleanroomAreaReportId ?? 0)}/${CleanroomAreaTests.soundlevel}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        builder.addCase(createCleanroomAreaLITest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomAreaReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomArea.Edit(action.payload?.cleanroomAreaReportId ?? 0)}/${CleanroomAreaTests.lightintensity}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        builder.addCase(createCleanroomAreaUVLITest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomAreaReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomArea.Edit(action.payload?.cleanroomAreaReportId ?? 0)}/${CleanroomAreaTests.uvlightintensity}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        builder.addCase(createCleanroomAreaDFTest.fulfilled, (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
            if (action.payload?.cleanroomAreaReportId && action.payload?.id) {
                window.location.href = `${ScreenUrls.Report.CleanroomArea.Edit(action.payload?.cleanroomAreaReportId ?? 0)}/${CleanroomAreaTests.differentialpressure}/${ScreenUrls.EditUri}/${action.payload?.id ?? 0}`;
            }

        });

        // create,edit loader
        builder.addMatcher(isPending(createACPHTest, editACPHTest, createFITest, editFITest, createNVPCTest, editNVPCTest, createCleanroomAreaRecoveryTest, editCleanroomAreaRecoveryTest, createCleanroomAreaAFPTest, editCleanroomAreaAFPTest, createCleanroomAreaSLTest, editCleanroomAreaSLTest, createCleanroomAreaLITest, editCleanroomAreaLITest, createCleanroomAreaUVLITest, editCleanroomAreaUVLITest, createCleanroomAreaDFTest, editCleanroomAreaDFTest), (state, action) => {
            state.createEditLoading = true;
        });

        builder.addMatcher(isRejected(createACPHTest, editACPHTest, createFITest, editFITest, createNVPCTest, editNVPCTest, createCleanroomAreaRecoveryTest, editCleanroomAreaRecoveryTest, createCleanroomAreaAFPTest, editCleanroomAreaAFPTest, createCleanroomAreaSLTest, editCleanroomAreaSLTest, editCleanroomAreaLITest, editCleanroomAreaUVLITest, editCleanroomAreaDFTest), (state, action) => {
            state.createEditLoading = false;
        });

        builder.addMatcher(isFulfilled(editACPHTest, editFITest, editNVPCTest, editCleanroomAreaRecoveryTest, editCleanroomAreaAFPTest, editCleanroomAreaSLTest, editCleanroomAreaLITest, editCleanroomAreaUVLITest, createCleanroomAreaDFTest, editCleanroomAreaDFTest), (state, action) => {
            state.testData = action.payload;
            state.createEditLoading = false;
        });

        // --- fetch data
        builder.addMatcher(isPending(loadACPHTestById, loadFITestById, loadNVPCTestById, loadCleanroomAreaRecoveryTest, loadCleanroomAreaAFPTest, loadCleanroomAreaSLTest, loadCleanroomAreaLITest, loadCleanroomAreaUVLITest, loadCleanroomAreaDFTest), (state, action) => {
            state.dataLoading = true;
        });

        builder.addMatcher(isRejected(loadACPHTestById, loadFITestById, loadNVPCTestById, loadCleanroomAreaRecoveryTest, loadCleanroomAreaAFPTest, loadCleanroomAreaSLTest, loadCleanroomAreaLITest, loadCleanroomAreaUVLITest, loadCleanroomAreaDFTest), (state, action) => {
            state.dataLoading = false;
            state.refetchApi = false;
        });

        builder.addMatcher(isFulfilled(loadACPHTestById, loadFITestById, loadNVPCTestById, loadCleanroomAreaRecoveryTest, loadCleanroomAreaAFPTest, loadCleanroomAreaSLTest, loadCleanroomAreaLITest, loadCleanroomAreaUVLITest, loadCleanroomAreaDFTest), (state, action) => {
            state.testData = action.payload;
            state.refetchApi = false;
            state.dataLoading = false;
        });

        // --- observations 
        builder.addMatcher(isFulfilled(createACPHObservation, editACPHObservation, deleteObservation, createACPHReading, editACPHReading, deleteReading, createFIObservation, editFIObservation, createNVPCLocation, editNVPCLocation, editNVPCLocation, createNVPCObservation, editNVPCObservation, deleteNVPCLocation, deleteNVPCObservation, createCleanroomAreaRecoveryReading, deleteCleanroomAreaRecoveryReading, editCleanroomAreaRecoveryReading, createAFPTObservation, editAFPTObservation, createAFPTObservationSmokeFlow, editAFPTObservationSmokeFlow, deleteAFPTObservationSmokeFlow, createSLObservation, editSLObservation, createSLReading, editSLReading, createLIObservation, editLIObservation, createLIReading, editLIReading, createUVLIObservation, createUVLIObservation, editUVLIObservation, createUVLIReading, editUVLIReading, createDFObservation, editDFObservation, DeleteDFObservation, enableCleanroomAreaTestEdit), (state, action) => {
            state.refetchApi = true;
        });
    }
});

const { actions, reducer } = cleanroomAreaSlice;

export const { clearTestDataState, updateTestDataState } = actions;

export default reducer;