import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType} from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "locationNumber",
        rules: [
            {
                type: ValidationType.Required,
                message: "Location number is required"
            }
        ]
    },
    // {
    //     fieldName: "pointFiveOrMoreMicron",
    //     dependencyFieldName: "considered",
    //     callBack: (value, dependancyFieldValue) => {
    //         return dependancyFieldValue == ZoneClassificationType.EUGMP;
    //     },
    //     rules: [
    //         {
    //             type: ValidationType.ValidateIfCallbackIsTrue,
    //             message: "Grade is required",
    //         },
    //         {
    //             type: ValidationType.NumberGreaterThanZero,
    //             message: "Grade is required",
    //         },
    //     ]
    // },
    // {
    //     fieldName: "oneOrMoreMicron",
    //     rules: [
    //         {
    //             type: ValidationType.NumberGreaterThanZero,
    //             message: "Unit is required"
    //         }
    //     ]
    // },
    // {
    //     fieldName: "fiveOrMoreMicron",
    //     rules: [
    //         {
    //             type: ValidationType.Required,
    //             message: "AcceptanceCriteriaMin is required",
    //         },
    //         {
    //             type:ValidationType.NumberOnly,
    //             message: "AcceptanceCriteriaMin should be number",
    //         }
    //     ]
    // },
]

export const CleanroomAreaNonViableParticleCountTestObservationValidator: IValidatorModel = {
    screen: ValidationScreens.CleanroomAreaNonViableParticleCountTestObservation,
    fields: fields
}
