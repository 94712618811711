import ApiUrls from "../../../../common/api-urls";
import HttpService from "../../../../common/service/http.service";
import { IACPHTestReadingPayload, IAirChangesPerHourTestObservationReading } from "../../model/cleanroom-area/test/air-changes-per-hour-test.model";
import { ICleanroomAreaAFPTestObservationSmokeFlowPayload, ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel } from "../../model/cleanroom-area/test/airflow-pattern-test.model";
import { ICleanroomAreaLightIntensityTestReadingModel, ICleanroomAreaLITestReadingPayload } from "../../model/cleanroom-area/test/light-intensity-test.model";
import { ICleanroomAreaNonViableParticleCountTestObservationModel, ICleanroomAreaNVPCTestObservationPayload } from "../../model/cleanroom-area/test/non-viable-particle-count-test.model";
import { ICleanroomAreaSLTestReadingPayload, ICleanroomAreaSoundLevelTestReadingModel } from "../../model/cleanroom-area/test/sound-level-test-model";
import { ICleanroomAreaUVLightIntensityTestReadingModel, ICleanroomAreaUVLITestReadingPayload } from "../../model/cleanroom-area/test/uv-light-intensity-test.model";
import { IBaseTestPayload } from "../../model/cleanroom-equipment/test/test.model";

export default class CleanroomAreaReadingService {
    // ---- ACPH
    static async CreateACPHReading(payload: IACPHTestReadingPayload): Promise<IAirChangesPerHourTestObservationReading> {
        return await HttpService.Post<IAirChangesPerHourTestObservationReading | null, IAirChangesPerHourTestObservationReading>(ApiUrls.Report.CleanroomAreaReading.Create(payload.testName), payload.data ?? null);
    }

    static async EditACPHReading(payload: IACPHTestReadingPayload): Promise<IAirChangesPerHourTestObservationReading> {
        return await HttpService.Patch<IAirChangesPerHourTestObservationReading | null, IAirChangesPerHourTestObservationReading>(ApiUrls.Report.CleanroomAreaReading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetACPHReadingById(payload: IACPHTestReadingPayload): Promise<IAirChangesPerHourTestObservationReading> {
        return await HttpService.Get<IAirChangesPerHourTestObservationReading>(ApiUrls.Report.CleanroomAreaReading.GetById(payload.testName, payload.id ?? 0));
    }
    // --- common 
    static async DeleteReading(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.CleanroomAreaReading.Delete(payload.testName, payload.id ?? 0));
    }

    // ---- Non Viable Particle Count ----
    static async CreateNVPCObservation(payload: ICleanroomAreaNVPCTestObservationPayload): Promise<ICleanroomAreaNonViableParticleCountTestObservationModel> {
        return await HttpService.Post<ICleanroomAreaNonViableParticleCountTestObservationModel | null, ICleanroomAreaNonViableParticleCountTestObservationModel>(ApiUrls.Report.CleanroomAreaLocationObservation.Create(payload.testName), payload.data ?? null);
    }

    static async EditNVPCObservation(payload: ICleanroomAreaNVPCTestObservationPayload): Promise<ICleanroomAreaNonViableParticleCountTestObservationModel> {
        return await HttpService.Patch<ICleanroomAreaNonViableParticleCountTestObservationModel | null, ICleanroomAreaNonViableParticleCountTestObservationModel>(ApiUrls.Report.CleanroomAreaLocationObservation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetNVPCObservationById(payload: ICleanroomAreaNVPCTestObservationPayload): Promise<ICleanroomAreaNonViableParticleCountTestObservationModel> {
        return await HttpService.Get<ICleanroomAreaNonViableParticleCountTestObservationModel>(ApiUrls.Report.CleanroomAreaLocationObservation.GetById(payload.testName, payload.id ?? 0));
    }
    static async DeleteNVPCObservation(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.CleanroomAreaLocationObservation.Delete(payload.testName, payload.id ?? 0));
    }

    // AFPT Smoke Flow
    static async CreateAFPTSmokeFlow(payload: ICleanroomAreaAFPTestObservationSmokeFlowPayload): Promise<ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel> {
        return await HttpService.Post<ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel | null, ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel>(ApiUrls.Report.CleanroomAreaAFPTObservationSmokeFlow.Create(payload.testName), payload.data ?? null);
    }

    static async EditAFPTSmokeFlow(payload: ICleanroomAreaAFPTestObservationSmokeFlowPayload): Promise<ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel> {
        return await HttpService.Patch<ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel | null, ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel>(ApiUrls.Report.CleanroomAreaAFPTObservationSmokeFlow.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetAFPTSmokeFlowById(payload: ICleanroomAreaAFPTestObservationSmokeFlowPayload): Promise<ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel> {
        return await HttpService.Get<ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel>(ApiUrls.Report.CleanroomAreaAFPTObservationSmokeFlow.GetById(payload.testName, payload.id ?? 0));
    }
    static async DeleteAFPTSmokeFlow(payload: ICleanroomAreaAFPTestObservationSmokeFlowPayload) {
        await HttpService.Delete(ApiUrls.Report.CleanroomAreaAFPTObservationSmokeFlow.Delete(payload.testName, payload.id ?? 0));
    }

    static async CreateSLReading(payload:ICleanroomAreaSLTestReadingPayload): Promise<ICleanroomAreaSoundLevelTestReadingModel> {
        return await HttpService.Post<ICleanroomAreaSoundLevelTestReadingModel | null,ICleanroomAreaSoundLevelTestReadingModel>(ApiUrls.Report.CleanroomAreaReading.Create(payload.testName), payload.data ?? null);
    }

    static async EditSLReading(payload:ICleanroomAreaSLTestReadingPayload): Promise<ICleanroomAreaSoundLevelTestReadingModel> {
        return await HttpService.Patch<ICleanroomAreaSoundLevelTestReadingModel | null,ICleanroomAreaSoundLevelTestReadingModel>(ApiUrls.Report.CleanroomAreaReading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetSLReadingById(payload:ICleanroomAreaSLTestReadingPayload): Promise<ICleanroomAreaSoundLevelTestReadingModel> {
        return await HttpService.Get<ICleanroomAreaSoundLevelTestReadingModel>(ApiUrls.Report.CleanroomAreaReading.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateLIReading(payload:ICleanroomAreaLITestReadingPayload): Promise<ICleanroomAreaLightIntensityTestReadingModel> {
        return await HttpService.Post<ICleanroomAreaLightIntensityTestReadingModel | null,ICleanroomAreaLightIntensityTestReadingModel>(ApiUrls.Report.CleanroomAreaReading.Create(payload.testName), payload.data ?? null);
    }

    static async EditLIReading(payload:ICleanroomAreaLITestReadingPayload): Promise<ICleanroomAreaLightIntensityTestReadingModel> {
        return await HttpService.Patch<ICleanroomAreaLightIntensityTestReadingModel | null,ICleanroomAreaLightIntensityTestReadingModel>(ApiUrls.Report.CleanroomAreaReading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetLIReadingById(payload:ICleanroomAreaLITestReadingPayload): Promise<ICleanroomAreaLightIntensityTestReadingModel> {
        return await HttpService.Get<ICleanroomAreaLightIntensityTestReadingModel>(ApiUrls.Report.CleanroomAreaReading.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateUVLIReading(payload:ICleanroomAreaUVLITestReadingPayload): Promise<ICleanroomAreaUVLightIntensityTestReadingModel> {
        return await HttpService.Post<ICleanroomAreaUVLightIntensityTestReadingModel | null,ICleanroomAreaUVLightIntensityTestReadingModel>(ApiUrls.Report.CleanroomAreaReading.Create(payload.testName), payload.data ?? null);
    }

    static async EditUVLIReading(payload:ICleanroomAreaUVLITestReadingPayload): Promise<ICleanroomAreaUVLightIntensityTestReadingModel> {
        return await HttpService.Patch<ICleanroomAreaUVLightIntensityTestReadingModel | null,ICleanroomAreaUVLightIntensityTestReadingModel>(ApiUrls.Report.CleanroomAreaReading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetUVLIReadingById(payload:ICleanroomAreaUVLITestReadingPayload): Promise<ICleanroomAreaUVLightIntensityTestReadingModel> {
        return await HttpService.Get<ICleanroomAreaUVLightIntensityTestReadingModel>(ApiUrls.Report.CleanroomAreaReading.GetById(payload.testName, payload.id ?? 0));
    }
}
