import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import stageAssignmenSlice from "./common/stores/stage-assignment.slice";
import commentSlice from "./common/stores/comment.slice";
import navigationSlice from "./common/stores/navigation.slice";
import userSlice from "./features/user/store/user.slice";
import clientSlice from "./features/client/store/client.slice";
import clientEquipmentSlice from './features/client/store/client-equipment.slice';
import clientAreaSlice from "./features/client/store/client-area.slice";
import dataLoggerSlice from "./features/data-logger/store/data-logger.slice";
import workflowSlice from "./features/workflow/store/workflow.slice";
import userSessionSlice from "./features/user/store/user-session.slice";
import purchaseOrderSlice from "./features/purchase-order/store/purchase-order.slice";
import accessRoleSlice from "./features/access/store/access-role.slice";
import dataLoggerMakeSlice from "./features/data-logger/store/data-logger-make.slice";
import categorySlice from "./common/stores/category.slice";
import dataLoggerBookingSlice from "./features/data-logger/store/data-logger-booking.slice";
import purchaseOrderServiceItemSlice from "./features/purchase-order/store/purchase-order-service-item.slice";
import clientAddressSlice from "./features/client/store/client-address.slice";
import workflowTaskSlice from "./features/workflow-task/store/workflow-task.slice";
import purchaseOrderLineItemSlice from "./features/purchase-order/store/purchase-order-line-item.slice";
import featureSlice from "./features/access/store/feature.slice";
import fileSlice from "./common/stores/file.slice";
import dashboardSlice from "./features/dashboard/store/dashboard.slice";
import validatorSlice from "./common/stores/validator.slice";
import myWorkSlice from "./features/my-work/store/my-work.slice";
import alertMsgSlice from "./common/stores/alertMsg.slice";
import notificationSlice from "./features/notifications/store/notification.slice";
import passwordRecoverySlice from "./features/password-recovery/store/password-recovery.slice";
import dataLoggerCameraDetailSlice from "./features/data-logger/store/data-logger-camera-detail.slice";

// ---- Reports ----
import reportSlice from './features/report/store/report.slice';
import cleanroomEquipmentSlice from './features/report/store/cleanroom-equipment/cleanroom-equipment.slice';
import reportTestSlice from "./features/report/store/cleanroom-equipment/test.slice";
import reportObservationSlice from "./features/report/store/cleanroom-equipment/observation.slice";
import cleanroomAreaTestSlice from "./features/report/store/cleanroom-area/cleanroom-area-test.slice";
import cleanroomAreaTestObservationSlice from "./features/report/store/cleanroom-area/cleanroom-area-observation.slice";

const persistConfig = {
    key: 'root',
    storage,
}

const reducer = combineReducers({
    client: clientSlice,
    clientAddress: clientAddressSlice,
    clientEquipment: clientEquipmentSlice,
    clientArea: clientAreaSlice,
    category: categorySlice,
    dashboard: dashboardSlice,
    dataLogger: dataLoggerSlice,
    dataLoggerMake: dataLoggerMakeSlice,
    dataLoggerBooking: dataLoggerBookingSlice,
    userSession: persistReducer(persistConfig, userSessionSlice),
    navigation: navigationSlice,
    user: userSlice,
    accessRole: accessRoleSlice,
    feature: featureSlice,
    workflow: workflowSlice,
    workflowTask: workflowTaskSlice,
    myWork: myWorkSlice,
    purchaseOrder: purchaseOrderSlice,
    purchaseOrderLineItem: purchaseOrderLineItemSlice,
    purchaseOrderServiceItem: purchaseOrderServiceItemSlice,
    file: fileSlice,
    validator: validatorSlice,
    alertMsg: alertMsgSlice,
    stageAssignment: stageAssignmenSlice,
    notification: notificationSlice,
    comment: commentSlice,
    passwordRecovery: passwordRecoverySlice,
    report: reportSlice,
    cleanroomequipment: cleanroomEquipmentSlice,
    reportTest: reportTestSlice,
    testObservation: reportObservationSlice,
    dataLoggerCameraDetail: dataLoggerCameraDetailSlice,
    cleanroomAreaTest:cleanroomAreaTestSlice,
    cleanroomAreaObservation:cleanroomAreaTestObservationSlice,
});

const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            thunk: true,
            serializableCheck: false,
        }),
});

export default store;
export const persist = persistStore(store)

export type IAppState = ReturnType<typeof reducer>;
