import { clientEquipmentDefaults } from "../model/client-equipment.model";
import { IAppState } from "../../../store";
import { connect } from "react-redux";
import React from "react";
import ClientService from "../service/client.service";
import {
    quickAddEquipment,
    clearEquipmentDataState, clearEquipmentListState, updateClientEquipmentDataState
} from "../store/client-equipment.slice";
import AsyncSelect from "react-select/async";
import { DeferredLoadOptions } from "../../../common/utils";
import { IStoreSaveModel } from "../../../common/model/store-save.model";
import DropdownDisplayHelper from "../../../common/helper/dropdown-display.helper";
import { FormBaseComponent, IFormBaseState } from "../../../common/component/form-base.component";
import ValidationMessageControl from "../../../common/control/validation-message.control";
import { ValidationScreens } from "../../../common/enums";
import { clientAreaDefault, IClientAreaModel } from "../model/client-area.model";
import { clearAreaDataState, clearAreaListState, quickAddArea, updateClientAreaDataState } from "../store/client-area.slice";

export interface IProps {
    dataLoading: boolean,
    createEditLoading: boolean,
    callBackHandler: () => void,
    clientId: number,
    clientAreaData: IClientAreaModel,
    quickAddArea: (data: IClientAreaModel) => void;
    clearClientAreaDataState: (payload) => void;
    updateClientAreaDataState: (payload: IStoreSaveModel) => void;
}

export interface IState extends IFormBaseState {
}

class ClientAreaCreateEditComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (prevProps?.clientId != this.props.clientId) {
            let payload = { ...clientAreaDefault };
            if (this.props.clientId) {
                const category = await ClientService.GetById(this.props.clientId);
                payload.client = category;
                payload.clientId = category.id;
            }
            await this.props.clearClientAreaDataState(payload);
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.ClientArea);
        if (this.props.clientId) {
            let payload = { ...clientAreaDefault }
            const category = await ClientService.GetById(this.props.clientId);
            payload.client = category;
            payload.clientId = category.id;
            await this.props.clearClientAreaDataState(payload);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    loadClientOptions = (input: string) => DeferredLoadOptions(input, ClientService.ClientDropdown)
    render() {
        return <React.Fragment>
            <div className="">
                <div className="pcoded-inner-content">
                    {(() => { if (this.props.dataLoading || this.props.createEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
                    <div className="page-wrapper">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    <div className="col-3">
                                        <div className={`form-group ${this.vResult("ahuOrVAUName").className}`}>
                                            <label htmlFor="ahuOrVAUName">AHU/VAU Name<span className="requried-span">*</span></label>
                                            <input id="ahuOrVAUName" className="form-control" type="text"
                                                value={this.props.clientAreaData.ahuOrVAUName ?? ""}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    this.ValidateField("ahuOrVAUName", e.target.value);
                                                    this.props.updateClientAreaDataState({ name: "ahuOrVAUName", value: e.target.value });
                                                }} />
                                            <ValidationMessageControl message={this.vResult("ahuOrVAUName").message} />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className={`form-group ${this.vResult("ahuOrVAUId").className}`}>
                                            <label htmlFor="ahuOrVAUId">AHU/VAU Id<span className="requried-span">*</span></label>
                                            <input id="ahuOrVAUId" className="form-control" type="text"
                                                value={this.props.clientAreaData.ahuOrVAUId ?? ""}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    this.ValidateField("ahuOrVAUId", e.target.value);
                                                    this.props.updateClientAreaDataState({ name: "ahuOrVAUId", value: e.target.value });
                                                }} />
                                            <ValidationMessageControl message={this.vResult("ahuOrVAUId").message} />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className={`form-group ${this.vResult("areaOfTheTest").className}`}>
                                            <label htmlFor="areaOfTheTest">Area of The Test<span className="requried-span">*</span></label>
                                            <input id="areaOfTheTest" className="form-control" type="text"
                                                value={this.props.clientAreaData.areaOfTheTest ?? ""}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    this.ValidateField("areaOfTheTest", e.target.value);
                                                    this.props.updateClientAreaDataState({ name: "areaOfTheTest", value: e.target.value });
                                                }} />
                                            <ValidationMessageControl message={this.vResult("areaOfTheTest").message} />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className={`form-group ${this.vResult("location").className}`}>
                                            <label htmlFor="location">Location<span className="requried-span">*</span></label>
                                            <input id="location" className="form-control" type="text"
                                                value={this.props.clientAreaData.location ?? ""}
                                                onChange={e => {
                                                    e.preventDefault();
                                                    this.ValidateField("location", e.target.value);
                                                    this.props.updateClientAreaDataState({ name: "location", value: e.target.value });
                                                }} />
                                            <ValidationMessageControl message={this.vResult("location").message} />
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className={`form-group ${this.vResult("clientId").className}`}>
                                            <label htmlFor="clientId">Client<span className="requried-span">*</span></label>
                                            <AsyncSelect id="clientId" cacheOptions defaultOptions
                                                className="async-select-control"
                                                loadOptions={this.loadClientOptions}
                                                value={this.props.clientAreaData.client
                                                    ? { label: DropdownDisplayHelper.Client(this.props.clientAreaData.client), value: this.props.clientAreaData.client.id.toString() ?? "0" }
                                                    : { label: "Select Client", value: "" }}
                                                onChange={async (e) => {
                                                    const cId = Number(e?.value);
                                                    if (cId === null || cId === 0) return;
                                                    this.ValidateField("clientId", cId);
                                                    const category = await ClientService.GetById(cId);
                                                    this.props.updateClientAreaDataState({ name: "client", value: category });
                                                    this.props.updateClientAreaDataState({ name: "clientId", value: category.id });
                                                }} />
                                            <ValidationMessageControl message={this.vResult("clientId").message} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <div className="form-group">
                                            <button type="button" className="btn btn-primary btn--right"
                                                disabled={this.props.clientAreaData == null || this.hasError}
                                                onClick={async e => {
                                                    e.preventDefault();
                                                    this.props.callBackHandler();
                                                }}>Cancel</button>
                                            <button type="button" className="btn btn-primary btn--right"
                                                disabled={this.props.clientAreaData == null || this.hasError}
                                                onClick={async e => {
                                                    e.preventDefault();
                                                    const error = !this.ValidateForm(this.props.clientAreaData);
                                                    this.reloadForm();
                                                    if (error) return;
                                                    this.props.quickAddArea(this.props.clientAreaData);
                                                    this.props.callBackHandler();
                                                }}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment >
    }
}

const mapStateToProps = (state: IAppState) => ({
    clientAreaData: state.clientArea.areaData,
    dataLoading: state.clientArea.dataLoading,
    createEditLoading: state.clientArea.createEditLoading
})
export default connect(mapStateToProps, {
    quickAddArea,
    clearClientAreaDataState: clearAreaDataState, clearAreaListState, updateClientAreaDataState
})(ClientAreaCreateEditComponent);
