import { IFieldValidationModel, IValidatorModel } from "../../../../../../common/model/validator.model";
import { ValidationScreens, ValidationType} from "../../../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "filterOrGrillId",
        rules: [
            {
                type: ValidationType.Required,
                message: "FilterOrGrillId is required"
            }
        ]
    },
]

export const airChangesPerHourTestObservationValidator: IValidatorModel = {
    screen: ValidationScreens.CleanroomAreaAirChangesPerHourTestObservation,
    fields: fields
}
