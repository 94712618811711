import ApiUrls from "../../../../common/api-urls";
import HttpService from "../../../../common/service/http.service";
import { IACPHTestObservationPayload, IAirChangesPerHourTestObservationModel } from "../../model/cleanroom-area/test/air-changes-per-hour-test.model";
import { ICleanroomAreaAFPTestObservationPayload, ICleanroomAreaAirflowPatternTestObservationModel } from "../../model/cleanroom-area/test/airflow-pattern-test.model";
import { ICleanroomAreaDFTestRoomObservationPayload, ICleanroomAreaDifferentialPressureTestRoomObservationModel } from "../../model/cleanroom-area/test/differential-pressure-test.model";
import { ICleanroomAreaFilterIntegrityTestObservationModel, ICleanroomAreaFITestObservationPayload, } from "../../model/cleanroom-area/test/filter-integrity-test.model";
import { ICleanroomAreaLightIntensityTestObservationModel, ICleanroomAreaLITestObservationPayload } from "../../model/cleanroom-area/test/light-intensity-test.model";
import { ICleanroomAreaNonViableParticleCountTestLocationModel, ICleanroomAreaNVPCTestLocationPayload } from "../../model/cleanroom-area/test/non-viable-particle-count-test.model";
import { ICleanroomAreaRecoveryReading, ICleanroomAreaRecoveryReadingPayload } from "../../model/cleanroom-area/test/recovery-test.model";
import { ICleanroomAreaSLTestObservationPayload, ICleanroomAreaSoundLevelTestObservationModel } from "../../model/cleanroom-area/test/sound-level-test-model";
import { ICleanroomAreaUVLightIntensityTestObservationModel, ICleanroomAreaUVLITestObservationPayload } from "../../model/cleanroom-area/test/uv-light-intensity-test.model";
import { IBaseTestPayload } from "../../model/cleanroom-equipment/test/test.model";

export default class CleanroomAreaObservationService {
    static async CreateACPHObservation(payload: IACPHTestObservationPayload): Promise<IAirChangesPerHourTestObservationModel> {
        return await HttpService.Post<IAirChangesPerHourTestObservationModel | null, IAirChangesPerHourTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Create(payload.testName), payload.data ?? null);
    }

    static async EditACPHObservation(payload: IACPHTestObservationPayload): Promise<IAirChangesPerHourTestObservationModel> {
        return await HttpService.Patch<IAirChangesPerHourTestObservationModel | null, IAirChangesPerHourTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetACPHObservationById(payload: IACPHTestObservationPayload): Promise<IAirChangesPerHourTestObservationModel> {
        return await HttpService.Get<IAirChangesPerHourTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateAFPTObservation(payload: ICleanroomAreaAFPTestObservationPayload): Promise<ICleanroomAreaAirflowPatternTestObservationModel> {
        return await HttpService.Post<ICleanroomAreaAirflowPatternTestObservationModel | null, ICleanroomAreaAirflowPatternTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Create(payload.testName), payload.data ?? null);
    }

    static async EditAFPTObservation(payload: ICleanroomAreaAFPTestObservationPayload): Promise<ICleanroomAreaAirflowPatternTestObservationModel> {
        return await HttpService.Patch<ICleanroomAreaAirflowPatternTestObservationModel | null, ICleanroomAreaAirflowPatternTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetAFPTObservationById(payload: ICleanroomAreaAFPTestObservationPayload): Promise<ICleanroomAreaAirflowPatternTestObservationModel> {
        return await HttpService.Get<ICleanroomAreaAirflowPatternTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.GetById(payload.testName, payload.id ?? 0));
    }

    //--- common ---
    static async DeleteObservation(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.CleanroomAreaObservation.Delete(payload.testName, payload.id ?? 0));
    }

    // --- Filter Integrity ---
    static async CreateFIObservation(payload: ICleanroomAreaFITestObservationPayload): Promise<ICleanroomAreaFilterIntegrityTestObservationModel> {
        return await HttpService.Post<ICleanroomAreaFilterIntegrityTestObservationModel | null, ICleanroomAreaFilterIntegrityTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Create(payload.testName), payload.data ?? null);
    }

    static async EditFIObservation(payload: ICleanroomAreaFITestObservationPayload): Promise<ICleanroomAreaFilterIntegrityTestObservationModel> {
        return await HttpService.Patch<ICleanroomAreaFilterIntegrityTestObservationModel | null, ICleanroomAreaFilterIntegrityTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetFIObservationById(payload: ICleanroomAreaFITestObservationPayload): Promise<ICleanroomAreaFilterIntegrityTestObservationModel> {
        return await HttpService.Get<ICleanroomAreaFilterIntegrityTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.GetById(payload.testName, payload.id ?? 0));
    }

    // == NonViable Particle Count ==
    static async CreateNVPCLocation(payload: ICleanroomAreaNVPCTestLocationPayload): Promise<ICleanroomAreaNonViableParticleCountTestLocationModel> {
        return await HttpService.Post<ICleanroomAreaNonViableParticleCountTestLocationModel | null, ICleanroomAreaNonViableParticleCountTestLocationModel>(ApiUrls.Report.CleanroomAreaLocation.Create(payload.testName), payload.data ?? null);
    }

    static async EditNVPCLocation(payload: ICleanroomAreaNVPCTestLocationPayload): Promise<ICleanroomAreaNonViableParticleCountTestLocationModel> {
        return await HttpService.Patch<ICleanroomAreaNonViableParticleCountTestLocationModel | null, ICleanroomAreaNonViableParticleCountTestLocationModel>(ApiUrls.Report.CleanroomAreaLocation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetNVPCLocationById(payload: ICleanroomAreaNVPCTestLocationPayload): Promise<ICleanroomAreaNonViableParticleCountTestLocationModel> {
        return await HttpService.Get<ICleanroomAreaNonViableParticleCountTestLocationModel>(ApiUrls.Report.CleanroomAreaLocation.GetById(payload.testName, payload.id ?? 0));
    }

    static async DeleteNVPCLocation(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.CleanroomAreaLocation.Delete(payload.testName, payload.id ?? 0));
    }

    static async CreateRecoveryReading(payload: ICleanroomAreaRecoveryReadingPayload): Promise<ICleanroomAreaRecoveryReading> {
        return await HttpService.Post<ICleanroomAreaRecoveryReading | null, ICleanroomAreaRecoveryReading>(ApiUrls.Report.CleanroomAreaRecoveryReading.Create(payload.testName), payload.data ?? null);
    }

    static async EditRecoveryReading(payload: ICleanroomAreaRecoveryReadingPayload): Promise<ICleanroomAreaRecoveryReading> {
        return await HttpService.Patch<ICleanroomAreaRecoveryReading | null, ICleanroomAreaRecoveryReading>(ApiUrls.Report.CleanroomAreaRecoveryReading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetRecoveryReadingById(payload: ICleanroomAreaRecoveryReadingPayload): Promise<ICleanroomAreaRecoveryReading> {
        return await HttpService.Get<ICleanroomAreaRecoveryReading>(ApiUrls.Report.CleanroomAreaRecoveryReading.GetById(payload.testName, payload.id ?? 0));
    }

    static async DeleteRecoveryReading(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.CleanroomAreaRecoveryReading.Delete(payload.testName, payload.id ?? 0));
    }

    static async CreateSLObservation(payload: ICleanroomAreaSLTestObservationPayload): Promise<ICleanroomAreaSoundLevelTestObservationModel> {
        return await HttpService.Post<ICleanroomAreaSoundLevelTestObservationModel | null, ICleanroomAreaSoundLevelTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Create(payload.testName), payload.data ?? null);
    }

    static async EditSLObservation(payload: ICleanroomAreaSLTestObservationPayload): Promise<ICleanroomAreaSoundLevelTestObservationModel> {
        return await HttpService.Patch<ICleanroomAreaSoundLevelTestObservationModel | null, ICleanroomAreaSoundLevelTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetSLObservationById(payload: ICleanroomAreaSLTestObservationPayload): Promise<ICleanroomAreaSoundLevelTestObservationModel> {
        return await HttpService.Get<ICleanroomAreaSoundLevelTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateLIObservation(payload: ICleanroomAreaLITestObservationPayload): Promise<ICleanroomAreaLightIntensityTestObservationModel> {
        return await HttpService.Post<ICleanroomAreaLightIntensityTestObservationModel| null,ICleanroomAreaLightIntensityTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Create(payload.testName), payload.data ?? null);
    }

    static async EditLIObservation(payload: ICleanroomAreaLITestObservationPayload): Promise<ICleanroomAreaLightIntensityTestObservationModel> {
        return await HttpService.Patch<ICleanroomAreaLightIntensityTestObservationModel| null,ICleanroomAreaLightIntensityTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetLIObservationById(payload: ICleanroomAreaLITestObservationPayload): Promise<ICleanroomAreaLightIntensityTestObservationModel> {
        return await HttpService.Get<ICleanroomAreaLightIntensityTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateUVLIObservation(payload: ICleanroomAreaUVLITestObservationPayload): Promise<ICleanroomAreaUVLightIntensityTestObservationModel> {
        return await HttpService.Post<ICleanroomAreaUVLightIntensityTestObservationModel| null,ICleanroomAreaUVLightIntensityTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Create(payload.testName), payload.data ?? null);
    }

    static async EditUVLIObservation(payload: ICleanroomAreaUVLITestObservationPayload): Promise<ICleanroomAreaUVLightIntensityTestObservationModel> {
        return await HttpService.Patch<ICleanroomAreaUVLightIntensityTestObservationModel| null,ICleanroomAreaUVLightIntensityTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetUVLIObservationById(payload: ICleanroomAreaUVLITestObservationPayload): Promise<ICleanroomAreaUVLightIntensityTestObservationModel> {
        return await HttpService.Get<ICleanroomAreaUVLightIntensityTestObservationModel>(ApiUrls.Report.CleanroomAreaObservation.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateDFObservation(payload: ICleanroomAreaDFTestRoomObservationPayload): Promise<ICleanroomAreaDifferentialPressureTestRoomObservationModel> {
        return await HttpService.Post<ICleanroomAreaDifferentialPressureTestRoomObservationModel| null,ICleanroomAreaDifferentialPressureTestRoomObservationModel>(ApiUrls.Report.CleanroomAreaDifferentialPressureRoomObservation.Create(payload.testName), payload.data ?? null);
    }

    static async EditDFObservation(payload: ICleanroomAreaDFTestRoomObservationPayload): Promise<ICleanroomAreaDifferentialPressureTestRoomObservationModel> {
        return await HttpService.Patch<ICleanroomAreaDifferentialPressureTestRoomObservationModel| null,ICleanroomAreaDifferentialPressureTestRoomObservationModel>(ApiUrls.Report.CleanroomAreaDifferentialPressureRoomObservation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetDFObservationById(payload:ICleanroomAreaDFTestRoomObservationPayload): Promise<ICleanroomAreaDifferentialPressureTestRoomObservationModel> {
        return await HttpService.Get<ICleanroomAreaDifferentialPressureTestRoomObservationModel>(ApiUrls.Report.CleanroomAreaDifferentialPressureRoomObservation.GetById(payload.testName, payload.id ?? 0));
    }

    static async DeleteDFObservation(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.CleanroomAreaDifferentialPressureRoomObservation.Delete(payload.testName, payload.id ?? 0));
    }

}
