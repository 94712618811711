import { CleanroomAreaTests, ZoneClassificationType, } from "../../../../../common/enums";
import { ICleanroomAreaModel } from "../../../model/report.model";
import CleanroomAreaTestService from "../../../service/cleanroom-area/test.service";
import { IAirChangesPerHourTestObservationModel } from "../../../model/cleanroom-area/test/air-changes-per-hour-test.model";
import { editACPHTest, editCleanroomAreaLITest, editCleanroomAreaSLTest, editCleanroomAreaUVLITest, editNVPCTest } from "../../../store/cleanroom-area/cleanroom-area-test.slice";

import {
    styles,
    useDispatch,
    moment,
    React, useEffect, useState, PDFViewer,
    Document, Page, Text, View, pdf, saveAs, IUserModel,
    displayDate, localToUtcDate, PDFHeaderComponent,
    PDFFooterComponent,
    PDFClientDetailComponent,
    PDFRemarkComponent
} from "../PDF/base-pdf-components";
import { ICleanroomAreaSoundLevelTestObservationModel } from "../../../model/cleanroom-area/test/sound-level-test-model";
import { ICleanroomAreaLightIntensityTestObservationModel } from "../../../model/cleanroom-area/test/light-intensity-test.model";
import { ICleanroomAreaUVLightIntensityTestObservationModel } from "../../../model/cleanroom-area/test/uv-light-intensity-test.model";

export const BaseTest = ({ data, title, maxReadings, testName, callBack }: { title: string, testName: string, data: ICleanroomAreaModel | any, maxReadings?: number, callBack: any }) => {

    return (
        <Document>
            <Page size="A4" style={styles.page} wrap={false}>
                {/* ---- header ------ */}
                <PDFHeaderComponent />
                {/* ---- body ---- */}
                <View style={{ ...styles.section }}>
                    <View style={{ ...styles.main }}>
                        <PDFClientDetailComponent data={data} title={title} />

                        {/* ----  Area details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <Text style={{ ...styles.font_12, ...styles.bold }}>Details of Area:</Text>
                        </View>

                        <View style={{}}>
                            {/* --- fixed allignment --- */}
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>AHU Name
                                        </Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>
                                            {data?.clientArea?.ahuOrVAUName ?? ""}
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Area Of Test</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.clientArea?.areaOfTheTest ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>AHU Id</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>
                                            {data.clientArea?.ahuOrVAUId ?? ""}
                                        </Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Location</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data.clientArea?.location ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Area Classification</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>
                                            {data?.zoneClassificationType == ZoneClassificationType.ClientSpecification ? "Client Specification" :
                                                data?.zoneClassificationType == ZoneClassificationType.EUGMP ?
                                                    `Grade ${data.eugmpStandard?.grade}` :
                                                    data?.zoneClassificationType == ZoneClassificationType.ISO ? `ISO ${data?.isoStandard?.classNumber}` : ""
                                            }
                                        </Text>
                                    </View>
                                </View>
                                {
                                    testName != CleanroomAreaTests.soundlevel && testName != CleanroomAreaTests.lightintensity && testName != CleanroomAreaTests.uvlightintensity && testName != CleanroomAreaTests.nonviableparticlecount ?
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                                <View style={{ ...styles.label, ...styles.col }}>
                                                    <Text style={{}}>Acceptance Criteria
                                                    </Text>
                                                </View>
                                                <View style={{ ...styles.flex_1, ...styles.col }}>
                                                    <Text style={{}}>NLT {data?.acceptanceCriteria ?? ""}</Text>
                                                </View>
                                            </View>
                                        </View> : ""
                                }

                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.col }}>
                                        <Text style={{}}>Room Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.col }}>
                                        <Text style={{}}>{data?.roomName ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Room Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.roomNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>
                        </View>

                        {/* ---- data logger details --- */}
                        <View style={{ ...styles.paddingTB }}>
                            <View style={{}}>
                                <Text style={{ ...styles.font_12, ...styles.bold }}>Details Of Standard :</Text>
                            </View>

                            <View style={{ ...styles.paddingTB }}>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.label, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>Name</Text>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox, ...styles.col }}>
                                        <Text style={{}}>{data?.dataLogger?.name ?? ""}</Text>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Model Number</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.modelNumber ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Make</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>{data.dataLogger?.make?.name ?? ""}</Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Instrument Id</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.instrumentId ?? ""}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Serial Number</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{data?.dataLogger?.serialNumber ?? ""}</Text>
                                        </View>
                                    </View>
                                </View>
                                <View style={{ ...styles.flexBox }}>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.label, ...styles.col }}>
                                            <Text style={{}}>Calibration Done Date</Text>
                                        </View>
                                        <View style={{ ...styles.flex_1, ...styles.col }}>
                                            <Text style={{}}>{displayDate(data.dataLogger?.lastCalibratedOn)}</Text>
                                        </View>
                                    </View>
                                    <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                        <View style={{ ...styles.flex_1, ...styles.flexBox }}>
                                            <View style={{ ...styles.label, ...styles.col }}>
                                                <Text style={{}}>Calibration Due Date</Text>
                                            </View>
                                            <View style={{ ...styles.flex_1, ...styles.col }}>
                                                <Text style={{}}>
                                                    {displayDate(data.dataLogger?.calibrationDueOn)}
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Name</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, textAlign: "center" }}>

                                    <Text style={{ ...styles.bold }}>Protocol Number</Text>
                                </View>
                            </View>
                            <View style={{ ...styles.flexBox }}>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>{data?.refStandardOrProtocolOrDocumentName ?? ""}</Text>
                                </View>
                                <View style={{ ...styles.flex_1, ...styles.col, ...styles.flex_center }}>
                                    <Text style={{}}>{data?.procedureOrProtocolOrDocumentNumber ?? ""}</Text>
                                </View>
                            </View>
                        </View>

                        {
                            callBack(styles, data, maxReadings)
                        }

                        <PDFRemarkComponent data={data} />
                    </View>
                </View>

                {/* --- footer ---- */}
                <PDFFooterComponent />
            </Page >
        </Document >
    )
}

export interface IProps {
    data: ICleanroomAreaModel,
    id: number,
    currentUser: IUserModel,
    testName: string,
    title: string,
    callBack: any,
}
const BasePDFComponent = ({ id, data, currentUser, testName, title, callBack }: IProps) => {
    const [loading, setLoading] = useState(false);

    const dispatch: any = useDispatch();

    const downloadPdf = async () => {
        let res: any;
        let arr: any;
        setLoading(true);
        try {
            if (id && testName == CleanroomAreaTests.airchangesperhour) {
                res = await CleanroomAreaTestService.GetACPHById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: IAirChangesPerHourTestObservationModel, item2: IAirChangesPerHourTestObservationModel) => item2.readings?.length - item1?.readings?.length);
                    const fileName = `air-changes-per-hour.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.readings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
            else if (id && testName == CleanroomAreaTests.nonviableparticlecount) {
                res = await CleanroomAreaTestService.GetNVPCById({ testName, id });
                arr = res.locations ? [...res.locations] : [];
                const fileName = `non-viable-particle-count-test.pdf`;
                const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.roomReadings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                saveAs(blob, fileName);
            } else if (id && testName == CleanroomAreaTests.soundlevel) {
                res = await CleanroomAreaTestService.GetSLTestById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: ICleanroomAreaSoundLevelTestObservationModel, item2: ICleanroomAreaSoundLevelTestObservationModel) => item2.readings?.length - item1.readings.length);
                    const fileName = `sound-level-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.readings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
            else if (id && testName == CleanroomAreaTests.lightintensity) {
                res = await CleanroomAreaTestService.GetLITestById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: ICleanroomAreaLightIntensityTestObservationModel, item2: ICleanroomAreaLightIntensityTestObservationModel) => item2.readings?.length - item1.readings.length);
                    const fileName = `light-intensity-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.readings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
            else if (id && testName == CleanroomAreaTests.uvlightintensity) {
                res = await CleanroomAreaTestService.GetUVLITestById({ testName, id });
                if (res?.id) {
                    arr = res.observations ? [...res.observations] : [];
                    arr?.sort((item1: ICleanroomAreaUVLightIntensityTestObservationModel, item2: ICleanroomAreaUVLightIntensityTestObservationModel) => item2.readings?.length - item1.readings.length);
                    const fileName = `uv-light-intensity-test.pdf`;
                    const blob = await pdf(<BaseTest testName={testName} title={title} maxReadings={arr?.[0]?.readings?.length ?? 0} data={{ ...data, ...res }} callBack={callBack} />).toBlob();
                    saveAs(blob, fileName);
                }
            }
        } catch (errr) {

        } finally {
            setLoading(false);
            let date = moment(new Date()).toString();
            let obj = {
                testName: testName, data:
                {
                    ...res,
                    editLocked: true,
                    reportPdfGeneratedById: currentUser?.id,
                    reportPdfGeneratedOn: localToUtcDate(date),
                },
            };

            if (testName == CleanroomAreaTests.airchangesperhour) {
                dispatch(editACPHTest(obj));
            }
            else if (testName == CleanroomAreaTests.nonviableparticlecount) {
                dispatch(editNVPCTest(obj));
            } else if (testName == CleanroomAreaTests.soundlevel) {
                dispatch(editCleanroomAreaSLTest(obj))
            }
            else if (testName == CleanroomAreaTests.lightintensity) {
                dispatch(editCleanroomAreaLITest(obj))
            }
            else if (testName == CleanroomAreaTests.uvlightintensity) {
                dispatch(editCleanroomAreaUVLITest(obj))
            }
        }
    };

    useEffect(() => {
        setLoading(false);
    }, [data]);

    return (<React.Fragment>
        {/* <PDFViewer width={'100%'}>
            <BaseTest
                data={data}
                maxReadings={10}
                testName={testName}
                title={title}
                callBack={callBack} />
        </PDFViewer> */}
        <button className="btn m-0 btn-primary px-3 py-2" onClick={downloadPdf}>{!loading ? "Download PDF" : "Loading"}</button>
    </React.Fragment>)
}

export default BasePDFComponent;





