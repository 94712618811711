import { IFieldValidationModel, IValidatorModel } from "../../../../common/model/validator.model";
import { ValidationScreens, ValidationType, ZoneClassificationType } from "../../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "reportNumber",
        rules: [
            {
                type: ValidationType.Required,
                message: "Report number is required"
            }
        ]
    },
    {
        fieldName: "clientId",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Client is required",
            },
        ]
    },
    {
        fieldName: "clientAddressId",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Client address is required",
            },
        ]
    },
    {
        fieldName: "clientAreaId",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Area is required",
            }
        ]
    },
    {
        fieldName: "eugmpStandardId",
        dependencyFieldName: "zoneClassificationType",
        callBack: (value, dependancyFieldValue) => {
            return dependancyFieldValue == ZoneClassificationType.EUGMP;
        },
        rules: [
            {
                type: ValidationType.ValidateIfCallbackIsTrue,
                message: "Grade is required",
            },
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Grade is required",
            },
        ]
    },
    {
        fieldName: "isoStandardId",
        dependencyFieldName: "zoneClassificationType",
        callBack: (value, dependancyFieldValue) => {
            return dependancyFieldValue == ZoneClassificationType.ISO;
        },
        rules: [
            {
                type: ValidationType.ValidateIfCallbackIsTrue,
                message: "Classname is required",
            },
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Classname is required",
            },
        ]
    },
]

export const cleanroomAreaValidator: IValidatorModel = {
    screen: ValidationScreens.ReportCleanroomArea,
    fields: fields
}
