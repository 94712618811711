import { FormBaseComponent, IFormBaseState } from "../../../../../../../common/component/form-base.component";
import React from "react";
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import { AgGridDefaultColDef } from "../../../../../../../common/app-defaults";
import { AgGridReact } from "ag-grid-react";
import { IBaseTestPayload } from "../../../../../model/cleanroom-equipment/test/test.model";
import { IUserModel } from "../../../../../../user/model/user.model";
import ScreenUrls from "../../../../../../../common/screen-urls";
import DataLoggerService from "../../../../../../data-logger/service/data-logger.service";
import DataLoggerSelectorAccordionComponent from "../../../../../../workflow-task/component/data-logger-selector.component";
import { Link } from "react-router-dom";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { clearTestDataState, updateTestDataState, } from "../../../../../store/cleanroom-area/cleanroom-area-test.slice";
import DatePicker from "react-datepicker";
import ReactSelect from "react-select";
import { AirChangesPerHourTestInstrumentList, AirChangesPerHourTestObservationlabel } from "../../../../../../../common/_static/report.data";
import { deleteObservation } from "../../../../../store/cleanroom-area/cleanroom-area-observation.slice";
import DropdownDisplayHelper from "../../../../../../../common/helper/dropdown-display.helper";
import { AppConstants } from "../../../../../../../common/app-constants";
import { utcToLocalDate, localToUtcDate } from "../../../../../../../common/utils";
import { IFormControlDropdownItemModel } from "../../../../../../../common/control/model/form.control.field.model";
import AsyncSelect from "react-select/async";
import { DeferredLoadOptions } from "../../../../../../../common/utils";
import UserService from "../../../../../../user/service/user.service";
import { loadCleanroomAreaById, } from "../../../../../store/cleanroom-equipment/cleanroom-equipment.slice";
import { ICleanroomAreaModel } from "../../../../../model/report.model";
import TestObservationComponent from "./test-observation";
import { AccessLevel, CleanroomAreaTests, TestObservationResult, ValidationScreens } from "../../../../../../../common/enums";
import { IACPHTestPayload, IAirChangesPerHourTestModel, IAirChangesPerHourTestObservationModel } from "../../../../../model/cleanroom-area/test/air-changes-per-hour-test.model";
import { createACPHTest, editACPHTest, loadACPHTestById } from "../../../../../store/cleanroom-area/cleanroom-area-test.slice";
import { airChangesPerHourTestDefault, } from "../../../../../model/cleanroom-area/defaults/test.default";
import AirChangesPerHourPDFComponent from "../../../../../component/cleanroom/cleanroom-area-pdf/air-changes-per-hour-test-pdf";
import TestEditEnableComponent from "../../../../../component/cleanroom/test-edit-enable-component";
export interface IProps {
    id: number,
    testId?: number,
    accessLevel: number,
    testData: IAirChangesPerHourTestModel,
    equipmentData: ICleanroomAreaModel,
    dataLoading: boolean,
    createTest: (data: IACPHTestPayload) => void,
    editTest: (data: IACPHTestPayload) => void,
    loadTestById: (data: IACPHTestPayload) => void,
    createEditLoading: boolean,
    currentUser: IUserModel,
    clearTestDataState: (payload) => void,
    updateTestDataState: (payload) => void,
    loadCleanroomAreaById: (id: number) => void,
    refetchApi: boolean,
    deleteObservation: (data: IBaseTestPayload) => void,
}

export interface IState extends IFormBaseState {
    openObservationPopup: boolean,
    hideZoneClassification: boolean,
    editObservation: null | IAirChangesPerHourTestObservationModel,
}

class TestManager extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false,
            openObservationPopup: false,
            editObservation: null,
            hideZoneClassification: false,
        };
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.CleanroomAreaAirChangesPerHourTest);
        if (this.props.id) {
            await this.props.loadCleanroomAreaById(Number(this.props.id));
        }
        if (this.props.testId) {
            await this.props.loadTestById({ testName: CleanroomAreaTests.airchangesperhour ?? "", id: Number(this.props.testId) });
        } else {
            await this.props.clearTestDataState(airChangesPerHourTestDefault);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.testData != prevProps.testData) {
            if (this.state.editObservation?.id) {
                this.setState({ ...this.state, editObservation: this.props.testData?.observations?.find((item) => item.id == this.state.editObservation?.id) ?? null });
            }
            // --- when adding new observation ----
            else if (this.state.editObservation == null && this.state.openObservationPopup && this.props.testData?.observations?.length) {
                this.setState({ ...this.state, editObservation: this.props.testData?.observations[this.props.testData?.observations?.length - 1] ?? null });
            }
        }
        if (!prevProps.refetchApi && this.props.refetchApi && this.props.testData?.id) {
            this.props.loadTestById({ testName: CleanroomAreaTests.airchangesperhour ?? "", id: Number(this.props.testData?.id) });
        };
    }

    loadUserOption = (inputValue: string) => DeferredLoadOptions(inputValue, UserService.UserDropdown)

    loadDataLoggers = (inputValue: string) => DeferredLoadOptions(inputValue, (search: string) =>
        new Promise<IFormControlDropdownItemModel[]>(async (resolve) => {
            const dataLoggers = await DataLoggerService.LoadAll();

            if (dataLoggers == null || !Array.isArray(dataLoggers?.data)) {
                resolve([]); return;
            }

            const list = dataLoggers?.data?.map(d => {
                const obj: IFormControlDropdownItemModel = {
                    label: DropdownDisplayHelper.DataLogger(d),
                    value: d.id.toString()
                }
                return obj;
            });

            if (search == null || search === "") resolve(list);
            resolve(list.filter(l => l.label.indexOf(search) > 0))
        }));

    render() {
        if (this.state.hideForm) return;
        return (
            <React.Fragment>
                {(() => {
                    if (this.props.dataLoading || this.props.createEditLoading)
                        return (
                            <div className="loading--bar fixed--top">
                                <span></span>
                            </div>
                        );
                })()}
                <div className="pcoded-content">
                    <div className="pcoded-inner-content">
                        <div className="page-header">
                            <div className="page-block">
                                <div className="row">
                                    <div className="col-md-12 d-flex align-items-center justify-content-between">
                                        <Link
                                            to={ScreenUrls.Report.CleanroomArea.Edit(this.props.id)}
                                            className="btn drp-icon btn-rounded btn-primary dropdown-toggle"
                                        >
                                            <i className="feather icon-arrow-left"></i>
                                        </Link>
                                        <div className="col-auto">
                                            {
                                                this.props?.testData?.id ?

                                                    <div className="d-flex">
                                                        {
                                                            this.props?.testData && this.props.testData.id ?
                                                                <AirChangesPerHourPDFComponent id={this.props.testData?.id ?? 0}
                                                                    currentUser={this.props.currentUser}
                                                                    data={{ ...this.props.equipmentData, ...this.props.testData }} />
                                                                : null
                                                        }

                                                        {
                                                            this.props.testData.editLocked ?
                                                                <TestEditEnableComponent
                                                                    category="cleanroomArea"
                                                                    testName={CleanroomAreaTests.airchangesperhour}
                                                                    reportCategory={this.props.equipmentData?.reportCategoryId ?? 0}
                                                                    testId={this.props.testData?.id}
                                                                    reportId={this.props.testData?.cleanroomAreaReportId}
                                                                /> : ""
                                                        }
                                                    </div>
                                                    : null
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`main-body ${this.props?.testData?.editLocked ? 'disabledSection' : ""}`}>
                            <div className="page-wrapper">
                                <div className="row">
                                    <div className="col-sm-12">
                                        <article className="card mb-4">
                                            <div className="card-header card-form-header">
                                                <div className="card-form-header-title">
                                                    Air Changes Per Hour Test Details
                                                </div>
                                            </div>
                                            <div className="card-block">
                                                <div className="row">
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("dateOfTest").className}`}>
                                                            <label htmlFor="dateOfTest">Date of Test<span className="requried-span">*</span></label>
                                                            <DatePicker key="dateOfTest"
                                                                selected={utcToLocalDate(this.props.testData?.dateOfTest ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.ValidateField("dateOfTest", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "dateOfTest", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("dateOfTest").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("dueDate").className}`}>
                                                            <label htmlFor="dueDate">Due Date</label>
                                                            <DatePicker key="dueDate"
                                                                selected={utcToLocalDate(this.props?.testData?.dueDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.props.updateTestDataState({ name: "dueDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("preparedById").className}`}>
                                                            <label htmlFor="preparedById">Prepared By<span className="requried-span">*</span></label>
                                                            <AsyncSelect id="preparedById" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadUserOption}
                                                                value={this.props.testData.preparedBy
                                                                    ? { label: DropdownDisplayHelper.User(this.props.testData?.preparedBy), value: this.props.testData?.preparedBy?.id?.toString() }
                                                                    : { label: "Select User", value: "" }}
                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    this.ValidateField("preparedById", cId);
                                                                    if (cId === null || cId === 0 || (this.props.testData.preparedBy != null && cId === this.props.testData.preparedBy.id)) return;

                                                                    const client = await UserService.GetById(cId);

                                                                    this.props.updateTestDataState({ name: "preparedBy", value: client });
                                                                    this.props.updateTestDataState({ name: "preparedById", value: client.id });

                                                                    //this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("preparedById").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("preparedOnDate").className}`}>
                                                            <label htmlFor="preparedOnDate">Prepared On Date<span className="requried-span">*</span></label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData.preparedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.ValidateField("preparedOnDate", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "preparedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("preparedOnDate").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("checkedById").className}`}>
                                                            <label htmlFor="checkedById">Checked By</label>
                                                            <AsyncSelect id="checkedById" cacheOptions defaultOptions
                                                                className="async-select-control"
                                                                loadOptions={this.loadUserOption}
                                                                value={this.props.testData.checkedBy
                                                                    ? { label: DropdownDisplayHelper.User(this.props.testData.checkedBy), value: this.props.testData.checkedBy.id.toString() }
                                                                    : { label: "Select User", value: "" }}
                                                                onChange={async (e) => {
                                                                    const cId = Number(e?.value);
                                                                    this.ValidateField("checkedById", cId);
                                                                    if (cId === null || cId === 0 || (this.props.testData.checkedBy != null && cId === this.props.testData.checkedBy.id)) return;

                                                                    const client = await UserService.GetById(cId);

                                                                    this.props.updateTestDataState({ name: "checkedBy", value: client });
                                                                    this.props.updateTestDataState({ name: "checkedById", value: client.id });

                                                                    //this.props.updateHasError(this.hasError);
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("checkedById").message} />

                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Checked On Date</label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData.checkedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    this.props.updateTestDataState({ name: "checkedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("name").className}`}>
                                                            <label htmlFor="name">Verified By</label>
                                                            <input id="name" className="form-control" type="text"
                                                                value={this.props.testData?.verifiedByName ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "verifiedByName", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />

                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group d-flex flex-column ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Verified On Date</label>
                                                            <DatePicker key="poDate"
                                                                selected={utcToLocalDate(this.props.testData.verifiedOnDate ?? "")}
                                                                dateFormat={AppConstants.DatePickerFormat}
                                                                onChange={(d) => {
                                                                    // this.ValidateField("poDate", localToUtcDate(d));
                                                                    this.props.updateTestDataState({ name: "verifiedOnDate", value: localToUtcDate(d) });
                                                                    // this.props.updateHasError(this.hasError);
                                                                }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("acceptanceCriteria").className}`}>
                                                            <label htmlFor="acceptanceCriteria">Acceptance Criteria <span title={"Not Less Than"}>(NLT)</span><span className="requried-span">*</span></label>
                                                            <input id="acceptanceCriteria" className="form-control" type="number"
                                                                value={this.props.testData?.acceptanceCriteria ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("acceptanceCriteria", +e.target.value);
                                                                    this.props.updateTestDataState({ name: "acceptanceCriteria", value: e?.target?.value });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("acceptanceCriteria").message} />
                                                        </div>
                                                    </div>

                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("instrumentUsedToPerformObservation").className}`}>
                                                            <label htmlFor="instrumentUsedToPerformObservation">Instrument Used for Observation<span className="requried-span">*</span></label>
                                                            <ReactSelect id="instrumentUsedToPerformObservation"
                                                                className="async-select-control"
                                                                options={AirChangesPerHourTestInstrumentList}
                                                                value={this.props.testData?.instrumentUsedToPerformObservation
                                                                    ? AirChangesPerHourTestInstrumentList.find((item) => item.value == this.props.testData?.instrumentUsedToPerformObservation)
                                                                    : { label: "Select Instrument", value: "" }}

                                                                onChange={async (e) => {
                                                                    const cId = e?.value;
                                                                    if (!cId) return;
                                                                    this.ValidateField("instrumentUsedToPerformObservation", cId);
                                                                    this.props.updateTestDataState({ name: "instrumentUsedToPerformObservation", value: cId });
                                                                }}
                                                            />
                                                            <ValidationMessageControl message={this.vResult("instrumentUsedToPerformObservation").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("observationLabelName").className}`}>
                                                            <label htmlFor="standard">Observation Label Name<span className="requried-span">*</span></label>
                                                            <ReactSelect id="standard"
                                                                className="async-select-control"
                                                                options={AirChangesPerHourTestObservationlabel}
                                                                value={this.props.testData?.observationLabelName
                                                                    ? AirChangesPerHourTestObservationlabel.find((item) => item.value == this.props.testData?.observationLabelName)
                                                                    : { label: "Select Label", value: "" }}

                                                                onChange={async (e) => {
                                                                    const cId = e?.value;
                                                                    if (!cId) return;
                                                                    this.ValidateField("observationLabelName", cId);
                                                                    this.props.updateTestDataState({ name: "observationLabelName", value: cId });
                                                                }}
                                                            />
                                                            <ValidationMessageControl message={this.vResult("observationLabelName").message} />
                                                        </div>
                                                    </div>
                                                    <div className="col-3">
                                                        <div className={`form-group ${this.vResult("").className}`}>
                                                            <label htmlFor="">Room volume</label>
                                                            <input id="" className="form-control" type="number"
                                                                value={this.props.testData?.roomVolume ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.ValidateField("roomVolume", +e.target.value);
                                                                    this.props.updateTestDataState({ name: "roomVolume", value: e?.target?.value });
                                                                }} />
                                                            <ValidationMessageControl message={this.vResult("roomVolume").message} />
                                                        </div>
                                                    </div>
                                                    {(() => {
                                                        if (this.props.testData?.id) {
                                                            return (
                                                                <>
                                                                    <div className="col-3">
                                                                        <div className={`form-group ${this.vResult("").className}`}>
                                                                            <label htmlFor="">Air Changes Per Hour</label>
                                                                            <input id="" className="form-control not-allowed" type="number"
                                                                                value={this.props.testData?.airChangesPerHour ?? ""}
                                                                                disabled={true}
                                                                                onChange={e => {
                                                                                    e.preventDefault();
                                                                                    this.ValidateField("airChangesPerHour", +e.target.value);
                                                                                    this.props.updateTestDataState({ name: "airChangesPerHour", value: e?.target?.value });
                                                                                }} />
                                                                            <ValidationMessageControl message={this.vResult("airChangesPerHour").message} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className={`form-group`}>
                                                                            <label htmlFor="">Total AirFlow Rate</label>
                                                                            <input id="" className="form-control not-allowed" type="text" disabled={true}
                                                                                value={this.props.testData?.totalAirFlowRate ?? ""}
                                                                            />

                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className={`form-group`}>
                                                                            <label htmlFor="">Result</label>
                                                                            <input id="" className="form-control not-allowed" type="text" disabled={true}
                                                                                value={this.props.testData?.testObservationResult == TestObservationResult.Pass ? "Pass" :
                                                                                    this.props.testData?.testObservationResult == TestObservationResult.Fail ? "Fail" : ""}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )
                                                        }
                                                    })()
                                                    }

                                                    <div className="col-12 mb-3">
                                                        <div className={`form-group ${this.vResult("dataLoggerId").className}`}>
                                                            <ValidationMessageControl message={this.vResult("dataLoggerId").message} />
                                                            <DataLoggerSelectorAccordionComponent
                                                                defaultSelections={this.props.testData?.dataLogger ? [this.props.testData?.dataLogger] : null}
                                                                category={null}
                                                                onSelection={(d) => {
                                                                    this.ValidateField('dataLoggerId', d.id);
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: d });
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: d.id });
                                                                }}
                                                                onUnSelection={(d) => {
                                                                    this.ValidateField('dataLoggerId', 0);
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: 0 });
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: null });
                                                                }}
                                                                onUnSelectionAll={() => {
                                                                    this.props.updateTestDataState({ name: "dataLoggerId", value: 0 });
                                                                    this.props.updateTestDataState({ name: "dataLogger", value: null });
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className={`form-group ${this.vResult("equipmentName").className}`}>
                                                            <label htmlFor="equipmentName">Conclusion</label>
                                                            <textarea id="equipmentName"
                                                                className="form-control"
                                                                value={this.props.testData?.remarks ?? ""}
                                                                onChange={e => {
                                                                    e.preventDefault();
                                                                    this.props.updateTestDataState({ name: "remarks", value: e.target.value })
                                                                    // this.props.updateHasError(this.hasError);
                                                                }}>
                                                            </textarea>
                                                            {/* <ValidationMessageControl message={this.vResult("poNumber").message} /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    </div>
                                </div>
                            </div>
                            <article className="card no-bg">
                                <div className="row">
                                    <div className="col-12 text-right">

                                        <button type="button" className="btn mr-0 mb-0 btn-primary"
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                const error = !this.ValidateForm(this.props.testData, this.props.testData);
                                                this.reloadForm();
                                                if (error) return;
                                                if (this.props.testData?.id) {
                                                    await this.props.editTest({ testName: CleanroomAreaTests.airchangesperhour ?? "", data: this.props.testData });
                                                } else {
                                                    const { createdById, createdBy, preparedBy, checkedBy,

                                                        ...rest } = this.props.testData;
                                                    const payload = {
                                                        ...rest,
                                                        cleanroomAreaReportId: Number(this.props.id ?? 0),
                                                        clientId: this.props.equipmentData.clientId,
                                                        clientAddressId: this.props.equipmentData.clientAddressId,
                                                        clientEquipmentId: this.props.equipmentData.clientAreaId,
                                                    };

                                                    this.props.createTest({ testName: CleanroomAreaTests.airchangesperhour ?? "", data: payload });
                                                }
                                            }}>Submit</button>
                                    </div>
                                </div>
                            </article>

                            {
                                (() => {
                                    if (!this.props.testData?.id) return;
                                    return (
                                        <article className="card mb-4">
                                            <div className="card-header card-form-header d-flex w-100 align-items-center justify-content-between">
                                                <div className="card-form-header-title">
                                                    Test Observations
                                                </div>

                                                <button className="btn mr-0 mb-0 btn-primary small-btn"
                                                    onClick={() => this.setState({ ...this.state, openObservationPopup: !this.state.openObservationPopup, editObservation: null })}
                                                    disabled={this.props.createEditLoading}
                                                >
                                                    Add Observations
                                                </button>
                                            </div>
                                            <div className="card-body pt-0">
                                                <div className="row">
                                                    <div className="col-12 grid-wrapper">
                                                        <div className="card-block px-0 ag-theme-alpine">
                                                            <AgGridReact
                                                                pagination={true}
                                                                paginationPageSize={10}
                                                                unSortIcon={true}
                                                                rowHeight={60}
                                                                domLayout="autoHeight"
                                                                defaultColDef={AgGridDefaultColDef}
                                                                columnDefs={[
                                                                    {
                                                                        hide: this.props.accessLevel < AccessLevel.Edit,
                                                                        headerName: "Edit", field: 'id', width: 80, cellClass: "grid-cell grid-cell-link",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => {
                                                                            return (
                                                                                <span
                                                                                    onClick={() => {
                                                                                        this.setState({ ...this.state, editObservation: params.data, openObservationPopup: true })
                                                                                    }}
                                                                                >
                                                                                    <i className={`feather icon-edit f-16 text-green`}></i>
                                                                                </span>
                                                                            )
                                                                        }
                                                                    },
                                                                    {
                                                                        hide: this.props.accessLevel < AccessLevel.Delete,
                                                                        headerName: "Delete", field: 'id', width: 80, cellClass: "grid-cell grid-cell-link",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => {
                                                                            return (
                                                                                <span
                                                                                    onClick={async () => {
                                                                                        this.props.deleteObservation({ testName: CleanroomAreaTests.airchangesperhour, id: params.value });
                                                                                    }}
                                                                                >
                                                                                    <i className={`feather icon-trash ml-2 f-16 text-green`}></i>
                                                                                </span>
                                                                            )
                                                                        }
                                                                    },

                                                                    {
                                                                        headerName: "ID#", field: "id", width: 80, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    {
                                                                        headerName: `${this.props.testData?.observationLabelName} Id`, field: "filterOrGrillId", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },

                                                                    {
                                                                        headerName: "Air Flow Rate", field: "airFlowRate", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    },
                                                                    ...(this.props.testData?.instrumentUsedToPerformObservation == "Anemometer" ? [{
                                                                        headerName: `${this.props.testData?.observationLabelName} Area`, field: "filterOrGrillArea", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    }, {
                                                                        headerName: `Average Flow Rate`, field: "averageFlowRate", flex: 1, sortable: true, cellClass: "grid-cell",
                                                                        suppressMovable: true,
                                                                        cellRenderer: (params) => <span>{params.value}</span>
                                                                    }] : [])
                                                                ]}
                                                                rowData={this.props.testData?.observations ?? []}>
                                                            </AgGridReact>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </article>
                                    )
                                })()
                            }

                            {this.props.testData?.lastComment?.commentDescription ?
                                <div className="card card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <label htmlFor="equipmentName">Recent comment</label>
                                            <textarea
                                                className="form-control not-allowed"
                                                disabled
                                                value={this.props.testData?.lastComment?.commentDescription}
                                            >
                                            </textarea>
                                        </div>
                                    </div>
                                </div>
                                : ""
                            }

                            {
                                this.props.testData?.id && this.state.openObservationPopup ?
                                    <TestObservationComponent
                                        toggle={this.state.openObservationPopup}
                                        handleToggleState={() => this.setState({ ...this.state, openObservationPopup: false, editObservation: null })}
                                        testData={this.props.testData}
                                        testName={CleanroomAreaTests.airchangesperhour ?? ""}
                                        defaultData={this.state.editObservation}
                                    />
                                    : null
                            }
                        </div>
                    </div>
                </div >
            </React.Fragment >
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    testData: state.cleanroomAreaTest.testData,
    dataLoading: state.cleanroomAreaTest.dataLoading,
    createEditLoading: state.cleanroomAreaTest.createEditLoading,
    currentUser: state.userSession.currentUser,
    equipmentData: state.cleanroomequipment.equipmentData,
    refetchApi: state.cleanroomAreaTest.refetchApi,
});
export default connect(mapStateToProps, {
    clearTestDataState, updateTestDataState, editTest: editACPHTest, createTest: createACPHTest, loadTestById: loadACPHTestById, loadCleanroomAreaById, deleteObservation
})(TestManager);



