import { IFieldValidationModel, IValidatorModel } from "../../../common/model/validator.model";
import { ValidationScreens, ValidationType } from "../../../common/enums";

const fields: IFieldValidationModel[] = [
    {
        fieldName: "ahuOrVAUName",
        rules: [
            {
                type: ValidationType.Required,
                message: "AHU Or VAU Name name is required"
            }
        ]
    },
    {
        fieldName: "ahuOrVAUId",
        rules: [
            {
                type: ValidationType.Required,
                message: "AHU/VAU Id is required",
            },
        ]
    },
    {
        fieldName: "areaOfTheTest",
        rules: [
            {
                type: ValidationType.Required,
                message: "Area Of The Test is required",
            },
        ]
    },
    {
        fieldName: "location",
        rules: [
            {
                type: ValidationType.Required,
                message: "Location is required",
            },
        ]
    },
    {
        fieldName: "clientId",
        rules: [
            {
                type: ValidationType.NumberGreaterThanZero,
                message: "Client is required",
            },
        ]
    },
]

export const clientAreaValidator: IValidatorModel = {
    screen: ValidationScreens.ClientArea,
    fields: fields
}
