import { ReportCommentPayload, ReportCommentModel } from "../../model/report.model";
import ApiUrls from "../../../../common/api-urls";
import HttpService from "../../../../common/service/http.service";
import { IACPHTestPayload, IAirChangesPerHourTestModel} from "../../model/cleanroom-area/test/air-changes-per-hour-test.model";
import { IBaseTestPayload } from "../../model/cleanroom-equipment/test/test.model";
import { ICleanroomAreaFilterIntegrityTestModel, ICleanroomAreaFITestPayload } from "../../model/cleanroom-area/test/filter-integrity-test.model";
import { ICleanroomAreaNonViableParticleCountTestModel, ICleanroomAreaNVPCTestPayload } from "../../model/cleanroom-area/test/non-viable-particle-count-test.model";
import { ICleanroomAreaRecoveryTestModel, ICleanroomAreaRecoveryTestPayload } from "../../model/cleanroom-area/test/recovery-test.model";
import { ICleanroomAreaAFPTestPayload, ICleanroomAreaAirflowPatternTestModel } from "../../model/cleanroom-area/test/airflow-pattern-test.model";
import { ICleanroomAreaSLTestPayload,ICleanroomAreaSoundLevelTestModel } from "../../model/cleanroom-area/test/sound-level-test-model";
import { ICleanroomAreaLightIntensityTestModel, ICleanroomAreaLITestPayload } from "../../model/cleanroom-area/test/light-intensity-test.model";
import { ICleanroomAreaUVLightIntensityTestModel, ICleanroomAreaUVLITestPayload } from "../../model/cleanroom-area/test/uv-light-intensity-test.model";
import { ICleanroomAreaDFTestPayload, ICleanroomAreaDifferentialPressureTestModel } from "../../model/cleanroom-area/test/differential-pressure-test.model";

export default class CleanroomAreaTestService {
    static async GetACPHById(payload: IACPHTestPayload): Promise<IAirChangesPerHourTestModel> {
        return await HttpService.Get<IAirChangesPerHourTestModel>(ApiUrls.Report.CleanroomAreaTest.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateACPHTest(payload: IACPHTestPayload): Promise<IAirChangesPerHourTestModel> {
        return await HttpService.Post<IAirChangesPerHourTestModel | null, IAirChangesPerHourTestModel>(ApiUrls.Report.CleanroomAreaTest.Create(payload.testName), payload.data ?? null);
    }

    static async EditACPHTest(payload: IACPHTestPayload): Promise<IAirChangesPerHourTestModel> {
        return await HttpService.Patch<IAirChangesPerHourTestModel | null, IAirChangesPerHourTestModel>(ApiUrls.Report.CleanroomAreaTest.Edit(payload.testName, payload.data?.id ?? 0), payload.data ?? null);
    }

    // ==== common route ====
    static async DeleteTest(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.CleanroomAreaTest.Delete(payload.testName, payload.id ?? 0));
    }

    static async EnableTestEdit(payload: ReportCommentPayload) {
        return await HttpService.Patch<ReportCommentModel, IAirChangesPerHourTestModel>(ApiUrls.Report.CleanroomAreaTest.EnableEdit(payload.testName), payload.data)
    }

    // ==== Filter integrity ===
    static async GetFIById(payload: ICleanroomAreaFITestPayload): Promise<ICleanroomAreaFilterIntegrityTestModel> {
        return await HttpService.Get<ICleanroomAreaFilterIntegrityTestModel>(ApiUrls.Report.CleanroomAreaTest.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateFITest(payload:ICleanroomAreaFITestPayload): Promise<ICleanroomAreaFilterIntegrityTestModel> {
        return await HttpService.Post<ICleanroomAreaFilterIntegrityTestModel | null, ICleanroomAreaFilterIntegrityTestModel>(ApiUrls.Report.CleanroomAreaTest.Create(payload.testName), payload.data ?? null);
    }

    static async EditFITest(payload:ICleanroomAreaFITestPayload): Promise<ICleanroomAreaFilterIntegrityTestModel> {
        return await HttpService.Patch<ICleanroomAreaFilterIntegrityTestModel | null, ICleanroomAreaFilterIntegrityTestModel>(ApiUrls.Report.CleanroomAreaTest.Edit(payload.testName, payload.data?.id ?? 0), payload.data ?? null);
    }

    // === Non Viable Particle Count ===
    static async GetNVPCById(payload: ICleanroomAreaNVPCTestPayload): Promise<ICleanroomAreaNonViableParticleCountTestModel> {
        return await HttpService.Get<ICleanroomAreaNonViableParticleCountTestModel>(ApiUrls.Report.CleanroomAreaTest.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateNVPCTest(payload:ICleanroomAreaNVPCTestPayload): Promise<ICleanroomAreaNonViableParticleCountTestModel> {
        return await HttpService.Post<ICleanroomAreaNonViableParticleCountTestModel | null,ICleanroomAreaNonViableParticleCountTestModel>(ApiUrls.Report.CleanroomAreaTest.Create(payload.testName), payload.data ?? null);
    }

    static async EditNVPCTest(payload:ICleanroomAreaNVPCTestPayload): Promise<ICleanroomAreaNonViableParticleCountTestModel> {
        return await HttpService.Patch<ICleanroomAreaNonViableParticleCountTestModel | null, ICleanroomAreaNonViableParticleCountTestModel>(ApiUrls.Report.CleanroomAreaTest.Edit(payload.testName, payload.data?.id ?? 0), payload.data ?? null);
    }

    static async GetRecoveryTestById(payload: ICleanroomAreaRecoveryTestPayload): Promise<ICleanroomAreaRecoveryTestModel> {
        return await HttpService.Get<ICleanroomAreaRecoveryTestModel>(ApiUrls.Report.CleanroomAreaTest.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateRecoveryTest(payload:ICleanroomAreaRecoveryTestPayload): Promise<ICleanroomAreaRecoveryTestModel> {
        return await HttpService.Post<ICleanroomAreaRecoveryTestModel | null,ICleanroomAreaRecoveryTestModel>(ApiUrls.Report.CleanroomAreaTest.Create(payload.testName), payload.data ?? null);
    }

    static async EditRecoveryTest(payload:ICleanroomAreaRecoveryTestPayload): Promise<ICleanroomAreaRecoveryTestModel> {
        return await HttpService.Patch<ICleanroomAreaRecoveryTestModel | null, ICleanroomAreaRecoveryTestModel>(ApiUrls.Report.CleanroomAreaTest.Edit(payload.testName, payload.data?.id ?? 0), payload.data ?? null);
    }
   
    static async GetAFPTestById(payload:ICleanroomAreaAFPTestPayload): Promise<ICleanroomAreaAirflowPatternTestModel> {
        return await HttpService.Get<ICleanroomAreaAirflowPatternTestModel>(ApiUrls.Report.CleanroomAreaTest.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateAFPTest(payload: ICleanroomAreaAFPTestPayload): Promise<ICleanroomAreaAirflowPatternTestModel> {
        return await HttpService.Post<ICleanroomAreaAirflowPatternTestModel | null, ICleanroomAreaAirflowPatternTestModel>(ApiUrls.Report.CleanroomAreaTest.Create(payload.testName), payload.data ?? null);
    }
    static async EditAFPTest(payload:ICleanroomAreaAFPTestPayload): Promise<ICleanroomAreaAirflowPatternTestModel> {
        return await HttpService.Patch<ICleanroomAreaAirflowPatternTestModel | null, ICleanroomAreaAirflowPatternTestModel>(ApiUrls.Report.CleanroomAreaTest.Edit(payload.testName, payload.data?.id ?? 0), payload.data ?? null);
    }
    static async GetSLTestById(payload:ICleanroomAreaSLTestPayload): Promise<ICleanroomAreaSoundLevelTestModel> {
        return await HttpService.Get<ICleanroomAreaSoundLevelTestModel>(ApiUrls.Report.CleanroomAreaTest.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateSLTest(payload:ICleanroomAreaSLTestPayload): Promise<ICleanroomAreaSoundLevelTestModel> {
        return await HttpService.Post<ICleanroomAreaSoundLevelTestModel | null,ICleanroomAreaSoundLevelTestModel>(ApiUrls.Report.CleanroomAreaTest.Create(payload.testName), payload.data ?? null);
    }

    static async EditSLTest(payload:ICleanroomAreaSLTestPayload): Promise<ICleanroomAreaSoundLevelTestModel> {
        return await HttpService.Patch<ICleanroomAreaSoundLevelTestModel | null,ICleanroomAreaSoundLevelTestModel>(ApiUrls.Report.CleanroomAreaTest.Edit(payload.testName, payload.data?.id ?? 0), payload.data ?? null);
    }

    static async GetLITestById(payload:ICleanroomAreaLITestPayload): Promise<ICleanroomAreaLightIntensityTestModel> {
        return await HttpService.Get<ICleanroomAreaLightIntensityTestModel>(ApiUrls.Report.CleanroomAreaTest.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateLITest(payload:ICleanroomAreaLITestPayload): Promise<ICleanroomAreaLightIntensityTestModel> {
        return await HttpService.Post<ICleanroomAreaLightIntensityTestModel | null,ICleanroomAreaLightIntensityTestModel>(ApiUrls.Report.CleanroomAreaTest.Create(payload.testName), payload.data ?? null);
    }

    static async EditLITest(payload:ICleanroomAreaLITestPayload): Promise<ICleanroomAreaLightIntensityTestModel> {
        return await HttpService.Patch<ICleanroomAreaLightIntensityTestModel | null,ICleanroomAreaLightIntensityTestModel>(ApiUrls.Report.CleanroomAreaTest.Edit(payload.testName, payload.data?.id ?? 0), payload.data ?? null);
    }

    static async GetUVLITestById(payload:ICleanroomAreaUVLITestPayload): Promise<ICleanroomAreaUVLightIntensityTestModel> {
        return await HttpService.Get<ICleanroomAreaUVLightIntensityTestModel>(ApiUrls.Report.CleanroomAreaTest.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateUVLITest(payload:ICleanroomAreaUVLITestPayload): Promise<ICleanroomAreaUVLightIntensityTestModel> {
        return await HttpService.Post<ICleanroomAreaUVLightIntensityTestModel | null,ICleanroomAreaUVLightIntensityTestModel>(ApiUrls.Report.CleanroomAreaTest.Create(payload.testName), payload.data ?? null);
    }

    static async EditUVLITest(payload:ICleanroomAreaUVLITestPayload): Promise<ICleanroomAreaUVLightIntensityTestModel> {
        return await HttpService.Patch<ICleanroomAreaUVLightIntensityTestModel | null,ICleanroomAreaUVLightIntensityTestModel>(ApiUrls.Report.CleanroomAreaTest.Edit(payload.testName, payload.data?.id ?? 0), payload.data ?? null);
    }

    static async GetDFTestById(payload:ICleanroomAreaDFTestPayload): Promise<ICleanroomAreaDifferentialPressureTestModel> {
        return await HttpService.Get<ICleanroomAreaDifferentialPressureTestModel>(ApiUrls.Report.CleanroomAreaTest.GetById(payload.testName, payload.id ?? 0));
    }

    static async CreateDFTest(payload:ICleanroomAreaDFTestPayload): Promise<ICleanroomAreaDifferentialPressureTestModel> {
        return await HttpService.Post<ICleanroomAreaDifferentialPressureTestModel | null,ICleanroomAreaDifferentialPressureTestModel>(ApiUrls.Report.CleanroomAreaTest.Create(payload.testName), payload.data ?? null);
    }

    static async EditDFTest(payload:ICleanroomAreaDFTestPayload): Promise<ICleanroomAreaDifferentialPressureTestModel> {
        return await HttpService.Patch<ICleanroomAreaDifferentialPressureTestModel | null,ICleanroomAreaDifferentialPressureTestModel>(ApiUrls.Report.CleanroomAreaTest.Edit(payload.testName, payload.data?.id ?? 0), payload.data ?? null);
    }
}
