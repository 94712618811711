import React from "react";
import ScreenUrls from "../../../../common/screen-urls";
import { IClientAreaModel } from "../../model/client-area.model";
import { IAppState } from "../../../../store";
import { connect } from "react-redux";
import { loadAreaById, createArea, editArea, clearAreaDataState, updateClientAreaDataState } from "../../store/client-area.slice";
import { Link } from "react-router-dom";
import AsyncSelect from "react-select/async";
import ClientService from "../../service/client.service";
import { DeferredLoadOptions } from "../../../../common/utils";
import { ValidationScreens } from "../../../../common/enums";
import { IStoreSaveModel } from "../../../../common/model/store-save.model";
import DropdownDisplayHelper from "../../../../common/helper/dropdown-display.helper";
import { FormBaseComponent, IFormBaseState } from "../../../../common/component/form-base.component";
import ValidationMessageControl from "../../../../common/control/validation-message.control";

export interface IProps {
    accessLevel: number,
    id?: string,
    dataLoading: boolean,
    createEditLoading: boolean
    clientAreaData: IClientAreaModel,
    loadAreaById: (id: number) => void;
    createArea: (data: IClientAreaModel) => void;
    editArea: (data: IClientAreaModel) => void;
    clearClientAreaDataState: (data?: any) => void;
    updateClientAreaDataState: (payload: IStoreSaveModel) => void;
}

export interface IState extends IFormBaseState {
}

class ClientAreaCreateEditPage extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            hideForm: false
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.ClientArea);
        if (this.props.id != null && Number(this.props.id) > 0) {
            await this.props.loadAreaById(Number(this.props.id));
        } else {
            await this.props.clearClientAreaDataState();
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    loadClientOptions = (input: string) => DeferredLoadOptions(input, ClientService.ClientDropdown);

    render() {

        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="page-header">
                        <div className="page-block">
                            <div className="row">
                                <div className="col-md-12">
                                    <Link to={ScreenUrls.Clients.Area.Index()}
                                        className="btn drp-icon btn-rounded btn-primary dropdown-toggle">
                                        <i className="feather icon-arrow-left"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    {(() => { if (this.props.dataLoading || this.props.createEditLoading) return <div className="loading--bar fixed--top"><span></span></div> })()}
                    {
                        (() => {
                            if (this.state.hideForm) return;
                            return (
                                <div className="main-body">
                                    <div className="page-wrapper">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <article className="card mb-4">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("ahuOrVAUName").className}`}>
                                                                    <label htmlFor="ahuOrVAUName">AHU/VAU Name<span className="requried-span">*</span></label>
                                                                    <input id="ahuOrVAUName" className="form-control" type="text"
                                                                        value={this.props.clientAreaData.ahuOrVAUName ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            this.ValidateField("ahuOrVAUName", e.target.value);
                                                                            this.props.updateClientAreaDataState({ name: "ahuOrVAUName", value: e.target.value });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("ahuOrVAUName").message} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("ahuOrVAUId").className}`}>
                                                                    <label htmlFor="ahuOrVAUId">AHU/VAU Id<span className="requried-span">*</span></label>
                                                                    <input id="ahuOrVAUId" className="form-control" type="text"
                                                                        value={this.props.clientAreaData.ahuOrVAUId ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            this.ValidateField("ahuOrVAUId", e.target.value);
                                                                            this.props.updateClientAreaDataState({ name: "ahuOrVAUId", value: e.target.value });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("ahuOrVAUId").message} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("areaOfTheTest").className}`}>
                                                                    <label htmlFor="areaOfTheTest">Area of The Test<span className="requried-span">*</span></label>
                                                                    <input id="areaOfTheTest" className="form-control" type="text"
                                                                        value={this.props.clientAreaData.areaOfTheTest ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            this.ValidateField("areaOfTheTest", e.target.value);
                                                                            this.props.updateClientAreaDataState({ name: "areaOfTheTest", value: e.target.value });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("areaOfTheTest").message} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("location").className}`}>
                                                                    <label htmlFor="location">Location<span className="requried-span">*</span></label>
                                                                    <input id="location" className="form-control" type="text"
                                                                        value={this.props.clientAreaData.location ?? ""}
                                                                        onChange={e => {
                                                                            e.preventDefault();
                                                                            this.ValidateField("location", e.target.value);
                                                                            this.props.updateClientAreaDataState({ name: "location", value: e.target.value });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("location").message} />
                                                                </div>
                                                            </div>
                                                            <div className="col-3">
                                                                <div className={`form-group ${this.vResult("clientId").className}`}>
                                                                    <label htmlFor="clientId">Client<span className="requried-span">*</span></label>
                                                                    <AsyncSelect id="clientId" cacheOptions defaultOptions
                                                                        className="async-select-control"
                                                                        loadOptions={this.loadClientOptions}
                                                                        value={this.props.clientAreaData.client
                                                                            ? { label: DropdownDisplayHelper.Client(this.props.clientAreaData.client), value: this.props.clientAreaData.client.id.toString() ?? "0" }
                                                                            : { label: "Select Client", value: "" }}
                                                                        onChange={async (e) => {
                                                                            const cId = Number(e?.value);
                                                                            if (cId === null || cId === 0) return;
                                                                            this.ValidateField("clientId", cId);
                                                                            const category = await ClientService.GetById(cId);
                                                                            this.props.updateClientAreaDataState({ name: "client", value: category });
                                                                            this.props.updateClientAreaDataState({ name: "clientId", value: category.id });
                                                                        }} />
                                                                    <ValidationMessageControl message={this.vResult("clientId").message} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </article>

                                                <div className="row">
                                                    <div className="col-12">
                                                        <div className="form-group">
                                                            <button type="button" className="btn btn-primary btn--right"
                                                                disabled={this.props.clientAreaData == null || this.hasError}
                                                                onClick={async e => {
                                                                    e.preventDefault();
                                                                    const error = !this.ValidateForm(this.props.clientAreaData);
                                                                    this.reloadForm();
                                                                    if (error) return;
                                                                    if (this.props.clientAreaData.id > 0) {
                                                                        this.props.editArea(this.props.clientAreaData);
                                                                    } else {
                                                                        this.props.createArea(this.props.clientAreaData);
                                                                    }
                                                                }}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })()
                    }

                </div>
            </div >
        </React.Fragment >
    }
}

const mapStateToProps = (state: IAppState) => ({
    clientAreaData: state.clientArea.areaData,
    dataLoading: state.clientArea.dataLoading,
    createEditLoading: state.clientArea.createEditLoading
})
export default connect(mapStateToProps, {
    loadAreaById,
    createArea,
    editArea,
    clearClientAreaDataState: clearAreaDataState, updateClientAreaDataState
})(ClientAreaCreateEditPage);
