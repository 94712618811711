import React from "react";
import { BaseComponent } from "../../../common/component/base.component";
import { AgGridDefaultColDef } from "../../../common/app-defaults";
import { AgGridReact } from "ag-grid-react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { IAppState } from "../../../store";
import { IReportModel } from "../model/report.model";
import { loadEquipmentReports, deleteEquipmentReport, loadAreaReports, deleteAreaReport } from "../store/report.slice";
import ScreenUrls from "../../../common/screen-urls";
import { AccessLevel, ReportCategories } from "../../../common/enums";

export interface IProps {
    reportList: IReportModel[],
    loadEquipmentReport: () => void,
    deleteEquipmentReport: (id: number) => void,
    loadAreaReport: () => void,
    deleteAreaReport: (id: number) => void,
    listLoading: boolean,
    accessLevel: number,
    refetchReportAPI: boolean,
}

export interface IState {
    reportType: ReportCategories,
}

class ReportPage extends BaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            reportType: ReportCategories.CleanroomEquipment,
        }
    }
    componentDidMount() {
        this.loadReports();
    }

    loadReports = () => {
        if (this.state.reportType == ReportCategories.CleanroomEquipment) {
            this.props.loadEquipmentReport();
        } else if (this.state.reportType == ReportCategories.CleanroomArea) {
            this.props.loadAreaReport();
        }
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<any>, snapshot?: any): void {
        if (prevProps.refetchReportAPI != this.props.refetchReportAPI || this.state.reportType != prevState.reportType) {
            this.loadReports();
        }
    }

    handleReportType = (type: ReportCategories) => {
        if (this.state.reportType != type) {
            this.setState({ reportType: type });
        }
    }

    render() {
        if (this.props.accessLevel >= AccessLevel.View) {

        } else {
            return (<Navigate to={ScreenUrls.AccessDenied()} replace />)
        }
        return <React.Fragment>
            <div className="pcoded-content">
                <div className="pcoded-inner-content">
                    <div className="main-body">
                        <div className="page-wrapper">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="card">
                                        <div className="card-header d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <h5>List of Reports</h5>
                                                <div className="grid-tile-toggle-btn">
                                                    <button
                                                        className={`${this.state.reportType == ReportCategories.CleanroomEquipment ? "active" : ""}`}
                                                        onClick={() =>
                                                            this.handleReportType(ReportCategories.CleanroomEquipment)
                                                        }
                                                    >
                                                        Equipment
                                                    </button>
                                                    <button
                                                        className={`${this.state.reportType == ReportCategories.CleanroomArea ? "active" : ""}`}
                                                        onClick={() =>
                                                            this.handleReportType(ReportCategories.CleanroomArea)
                                                        }
                                                    >
                                                        Area
                                                    </button>
                                                </div></div>
                                            <div className="">
                                                {(() => {
                                                    if (this.props.accessLevel >= AccessLevel.Create) {
                                                        if (this.props.listLoading) return;
                                                        return <><Link className="btn drp-icon btn-rounded btn-primary m-0" to={ScreenUrls.Report.CleanroomEquipment.Create()}>
                                                            Add Equipment Report
                                                        </Link>
                                                            <Link className="btn drp-icon btn-rounded btn-primary m-0 ml-2" to={ScreenUrls.Report.CleanroomArea.Create()}>
                                                                Add Area Report
                                                            </Link>
                                                        </>
                                                    }
                                                })()}
                                            </div>
                                        </div>
                                        {(() => { if (this.props.listLoading) return <div className="loading--bar"><span></span></div> })()}
                                        <div className="grid-wrapper">
                                            <div className="card-block ag-theme-alpine">
                                                <AgGridReact
                                                    pagination={true}
                                                    paginationPageSize={10}
                                                    unSortIcon={true}
                                                    rowHeight={60}
                                                    domLayout="autoHeight"
                                                    defaultColDef={AgGridDefaultColDef}
                                                    columnDefs={[
                                                        {
                                                            hide: this.props.accessLevel < AccessLevel.Edit,
                                                            headerName: "Edit", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link  grid-cell-link",
                                                            suppressMovable: true,
                                                            cellRenderer: (params) => {
                                                                return (
                                                                    <div>
                                                                        <Link to={this.state.reportType == ReportCategories.CleanroomEquipment ? ScreenUrls.Report.CleanroomEquipment.Edit(params.value) : ScreenUrls.Report.CleanroomArea.Edit(params.value)
                                                                        }>
                                                                            <i className={`feather icon-edit`}></i>
                                                                        </Link>

                                                                    </div>
                                                                )
                                                            }
                                                        },
                                                        {
                                                            hide: this.props.accessLevel < AccessLevel.Delete,
                                                            headerName: "Delete", field: 'id', width: 50, cellClass: "grid-cell grid-cell-link  grid-cell-link",
                                                            suppressMovable: true,
                                                            cellRenderer: (params) => {
                                                                return (

                                                                    <span
                                                                        onClick={async () => {
                                                                            if (this.state.reportType == ReportCategories.CleanroomEquipment) {
                                                                                this.props.deleteEquipmentReport(params.value);
                                                                            } else if (this.state.reportType == ReportCategories.CleanroomArea) {
                                                                                this.props.deleteAreaReport(params.value)
                                                                            }
                                                                        }}
                                                                    >
                                                                        <i className={`feather icon-trash text-green f-16`}></i>
                                                                    </span>
                                                                )
                                                            }
                                                        },
                                                        {
                                                            headerName: "Report No", field: "reportNumber", flex: 1, sortable: true, cellClass: "grid-cell",
                                                            suppressMovable: true,
                                                            cellRenderer: (params) => <span>{params.value}</span>
                                                        },
                                                        {
                                                            headerName: "Type", field: "reportCategory", width: 200, sortable: true, cellClass: "grid-cell",
                                                            suppressMovable: true,
                                                            cellRenderer: (params) => <span>
                                                                {params.value?.name}
                                                            </span>
                                                        },
                                                        {
                                                            headerName: "Client", field: "client", flex: 3, sortable: true, cellClass: "grid-cell",
                                                            comparator: (valueA, valueB, nodeA, nodeB, isDescending) => {
                                                                if (valueA?.client?.companyName == valueB?.client?.companyName) return 0;
                                                                return (valueA?.client?.companyName > valueB?.client?.companyName) ? 1 : -1;
                                                            },
                                                            suppressMovable: true,
                                                            cellRenderer: (params) => <span>{params.value?.companyName}</span>
                                                        },
                                                    ]}
                                                    rowData={this.props.reportList}>
                                                </AgGridReact>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    }
}


const mapStateToProps = (state: IAppState) => ({
    reportList: state.report.reportList,
    listLoading: state.report.listLoading,
    refetchReportAPI: state.report.refetchReportAPI,
})

export default connect(mapStateToProps, { loadEquipmentReport: loadEquipmentReports, deleteEquipmentReport, loadAreaReport: loadAreaReports, deleteAreaReport })(ReportPage);
