import ApiUrls from "../../../../common/api-urls";
import HttpService from "../../../../common/service/http.service";
import { ILITestReadingPayload } from "../../model/cleanroom-equipment/test/light-intensity-test.model";
import { IPCObservation, IPCObservationPayload } from "../../model/cleanroom-equipment/test/particle-count-test.model";
import { IRecoveryReadingPayload, IRecoveryReadings } from "../../model/cleanroom-equipment/test/recovery-test.model";
import { ISLRoomReadingModel, ISoundLevelReadingPayload } from "../../model/cleanroom-equipment/test/sound-level-test.model";
import { IRoomReadingModel, IAVTestReadingPayload, IBaseTestPayload } from "../../model/cleanroom-equipment/test/test.model";
import { IUVTestReadingPayload } from "../../model/cleanroom-equipment/test/uv-light-test.model";

export default class ReadingService {
    // ---- av
    static async CreateAVReading(payload: IAVTestReadingPayload): Promise<IRoomReadingModel> {
        return await HttpService.Post<IRoomReadingModel | null, IRoomReadingModel>(ApiUrls.Report.Reading.Create(payload.testName), payload.data ?? null);
    }

    static async EditAVReading(payload: IAVTestReadingPayload): Promise<IRoomReadingModel> {
        return await HttpService.Patch<IRoomReadingModel | null, IRoomReadingModel>(ApiUrls.Report.Reading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetAVReadingById(payload: IAVTestReadingPayload): Promise<IRoomReadingModel> {
        return await HttpService.Get<IRoomReadingModel>(ApiUrls.Report.Observation.GetById(payload.testName, payload.id ?? 0));
    }
    // --- common 
    static async DeleteReading(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.Reading.Delete(payload.testName, payload.id ?? 0));
    }

    // --- LI ---
    static async CreateLIReading(payload: ILITestReadingPayload): Promise<IRoomReadingModel> {
        return await HttpService.Post<IRoomReadingModel | null, IRoomReadingModel>(ApiUrls.Report.Reading.Create(payload.testName), payload.data ?? null);
    }

    static async EditLIReading(payload: ILITestReadingPayload): Promise<IRoomReadingModel> {
        return await HttpService.Patch<IRoomReadingModel | null, IRoomReadingModel>(ApiUrls.Report.Reading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetLIReadingById(payload: ILITestReadingPayload): Promise<IRoomReadingModel> {
        return await HttpService.Get<IRoomReadingModel>(ApiUrls.Report.Observation.GetById(payload.testName, payload.id ?? 0));
    }

    // --- PC ---
    static async CreatePCObservation(payload: IPCObservationPayload): Promise<IPCObservation> {
        return await HttpService.Post<IPCObservation | null, IPCObservation>(ApiUrls.Report.LocationObservation.Create(payload.testName), payload.data ?? null);
    }

    static async EditPCObservation(payload: IPCObservationPayload): Promise<IPCObservation> {
        return await HttpService.Patch<IPCObservation | null, IPCObservation>(ApiUrls.Report.LocationObservation.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetPCObservationById(payload: IPCObservationPayload): Promise<IPCObservation> {
        return await HttpService.Get<IPCObservation>(ApiUrls.Report.LocationObservation.GetById(payload.testName, payload.id ?? 0));
    }

    static async DeletePCObservation(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.LocationObservation.Delete(payload.testName, payload.id ?? 0));
    }

    //  === Recovery Test ==
    static async CreateRecoveryReading(payload: IRecoveryReadingPayload): Promise<IRecoveryReadings> {
        return await HttpService.Post<IRecoveryReadings | null, IRecoveryReadings>(ApiUrls.Report.RecoveryReading.Create(payload.testName), payload.data ?? null);
    }

    static async EditRecoveryReading(payload: IRecoveryReadingPayload): Promise<IRecoveryReadings> {
        return await HttpService.Patch<IRecoveryReadings | null, IRecoveryReadings>(ApiUrls.Report.RecoveryReading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    static async GetRecoveryReading(payload: IRecoveryReadingPayload): Promise<IRecoveryReadings> {
        return await HttpService.Get<IRecoveryReadings>(ApiUrls.Report.RecoveryReading.GetById(payload.testName, payload.id ?? 0));
    }

    static async DeleteRecoveryReading(payload: IBaseTestPayload) {
        await HttpService.Delete(ApiUrls.Report.RecoveryReading.Delete(payload.testName, payload.id ?? 0));
    }

    // --- sound level
    static async CreateSLReading(payload: ISoundLevelReadingPayload): Promise<ISLRoomReadingModel> {
        return await HttpService.Post<ISLRoomReadingModel | null, ISLRoomReadingModel>(ApiUrls.Report.Reading.Create(payload.testName), payload.data ?? null);
    }

    static async EditSLReading(payload: ISoundLevelReadingPayload): Promise<ISLRoomReadingModel> {
        return await HttpService.Patch<ISLRoomReadingModel | null, ISLRoomReadingModel>(ApiUrls.Report.Reading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }

    // --- UVLI level
    static async CreateUVLIReading(payload: IUVTestReadingPayload): Promise<IRoomReadingModel> {
        return await HttpService.Post<ISLRoomReadingModel | null, ISLRoomReadingModel>(ApiUrls.Report.Reading.Create(payload.testName), payload.data ?? null);
    }

    static async EditUVLIReading(payload: IUVTestReadingPayload): Promise<ISLRoomReadingModel> {
        return await HttpService.Patch<ISLRoomReadingModel | null, ISLRoomReadingModel>(ApiUrls.Report.Reading.Edit(payload.testName, payload?.data?.id ?? 0), payload.data ?? null);
    }
}
