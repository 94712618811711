import { LocalStorageKeys } from "../enums";
import { apiResponse } from "../utils";

export default class HttpService {

    private static GetHeaders = (): HeadersInit => {
        const token = localStorage.getItem(LocalStorageKeys.AccessToken.toString())

        if (token == null || token == "") {
            return {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }

        return {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        };
    }

    static async Get<TResp>(url: string): Promise<TResp> {
        const response = await fetch(url, {
            method: "GET",
            headers: HttpService.GetHeaders()
        });
        this.RequestLog(url, response);
        const resp = apiResponse(await response.json());
        return resp as TResp;
    }

    static async PlainPost<TResp>(url: string): Promise<TResp> {
        const response = await fetch(url, {
            method: "POST",
            headers: HttpService.GetHeaders()
        });
        this.RequestLog(url, response);
        const resp = apiResponse(await response.json());
        return resp as TResp;
    }

    static async Post<TReq, TResp>(url: string, data: TReq): Promise<TResp> {
        const response = await fetch(url, {
            method: "POST",
            headers: HttpService.GetHeaders(),
            body: JSON.stringify(data)
        });
        this.RequestLog(url, response);
        const resp = apiResponse(await response.json());
        return resp as TResp;
    }

    static async Patch<TReq, TResp>(url: string, data: TReq): Promise<TResp> {
        const response = await fetch(url, {
            method: "PATCH",
            headers: HttpService.GetHeaders(),
            body: JSON.stringify(data),
        });
        this.RequestLog(url, response);
        const resp = apiResponse(await response.json());
        return resp as TResp;
    }

    static async Delete(url: string): Promise<boolean> {
        const response = await fetch(url, {
            method: "DELETE",
            headers: HttpService.GetHeaders()
        });
        this.RequestLog(url, response);
        const resp = apiResponse(await response.json());
        return resp as boolean;
    }

    static RequestLog(url: string, response: globalThis.Response) {
        if (response.status != 200) console.log(`response for ${url}`, response);
    }
}
