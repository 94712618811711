import React from "react";
import { IFormBaseState, FormBaseComponent, } from "../../../../../../../common/component/form-base.component";
import Modal from 'react-modal';
import { CleanroomAreaTests, ValidationScreens } from "../../../../../../../common/enums";
import { connect } from "react-redux";
import { IAppState } from "../../../../../../../store";
import { clearObservationDataState, updateObservationDataState, createAFPTObservation, editAFPTObservation, createAFPTObservationSmokeFlow, editAFPTObservationSmokeFlow, deleteAFPTObservationSmokeFlow, setDeletdRoomId } from "../../../../../store/cleanroom-area/cleanroom-area-observation.slice";
import ValidationMessageControl from "../../../../../../../common/control/validation-message.control";
import { CleanroomAreaAirflowPatternTestObservationDefault, CleanroomAreaAirFlowPatternTestObservationSmokeFlow } from "../../../../../model/cleanroom-area/defaults/test.default";
import { ICleanroomAreaAFPTestObservationPayload, ICleanroomAreaAFPTestObservationSmokeFlowPayload, ICleanroomAreaAirflowPatternTestModel, ICleanroomAreaAirflowPatternTestObservationModel, ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel } from "../../../../../model/cleanroom-area/test/airflow-pattern-test.model";
import { IBaseTestPayload } from "../../../../../model/cleanroom-equipment/test/test.model";

export interface IProps {
    toggle: boolean,
    handleToggleState: any,
    testData: ICleanroomAreaAirflowPatternTestModel,
    observationData: ICleanroomAreaAirflowPatternTestObservationModel,
    clearObservationDataState: (data: ICleanroomAreaAirflowPatternTestObservationModel) => void,
    updateObservationDataState: (data) => void,
    createObservation: (data: ICleanroomAreaAFPTestObservationPayload) => void,
    editObservation: (data: ICleanroomAreaAFPTestObservationPayload) => void,
    testName: string,
    defaultData?: null | ICleanroomAreaAirflowPatternTestObservationModel,
    editSmokeFlow: (data: ICleanroomAreaAFPTestObservationSmokeFlowPayload) => void,
    createSmokeFlow: (data: ICleanroomAreaAFPTestObservationSmokeFlowPayload) => void,
    deleteSmokeFlow: (data: IBaseTestPayload) => void,
    deletedRoomId?: number,
    setDeletdRoomId: (data: number) => void,
}

export interface IState extends IFormBaseState {
    smokeFlowData: ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel,
    openSmokeFlowSection: boolean,
    editSmokeFlowIndex: number,
}

class TestObservationComponent extends FormBaseComponent<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            smokeFlowData: CleanroomAreaAirFlowPatternTestObservationSmokeFlow,
            hideForm: false,
            openSmokeFlowSection: false,
            editSmokeFlowIndex: -1,
        }
    }

    async componentDidMount() {
        await this.setValidator(ValidationScreens.CleanroomAreaAirFlowPatternTestObservation);
        this.props.clearObservationDataState(this.props.defaultData ?? CleanroomAreaAirflowPatternTestObservationDefault);
    }

    async componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.props.defaultData && this.props.defaultData != prevProps.defaultData) {
            this.props.clearObservationDataState(this.props.defaultData);
        }
    }

    async componentWillUnmount() {
        await this.setValidator(ValidationScreens.None);
    }

    render() {
        return <React.Fragment>
            <Modal
                ariaHideApp={false}
                isOpen={this.props.toggle}
                onRequestClose={this.props.handleToggleState}
            >
                <div className={`d-flex flex-column`} style={{ maxHeight: "90vh" }}>
                    <div className="f-18">
                        Add Observation
                    </div>
                    <div className="row my-2">
                        <div className="col-12">
                            <div className={`form-group ${this.vResult("description").className} suggetion-box d-flex flex-column`}>
                                <label htmlFor="description">Description<span className="requried-span">*</span></label>
                                <input id="description" className="form-control" type="text"
                                    value={this.props.observationData?.description ?? ""}
                                    onChange={e => {
                                        e.preventDefault();
                                        this.ValidateField("description", e.target?.value);
                                        this.props.updateObservationDataState({ name: "description", value: e.target.value })
                                    }} />
                                <ValidationMessageControl message={this.vResult("description").message} />
                            </div>
                        </div>

                        <div className="col-12">
                            <input type="checkbox"
                                className="checkbox--input"
                                checked={this.props.observationData.doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills ?? false}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setTimeout(() => {
                                        this.props.updateObservationDataState({
                                            name: "doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills",
                                            value: this.props.observationData?.doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills == null ? true : !this.props.observationData?.doesTheSmokeFlowsSmoothlyDownwardsFromSupplyGrills
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="isPostVerificationCompleted" className="checkbox--label">
                                Does the smoke flows smoothly downwards from supply grills ?
                            </label>
                        </div>
                        <div className="col-12">
                            <input type="checkbox"
                                className="checkbox--input"
                                checked={this.props.observationData.doesTheSmokeFlowsTowardsTheReturnGrills ?? false}
                                onChange={(e) => {
                                    e.preventDefault();
                                    setTimeout(() => {
                                        this.props.updateObservationDataState({
                                            name: "doesTheSmokeFlowsTowardsTheReturnGrills",
                                            value: this.props.observationData?.doesTheSmokeFlowsTowardsTheReturnGrills == null ? true : !this.props.observationData?.doesTheSmokeFlowsTowardsTheReturnGrills
                                        });
                                    }, 1)
                                }} />
                            <label htmlFor="isPostVerificationCompleted" className="checkbox--label">
                                Does the smoke flows towards the return grills ?
                            </label>
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-12 text-right">
                            <button
                                onClick={(e: any) => {
                                    const error = !this.ValidateForm(this.props.observationData);
                                    this.reloadForm();
                                    if (error) return;
                                    let payload = { ...this.props.observationData, airFlowPatternTestId: this.props.testData?.id ?? 0 }
                                    if (this.props.observationData?.id) {
                                        this.props.editObservation({ testName: this.props.testName, data: payload })
                                    } else {
                                        this.props.createObservation({ testName: this.props.testName, data: payload })
                                    }
                                }}
                                className="text-capitalize f-w-800 border-0 btn-primary btn-sm align-self-end">
                                Submit
                            </button>
                        </div>
                    </div>

                    {
                        this.props?.observationData && this.props.observationData?.id ?
                            <div className="row">
                                <div className="col-12">
                                    <div className="my-2">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                Smoke Flow Details :
                                                {!this.state.openSmokeFlowSection && this.props?.observationData?.smokeFlowDetails?.length < 11 ?
                                                    <button
                                                        className="text-capitalize f-w-800 ml-2 border-0 btn-primary btn-sm align-self-end"
                                                        onClick={() => {
                                                            this.setState({ ...this.state, openSmokeFlowSection: !this.state.openSmokeFlowSection });
                                                        }}>+</button>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        this.state.openSmokeFlowSection &&
                                        <div className="row my-2">
                                            <div className="col-6">
                                                <label htmlFor="lowerPressureGradient">Higher Pressure Gradient
                                                    <span className="requried-span">*</span></label>
                                                <input id="name" className="form-control" type="text"
                                                    value={this.state.smokeFlowData.higherPressureGradient ?? ""}
                                                    onChange={e => {
                                                        e.preventDefault();
                                                        this.ValidateField("", e.target.value);
                                                        this.setState({
                                                            ...this.state,
                                                            smokeFlowData: { ...this.state.smokeFlowData, higherPressureGradient: e.target.value }
                                                        });
                                                    }}
                                                />
                                            </div>
                                            <div className="col-6">
                                                <label htmlFor="lowerPressureGradient">Lower Pressure Gradient
                                                    <span className="requried-span">*</span></label>
                                                <input id="name" className="form-control" type="text"
                                                    value={this.state.smokeFlowData.lowerPressureGradient ?? ""}
                                                    onChange={e => {
                                                        e.preventDefault();
                                                        this.ValidateField("", e.target.value);
                                                        this.setState({
                                                            ...this.state,
                                                            smokeFlowData: { ...this.state.smokeFlowData, lowerPressureGradient: e.target.value }
                                                        });
                                                    }}
                                                />
                                            </div>

                                            <div className="col-12 my-1">
                                                <input type="checkbox"
                                                    className="checkbox--input"
                                                    checked={this.state.smokeFlowData?.doesTheDifferentialPressureIsZeroWhileDoorOpening ?? false}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        setTimeout(() => {
                                                            this.setState({
                                                                ...this.state,
                                                                smokeFlowData: { ...this.state.smokeFlowData, doesTheDifferentialPressureIsZeroWhileDoorOpening: this.state.smokeFlowData?.doesTheDifferentialPressureIsZeroWhileDoorOpening == null ? true : !this.state.smokeFlowData?.doesTheDifferentialPressureIsZeroWhileDoorOpening }
                                                            });
                                                        }, 1)
                                                    }} />
                                                <label htmlFor="isPostVerificationCompleted" className="checkbox--label">
                                                    Does the differential pressure is zero while door opening?
                                                </label>
                                            </div>
                                            <div className="col-12 text-right">
                                                <button
                                                    className="text-capitalize f-w-800 border-0 btn-primary btn-sm"
                                                    onClick={() => {
                                                        let item = { ...this.state.smokeFlowData, observationId: this.props.observationData?.id ?? 0 };
                                                        if (this.state.smokeFlowData?.observationId && this.state.editSmokeFlowIndex > -1) {
                                                            this.props.editSmokeFlow({ testName: this.props.testName, data: item });
                                                        } else {
                                                            this.props.createSmokeFlow({ testName: this.props.testName, data: item });
                                                        }
                                                        this.setState({ ...this.state, openSmokeFlowSection: false, editSmokeFlowIndex: -1, smokeFlowData: CleanroomAreaAirFlowPatternTestObservationSmokeFlow });
                                                    }}
                                                >
                                                    {this.state.editSmokeFlowIndex > -1 ? "Edit" : "Add"}
                                                </button>
                                                <button
                                                    className="text-capitalize f-w-800 border-0 btn-primary btn-sm ml-2"
                                                    onClick={() => {

                                                        this.setState({ ...this.state, openSmokeFlowSection: false, editSmokeFlowIndex: -1, smokeFlowData: CleanroomAreaAirFlowPatternTestObservationSmokeFlow });
                                                    }}>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {
                                        Array.isArray(this.props.observationData?.smokeFlowDetails) && this.props.observationData?.smokeFlowDetails?.length > 0 ?
                                            <div className="">
                                                {this.props.observationData?.smokeFlowDetails?.map((item: ICleanroomAreaAirFlowPatternTestObservationSmokeFlowModel, index: number) => {
                                                    return <div className="row">
                                                        <div className="col-6">Higher Pressure Gradient :{item?.higherPressureGradient ?? ""}</div>
                                                        <div className="col-6">Lower Pressure Gradient :{item?.lowerPressureGradient ?? ""}</div>
                                                        <div className="col-10">
                                                            Does the differential pressure is zero while door opening : {item.doesTheDifferentialPressureIsZeroWhileDoorOpening ? "Yes" : item.doesTheDifferentialPressureIsZeroWhileDoorOpening == false ? "No" : "NA"}
                                                        </div>
                                                        <div className="col-auto">
                                                            <span
                                                                onClick={() => {
                                                                    this.setState({ ...this.state, editSmokeFlowIndex: index, smokeFlowData: item, openSmokeFlowSection: true });
                                                                }}
                                                            >
                                                                <i className={`feather icon-edit f-14  text-green`}></i>
                                                            </span>
                                                            <span
                                                                onClick={() => {
                                                                    this.props.setDeletdRoomId(index);
                                                                    this.props.deleteSmokeFlow({ testName: CleanroomAreaTests.airflowpattern, id: item.id });
                                                                }}
                                                            >
                                                                <i className={`ml-2 feather icon-trash f-14 text-green`}></i>
                                                            </span>
                                                        </div>

                                                        <div className="col-12"><hr /></div>
                                                    </div>
                                                })}
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                            :
                            null
                    }
                </div>
            </Modal>
        </React.Fragment >
    }
}


const mapStateToProps = (state: IAppState) => ({
    observationData: state.cleanroomAreaObservation.observationData,
    dataLoading: state.cleanroomAreaObservation.dataLoading,
    createEditLoading: state.cleanroomAreaObservation.createEditLoading,
    deleteRoomId: state.cleanroomAreaObservation.deletedRoomId,
});
export default connect(mapStateToProps, {
    clearObservationDataState, updateObservationDataState, setDeletdRoomId, createObservation: createAFPTObservation, editObservation: editAFPTObservation, createSmokeFlow: createAFPTObservationSmokeFlow, editSmokeFlow: editAFPTObservationSmokeFlow, deleteSmokeFlow: deleteAFPTObservationSmokeFlow
})(TestObservationComponent);

