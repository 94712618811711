import { ZoneClassificationType, EUGMPZoneClassificationState, CleanroomEquipmentTests, AerosolGeneratorType, AerosolMedium, TestObservationOn, AVTestObservationUnit, ObservationLocationLabel, FilterIntegrityTestAcceptanceCriterias } from "../enums";

export const ReportTests = {
    cleanroomEquipment: [
        ["AirFlow Velocity Test", CleanroomEquipmentTests.airflowvelocity, "api/report/cleanroom/equipment"],
        ["AirFlow Pattern Test", CleanroomEquipmentTests.airflowvelocity],
        ["Light Intensity Test", CleanroomEquipmentTests.lightintensity],
        ["Filter Integrity Test", CleanroomEquipmentTests.filterintegrity],
        ["Sound Level Test", CleanroomEquipmentTests.soundlevel],
        ["Recovery Test", CleanroomEquipmentTests.recovery],
        ["Non Viable Particle Count Test", CleanroomEquipmentTests.nonviableparticlecount],
    ],

}

export const Standards = [
    { value: ZoneClassificationType.ISO.toString(), label: "ISO" },
    { value: ZoneClassificationType.EUGMP.toString(), label: "EUGMP" },
    { value: ZoneClassificationType.ClientSpecification.toString(), label: "Client Specification" },
];

export const ReportTestEquipmentState = [
    { value: EUGMPZoneClassificationState.AtRest.toString(), label: "At Rest" },
    { value: EUGMPZoneClassificationState.InOperation.toString(), label: "In Operation" },
    { value: EUGMPZoneClassificationState.AsBuilt.toString(), label: "As Built" },
];

export const AerosolMediumList = [
    { label: AerosolMedium.PolyAlphaOlefin, value: "0" },
    { label: AerosolMedium.Dioctyl, value: "1" },
    { label: AerosolMedium.Phthalate, value: "2" },
    { label: AerosolMedium.Di2EthylHexylSebacate, value: "3" },
];

export const AerosolGeneratorTypeList = [
    { label: AerosolGeneratorType.HotGenerator, value: "0" },
    { label: AerosolGeneratorType.LaskinTypePolyDisperseAerosolGenerator, value: "1" },
];

export const AVTestTestOnList = [
    {
        label: "Filter Id", value: TestObservationOn.FilterId.toString()
    },
    {
        label: "Grill Id", value: TestObservationOn.GrillId.toString()
    },
];

export const AVTestTestObservationUnitList = [
    {
        label: "FPM", value: AVTestObservationUnit.FPM.toString()
    },
    {
        label: "m/s", value: AVTestObservationUnit.MS.toString()
    },
];

export const EUGMPConsideredParticleSizeList = [
    { label: '0.5µm', value: "0.5µm" },
    { label: '5µm', value: "5µm" },
];


export const ISOConsideredParticleSizeList = [
    // { label: '0.1um', value: "0.1um" },
    // { label: '0.2um', value: "0.2um" },
    // { label: '0.3um', value: "0.3um" },
    { label: '0.5µm', value: "0.5µm" },
    { label: '1µm', value: "1µm" },
    { label: '5µm', value: "5µm" },
];

export const TestObservationLabelList = [
    { label: "Location Name", value: ObservationLocationLabel.LocationName?.toString() },
    { label: "Location Number", value: ObservationLocationLabel.LocationNumber?.toString() },
    { label: "Location Name / Location Number", value: ObservationLocationLabel.LocationNameOrNumber?.toString() },
];


export const getObservationLocationLabel = (num: number) => {
    if (num == ObservationLocationLabel.LocationName) {
        return "Location Name";
    }
    if (num == ObservationLocationLabel.LocationNumber) {
        return "Location Number";
    }
    return "Location Name / Location Number";
}

export const AirChangesPerHourTestInstrumentList = [
    {
        label: "Capture Hood", value: "CaptureHood"
    },
    {
        label: "Anemometer", value: "Anemometer"
    },
];

export const AirChangesPerHourTestObservationlabel = [

    {
        label: "Filter Id", value: "Filter"
    },
    {
        label: "Grill Id", value: "Grill"
    },

]

export const FilterIntegrityTestAcceptanceCriteriaList = [
    {
        label: FilterIntegrityTestAcceptanceCriterias['≤ 0.01%'], value: FilterIntegrityTestAcceptanceCriterias['≤ 0.01%']
    },
    {
        label: FilterIntegrityTestAcceptanceCriterias['< 0.01%'], value: FilterIntegrityTestAcceptanceCriterias['< 0.01%']
    },

]